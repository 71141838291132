import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, IconButton, Button } from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import { getSearchResults } from "../../ducks/actionCreators/searchActionCreator"
import {useNavigate} from "react-router-dom";
import {Loader} from "@drowl/drowl-react-library";
import SearchResults from "../SearchResults/SearchResults";

const AddNewPatient = () => {
    const [searchText, setSearchText] = useState('');
    const [searchClicked, setSearchClicked] = useState(false);
    const dispatch = useDispatch();
    const loaderData = useSelector((state) => state.loader);

    const navigate = useNavigate();

    const data = useSelector((state) => state.search);
    const { search : results } = data;
    // Top search bar
    const searchForText = (e) => {
        e.preventDefault();
        setSearchClicked(true);
        const trimmedSearchText = searchText.trim();
        const searchParams = { 'za_nationalid': trimmedSearchText };
        dispatch(getSearchResults(searchParams))
        // findPatients(searchParams);
    };

    return (
        <div id="search" className="searchWindow" >
            <> {loaderData.loading && <Loader /> } </>
            <h1 className='page-title'>
                Add New Patient
            </h1>
            <br />
            <i>Note: Search by ZA ID Number to check if a patient exists in
                Eagle Intelligent Health before adding a new patient.</i>
            <br />   <br />
            <TextField
                variant='outlined'
                placeholder="Search by ID Number"
                value={searchText}
                className='addFields'
                id='primarySearchBox'
                onChange={(e) => setSearchText(e.target.value)}
                InputProps={{
                    endAdornment:
                        <IconButton
                            aria-label="search"
                            onClick={searchForText}>
                            <SearchIcon />
                        </IconButton>
                }}
            />
            <div>

            </div>

            { searchClicked && ((results && results.length === 0) || results === null) ?
                <>
                    <p  style={{marginTop: "50px"}} >No Patient was found with your search criteria.</p>
                    <Button variant="contained" style={{marginTop: "50px"}} onClick={() => {navigate("/createPatient")} }>Create New Patient</Button>
                </>
                : <div id='results'>
                    <SearchResults dontShowPatients={true}/>
                </div> }
        </div >
    );
}

export default AddNewPatient;

