import React, {useState, useEffect} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Select, TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {MEDICAL_DOCUMENTS_LIST} from "../../utils/constants";
import {createUUID, getUserSubId} from "../../utils";
import {useDispatch, useSelector} from "react-redux";
import {addOrDeleteTelevistDocument, setUploadDocStatus} from "../../ducks/actionCreators/patientOverviewActionCreator";
import {useParams} from "react-router-dom";
import {Storage} from "aws-amplify";
import {Alert} from "@mui/lab";
import {Loader} from "@drowl/drowl-react-library";
import {setLoader} from "../../ducks/actionCreators/loaderActionCreator";


const UploadModal = ({showModal, handleUploadDocClick}) => {

    const [file, setFile] = useState();
    const [docName, setDocName] = useState();
    const [docTitle, setDocTitle] = useState();
    const [error, setError] = useState(false);
    const loader = useSelector((state) => state.loader);
    const patientOverviewData = useSelector(state => state.patientOverview);
    const { upldDocStatus } = patientOverviewData;

    const dispatch = useDispatch();

    const ticketID = useParams();

    useEffect(() => {

        if (upldDocStatus === "success") {
            handleUploadDocClick(false);
            setDocName("");
            setFile("");
            setDocTitle("");
            dispatch(setUploadDocStatus(null));
        }

    }, [upldDocStatus])


    const handleUploadDocuments = async () => {
        try{
            dispatch(setLoader(true));
            const userId = await getUserSubId();
            const uuid =  createUUID();
            if(!docName){
                setError({show: true, message: "Please select a document type to proceed.", type: "error"});
            }
            else if(!file) {
                setError({show: true, message: "Please upload a document to proceed.", type: "error"});
            }
            else {
                const fileType = file.type.split("/")[1];

                if(!docTitle) {
                    setDocTitle(file.name.split(".")[0]);
                }
                let newImgS3loc;
                newImgS3loc = "private/" + "providers/" + userId + "/" + uuid + "/" + docName.replace(/\s/g, '') + "." + fileType;
                try {
                    await Storage.put(newImgS3loc, file);
                } catch (err) {
                    setFile("");
                    setError({
                        show: true,
                        message: "Error trying to upload a document, please try agan later.",
                        type: "error"
                    });
                }

                dispatch(addOrDeleteTelevistDocument(ticketID.ticket, "public/" + newImgS3loc, null, docTitle || file.name.split(".")[0], docName, false));
            }
        } catch(err) {
            dispatch(setLoader(false));
        }

    }

    return (

        <Dialog open={showModal} className="assessmentModal">
            <> {loader.loading && <Loader /> } </>
            <DialogTitle>
                Upload Documents
                <IconButton
                    onClick={()=> handleUploadDocClick()}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                {error && error.show &&  <Alert severity={error.type} style={{width: "94%"}} onClose={() => setError({show:false, message: "", type: ""})}>
                    {error.message}
                </Alert> }
                <Grid container spacing={{ xs: 1, md: 0 }} style={{marginTop: "20px"}}>
                    <Grid item  xs={12} md={2}> Document </Grid>
                    <Grid item xs={12} md={5}>
                        <Select id="gender"
                                labelme="gender"
                                className="form-control genderSelect"
                                onChange={(e) => {  setError(false); setDocName(e.target.value) }}
                                value={docName}
                                style={{ background: 'white', width : "89%" }}
                        >
                            {MEDICAL_DOCUMENTS_LIST.map(x =>  <MenuItem value={x.label} style={{ background: 'white' }} >{x.label}</MenuItem>)}
                        </Select>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <TextField
                                type="file"
                                onChange={() => {
                                    setError(false);
                                    setFile(event.target.files[0]);
                                           if(!docTitle){
                                               const fileName = event.target.files[0].name.split(".")[0];
                                               setDocTitle(fileName)
                                           }
                                           }}
                                       style={{ width : "89%" }}
                            />
                        </Grid>
                </Grid>
                <Grid container spacing={{ xs: 1, md: 0 }} style={{marginTop: "20px"}}>
                    <Grid item  xs={12} md={2}> Title </Grid>
                    <Grid item xs={12} md={10}>
                        <TextField type="text"
                                   value={docTitle}
                                   onChange={() => setDocTitle(event.target.value)}
                                   style={{width: "95%"}}
                        />
                    </Grid>
                </Grid>
                    <div style={{paddingTop: "20px", marginBottom: "25px"}}></div>
            </DialogContent>

            <DialogActions>
                <Button
                        // disabled={true}
                    variant="contained"
                    color="primary"
                    onClick={() => handleUploadDocuments()}>
                    Submit
                </Button>
                <Button variant="contained"
                        onClick={() => handleUploadDocClick()}
                >
                    Cancel
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default UploadModal;
