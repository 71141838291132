import React, { useState } from "react";
import { useSelector } from "react-redux";
import {getChartData} from "../../../utils/utlis";
import {getChartOptions } from "../../../utils/chartConstants";
import "./Charts.css";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    PointElement
} from "chart.js";
import {Line} from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);

export const Charts = () => {
    const statsData = useSelector(state => state.statsReducer);
    const {stats} = statsData;


    return (
        <>
            <br/> <br/>
            {/*<br/> <h2>Telemedicine Visit Graphs </h2>*/}
            <div className="chartConfigContainer">
                <div className="smallChart">
                    <h4>Telemedicine Visits</h4>
                    <div>
                        {/*{(stats && stats.dailyTicketsGraph && Object.keys(stats.dailyTicketsGraph).length > 0) &&*/}
                            <Line
                                options={getChartOptions("line" )}
                                data={getChartData(statsData.stats.dailyTicketsGraph, ["#0fabd2"])}

                            />
                        {/*// }*/}
                    </div>
                </div>
                <div className="smallChart">
                    <h4>Money Earned</h4>
                    <div>
                        {/*{(stats && stats.dailyAmountGraph && Object.keys(stats.dailyAmountGraph).length > 0) &&*/}
                            <Line
                                options={getChartOptions("line" )}
                                data={getChartData(statsData.stats.dailyAmountGraph, ["#0fabd2"])}
                            />
                        {/*}*/}
                    </div>
                </div>
            </div>
        </>

    );
};