import {
    SET_TICKET_INFO,
    SET_DOCTOR_INFO,
    SET_PATIENT_SETTINGS_INFO,
    SET_NEW_COMMENT,
    SET_COMMENTS,
    SET_PROGRESS_NOTE,
    SET_TICKET_ID,
    SET_PATIENT_CHR,
    SET_PRESCRIPTION,
    SET_NOTE,
    UPDATE_PRESCRIPTION_STATUS,
    CREATE_PATIENT_TICKET,
    GET_NOTES,
    NOTE_CREATION_STATUS,
    SET_PRESCRIPTION_LIST,
    SET_TELEDOCS_LIST,
    VIEW_TELMED_DOC,
    SET_DOC_UPLOAD_STATUS,
    GET_SYMPTOMS_HISTORY,
    GET_PATIENT_DOCUMENTS,
    OPEN_PATIENT_DOC_LINK,
    GET_DOC_ADDED_NOTES,
    DOC_NOTE_ADDED,
    NOTIFY_MESSAGE,
    CREATE_PRES_STATUS,
    NOTIFY_MESSAGE_CASE
} from "../actions/patientOverviewActions";
import { setMessage } from "./messageActionCreator";
import { setLoader } from "./loaderActionCreator";
import {Analytics, API, graphqlOperation} from "aws-amplify";
import * as API_SCHEMA_QUERIES from "../../graphql/queries";
import {getCurrentUserToken, getHeader, BASE_URL, getUserSubId} from "../../utils";
import * as API_SCHEMA_MUTATIONS from "../../graphql/mutations";

export const setTicketInfo = (data) => ({ type: SET_TICKET_INFO, payload: data });
export const setDoctorInfo = (data) => ({ type: SET_DOCTOR_INFO, payload: data });
export const setPatientSettingsInfo = (data) => ({ type: SET_PATIENT_SETTINGS_INFO, payload: data });
export const setNewComment = (data) => ({ type: SET_NEW_COMMENT, payload: data });
export const setComments = (data) => ({ type: SET_COMMENTS, payload: data });
export const setProgressNote = (data) => ({ type: SET_PROGRESS_NOTE, payload: data });
export const setTicketId = (data) => ({ type: SET_TICKET_ID, payload: data });
export const setPatientChr = (data) => ({ type: SET_PATIENT_CHR, payload: data });
export const setPrescription = (data) => ({ type: SET_PRESCRIPTION, payload: data});
export const setNotes = (data) => ({ type: SET_NOTE, payload: data});
export const setUpdatePresccriptionStatus = (data) => ({ type: UPDATE_PRESCRIPTION_STATUS, payload: data});
export const setCreateTicketResponse = (data) => ({ type: CREATE_PATIENT_TICKET, payload: data});
export const getPatientNotes = (data) => ({ type: GET_NOTES, payload: data});
export const setNoteCreationStatus = (data) => ({ type: NOTE_CREATION_STATUS, payload: data});
export const setPrescriptionList = (data) => ({
    type: SET_PRESCRIPTION_LIST, payload: data
})
export const setTeleMedDocs = (data) => ({ type: SET_TELEDOCS_LIST, payload: data })
export const viewTelemedDoc = (data) => ({ type: VIEW_TELMED_DOC, payload: data})
export const setUploadDocStatus = (data) => ({ type: SET_DOC_UPLOAD_STATUS, payload: data})
export const setSymptomsHistory = (data) => ({ type: GET_SYMPTOMS_HISTORY, payload: data})
export const setPatientDocuments = (data) => ({ type: GET_PATIENT_DOCUMENTS, payload: data})
export const setPatientDocumentLink = (data) => ({ type: OPEN_PATIENT_DOC_LINK, payload: data})
export const setDoctorAddedNotes = (data) => ({ type: GET_DOC_ADDED_NOTES, payload: data})
export const setDoctorAddedNotesStatus = (data) => ({ type: DOC_NOTE_ADDED, payload: data})
export const notifyDoctorMessage = (data) => ({ type: NOTIFY_MESSAGE, payload: data})
export const messageNotificationOnCase = (data) => ({ type: NOTIFY_MESSAGE_CASE, payload: data})
export const setPrescriptionListSuccess = (data) => ({ type: CREATE_PRES_STATUS, payload: data})

export const getTicketInfo = (ticketId) => async (dispatch) => {
    dispatch(setLoader(true));
    // dispatch(getPatientSettings(""));
    const token = await getCurrentUserToken();
    try {
        const res = await API.graphql(graphqlOperation(API_SCHEMA_QUERIES.getTicketInfo, { token: token, ticketId: ticketId }));

        if (res && res.data && res.data.getTicketInfo &&  res.data.getTicketInfo.status) {
            // const progressNote = res.data.getTicketInfo.ticket.progressNote.replaceAll('%26', '&');
            //
            // console.log(JSON.parse(progressNote), "progressNote");
            await dispatch(getProgressNote(ticketId));
            // await dispatch(getDoctors(res.data.getTicketInfo.ticket));
            await dispatch(getPatientSettings(res.data.getTicketInfo.ticket.owner));
            dispatch(setTicketInfo(res.data.getTicketInfo.ticket));
            //dispatch(setProgressNote(JSON.parse(progressNote)));
            dispatch(setComments(res.data.getTicketInfo.ticket.comments));
            dispatch(setTicketId(res.data.getTicketInfo.ticket._id));
            dispatch(setTeleMedDocs(res.data.getTicketInfo.ticket.documents))
            // dispatch(setPatientChr(res.data.getTicketInfo.ticket.patient_chr))

            dispatch(getPatientNotes(res.data.getTicketInfo.ticket.notes));

            dispatch(setPrescriptionList(res.data.getTicketInfo.ticket.prescriptions))
        } else {
                dispatch(setMessage({text: res.data.getTicketInfo.message, type: "error", show: true}))
            // dispatch(setPatientSettingsInfo({}));
            return;

        }
        dispatch(setLoader(false));
        return res.data.getTicketInfo.status;

    } catch (e) {
        dispatch(setLoader(false));
        dispatch(setPatientSettingsInfo({}));
        dispatch(setTicketInfo({}));
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
    }
}

export const getPatientSettings = (userId) => async (dispatch) => {
    const header = await getHeader();
    const requestOptions = {
        method: 'GET',
        headers: header,
        redirect: 'follow'
    }
    if(userId) {
        try {
            //dispatch(setLoader(true));
            const patientSettingsInfo = await fetch(BASE_URL + "/UserInfo/getUserInfoSettings?other_user_id=" + userId, requestOptions)
                .then((res) => res.json())
                .then(res => res);

            if (patientSettingsInfo && patientSettingsInfo.status) {
                dispatch(setPatientSettingsInfo(patientSettingsInfo))
            } else {
                dispatch(setPatientSettingsInfo(""))
            }
            dispatch(setLoader(false));
        }

         catch(e) {
             dispatch(setLoader(false));
             dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
                 type: "error", show: true}));
         }
    }
    else {
        dispatch(setPatientSettingsInfo(""))
    }
}

const getDoctors = (ticket) => async (dispatch) => {
    dispatch(setLoader(true));
    const token = await getCurrentUserToken();
    try {
        const temp = await API.graphql(graphqlOperation(API_SCHEMA_QUERIES.getDoctorsInGroup, { token: token, groupId: ticket.group }));
        dispatch(setDoctorInfo(temp.data.getDoctorsInGroup.doctors));
        dispatch(setLoader(false));
    } catch (e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
        dispatch(setLoader(false));
    }
}

export const updateProgressNote = (data, ticketId) => async(dispatch) => {
        //var user = await Auth.currentAuthenticatedUser();
    dispatch(setLoader(true));
        const token = await getCurrentUserToken();
        // data.diagnosis = this.state.progressNote.diagnosis;
        let pnStr = JSON.stringify(JSON.stringify(data));
        pnStr = pnStr.replaceAll('&', '%26');
        pnStr = pnStr.substring(1, pnStr.length - 1);

        try {
            await API.graphql(graphqlOperation(API_SCHEMA_MUTATIONS.updateProgressNote, {
                progressNote: {
                    token: token,
                    ticketId: ticketId,
                    progressNote: pnStr
                }
            }));
            dispatch(setLoader(false));
            //dispatch(setProgressNote(pnStr));
        } catch (e) {
            dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
                type: "error", show: true}));
            dispatch(setLoader(false));
        }
}

export const addComment = (ticketId, comment) => async(dispatch) => {
    dispatch(setLoader(true));
    const token = await getCurrentUserToken();
    const userId = await getUserSubId();
    try {
    await API.graphql(graphqlOperation(API_SCHEMA_MUTATIONS.addSessionCommentWithAdditonalUser, {
        token: token,
        ticketId: ticketId,
        comment: comment,
        type: "comment",
        other_user_id: userId
    }));
        dispatch(setLoader(false));
    }
    catch(e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
        dispatch(setLoader(false));
    }

}

export const updatePrescriptions = (ticketId, presc)  => async(dispatch) =>{
    dispatch(setLoader(true));
    const token = await getCurrentUserToken();
    let response = "";

    const prescriptionInput = {
        medications: [presc],
        ticketId: ticketId,
        token: token,
    };
    try {
        response = await API.graphql(graphqlOperation(API_SCHEMA_MUTATIONS.createPrescription, {'prescriptionInput': prescriptionInput}));

        dispatch(setUpdatePresccriptionStatus(response.data.createPrescription.status ? "success" : "error"));
        dispatch(setLoader(false));
        return response;
    } catch(e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
        dispatch(setLoader(false));
    }
}

export const deletePrescription = (ticketId, prescId) => async(dispatch) =>{
    dispatch(setLoader(true));
    const token = await getCurrentUserToken();
    let response = "";

    const prescriptionInput = {
        prescriptionId: prescId,
        ticketId: ticketId,
        token: token,
    };

    try {
        response = await API.graphql(graphqlOperation(API_SCHEMA_MUTATIONS.deletePrescription, prescriptionInput));

        if (response && response.data.deletePrescription.status) {
            //dispatch(updateProgressNote( response.ticket.progressNote,ticketId));
            //await dispatch(subscribeToTicket(ticketId));
            dispatch(getTicketInfo(ticketId));
            await Analytics.record({
                screen: "caseDocDeleted", docType: "rx"
            });
        }

        dispatch(setLoader(false));
        //dispatch(setUpdatePresccriptionStatus(response.data.deletePrescription.status ? "success" : "error" ));
        return response;
    }
    catch(e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
        dispatch(setLoader(false));
    }
}


export const createOrDeleteNote = (ticketId, note, noteId, isDelete, title, signature)  => async(dispatch) =>{
    dispatch(setLoader(true));
    const token = await getCurrentUserToken();
    let response = "";

    const noteInput = {
        ticketId: ticketId,
        token: token,
        title: title,
        note_id: noteId,
        note_content: note,
        delete_note: isDelete,
        imageContent: signature
    };
    try {
        response = await API.graphql(graphqlOperation(API_SCHEMA_MUTATIONS.createOrDeleteNotes, {'noteInput': noteInput}));
        if(response?.data?.createOrDeleteNotes?.status) {

        dispatch(setNoteCreationStatus(response.data.createOrDeleteNotes.status ? "success" : "error"));
        dispatch(setLoader(false));
            if(isDelete) {
                await Analytics.record({
                    screen: "caseDocDeleted", docType: "sick note"
                });
            } else {
                await Analytics.record({
                    screen: "caseDocCreated", docType: "sick note"
                });
            }
        }
        return response;
    } catch(e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
        dispatch(setLoader(false));
    }
}

export const getNotes = (ticketId, noteId)  => async(dispatch) => {
    dispatch(setLoader(true));
        await getCurrentUserToken();
        const header = await getHeader();

        const requestOptions = {
            method: 'GET',
            headers: header,
            redirect: 'follow'
        }
        try {
            const response = await fetch(BASE_URL + `/TeleMedicineDrOwl/getNotes?ticketId=${ticketId}&noteId=${noteId}`, requestOptions).then((res) => res.json()).then(res => res);
            // window.open(response.note, '_blank');
            dispatch(setNotes(response));
            dispatch(setLoader(false));
        } catch(e) {
            dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
    }
}

export const  getPrescription = (ticketId, prescriptionId)  => async(dispatch) => {
    dispatch(setLoader(true));
    await getCurrentUserToken();
    const header = await getHeader();

    const requestOptions = {
        method: 'GET',
        headers: header,
        redirect: 'follow'
    }
    try {
        const response = await fetch(BASE_URL + `/TeleMedicineDrOwl/getPrescription?ticketId=${ticketId}&prescriptionId=${prescriptionId}`, requestOptions).then((res) => res.json()).then(res => res);
        dispatch(setPrescription(response));
        dispatch(setLoader(false));
    } catch(e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
    }
}

export const  getTeleMedDoc = (ticketId, documentId)  => async(dispatch) => {
    dispatch(setLoader(true));
    await getCurrentUserToken();
    const header = await getHeader();

    const userId = await getUserSubId();

    const requestOptions = {
        method: 'GET',
        headers: header,
        redirect: 'follow'
    }
    try {
        const response = await fetch(BASE_URL + `/TeleMedicineDrOwl/getPatientDocument?ticketId=${ticketId}&documentId=${documentId}&other_user_id=${userId}`, requestOptions).then((res) => res.json()).then(res => res);
        if(response && response.status) {
            dispatch(viewTelemedDoc(response));
        }
        dispatch(setLoader(false));
    } catch(e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
    }
}

export const  getProgressNote = (ticketId)  => async(dispatch) => {
    // dispatch(setLoader(true));
    await getCurrentUserToken();
    const header = await getHeader();

    const requestOptions = {
        method: 'GET',
        headers: header,
        redirect: 'follow'
    }
    try {
        const response = await fetch(BASE_URL + `/TeleMedicineDrOwl/getProgressNoteOfTicket?ticketId=${ticketId}`, requestOptions).then((res) => res.json()).then(res => res);

        if(response.status) {
            const progressNote = response.ticket.progressNote.replaceAll('%26', '&');
            dispatch(setProgressNote(JSON.parse(progressNote)));
        }
        dispatch(setLoader(false));
    }
    catch(e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
    }
}

export const getPatientChrOfTicket = (ticketId)  => async(dispatch) => {
    dispatch(setLoader(true));
    try {

        const header = await getHeader();

        const requestOptions = {
            method: 'GET',
            headers: header,
            redirect: 'follow'
        }
        const response = await fetch(BASE_URL + "/TeleMedicineDrOwl/getPatientChrOfTicket?ticketId=" + ticketId, requestOptions);
        const chr = await response.json();
        if (chr && chr.status) {
            dispatch(setPatientChr(chr.ticket.patient_chr))
        } else {
            dispatch(setMessage({ text: chr.message, type: "error", show: true }));
        }
        dispatch(setLoader(false));
        // set state
    } catch (e) {
        dispatch(setLoader(false));
        dispatch(setMessage({ message: 'Snapshot ' + e ?? "Unable to get patients' snapshot.", type: "error", show: true }));
    }
}

export const getPatientSnapshotCHR = (userId) => async (dispatch) => {
    dispatch(setLoader(true));
    try {
        const header = await getHeader();

        const requestOptions = {
            method: 'GET',
            headers: header,
            redirect: 'follow'
        }
        const response = await fetch(BASE_URL + "/UserInfo/getChr?id=" + userId, requestOptions);
        const chr = await response.json();
        if (chr && chr.status) {
            dispatch(setPatientChr(chr.CHR))
        } else {
            dispatch(setMessage({ text: chr.message, type: "error", show: true }));
        }
        dispatch(setLoader(false));
        // set state
    } catch (e) {
        dispatch(setLoader(false));
        dispatch(setMessage({ message: 'Snapshot ' + e ?? "Unable to get patients' snapshot.", type: "error", show: true }));
    }

};


export const getSymptomsHistory = (userId) => async (dispatch, getState) => {

    dispatch(setLoader(true));
    try {
        const header = await getHeader();
        const requestOptions = {
            method: 'GET',
            headers: header,
            redirect: 'follow'
        }
        const response = await fetch(BASE_URL + "/TeleMedicineDrOwl/getSymptomsHistory?other_user_id=" + userId, requestOptions);
        const res = await response.json();
        dispatch(setSymptomsHistory(res.symptomHistory));
        dispatch(setLoader(false));
        // set state
    } catch (e) {
        dispatch(setLoader(false));
        dispatch(setMessage({ message: 'Snapshot ' + e ?? "Unable to get patients' snapshot.", type: "error", show: true }));
    }
}

export const getPatientDocuments = (userId) => async (dispatch) => {

    dispatch(setLoader(true));
    try {
        const header = await getHeader();
        const requestOptions = {
            method: 'GET',
            headers: header,
            redirect: 'follow'
        }
        const response = await fetch(BASE_URL + "/UserInfo/getUserAddedDocuments?id=" + userId, requestOptions);
        const res = await response.json();
        dispatch(setPatientDocuments(res.user_documents));
        dispatch(setLoader(false));
        // set state
    } catch (e) {
        dispatch(setLoader(false));
        dispatch(setMessage({ message: 'Snapshot ' + e ?? "Unable to get patients' snapshot.", type: "error", show: true }));
    }
}

export const openPatientDocument = (url,userId) => async (dispatch) => {
    dispatch(setLoader(true));
    try {
        const header = await getHeader();
        const requestOptions = {
            method: 'GET',
            headers: header,
            redirect: 'follow'
        }
        const response = await fetch(BASE_URL + "/UserInfo/getDocument?url=" + url + "&other_user_id=" + userId, requestOptions);
        const res = await response.json();
        if(res.status && res.url) {
            dispatch(setPatientDocumentLink(res.url));
        }
        dispatch(setLoader(false));
        // set state
    } catch (e) {
        dispatch(setLoader(false));
        dispatch(setMessage({ message: 'Snapshot ' + e ?? "Unable to get patients' snapshot.", type: "error", show: true }));
    }
}

// quickly create a ticket for the patient
export const createTicketForPatient =  (userId) => async (dispatch) => {
    dispatch(setLoader(true));
    const token =  await getCurrentUserToken();
    const provId = await getUserSubId();
    try {
       const response = await API.graphql(graphqlOperation(API_SCHEMA_MUTATIONS.createScheduledSession, { token: token, user_id: userId.userId, doctor_id: provId, start_time: "", end_time: "" }));
       dispatch(setCreateTicketResponse(response));
        dispatch(setLoader(false));
    } catch (e) {
        dispatch(setCreateTicketResponse(e));
        dispatch(setLoader(false));
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
    }
}


export const createPrescriptionList = (ticketId, prescInput, signature) => async(dispatch) => {
    console.log("call check")
    dispatch(setLoader(true));
    const token = await getCurrentUserToken();
    try {

        const createPrescription = {
            "imageContent" :  signature,
            "medications": prescInput,
            "token": token,
            "ticketId" : ticketId,
        }

        const resp = await API.graphql(graphqlOperation(API_SCHEMA_MUTATIONS.createPrescription, {'createPrescriptionInput': createPrescription}));
        if(resp?.data?.createPrescription){
            const progressNote = resp.data.createPrescription.ticket.progressNote.replaceAll('%26', '&');
            dispatch(setPrescriptionList(resp.data.createPrescription.ticket.prescriptions))
            dispatch(updateProgressNote(JSON.parse(progressNote), ticketId));
            dispatch(setPrescriptionListSuccess(false));
            // await Analytics.record({
            //     screen: "caseDocCreated", docType: "rx"
            // });
            dispatch(setLoader(false));
        }}

    catch (e) {
        dispatch(setPrescriptionListSuccess(false));
        dispatch(setLoader(false));
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
    }
}

export const addOrDeleteTelevistDocument = (ticketId,s3Location,documentId,title,documentType, isDeleteDocument, isDelete) => async(dispatch) => {
    // dispatch(setLoader(true));
    const userToken = await getCurrentUserToken();
    let response = "";

    let addOrDeleteTelevistDocumentInput;
    if(isDelete === "delete") {
        addOrDeleteTelevistDocumentInput = {
            ticketId: ticketId,
            token: userToken,
            documentId: documentId,
            deleteDocument: isDeleteDocument,
        }
    } else {
         addOrDeleteTelevistDocumentInput = {
             // documentId: documentId,
             ticketId: ticketId,
             token: userToken,
             s3Location: s3Location,
             title: title,
             documentType: documentType,
             deleteDocument: isDeleteDocument,
         }
    }

    try {
        response = await API.graphql(graphqlOperation(API_SCHEMA_MUTATIONS.addOrDeleteTelevistDocument, {'addDocumentInput': addOrDeleteTelevistDocumentInput}));
        if(response && response.data.addOrDeleteTelevistDocument.status){
            if(isDelete !== "delete"){
                dispatch(setUploadDocStatus("success"));
            }
            await dispatch(getTicketInfo(ticketId));
            if(isDelete === "delete") {
                await Analytics.record({
                    screen: "caseDocDeleted", docType: "document"
                });
            } else {
                await Analytics.record({
                    screen: "caseDocCreated", docType: "document"
                });
            }

        }else {
            dispatch(setUploadDocStatus("error"));
        }
        dispatch(setLoader(false));
        return response;
    } catch(e) {
        dispatch(setUploadDocStatus("error"));
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
        dispatch(setLoader(false));
    }
}


export const getDocNote = (patientId,limit,sessionTime) => async(dispatch) => {
    dispatch(setLoader(true));
    const token = await getCurrentUserToken();
    try {
        const apiUrl = BASE_URL+"/TeleMedicineDrOwl/getPatientTimelineNotes?patient_id=" +patientId+ "&limit=" +limit + "&last_session_end_time=" +sessionTime
        fetch(apiUrl, {
            "method": "GET",
            "headers": {
                "content-type": "application/json",
                "token": token
            },
        }).then(res => res.json()).then(async (response) => {
            if(response?.status) {
                dispatch(setDoctorAddedNotes(response.notes));
            }
            dispatch(setLoader(false));
        }).catch((e) => {
            dispatch(setLoader(false));
            dispatch(setMessage({text: "Something went wrong, Please try again later!",
                type: "error", show: true}));
        })
    }
    catch (e) {
        dispatch(setLoader(false));
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
    }
}

export const addNewDocNote = (patientId,note,deleteNote) => async(dispatch) => {
    dispatch(setLoader(true));
    const token = await getCurrentUserToken();
    try {
        const apiUrl = BASE_URL+"/TeleMedicineDrOwl/addorUpdatePatientTimelineNote?patient_id="+patientId+"&note="+note+"&delete_note="+deleteNote
        fetch(apiUrl, {
            "method": "POST",
            "headers": {
                "content-type": "application/json",
                "token": token
            },

        }).then(res => res.json()).then(async (response) => {
            if(response?.status)  {
                dispatch(getDocNote(patientId, 1000, ""));
            }
            dispatch(setDoctorAddedNotesStatus(response?.status));
            dispatch(setLoader(false));
        }).catch((e) => {
            dispatch(setLoader(false));
            dispatch(setMessage({text: "Something went wrong, Please try again later!",
                type: "error", show: true}));
        })
    }
    catch (e) {
        dispatch(setLoader(false));
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
    }
}