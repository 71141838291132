import React, {useEffect, useState} from 'react';
import {changeTicketAssignee, getProviders, setChangeAsigneeStatus} from "../../ducks/actionCreators/caseActionCreator";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, CircularProgress, Grid, TextField} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {Autocomplete} from "@mui/lab";
import {CaseStatues} from "../PatientOverview/PatientOverview";

const ProviderList = ({updateStatus}) => {

    const [provider, setProvider] = useState('');
    const [providerLoading, setProviderLoading] = useState(false);
    const patientOverviewData = useSelector(state => state.patientOverview);
    let { ticket } = patientOverviewData;

    const providerData = useSelector(state => state.case);
    const loader = useSelector((state) => state.loader);

    let { providers, providerChangeStatus } = providerData;
    providers ||= [];
    //
    // ticket || = {};

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProviders());
    },[]);

    useEffect(() => {
        console.log(ticket?.status, "status")
        if (ticket && ticket.status === CaseStatues.ASSESSING) {
            const curProvider = providers.find(el => `${el.name} ${el.family_name}` === ticket.assignee)
            if (curProvider) setProvider(curProvider);
        }
    },[ticket?.status]);

    useEffect(() => {
        if(providerChangeStatus === "success" || providerChangeStatus === "error") {
            setProviderLoading(false);
            dispatch(setChangeAsigneeStatus(""));
        }
    }, [providerChangeStatus])

    //
    //
    // // init provider select
    useEffect(() => {
        if (ticket && Object.keys(ticket).length > 0) {
            if (ticket.assignee.length !== 0 && !provider && provider.length === 0 && providers && providers.length > 0) {
                const curProvider = providers.find(el => `${el.name} ${el.family_name}` === ticket.assignee)
                if (curProvider) setProvider(curProvider);
            }
        }
    }, [providers]);


    const handleProviderChange =  (value) => {
        setProviderLoading(true);
        const previousProvider = provider;

        try {
            dispatch(changeTicketAssignee(value.id, ticket._id));
            setProvider(value);
            // if(updateStatus) {
            //     updateStatus('Closed');
            // }
            // setProviderLoading(false);
        } catch (e) {
            setProvider(previousProvider);
            setProviderLoading(false);
            console.error(e);
        }
    };

    return ( <Grid container item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"

              
        >
            <Grid item style={{ textAlign: 'start' }} xs={6}>
                <div className="caseLabel">Provider:</div>
                   <Autocomplete
                       // style={{marginRight: "10px"}}
                       className="providerFieldWidth"
                       disabled={ticket.status === 3}
                       isOptionEqualToValue={(option,value) => {
                           return option.id === value.id;
                       }}
                       getOptionLabel={option => `${option.name ?? ''} ${option.family_name ?? ''}`}
                       options={providers}
                       loading={providers && providers.length === 0}
                       // loadingText="Loading ..."
                       value={provider}
                       onChange={(_event, newValue) => {
                           handleProviderChange(newValue)
                       }}
                       renderOption={(props, option, { selected }) => (
                           <li {...props} >
                               <>
                                   <Avatar className='doctorImage' alt={option.family_name} src={option.image} />
                                   {`${option.name} ${option.family_name}`}
                               </>
                           </li>
                       )}
                       renderInput={(params) => (
                           <TextField

                               // variant="standard"
                               {...params}
                               // label="Provider"
                               // InputProps={{
                               //     ...params.InputProps,
                               //     endAdornment: (
                               //         <>
                               //             <ArrowDropDownIcon color="inherit" size={20} />
                               //         </>
                               //     ),
                               // }}
                           />
                       )}
                   />

            </Grid>
            <Grid item xs={2}>
                {providerLoading && provider ?
                    <CircularProgress style={{ margin: 'auto' }} size={24} />
                    :
                    <CheckCircleOutlineIcon style={{ color: '#99d590' }} />
                }
            </Grid>
        </Grid>
    ) }

export default ProviderList;