import React from 'react';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';
import ProviderList from "../Case/ProvidersList";

const ClosedStatusConfirmationModal = ({ open, close, confirm }) => {
    return (
        <Dialog
            open={open}
            onClose={(_, reason) => {
                        if (reason !== "backdropClick") {
                            close();
                        }
                    }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle>
                <b>Ticket Closed. Please confirm Provider.</b>
            </DialogTitle>
            <DialogContent>
                <div style={{marginTop: "20px", marginBottom: "20px"}}>
                </div>
                <ProviderList />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={close} >
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ClosedStatusConfirmationModal;