import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import React from "react";
import {Avatar, IconButton} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {green, red, yellow} from "@mui/material/colors";
import WarningIcon from "@mui/icons-material/Warning";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import {useNavigate} from "react-router-dom";


const TableMobile = ({columnHeaders, rowHeaders, rows, rowsPerPage, page, rowClick}) => {
    const navigate = useNavigate();
    return (
        <Table>
            {/* No Data  */}
            {rows && rows.length === 0 &&
                <TableRow>
                    <TableCell colSpan={6} align='center'>No data to display</TableCell>
                </TableRow>
            }

            {(rows && rowsPerPage > 0
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rows
            ).map((row, index) => {
                return ( <TableBody key={row.id || index} className="mobileTableRow"
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 },
                                        //     "&:nth-child(even) td" : {
                                        //     backgroundColor: "#3daf2c"
                                        // },
                                        "&:nth-child(odd) td" : {
                                            backgroundColor: "rgba(238,249,243,.8300000000000001)"
                                        }
                                    }}

                >
                    {columnHeaders.map((col, ind) => {
                        return (
                            <TableRow onClick={rowClick ? () => { navigate(`/doctorStats/${row.id}`)} : null}>
                                <TableCell variant='head'> {col} </TableCell>
                                <TableCell>
                                    <span style={{display: "inline-block"}}>{(rowHeaders[ind] === "doctor") && <Avatar src="" sx={{width: "20px", height: "20px", marginRight: "10px"}}/>}</span>
                                    <span style={{display: "inline-block"}}>{
                                        (rowHeaders[ind] === "user_reg_status") && row[rowHeaders[ind]] === "approved" && <IconButton> <CheckCircleIcon style={{ color: green[500] }}/></IconButton>}
                                        {(rowHeaders[ind] === "user_reg_status") && row[rowHeaders[ind]] === "pending" && <IconButton> <WarningIcon style={{ color: yellow[700] }}/></IconButton>}
                                        {(rowHeaders[ind] === "user_reg_status") && row[rowHeaders[ind]] === "denied" && <IconButton> <DoNotDisturbIcon style={{ color: red[500] }}/></IconButton>}
                                        {/*{(rowHeader === "status") && <Avatar src="" />}*/}
                                        {/*    <span style={{padding: "10px"}}> {row[rowHeader]} </span>*/}
                                            </span>
                                    {(rowHeaders[ind] === "doctor") && <span style={{marginLeft: "10px"}}>{`${row.name} ${row.family_name}`} </span> }
                                    {(rowHeaders[ind] === "name") && <span style={{marginLeft: "10px"}}>{`${row.first_name} ${row.last_name}`} </span> }
                                    {(rowHeaders[ind] !== "name" && rowHeaders[ind] !== "doctor") && <span style={{marginLeft: "10px"}}>{`${row[rowHeaders[ind]]}`} </span> }
                                    {/*{row[rowHeaders[ind]]}*/}
                                </TableCell>
                            </TableRow>)
                    })}
                </TableBody>)

            })}
        </Table>
    )
}

export default TableMobile;