import { Auth, Storage } from 'aws-amplify';

export const getCurrentAuthUser = async () => {
    return Auth.currentAuthenticatedUser();
}

export const strgContentType = (imgnm, imgfl) => {
    return Storage.put(imgnm, imgfl, {
        contentType: 'image/png'
      })
}
export const convertDate = (data) => {
    if (data === null || data === "") {
        return "";
    }
    if (data === undefined) {
        return ""
    }
    if (typeof data === 'string' && data.indexOf("-") !== -1) {
        return data;
    }
    if (Number(data)) {
        data = (new Date(data)).toLocaleDateString();
    }

    if (typeof data === "object") {
        const newDt = data.$numberLong.trim();
        data = new Date(Number(newDt)).toLocaleDateString();
    }

    if (typeof data === "object") {
        const newDt = data.$numberLong.trim();
        data = new Date(Number(newDt)).toLocaleDateString();
    }

    const dob = data.split("/");
    const month = dob[0];
    const day = dob[1];
    const year = dob[2];
    const result = year + "-" + month + "-" + day;
    return result;
}

export const convertCsDte = (data) => {

    if (data === null || data === "") {
        return "";
    }
    if (data.indexOf("-") !== -1) {
        return data;
    }

    let date = new Date(parseInt(data));
    let month = '' + (date.getMonth() + 1);
    let day = '' + (date.getDate());
    let year = date.getFullYear();
    let timeHour = (date.getHours() < 10 ? '0' : '') + date.getHours();
    let timeMinute = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    let formatDate = `${[year, month, day].join('-')} ${timeHour}:${timeMinute}`;

    return formatDate;
}

// const formatDate = (argdate) => {
//     let month = '' + (argdate.getMonth() + 1);
//     let day = '' + argdate.getDate();
//     let year = argdate.getFullYear();

//     if (month.length < 2) month = '0' + month;
//     if (day.length < 2) day = '0' + day;

//     return [year, month, day].join('-');
// }


export const getButtonForStatus = (status) => {
    let caseBtnClass = "";
    let caseStatusBtntext = "";
    let caseStatus = status;
    switch (caseStatus) {
        case 0:
            caseBtnClass = "caseOpen";
            caseStatusBtntext = "Open";
            break;

        case 1:
            caseBtnClass = "caseInprogress";
            caseStatusBtntext = "InProgress";
            break;

        case 2:
            caseBtnClass = "caseScheduled";
            caseStatusBtntext = "Scheduled";
            break;
        case 4:
            caseBtnClass = "caseAssessing";
            caseStatusBtntext = "Assessing";
            break;
        case 5:
            caseBtnClass = "caseWaitingForGp";
            caseStatusBtntext = "Waiting for Gp";
            break;
        default:
            caseBtnClass = "caseClosed";
            caseStatusBtntext = "Closed";
    }
    return {caseBtnClass, caseStatusBtntext}
}

// Format the users Date of Birth
export const formatDateOfBirth = (date) => {
    let mmDDYY = '';
    if (date.indexOf('-') !== -1) {
        const splitDate = date.split('-');
        mmDDYY = new Date(splitDate[1] + '-' + splitDate[2] + '-' + splitDate[0]);
    } else {
        mmDDYY = new Date(date);
    }

    const year = mmDDYY.getFullYear();

    let month = (1 + mmDDYY.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = (mmDDYY.getDate()).toString();
    day = day.length > 1 ? day : '0' + day;

    return day + '-' + month + '-' + year;
}


// Format the users Date with Slashes
export const formatDateSlashes = (date) => {
    if (!date) return date;

    let mmDDYY = '';
    if (date instanceof Date) {
        mmDDYY = date;
    } else if (date.indexOf('-') !== -1) {
        const splitDate = date.split('-');
        mmDDYY = new Date(splitDate[1] + '-' + splitDate[2] + '-' + splitDate[0]);
    } else {
        mmDDYY = new Date(date);
    }

    const year = mmDDYY.getFullYear();

    let month = (1 + mmDDYY.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = (mmDDYY.getDate()).toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
}

// Format the users Date with international format YYYY-MM-DD
export function formatDateInternational(date) {
    if (!date) return date;

    let mmDDYY = '';
    if (date instanceof Date) {
        mmDDYY = date;
    } else {
        mmDDYY = new Date(date);
    }

    const year = mmDDYY.getFullYear();

    let month = (1 + mmDDYY.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    let day = (mmDDYY.getDate()).toString();
    day = day.length > 1 ? day : '0' + day;

    return year + '-' + month + '-' + day;
}

// Format the users Date with international format YYYY-MM-DD while typing
export function formatDateInternationalTyping(date) {

    if (date.length === 0) {
        return '';
    }
    const cleaned = ('' + date).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return '';

}

export const validatePassword = (previousPassword, password) => {
    let regExp = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
    const isPasswordValid = regExp.test(password);
    if (previousPassword.length < 3 && password.length < 3) {
        return "Please enter valid password values";
    } else if (!isPasswordValid) {
        return "Password requirements not met";
    }
    return true;
};

export const validateConfirmPassword = (password, confirmPassword) => {
    if (password !== confirmPassword) {
        return "Password and confirm password do not match";
    } else {
        return validatePassword(confirmPassword, password);
    }
};

//Format phone numer +1 (xxx) xxx - xxxx
export function formatPhoneNumber(phoneNumberString) {
    if (phoneNumberString.length === 0) {
        return '';
    }
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return '';
}


export const validateSAIdNumber = (idNumber) => {
    idNumber = idNumber.toString().replace(" ","");
    let correct = true;
    let error = "";
    if (idNumber.length !== 13) {
        error = "ID number does not appear to be authentic - input not a valid number";
        correct = false;

        return {
            isValid: correct,
            error: error,
        };
    }
    else {
        // get first 6 digits as a valid date
        const tempDate = new Date(idNumber.substring(0, 2), idNumber.substring(2, 4) - 1, idNumber.substring(4, 6));

        const id_date = tempDate.getDate();
        const id_month = tempDate.getMonth();
        const id_year = tempDate.getFullYear();

        const fullDate = id_date + "-" + (id_month + 1) + "-" + id_year;

        if (!((tempDate.getYear() == idNumber.substring(0, 2)) && (id_month == idNumber.substring(2, 4) - 1) && (id_date == idNumber.substring(4, 6)))) {
            error = "ID number does not appear to be authentic - date part not valid";
            correct = false;
        }

        const genderCode = idNumber.substring(6, 10);
        const gender = parseInt(genderCode) < 5000 ? "Female" : "Male";

        // get country ID for citzenship
        const citizenship = parseInt(idNumber.substring(10, 11)) == 0 ? "Yes" : "No";

        return {
            isValid: correct,
            error: error,
            dob: fullDate,
            gender: gender,
            citizenship: citizenship
        };
    }


}


export const convertData = (data) => {
    let out = [];
    for (let key in data) {
        out.push({
            label: key,
            value: data[key]
        });
    }
    // out.sort(function (x, y) {
    // 	return y.value - x.value;
    // });

    return out;
};

export const getChartData = (chartData, colorRange) => {
    const convertedChartData = convertData(chartData);
    return {
        labels: convertedChartData.map(x => x.label),
        datasets: [
            {
                backgroundColor: colorRange,
                borderColor: colorRange,
                borderWidth: 1,
                data: convertedChartData.map(x => x.value)
            }
        ]
    };
};