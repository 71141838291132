import React, {useState, useEffect} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Grid} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
    updateProgressNote
} from "../../ducks/actionCreators/patientOverviewActionCreator";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import {Loader} from "@drowl/drowl-react-library";

const MedicationsModal = ({handleAddMedications, showAddMedications, filterOptionsMed, medOptions, isClosed}) => {

    const [medications, setMedications] = useState({
        medication: "",
        dosage: "",
        instructions: "",
        numPills: "",
        numRefills: ""
    });
    const [assessmentMedications, setAssessmentMedications] = useState([]);

    // const [showAddMedications, setShowAddMedications] = useState(false);
    const loaderData = useSelector((state) => state.loader);
    const ticketID = useParams();
    const dispatch = useDispatch();
    const patientOverviewData = useSelector(state => state.patientOverview);

    let { ticket, progressNote} = patientOverviewData;

    useEffect(() => {
        setAssessmentMedications(progressNote && progressNote.Medications || [])
    }, [progressNote && progressNote.Medications]);


    const updatedAssessmentMedications = (updatedData) => {
        dispatch(updateProgressNote(updatedData, ticket._id));
    };

    const handleActionAddMedButtonClick = async() => {
        const updatedData = [...assessmentMedications, {...medications}];
        let assessmentUpdate = {
            ...progressNote,
            'Medications': updatedData,
        };

        try {
            await updatedAssessmentMedications(assessmentUpdate);
            handleAddMedications();
        } catch (e) {
            console.error(e);
            return;
        }
    }

    return (
        <Dialog open={showAddMedications} className="assessmentModal">
            <> {loaderData.loading && <Loader /> } </>
            <DialogTitle>
                Add Medication
                <IconButton
                    onClick={()=> handleAddMedications()}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>

                {/*{noteError &&  <Alert severity="error" style={{width: "94%"}} onClose={() => setNoteError(false)}>*/}
                {/*    Please fill in the fields to continue.*/}
                {/*</Alert> }*/}

                <div>
                    <div className='assessmentModal-formGroup'>
                        <div className='assessmentModal-fieldLabel'>Medication:</div>
                        <Autocomplete
                            disabled={isClosed}
                            style={{ width: '50%' }}
                            freeSolo
                            options={medOptions}
                            filterOptions={filterOptionsMed}
                            value={medications.medication}
                            getOptionLabel={(option) => option}
                            onBlur={(event) => setMedications({...medications, medication : event.target.value})}
                            renderInput={(params) => <TextField {...params} className='progressNoteTextField' variant="outlined" />}
                        />
                    </div>
                    <div className='assessmentModal-formGroup'>
                        <div className='assessmentModal-fieldLabel'>Dosage:</div>
                        <TextField
                            disabled={isClosed}
                            style={{ width: '50%' }}
                            variant="outlined"
                            value={medications.dosage}
                            onChange={(event) => setMedications({...medications, dosage : event.target.value})}
                        />
                    </div>
                    <div className='assessmentModal-formGroup'>
                        <div className='assessmentModal-fieldLabel'>Quantity:</div>
                        <TextField
                            disabled={isClosed}
                            style={{ width: '50%' }}
                            variant="outlined"
                            value={medications.numPills}
                            onChange={(event) => setMedications({...medications, numPills : event.target.value})}
                        />
                    </div>
                    <div className='assessmentModal-formGroup'>
                        <div className='assessmentModal-fieldLabel'>Notes:</div>
                        <TextField
                            disabled={isClosed}
                            style={{ width: '50%' }}
                            variant="outlined"
                            value={medications.instructions}
                            minRows={5}
                            multiline={true}
                            onChange={(event) =>setMedications({...medications, instructions : event.target.value})}
                        />
                    </div>
                    {/*<div className='assessmentModal-formGroup'>*/}
                    {/*    <div className='assessmentModal-fieldLabel'>Digital Signature:</div>*/}
                    {/*    <SignaturePad />*/}
                    {/*</div>*/}

                </div>

                <div style={{marginBottom: "40px"}} />
            </DialogContent>

            <DialogActions>
                {!isClosed &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleActionAddMedButtonClick()}>
                        Add
                    </Button>
                }
                <Button variant="contained" onClick={() => handleAddMedications()}>
                    Cancel
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default MedicationsModal;
