import React, {useEffect} from "react";
import { Navigate } from "react-router-dom";
import {useUserRole} from "../hooks/useUserRole";
import {useDispatch, useSelector} from "react-redux";
import {isUserLoggedIn} from "../ducks/actionCreators/loginActionCreator";
import Unauthorized from "../Unauthorized";
import {ROLES} from "../Main";
import IdleComponent from "../components/IdleComponent";


export const PrivateRoute = ({ element, roles }) => {
    const {isUserAuthenticated} = useSelector((state) => state.login);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(isUserLoggedIn());
    }, [dispatch]);


    const userRoles = useUserRole(); // Retrieve the current user from your authentication system

    if(!isUserAuthenticated) {
        return <Navigate to="/login" />;
    }
   if (isUserAuthenticated && userRoles && userRoles.find(role => roles?.includes(role))) {
        return element
    }
    else if(isUserAuthenticated && !userRoles && !roles?.includes(role)){
        return <Unauthorized />
    }
};