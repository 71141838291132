import React, {useEffect, useState, useRef} from 'react';
import { Tab, Tabs, Paper, Snackbar, Alert } from '@mui/material';

import { useParams } from 'react-router-dom';
import './PatientProfile.css';
import CasesTimeline from '../CasesTimeline/CasesTimeline';
import Loading from '../Loading/Loading';
import Contact from '../Contact/Contact';
import { getPatientSettings, getPatientSnapshotCHR } from "../../ducks/actionCreators/patientOverviewActionCreator";
import { useSelector, useDispatch } from "react-redux";
import { getUserCases } from "../../ducks/actionCreators/casesActionCreator";
import PatientHeader from "../PatientHeader/PatientHeader";
import {Loader} from "@drowl/drowl-react-library";


// styling
const default_tab_item = {
    color: 'grey',
    minWidth: 10
};
const active_tab_item = {
    background_color: 'lightgrey',
    color: '#379437',
    minWidth: 10
};


const PatientProfile = () => {
    const [tab, setTab] = useState('allCases');
    const [alert, setAlert] = useState({ message: '', severity: '' });
    const { userId } = useParams();

    const dispatch = useDispatch();

    const casesData = useSelector(state => state.cases);
    const patientOverviewData = useSelector(state => state.patientOverview);
    const { patientInfo, patientCHR } = patientOverviewData;
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const loader = useSelector((state) => state.loader);

    const {userTickets} = casesData;

    const tabRef = useRef(null);

    useEffect(() => {
        function handleWindowResize() {
            setIsMobile(window.innerWidth < 600);
        };
        window.addEventListener("resize", handleWindowResize);

    }, []);

    useEffect(() => {

        (async () => {
            await getUserTickets();
            dispatch(getPatientSettings(userId));
            await getSnapshotCHR(userId);
        })()

    }, []);

    // get patient snapshot or chr
    const getSnapshotCHR = (userId) => {
        dispatch(getPatientSnapshotCHR(userId));
    };

    const getUserTickets = (last_session_end_time) => {
        const ticketLimit = 50;
        dispatch(getUserCases(userId,ticketLimit, last_session_end_time ?? ''));
    };

    // handle when a new tab is selected
    const handleChange = (event, selectedTab) => {
        setTab(selectedTab);
    };

    const handleLoadMoreTickets = async () => {
        if (userTickets && userTickets.length > 0) {
            const lastTicket = userTickets[userTickets.length - 1];
            await getUserTickets(lastTicket.timestamp);
        }
    };

    // after the change is handle then show the contents in the tab
    useEffect(() => {
        if(tabRef.current){
            tabRef.current.scrollIntoView();
            if (tab === 'snapshot'
                && isMobile
                && patientCHR) {
                openCHRInTab();
            }
        }
    }, [tab]);

    // Open a user's chr in a new tab
    const openCHRInTab = () => {
         window.open(patientCHR,'_target');
    };

    // set styling for an active tab
    const isActive = (isActiveTab) => {
        return isActiveTab ? active_tab_item : default_tab_item;
    };

    return (
        <span>
                <> {loader.loading && <Loader /> } </>
            <div className="patientOverviewWindow">
                 {/*Alert Messages*/}
                <Snackbar
                    open={!!(alert.message && alert.message !== '')}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    autoHideDuration={6000}
                    onClose={() => setAlert({ message: '', severity: alert.severity })}>
                    <Alert severity={alert.severity ?? ''} >
                        {alert.message}
                    </Alert>
                </Snackbar>

                {!patientInfo ? <Loading size={70} /> :
                    <div>
                        <PatientHeader view="patientProfile" ref={tabRef}/>
                                <Paper square className='patient-overview-tabs'>
                                    <Tabs
                                        value={tab}
                                        onChange={handleChange}
                                        variant="fullWidth"
                                        TabIndicatorProps={{ style: { background: '#379437', fontColor: '#379437' } }}
                                    >
                                        <Tab className="tab-item" style={isActive(tab === 'snapshot')} label='Snapshot' value="snapshot" />
                                        <Tab className="tab-item" style={isActive(tab === 'allCases')} label={'All Cases'} value="allCases" />
                                        <Tab className="tab-item" style={isActive(tab === 'contact')} label={'Contact'} value="contact" />
                                    </Tabs>
                                </Paper>
                        {/* Render Current Tab Contents */}
                        <div id="tab-contents-id" className="tab-contents">
                            {tab === "snapshot" &&
                                <div>
                                    {patientCHR ?
                                        <div>
                                            {isMobile ?
                                                < div > Opened Snapshot in new tab. Please ensure pop-up blockers are enabled in your browser. </div>
                                                :
                                                <div id="documents">
                                                    <iframe src={patientCHR} title="patient info pdf" id="iframePI" width="100%" height="100%"/>
                                                </div>
                                            }
                                        </div>
                                        :
                                        <Loading size={40} />
                                    }
                                </div>
                            }

                            {tab === 'allCases' &&
                                <CasesTimeline  handleLoadMoreTickets={handleLoadMoreTickets}/>
                            }

                            {tab === 'contact' &&
                                <Contact />
                            }
                        </div>
                    </div>
                 }
            </div >
        </span >
    );
}

export default PatientProfile;