import React from 'react';
import { Popper, Paper, MenuList, MenuItem, ClickAwayListener } from "@mui/material";

export const PatientDropdown = ({open, handleClose, anchorEl, handleSelect}) => {
    return (
        <Popper
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
        >
            {() => (
                <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                        <MenuList autoFocusItem={open} >
                            <MenuItem onClick={(event) => { handleSelect(event, 'snapshot') }}>Snapshot</MenuItem>
                            <MenuItem onClick={(event) => { handleSelect(event, 'allCases') }}>All Cases</MenuItem>
                            <MenuItem onClick={(event) => { handleSelect(event, 'allDocuments') }}>All Documents</MenuItem>
                            <MenuItem onClick={(event) => { handleSelect(event, 'contact') }}>Contact</MenuItem>
                            {/* TODO add back in when pages work
                                <MenuItem onClick={(event) => { handleSelect(event, 'timeline') }}>Timeline</MenuItem>
                                <MenuItem onClick={(event) => { handleSelect(event, 'providers') }}>Providers</MenuItem>
                                <MenuItem onClick={(event) => { handleSelect(event, 'prescriptions') }}>Prescriptions</MenuItem>
                                <MenuItem onClick={(event) => { handleSelect(event, 'other') }}>Other</MenuItem> */}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            )}
        </Popper>
    );
};