import { SET_SEARCH_RESULT, SET_PATIENT_RESULT, SET_DOCTORS_INGROUP, SET_SEARCH_BUTTON_CLICK } from "../actions/searchActions";

const init = {
    search: null,
    patients: null,
    doctorGroup: [],
    searchButtonClick: false
};

const searchReducer = (state = init, action) => {
    switch (action.type) {
        case SET_SEARCH_RESULT: {
            return { ...state, search: action.payload };
        }
        case SET_PATIENT_RESULT: {
            return { ...state, patients: action.payload.users };
        }
        case SET_DOCTORS_INGROUP: {
            return { ...state, doctorGroup: action.payload };
        }
        case SET_SEARCH_BUTTON_CLICK: {
            return { ...state, searchButtonClick: action.payload}
        }
        default:
            return state;
    }
};

export default searchReducer;