import React, {useState} from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    DialogActions, Grid
} from '@mui/material';
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {adminDenyPhysicianRequest} from "../../ducks/actionCreators/requestActionCreator";
import CloseIcon from "@mui/icons-material/Close";


const DenialModal = ({openModal, closeModal, id, setLoader, loader}) => {

    const {reqId} = useParams();
    const dispatch = useDispatch();

    const [denyReason , setDenyReason] = useState("");
    const [denyReasonErr, setDenyReasonErr] = useState(false);

    const handleDenialClick = async () => {
        if(!denyReason) {
            setDenyReasonErr(true);
        } else {
            setLoader(true);
            dispatch(adminDenyPhysicianRequest(reqId, id, denyReason));
        }
    };

    return (
        <Dialog open={openModal && !loader} className="assessmentModal">
            <DialogTitle>
                <Grid>

                    {/*<IconButton*/}
                    {/*    display="flex"*/}
                    {/*    onClick={()=> closeModal()}*/}
                    {/*    justifyContent="flex-end"*/}
                    {/*    alignItems="flex-end"*/}
                    {/*>*/}
                    {/*    <CloseIcon />*/}
                    {/*</IconButton>*/}
                </Grid>
                <Grid>
                    <IconButton
                        display="flex"
                        onClick={()=> closeModal()}
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>

            </DialogTitle>

            <DialogContent>
                <div style={{marginBottom: "20px"}} />
                <TextField
                    placeholder="Reason for denial *"
                    multiline
                    rows={4}
                    // maxRows={4}
                    style={{ width: "100%"}}
                    value={denyReason}
                    onChange={() => setDenyReason(event.target.value)}
                    error={denyReasonErr}
                    helperText={denyReasonErr && "This is required field."}
                />
                <div style={{marginBottom: "20px"}} />
            </DialogContent>

            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleDenialClick}>
                    Confirm
                </Button>
                <Button variant="contained" color="secondary" onClick={closeModal}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );

}

export default DenialModal;