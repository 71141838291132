import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
    IS_USER_AUTHENTICATED,
    SET_USER_DATA,
    SET_STEP,
    SET_USERNAME,
    CONFIRM_SIGN_UP,
    SET_USER_GROUP, NEW_PASSWORD_REQUIRED, SET_CODE_DELIVERY_DETAILS,
    SET_DOCTOR_GRPS
} from "../actions/loginActions";

const init = {
    isLoggedIn: false,
    isUserAuthenticated: false,
    userData: {},
    codeDeliveryDetails: {},
    phoneNumber: "",
    activeStep: 0,
    signupStatus: false,
    userName: "",
    userGroup: "",
    isFirstLogIn: false,
    doctorAssociatedGrps: []
};

const loginReducer = (state = init, action) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return { ...state, isLoggedIn: action.payload };
        case LOGIN_FAILURE:
            return { ...state, isLoggedIn: action.payload };
        case LOGOUT :
            return {...state, isLoggedIn: action.payload };
        case IS_USER_AUTHENTICATED :
            return {...state,
                isUserAuthenticated: action.payload};
        case SET_USER_DATA : {
            return {...state, userData: action.payload};
        }
        case SET_STEP: {
            return {...state, activeStep: action.payload};
        }
        case CONFIRM_SIGN_UP: {
            return {...state, signupStatus: action.payload};
        }
        case SET_USERNAME: {
            return {...state, userName: action.payload};
        }
        case SET_USER_GROUP: {
            return {...state, userGroup: action.payload};
        }
        case SET_CODE_DELIVERY_DETAILS : {
            return {...state, codeDeliveryDetails: action.payload};
        }
        case NEW_PASSWORD_REQUIRED:
            return {...state, isFirstLogIn: action.payload };
        case SET_DOCTOR_GRPS:
            return {...state, doctorAssociatedGrps: action.payload};
        default:
            return state;
    }
};
export default loginReducer;