import {CircularProgress, Divider, Grid, IconButton, TextField, Tooltip, Button} from "@mui/material";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import React, {useEffect, useState} from "react";
import VideocamIcon from '@mui/icons-material/Videocam';
import {useSelector, useDispatch} from "react-redux";
import {CaseStatues} from "../PatientOverview/PatientOverview";
import {startVideoChat} from "../../ducks/actionCreators/videoActionCreator";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {changeTicketStatus} from "../../ducks/actionCreators/caseActionCreator";
import {Link, useNavigate, useParams} from "react-router-dom";
import {createTicketForPatient, setCreateTicketResponse} from "../../ducks/actionCreators/patientOverviewActionCreator";
import {Alert} from "@mui/lab";
import "./PatientHeader.css";
import ScheduleStatusChangeConfirmationModal from "./ScheduleStatusChangeConfirmationModal";

import { useLocation } from 'react-router-dom'
import ClosedStatusConfirmationModal from "./ClosedStatusConfirmationModal";
import CloseCaseConfirmationModal from "./CloseCaseConfirmationModal";
import {setLoader} from "../../ducks/actionCreators/loaderActionCreator";
import MessageAlertWithRing from "../NotificationBar/NotificationBar";
import {convertDate} from "../../utils/utlis";

const PatientHeader = ({view, tab, ref}) => {
    const patientOverviewData = useSelector(state => state.patientOverview);
    const { patientInfo, ticket, createPatientTicketResp, progressNote, messageToNotify } = patientOverviewData;

    const {user_info} = patientInfo;

    const location = useLocation()

    const dispatch = useDispatch();

    const providerData = useSelector(state => state.case);

    const { ticketStatus } = providerData;

    const [status, setStatus] = useState();
    const [statusLoading, setStatusLoading] =  useState(false);
    const [alert, setAlert] = useState({ alert: '', severity: '' });

    const [scheduleStatusChange, setScheduleStatusChange] = useState(false);
    const [closedStatusChange, setClosedStatusChange] = useState(false);
    const [confirmScheduleStatusChange, setConfirmScheduleStatusChange] = useState(false);
    const [tempStatus, setTempStatus] =  useState();
    const [confirmCancellation, setConfirmCancellation] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const ticketID = useParams();

    const navigate = useNavigate();

    const startVideoCall = () => {
        if (ticket && progressNote && progressNote.chiefComplaint) {
            dispatch(startVideoChat(ticket._id, ticket));
        } else {
            setAlert({ alert: <div>Please update you complaint before starting a videocall.</div>, severity: 'error' });
            setTimeout(() => {
                setAlert({alert: "", severity: ""});

            }, 5000)
        }
    }

    useEffect(() => {
        if(confirmScheduleStatusChange) {
            setStatusLoading(true);
            setStatus(tempStatus);
            updateStatus(tempStatus);
            setConfirmScheduleStatusChange(false)
            setScheduleStatusChange(false)
            setTempStatus();
        }
    }, [confirmScheduleStatusChange])

    const handleCloseModal = (e) => {
        e.preventDefault();
        setShowCancelModal(false);
    }

    const handleConfirmClick = (e) => {
        e.preventDefault();
        setClosedStatusChange(true);
        setShowCancelModal(false);
        setStatusLoading(true);
        setStatus('Closed');

        // updateStatus('Closed');
    }

    const handleStatusChange = async (event) => {
        if(event.target.value === "Closed"){
            //Pop up a modal that confirms closing case
            setShowCancelModal(true);
        } else {
            if(status === "Scheduled") {
                setScheduleStatusChange(true);
                setTempStatus(event.target.value);
            }
    
            else if(status !== CaseStatues.SCHEDULED ) {
                setStatusLoading(true);
                setStatus(event.target.value);
                updateStatus(event.target.value);
            }
        }
    };

    // change status in db
    const updateStatus =  (newStatus) => {
        try {
            dispatch(setLoader(true));
            let newTicketStatus;
            if (newStatus === 'Open') {
                newTicketStatus = CaseStatues.OPEN;
            } else if (newStatus === 'In Progress') {
                newTicketStatus = CaseStatues.INPROGRESS;
            } else if (newStatus === 'Scheduled') {
                newTicketStatus = CaseStatues.SCHEDULED;
            } else if (newStatus === 'Assessing') {
                newTicketStatus = CaseStatues.ASSESSING;
            } else if (newStatus === 'WaitingForGP') {
                newTicketStatus = CaseStatues.WAITINGFORGP;
            }
            else {
                newTicketStatus = CaseStatues.CLOSED;
            }

            dispatch(changeTicketStatus(newTicketStatus, ticket._id));
           if( newTicketStatus === CaseStatues.CLOSED){
               navigate("/cases")
           }

        }
        catch (error) {
            console.error(error);
            dispatch(setLoader(false));
        }
    };

    const getTicketStatus = () => {
        if (ticket?.status === CaseStatues.OPEN || ticket?.status === 0 ) {
            return "Open";
        } else if (ticket?.status === CaseStatues.INPROGRESS || ticket?.status === 1 ) {
            return "In Progress";
        } else if (ticket?.status === CaseStatues.SCHEDULED || ticket?.status === 2) {
            return "Scheduled";
        }
        else if (ticket?.status === CaseStatues.ASSESSING || ticket?.status === 4 ) {
                return "Assessing";
        } else if (ticket?.status === CaseStatues.WAITINGFORGP || ticket?.status === 5) {
            return "WaitingForGP";
        }
        else {
            return "Closed";
        }
    };

    useEffect(() => {
        setStatusLoading(false);
        if (ticket && Object.keys(ticket).length > 0) {
            if (!status || status.length === 0) {
                const initStatus = getTicketStatus();
                setStatus(initStatus);
            }
        }
    }, [ticket?.status]);

    useEffect(() => {
        if (!ticketStatus) {
            setStatus(getTicketStatus());
        }
        setStatusLoading(false);
        if (status === CaseStatues.CLOSED && ticketStatus) {
            window.location.reload();
        }
    }, [ticketStatus])

    useEffect(() => {
            setStatus(getTicketStatus());
    }, [ticket])


    const createPatientTicketRespChange = createPatientTicketResp && createPatientTicketResp.data && createPatientTicketResp.data.createScheduledSession && createPatientTicketResp.data.createScheduledSession.status;

    useEffect(() => {
        if( createPatientTicketResp!== null) {
            const status = createPatientTicketRespChange ? "success" : "error";
            if(status === "success" ) {
                setAlert({ alert: <div>Created Ticket. Go to <Link to='/cases' className='linkTo'>cases</Link></div>, severity: 'success' });
            } else if(status === "error")
            {
                setAlert({alert: createPatientTicketResp && createPatientTicketResp.data?.createScheduledSession?.message ? createPatientTicketResp.data?.createScheduledSession?.message : 'We can not create a ticket for this patient at this time.', severity: "error"})
            }
            setTimeout(() => {
                setAlert({alert: "", severity: ""});
            }, 8000)
            dispatch(setCreateTicketResponse(null));
        }
    }, [createPatientTicketRespChange])

    // quickly create a ticket for the patient
    const createTicket =  () => {
        dispatch(createTicketForPatient(ticketID));
    }


    // width:100%;
    // background-color: white;
    // position: sticky !important;
    // position: -webkit-sticky !important;
    // z-index: 10;
    // top: 0;
    // overflow: initial;
    // margin-top: 0.5em;

    return (
        <div>
        <div ref={ref} >
            {(view === "patientProfile" ) &&
                <div style={{display: "flex", justifyContent: "center"}}>
                    <h1 className="page-title" >{ `${ user_info?.user_info.name } ${user_info?.user_info.family_name || ''}`}</h1>
                    <div className='createTicketButton noteIcon'>
                        <Tooltip title='Create Ticket'>
                            <IconButton
                                onClick={() => createTicket()}
                            >
                                <NoteAddIcon
                                    // fontSize={props.isMobile ? 'small' : 'large'}
                                    style={{ color: '#3daf2c' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>}
            {((view === "patientOverview" && ticket) || (location && location.state && location.state.from === "patientOverview"))
                // && tab ==="case"
                &&
                <div>
                    <Grid container >
                        <Grid item xs={2} md={1} ></Grid>
                        <Grid item xs={10} md={6}>
                            <Grid container spacing={0}  direction="row"
                                // style={{marginLeft : "35px"}}
                                  justifyContent="flex-start"
                                  alignItems="center">
                                    <h1 className="page-title">{`${user_info ? user_info.name : ''} ${user_info ? user_info.family_name : ''} ${user_info ? "- Case ID:" : ""} ${ticket?.uid ?? ''}`}
                                    {user_info && ticket && (ticket.status !== CaseStatues.CLOSED) &&

                                          <>  <Tooltip title='Start video chat'>
                                                <IconButton
                                                    onClick={() => startVideoCall()}
                                                >
                                                    <VideocamIcon fontSize='large' style={{color: '#3daf2c'}}/>
                                                </IconButton>
                                            </Tooltip>
                                          </>
                                    }
                                    </h1>
                                <>{messageToNotify && <MessageAlertWithRing  />}</>

                            </Grid>
                        </Grid>
                        {/* Status */}
                        {/*<Grid item xs={0}  md={1} > </Grid>*/}
                        <Grid item xs={12} md={4} >
                            <Grid xs={8} md={12} container direction="row" justifyContent="flex-end" style={{marginTop: "15px"}}>
                                <div className="caseLabel"  style={{color: "grey"}}>Status:</div>
                                <TextField
                                    variant="outlined"
                                    select
                                    style={{ width: '52%' , marginTop: "10px"}}
                                    value={status}
                                    onChange={handleStatusChange}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    // disabled={props.isClosed}
                                >
                                    <option key={99} value='' style={{ display: 'none' }}></option>
                                    <option key={CaseStatues.OPEN} value={'Open'}>Open</option>
                                    <option key={CaseStatues.ASSESSING} value={'Assessing'}>Assessing</option>
                                    <option key={CaseStatues.WAITINGFORGP} value={'WaitingForGP'}>Waiting for GP</option>
                                    <option key={CaseStatues.INPROGRESS} value={'In Progress'}>In Progress</option>
                                    <option key={CaseStatues.SCHEDULED} value={'Scheduled'}>Scheduled</option>
                                    <option key={CaseStatues.CLOSED} value={'Closed'}>Closed</option>
                                </TextField>

                                {statusLoading ?
                                    <CircularProgress className="statusIcon" style={{ marginTop: "15px !important" }} size={24} />
                                    :
                                    <CheckCircleOutlineIcon className="statusIcon" style={{ color: '#99d590' , marginTop: "15px !important"}} />
                                }
                            </Grid>
                            
                            
                            <Grid><div style={{marginBottom: "10px"}} /></Grid>
                        </Grid>
                    </Grid>
                </div>
             }
            {/*{((view === "patientOverview" && tab !=="case") ||  (view === "patientOverview" && tab ==="case" && ticket.status === CaseStatues.CLOSED)) && <div>*/}
            {/*    <h1 className="page-title">{`${user_info && user_info.name} ${user_info && user_info.family_name} - Case ID: ${ticket?.uid ?? ''}`}*/}
            {/*       <> {ticket && (ticket.status !== CaseStatues.CLOSED) &&*/}

            {/*            <Tooltip title='Start video chat'>*/}
            {/*                <IconButton onClick={() => startVideoCall()}>*/}
            {/*                    <VideocamIcon fontSize='large' style={{color: '#3daf2c'}}/>*/}
            {/*                </IconButton>*/}
            {/*            </Tooltip>*/}

            {/*        }</>*/}
            {/*    </h1>*/}
            {/*    </div> }*/}

        </div>

        <Divider className="title-divider" style={{ backgroundColor: "#379437", height: "2px" }} variant="middle" />

        <div className='patient-overview-contents'>
            {/* Patient Infomation Header  */}
            <div id="patient-status-div">
                <Grid  className="patient-info-container">
                    <Grid container>
                        <Grid container
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center" item xs={4}>
                            <h6 className="patient-info-label-subtitle" >{user_info && user_info.ethnicity || ''}</h6>
                        </Grid>
                        <Grid container
                              direction="row"
                              justifyContent="center"
                              alignItems="center" item xs={4}>
                            <h6 className="patient-info-label-subtitle" >{user_info && user_info.gender || ''}</h6>
                        </Grid>
                        <Grid item xs={4} container
                              direction="row"
                              justifyContent="flex-end"
                              alignItems="center">
                            <h6 className="patient-info-label-subtitle" >Dob: {user_info && convertDate(user_info.dob) || ''}</h6>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
            {alert && alert.alert  &&  <Alert
                style={{ width: "88%" }}
                severity={alert.severity ?? ''} onClose={() => setAlert({ alert: '', severity: '' })}>
                {alert.alert}
            </Alert> }

            {scheduleStatusChange && <ScheduleStatusChangeConfirmationModal
                open={scheduleStatusChange}
                close={() => setScheduleStatusChange(false)}
                confirm={() => setConfirmScheduleStatusChange(true)}
            />}

            {closedStatusChange && <ClosedStatusConfirmationModal
                open={closedStatusChange}
                close={async () => { await updateStatus('Closed'); setClosedStatusChange(false);  } }
                // confirm={() => setConfirmClosedStatusChange(true)}
                />}


            { showCancelModal && <CloseCaseConfirmationModal 
                open={showCancelModal}
                close={() => setShowCancelModal(false)}
                confirm={handleConfirmClick}
            />
            }

    </div > )
}

export default PatientHeader;