import React from 'react';
import { formatPhoneNumber } from '../../utils/utlis';
import { TextField, Grid } from '@mui/material';

import '../PatientProfile/PatientProfile.css';
import {useSelector} from "react-redux";

const Contact = () => {

    const patientOverviewData = useSelector(state => state.patientOverview);

    const { patientInfo } = patientOverviewData;

    const { user_info } = patientInfo;

    return (
        <div style={{ marginTop: '25px' }}>
            {/* Phone Number */}
            <Grid container justifyContent="flex-start">
                <Grid item xs={4} md={2}  className="contactFormFieldLabel">
                    Phone Number:
                </Grid>
                <Grid item xs={8} md={4}>
                        <TextField
                            style={{ width: '90%' }}
                            value={user_info && user_info.phone_number}
                            disabled
                            variant="standard"
                        />
                </Grid>
            </Grid>
            <br/>
            <Grid container justifyContent="flex-start">
                <Grid item xs={4} md={2} className="contactFormFieldLabel">
                   Email:
                </Grid>
                <Grid item xs={8} md={4}>
                        <TextField
                            style={{ width: '90%' }}
                            value={user_info && user_info.email || ''}
                            disabled
                            variant="standard"
                        />
                </Grid>
            </Grid>
        </div>
    );
}

export default Contact;