// let graphqlEndpoint = "https://iqplwuwrrrbcteliowd6tmtyxy.appsync-api.us-west-2.amazonaws.com/graphql";

let graphqlEndpoint = "https://prmijyym4zhphj5tneuiwtaecq.appsync-api.us-west-2.amazonaws.com/graphql";

if (process.env.environment === "production") {
  graphqlEndpoint = "https://prmijyym4zhphj5tneuiwtaecq.appsync-api.us-west-2.amazonaws.com/graphql";
}

const awsConfig = {
  identityPoolId: 'us-west-2:96f5dc9b-b203-42f5-875e-43c413efcd55',
  region: 'us-west-2',
  userPoolId: 'us-west-2_rEQOGWRuz',
  userPoolWebClientId: '263nhmf2ih2bsh2mfd1g4peehl',

  "aws_appsync_graphqlEndpoint": graphqlEndpoint,
  "aws_appsync_region": "us-west-2",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_appsync_apiKey": "da2-jry4cbw6jrgdjijg3wb46cqhgm",
}

export default awsConfig;
