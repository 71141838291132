export const SET_CURR_IMG_URL = "SET_CURR_IMG_URL";
export const SET_IMG_SUB = "SET_IMG_SUB";
export const SET_PROFILE_INFO = "SET_PROFILE_INFO";
export const SET_API_RESP_MSG = "SET_API_RESP_MSG";
export const SET_RESP_MSG_CLS = "SET_RESP_MSG_CLS";
export const SET_IS_LOADING = "SET_IS_LOADING";
export const SET_ACC_PRF_IMG_SRC = "SET_ACC_PRF_IMG_SRC";
export const SET_PRF_IMG = "SET_PRF_IMG";
export const SET_IMG_FILE = "SET_IMG_FILE";
export const SET_IMG_FILE_NAME = "SET_IMG_FILE_NAME";
export const  SET_NEW_IMG = "SET_NEW_IMG";
export const SET_PAGE_LOADING = "SET_PAGE_LOADING";
export const SET_DOCUMENTS = "SET_DOCUMENTS";
export const IS_PROFILE_VALID= "IS_PROFILE_VALID";
export const SET_LNG = "SET_LNG";
export const SET_LAT = "SET_LAT";
export const SET_RECOMMENDED_ADDRESS = "SET_RECOMMENDED_ADDRESS";
export const SET_ADDRESS_COMPONENTS = "SET_ADDRESS_COMPONENTS";
export const SET_DIALOG = "SET_DIALOG";