/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const startSession = /* GraphQL */ `
  mutation StartSession($token: String!, $other_user_id: String!) {
    startSession(token: $token, other_user_id: $other_user_id) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const startTelemedicineSession = /* GraphQL */ `
  mutation StartTelemedicineSession(
    $startTelemedicineDTO: StartTelemedicineDTO
  ) {
    startTelemedicineSession(startTelemedicineDTO: $startTelemedicineDTO) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const createScheduledSession = /* GraphQL */ `
  mutation CreateScheduledSession(
    $token: String!
    $doctor_id: String!
    $user_id: String!
    $start_time: String!
    $end_time: String!
  ) {
    createScheduledSession(
      token: $token
      doctor_id: $doctor_id
      user_id: $user_id
      start_time: $start_time
      end_time: $end_time
    ) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const updateTicketStatus = /* GraphQL */ `
  mutation UpdateTicketStatus($token: String!, $ticketId: ID!, $status: Int!) {
    updateTicketStatus(token: $token, ticketId: $ticketId, status: $status) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const assignTicketToDoctor = /* GraphQL */ `
  mutation AssignTicketToDoctor(
    $token: String!
    $ticketId: ID!
    $doctorId: String!
  ) {
    assignTicketToDoctor(
      token: $token
      ticketId: $ticketId
      doctorId: $doctorId
    ) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const updateProgressNote = /* GraphQL */ `
  mutation UpdateProgressNote($progressNote: ProgressNote) {
    updateProgressNote(progressNote: $progressNote) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const updateTicketSubscriptionForUser = /* GraphQL */ `
  mutation UpdateTicketSubscriptionForUser(
    $token: String!
    $ticketId: ID!
    $subscription: Boolean!
  ) {
    updateTicketSubscriptionForUser(
      token: $token
      ticketId: $ticketId
      subscription: $subscription
    ) {
      status
      alert_title
      message
      __typename
    }
  }
`;
export const createZoomMeeting = /* GraphQL */ `
  mutation CreateZoomMeeting($zoomMeeting: StartZoomMeeting) {
    createZoomMeeting(zoomMeeting: $zoomMeeting) {
      status
      message
      alert_title
      start_url
      join_url
      id
      host_signature
      join_signature
      comment {
        _id
        id
        image
        comment
        owner
        timestamp
        meetingId
        type
        document
        file_name
        document_title
        __typename
      }
      ticketId
      __typename
    }
  }
`;
export const addSessionComment = /* GraphQL */ `
  mutation AddSessionComment(
    $token: String!
    $ticketId: ID
    $comment: String
    $type: String
  ) {
    addSessionComment(
      token: $token
      ticketId: $ticketId
      comment: $comment
      type: $type
    ) {
      status
      message
      alert_title
      comment {
        _id
        id
        image
        comment
        owner
        timestamp
        meetingId
        type
        document
        file_name
        document_title
        __typename
      }
      ticketId
      user
      __typename
    }
  }
`;
export const addSessionDocument = /* GraphQL */ `
  mutation AddSessionDocument(
    $token: String!
    $ticketId: ID
    $other_user_id: String
    $comment: String
    $type: String
    $title: String
    $s3Location: String
  ) {
    addSessionDocument(
      token: $token
      ticketId: $ticketId
      other_user_id: $other_user_id
      comment: $comment
      type: $type
      title: $title
      s3Location: $s3Location
    ) {
      status
      message
      alert_title
      comment {
        _id
        id
        image
        comment
        owner
        timestamp
        meetingId
        type
        document
        file_name
        document_title
        __typename
      }
      ticketId
      user
      __typename
    }
  }
`;
export const addSessionCommentWithAdditonalUser = /* GraphQL */ `
  mutation AddSessionCommentWithAdditonalUser(
    $token: String!
    $other_user_id: String
    $ticketId: ID
    $comment: String
    $type: String
  ) {
    addSessionCommentWithAdditonalUser(
      token: $token
      other_user_id: $other_user_id
      ticketId: $ticketId
      comment: $comment
      type: $type
    ) {
      status
      message
      alert_title
      comment {
        _id
        id
        image
        comment
        owner
        timestamp
        meetingId
        type
        document
        file_name
        document_title
        __typename
      }
      ticketId
      user
      __typename
    }
  }
`;
export const deleteSessionComment = /* GraphQL */ `
  mutation DeleteSessionComment(
    $token: String!
    $ticketId: ID!
    $commentId: String!
    $type: String!
  ) {
    deleteSessionComment(
      token: $token
      ticketId: $ticketId
      commentId: $commentId
      type: $type
    ) {
      status
      alert_title
      message
      __typename
    }
  }
`;
export const addDoctorSchedule = /* GraphQL */ `
  mutation AddDoctorSchedule(
    $token: String!
    $id: String!
    $title: String!
    $ticketId: String
    $start_time: String!
    $end_time: String!
  ) {
    addDoctorSchedule(
      token: $token
      id: $id
      title: $title
      ticketId: $ticketId
      start_time: $start_time
      end_time: $end_time
    ) {
      status
      message
      alert_title
      schedule {
        _id
        id
        ticketId
        title
        start_time
        end_time
        available
        __typename
      }
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      id
      __typename
    }
  }
`;
export const editDoctorSchedule = /* GraphQL */ `
  mutation EditDoctorSchedule(
    $token: String!
    $id: ID!
    $schedule_id: String!
    $title: String!
    $ticketId: String
    $start_time: String!
    $end_time: String!
  ) {
    editDoctorSchedule(
      token: $token
      id: $id
      schedule_id: $schedule_id
      title: $title
      ticketId: $ticketId
      start_time: $start_time
      end_time: $end_time
    ) {
      status
      message
      alert_title
      schedule {
        _id
        id
        ticketId
        title
        start_time
        end_time
        available
        __typename
      }
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      id
      __typename
    }
  }
`;
export const deleteDoctorSchedule = /* GraphQL */ `
  mutation DeleteDoctorSchedule(
    $token: String!
    $id: ID!
    $schedule_id: String!
  ) {
    deleteDoctorSchedule(token: $token, id: $id, schedule_id: $schedule_id) {
      status
      message
      alert_title
      id
      __typename
    }
  }
`;
export const addDoctorAvailability = /* GraphQL */ `
  mutation AddDoctorAvailability($doctorAvailableSlots: DoctorAvailableSlots) {
    addDoctorAvailability(doctorAvailableSlots: $doctorAvailableSlots) {
      status
      message
      alert_title
      id
      available_slot {
        _id
        id
        start_hour
        start_minute
        end_hour
        end_minute
        start_time
        end_time
        start_date
        end_date
        time_zone
        SUN
        MON
        TUE
        WED
        THU
        FRI
        SAT
        available
        __typename
      }
      __typename
    }
  }
`;
export const removeDoctorAvailability = /* GraphQL */ `
  mutation RemoveDoctorAvailability(
    $token: String!
    $other_user_id: String!
    $slot_id: String!
  ) {
    removeDoctorAvailability(
      token: $token
      other_user_id: $other_user_id
      slot_id: $slot_id
    ) {
      status
      message
      alert_title
      id
      slot_id
      __typename
    }
  }
`;
export const createOrDeletePrescription = /* GraphQL */ `
  mutation CreateOrDeletePrescription($prescriptionInput: PrescriptionInput) {
    createOrDeletePrescription(prescriptionInput: $prescriptionInput) {
      status
      message
      alert_title
      prescription_id
      __typename
    }
  }
`;
export const createOrDeleteNotes = /* GraphQL */ `
  mutation CreateOrDeleteNotes($noteInput: NoteInput) {
    createOrDeleteNotes(noteInput: $noteInput) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const createPrescription = /* GraphQL */ `
  mutation CreatePrescription(
    $createPrescriptionInput: CreatePrescriptionInput
  ) {
    createPrescription(createPrescriptionInput: $createPrescriptionInput) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const deletePrescription = /* GraphQL */ `
  mutation DeletePrescription(
    $token: String!
    $ticketId: String!
    $prescriptionId: String!
  ) {
    deletePrescription(
      token: $token
      ticketId: $ticketId
      prescriptionId: $prescriptionId
    ) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const addUserConnection = /* GraphQL */ `
  mutation AddUserConnection($userConnection: UserConnection) {
    addUserConnection(userConnection: $userConnection) {
      status
      alert_title
      message
      key
      __typename
    }
  }
`;
export const addOrDeleteTelevistDocument = /* GraphQL */ `
  mutation AddOrDeleteTelevistDocument(
    $addDocumentInput: AddOrDeleteTelevistDocumentInput
  ) {
    addOrDeleteTelevistDocument(addDocumentInput: $addDocumentInput) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
