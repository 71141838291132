import {
    GET_REQUESTS,
    DOCUMENT_LINK,
    GET_ADMIN_REQUESTS,
    CREATE_PHYSICIAN_REQUEST,
    ADMIN_APPROVAL,
    ADMIN_DENIAL,
    GET_ADMIN_REQUESTS_HISTORY,
    SET_PROFILE_HISTORY
} from "../actions/requestActions";

const init = {
    requests: [],
    documentLink: "",
    adminRequests: [],
    makeRequestResponse: "",
    approveRequestResponse: "",
    denyRequestResponse: "",
    adminRequestsHistory: [],
    profileHistory: {}
};

const requestReducer = (state = init, action) => {
    switch (action.type) {
        case GET_REQUESTS: {
            return { ...state, requests: action.payload };
        }
        case DOCUMENT_LINK: {
            return { ...state, documentLink: action.payload };
        }
        case GET_ADMIN_REQUESTS: {
            return { ...state, adminRequests: action.payload };
        }
        case CREATE_PHYSICIAN_REQUEST: {
            return { ...state, makeRequestResponse: action.payload };
        }
        case ADMIN_APPROVAL: {
            return { ...state, approveRequestResponse: action.payload };
        }
        case ADMIN_DENIAL: {
            return { ...state, denyRequestResponse: action.payload };
        }
        case GET_ADMIN_REQUESTS_HISTORY: {
            return { ...state, adminRequestsHistory: action.payload };
        }
        case SET_PROFILE_HISTORY: {
            return {...state, profileHistory: action.payload};
        }
        default:
            return state;
    }
};

export default requestReducer;