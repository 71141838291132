import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from "@mui/material/IconButton";
import defaultProfile from "../../assets/images/profile-picture.svg";
import {useSelector, useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {signOut} from "../../ducks/actionCreators/loginActionCreator";

export default function UserMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

    const handleSignOut = () => {
        dispatch(signOut());
    };

  const userData = useSelector(state => state.settings);


  const navigate = useNavigate();

  return (
      <div>
        <Button
            id="demo-positioned-button"
            aria-controls={open ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
        >
            {userData && (userData.currImgUrl || userData.userimage) ?
                <div>
                    <IconButton >
                        <img className='userImage' src={userData.currImgUrl || userData.userimage} alt="User Profile" />
                    </IconButton>
                </div>
                : <div>
                    <IconButton >
                        <img className='userImage' src={defaultProfile} alt="User Profile" />
                    </IconButton>
                </div>
            }
        </Button>


        <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
        >
            {/*{ userData && userData.user_reg_status === "approved" ? */}
                <MenuItem onClick={() => {handleClose(); navigate("/settings")}}>Settings</MenuItem>
                {/*// : null}*/}
            <MenuItem onClick={() => {handleClose(); navigate("/changePassword")}}>Change Password</MenuItem>
          {/*// <MenuItem onClick={() => handleSignOut()}>Logout</MenuItem>*/}
        </Menu>
      </div>
  );
}