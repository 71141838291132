import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { persistReducer, persistStore } from "redux-persist";
import allReducers from "./reducers";

const persistConfig = {
    timeout: 100,
    key:"root",
    storage,
    whitelist: ["login", "patientOverview", "cases", "settings", "requestData", "statsReducer"]
};

const persistedReducer = persistReducer(persistConfig, allReducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
    devTools: window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
});

export const persistor = persistStore(store);

export default store;
