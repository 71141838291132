import React, {useEffect} from "react";
import {getCaseHistory} from "../../ducks/actionCreators/caseActionCreator";
import {useDispatch, useSelector} from "react-redux";
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator} from "@mui/lab";
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import {Avatar, Card} from "@mui/material";

const CaseHistory = ({}) => {

    const dispatch = useDispatch();

    const caseHistoryList = useSelector((state) => state.case)

    const {ticketHistoryList} = caseHistoryList;

    useEffect(() => {
        dispatch(getCaseHistory())
    }, []);

    return (
        <>

            <h3>Case History</h3>

            {ticketHistoryList && ticketHistoryList.length> 0 && ticketHistoryList.map((history, index) => (
                <Timeline
                    sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                        },
                    }}
                >
                    <TimelineItem>
                        <TimelineSeparator >
                            <TimelineDot />
                            {index !== ticketHistoryList.length-1 && <TimelineConnector /> }
                        </TimelineSeparator>
                        <TimelineContent>
                            <div style={{ width: "70%" }}>
                                <div style={{display: "flex", paddingLeft: "15px"}}>
                                    <Avatar alt="Remy Sharp" src={history.image} />
                                    <div style={{paddingLeft: "15px"}}> {history.description} at {new Date(Number(history.timestamp)).toLocaleString()} </div>
                                </div>
                            </div>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            ))}

        </>
    )


}

export default CaseHistory;