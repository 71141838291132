import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { List, ListItem, ListItemText, Paper } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    listItemOdd: {
        backgroundColor: "#FFFFFF",
        border: "1px solid grey",
        marginBottom: "5px"
    },
    listItemEven: {
        backgroundColor: "#e4f3e4",
        border: "1px solid green",
        marginBottom: "5px"
    },
}));

const SelectableMessages = ({ messages, setChatMessage }) => {
    const [selectedMessage, setSelectedMessage] = useState(null);
    const classes = useStyles();

    const handleSelectMessage = (message) => {
        setSelectedMessage(message);
        setChatMessage(message)
    };

    return (
            <List>
                {messages.map((message, index) => (
                    <ListItem
                        key={index}
                        className={index % 2 === 0 ? classes.listItemEven : classes.listItemOdd}
                        button
                        selected={selectedMessage === message.value}
                        onClick={() => handleSelectMessage(message.value)}
                    >
                        <ListItemText primary={message.value} />
                    </ListItem>
                ))}
            </List>
    );
};

export default SelectableMessages;
