import React, { useEffect, useState } from 'react';
import { createFilterOptions } from '@mui/material/Autocomplete';
import {
    Button,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemButton,
    ListItemText,
    Checkbox,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    TextField, DialogActions
} from '@mui/material';
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    createPrescriptionList, getPrescription, setPrescriptionListSuccess,
    updateProgressNote
} from "../../ducks/actionCreators/patientOverviewActionCreator";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import PlanTableWeb from "./PlanTableWeb";
import PlanMedTableWeb from "./PalnMedTableWeb";
import MedicationsModal from "./MedicationsModal";
import ConditionsModal from "./ConditionsModal";
import MedicationIcon from '@mui/icons-material/Medication';
import {deletePrescription} from "../../ducks/actionCreators/patientOverviewActionCreator";
import DeleteIcon from "@mui/icons-material/Delete";
import PlanTableMobile from "./PlanTableMobile";
import PlanMedTableMobile from "./PlanMedTableMobile";
import {Loader} from "@drowl/drowl-react-library";
import AllergiesModal from "./AllergiesModal";
import Table from "../Table/index";
import AllergiesTableWeb from "./AllergieTableWeb";
import AllergiesTableMobile from "./AllergiesTableMobile";
import CloseIcon from "@mui/icons-material/Close";
import {Alert} from "@mui/lab";
import SignaturePad from "../SignaturePad";
import UpdateSignature from "../SignaturePad/UpdateSignature";

const filterOptionsIcd = createFilterOptions({
    matchFrom: "any",
    limit: 100,
    stringify: (option) => option,
});

const filterOptionsMed = createFilterOptions({
    matchFrom: "any",
    limit: 100,
    stringify: (option) => option,
});

const AssessmentPlanModal = (props) => {

    const [selected, setSelected] = useState([]);
    const [error, setError] = useState(false);
    const [ controlCheckBox, setControlCheckBox ] = useState("");
    const [ controlCondCheckBox, setControlCondCheckBox ] = useState("");
    const [ controlAllergyCheckBox, setControlAllergyCheckBox ] = useState("");

    const [showAddConditions, setShowAddConditions] = useState(false);
    const [showAddMedications, setShowAddMedications] = useState(false);
    const [showAddAllergies, setShowAddAllergies] = useState(false);
    const [showMobileViewTable, setShowMobileViewTable] = useState(window.innerWidth < 600);

    const [showSignaturePad, setShowSignaturePad] = useState(false);


    const ticketID = useParams();

    const dispatch = useDispatch();
    const patientOverviewData = useSelector(state => state.patientOverview);

    const data = useSelector((state) => state.settings);

    const {profileInfo} = data;
    const {signature} = profileInfo;

    let { updatePrescStatus , ticket, progressNote, prescriptionsList, prescStatus } = patientOverviewData;

    updatePrescStatus ||= "";

    let [allergies, setAllergies] =  useState([]);

    let [selectedMedications, setSelectedMedications] =  useState([]);
    let [selectedConditions, setSelectedConditions] =  useState([]);
    let [selectedAllergies, setSelectedAllergies] =  useState([]);

    const loader = useSelector((state) => state.loader);

    useEffect(() => {
        function handleWindowResize() {
            setShowMobileViewTable(window.innerWidth < 600);
        };
        window.addEventListener("resize", handleWindowResize);
    }, []);

    useEffect(() => {
        setAllergies(progressNote.allergiesEntered)
    }, [progressNote]);

    useEffect(() => {
        if(updatePrescStatus && updatePrescStatus === "success") {
            dispatch(updateProgressNote({...progressNote, prescriptions: prescriptionsList}));
        }
        setSelectedMedications([]);
        setControlCheckBox([]);
        setSelectedConditions([]);
        setControlCondCheckBox([]);
        setSelectedAllergies([]);
        setControlAllergyCheckBox([]);
    }, [updatePrescStatus, progressNote])


    const handleAddConditions = () => {
        setShowAddConditions(!showAddConditions);
    }

    const handleAddMedications = () => {
        setShowAddMedications(!showAddMedications)
    }

    const handleAddAllergies = () => {
        setShowAddAllergies(!showAddAllergies)
    }

    const handleCreatePrescriptionClick = async () => {

        console.log("probHere-1", signature);
        // dispatch(setPrescriptionListSuccess(true));

        if(signature) {
            setSelected([]);
            const selected = [];
            for (let i = 0; i < selectedMedications.length; i++) {
                if (selectedMedications[i]) {
                    // if(progressNote.Medications[i].prescription_id) {
                        delete progressNote.Medications[i].prescription_id;
                    // }
                    selected.push(progressNote.Medications[i]);
                }
            }
            setSelected(selected);

            console.log("probHere")
            dispatch(createPrescriptionList(ticket._id,selected, signature))
        }

        else {
            setError(true);
        }

    }


    const handlePrescriptionLinkClick = async (prescriptionId) => {
        try {
            dispatch(getPrescription(ticketID.ticket, prescriptionId));
        } catch (e) {
            console.error(e);
        }
    };


    const deleteAPrescription = async(prescriptionId) => {
        try {
            dispatch(deletePrescription(ticketID.ticket, prescriptionId));
        } catch (e) {
            console.error(e);
        }
    }

    const deleteMedication = async() => {
        setSelected([]);
        const temp = [...progressNote.Medications];

        for (let i = selectedMedications.length; i > 0; i--) {
            if (selectedMedications[i-1]) {
                temp.splice(i-1,1);
            }
        }

        dispatch(updateProgressNote({...progressNote, Medications: temp}, ticketID.ticket));
    }

    const deleteAllergy = async() => {
        setSelected([]);
        const temp = [...progressNote.allergiesEntered];

        for (let i = selectedAllergies.length; i > 0; i--) {
            if (selectedAllergies[i-1]) {
                temp.splice(i-1,1);
            }
        }

        dispatch(updateProgressNote({...progressNote, allergiesEntered: temp}, ticketID.ticket));
    }

    const deleteCondition = async() => {
        setSelected([]);
        const temp = [...progressNote.Conditions];

        for (let i = selectedConditions.length; i > 0; i--) {
            if (selectedConditions[i-1]) {
                temp.splice(i-1,1);
            }
        }

        dispatch(updateProgressNote({...progressNote, Conditions: temp}, ticketID.ticket));
    }

    const checker = (arr, value) => arr.every(v => v === value);

    const handleCheckboxSelection = (e, index) => {
        const selectedCheckBoxesArray = [...selectedMedications];
        selectedCheckBoxesArray[index] = e.target.checked;
        setSelectedMedications(selectedCheckBoxesArray);
        let controlCheckBox;
        if(selectedCheckBoxesArray.length === progressNote.Medications.length && checker(selectedCheckBoxesArray, true)) {
            controlCheckBox = "true";
        }
        else if (checker(selectedCheckBoxesArray, false)) {
            controlCheckBox = "false";
        }
        else {
            controlCheckBox = "intermediate";
        }
        setControlCheckBox(controlCheckBox);
    };

    const handleCheckboxCondSelection = (e, index) => {
        const selectedCheckBoxesArray = [...selectedConditions];
        selectedCheckBoxesArray[index] = e.target.checked;
        setSelectedConditions(selectedCheckBoxesArray);
        let controlCondCheckBox;
        if(selectedCheckBoxesArray.length === progressNote.Conditions.length && checker(selectedCheckBoxesArray, true)) {
            controlCondCheckBox = "true";
        }
        else if (checker(selectedCheckBoxesArray, false)) {
            controlCondCheckBox = "false";
        }
        else {
            controlCondCheckBox = "intermediate";
        }
        setControlCondCheckBox(controlCondCheckBox);

    };

    const handleCheckboxAllergySelection = (e, index) => {
        const selectedCheckBoxesArray = [...selectedAllergies];
        selectedCheckBoxesArray[index] = e.target.checked;
        setSelectedAllergies(selectedCheckBoxesArray);
        let controlCheckBox;
        if(selectedCheckBoxesArray.length === progressNote.allergiesEntered.length && checker(selectedCheckBoxesArray, true)) {
            controlCheckBox = "true";
        }
        else if (checker(selectedCheckBoxesArray, false)) {
            controlCheckBox = "false";
        }
        else {
            controlCheckBox = "intermediate";
        }
        setControlAllergyCheckBox(controlCheckBox);
    }

    const handleControlCheckBox = (e) => {
        const selectedCheckBoxesArray = [...selectedMedications];
        for (let i=0; i<progressNote.Medications.length; i++){
            if(!progressNote.Medications[i].prescription_id){
                selectedCheckBoxesArray[i] = e.target.checked;
            } else {
                selectedCheckBoxesArray[i] = false;
            }
        }
        setControlCheckBox(e.target.checked ? "true": "false");
        setSelectedMedications(selectedCheckBoxesArray);
    };

    const handleCondsControlCheckBox = (e) => {
        const selectedCheckBoxesArray = [...selectedConditions];
        for (let i=0; i<progressNote.Conditions.length; i++){
                selectedCheckBoxesArray[i] = e.target.checked;
        }
        setControlCondCheckBox(e.target.checked ? "true": "false");
        setSelectedConditions(selectedCheckBoxesArray);
    }

    const handleAllergyControlCheckBox = (e) => {
        const selectedCheckBoxesArray = [...selectedAllergies];
        for (let i=0; i<progressNote.allergiesEntered.length; i++){
            selectedCheckBoxesArray[i] = e.target.checked;
        }

        setControlAllergyCheckBox(e.target.checked ? "true": "false");
        setSelectedAllergies(selectedCheckBoxesArray);
    }

    const assessmentConditionsHeadersColumns = [
        <FormControlLabel
            control={ <Checkbox
        checked={controlCondCheckBox === "true"}
        indeterminate={controlCondCheckBox === "intermediate"}
        onChange={(e) => handleCondsControlCheckBox(e)}
    />} />, "Name (ICD)", "Notes"]

    const assessmentMedicationsHeadersColumns = [
        <FormControlLabel
           control={ <Checkbox
        checked={controlCheckBox === "true"}
        indeterminate={controlCheckBox === "intermediate"}
        onChange={(e) => handleControlCheckBox(e)}
    />} />, "Medicine", "Dosage", "Quantity", "Notes", "Rx"]

    const assessmentAllergiesHeadersColumns = [
        <FormControlLabel
            control={ <Checkbox
                checked={controlAllergyCheckBox === "true"}
                indeterminate={controlAllergyCheckBox === "intermediate"}
                onChange={(e) => handleAllergyControlCheckBox(e)}
            />} />, "Allergy", "Severity"]

    return (
        <>
                <div >
                    <> {loader.loading && <Loader /> } </>
                    {error &&  <p>Please Update your settings in Setting to proceed further.</p>}
                    <div className='assessmentModal-fieldLabel' style={{display : "flex"}}>
                        New Allergies: {!props.isClosed &&
                        <IconButton onClick={() => handleAddAllergies()}>
                            <AddCircleOutlineIcon className='addPrescription' fontSize="large" style={{marginTop: "-10px"}}/>
                        </IconButton>
                    } </div>


                    {!showMobileViewTable && allergies && allergies.length > 0 &&
                        <AllergiesTableWeb columnHeaders={assessmentAllergiesHeadersColumns}
                                           rows={allergies}
                                           selectedAllergies={selectedAllergies}
                                           handleCheckboxSelection={handleCheckboxAllergySelection}/>
                    }

                    {showMobileViewTable && allergies && allergies.length > 0 &&
                        <AllergiesTableMobile columnHeaders={assessmentAllergiesHeadersColumns}
                                           rows={allergies} selectedAllergies={selectedAllergies}
                                           handleCheckboxSelection={handleCheckboxAllergySelection}/>
                    }
                    <br/> <br/>
                    <div style={{display: "flex"}}>
                        <Button
                            variant="contained" onClick={ () => deleteAllergy() }
                            endIcon={<DeleteIcon />}
                            disabled = {checker(selectedAllergies, false)}
                        >
                            Delete Allergy
                        </Button>
                    </div>

                    {showAddAllergies && <AllergiesModal
                        handleAddAllergies={handleAddAllergies}
                        showAddAllergies={showAddAllergies}
                        // filterOptionsIcd={filterOptionsIcd}
                        // icdOptions={props.icdOptions}
                    />}

                    <br/> <br/>

                    <div className='assessmentModal-fieldLabel' style={{display : "flex"}}>
                        Conditions: {!props.isClosed &&
                        <IconButton onClick={() => handleAddConditions()}>
                            <AddCircleOutlineIcon className='addPrescription' fontSize="large" style={{marginTop: "-10px"}}/>
                        </IconButton>
                    } </div>

                    {showAddConditions && <ConditionsModal
                        handleAddConditions={handleAddConditions}
                        showAddConditions={showAddConditions}
                        filterOptionsIcd={filterOptionsIcd}
                        icdOptions={props.icdOptions}
                    />}

                    {!showMobileViewTable && progressNote && progressNote.Conditions && progressNote.Conditions.length > 0 && <PlanTableWeb
                            columnHeaders={assessmentConditionsHeadersColumns}
                            assessmentConditions={progressNote.Conditions}
                            selectedConditions={selectedConditions}
                            handleCheckboxCondSelection={handleCheckboxCondSelection}
                        /> }

                    {showMobileViewTable && progressNote && progressNote.Conditions && progressNote.Conditions.length > 0 && <PlanTableMobile
                        columnHeaders={assessmentConditionsHeadersColumns}
                        assessmentConditions={progressNote.Conditions}
                        selectedConditions={selectedConditions}
                        handleCheckboxCondSelection={handleCheckboxCondSelection}
                    /> }

                    <br/> <br/>
                    <div style={{display: "flex"}}>
                        <Button
                            variant="contained" onClick={ () => deleteCondition() }
                            // endIcon={<DeleteIcon />}
                            disabled = {checker(selectedConditions, false)}
                        >
                            Delete Condition
                        </Button>
                    </div>

            </div>
            <br/>

                <div >
                    <div className='assessmentModal-fieldLabel' style={{display : "flex"}}>Medications: {!props.isClosed &&
                        <IconButton onClick={() => handleAddMedications()}>
                            <AddCircleOutlineIcon className='addPrescription' fontSize="large" style={{marginTop: "-10px"}}/>
                        </IconButton>
                    }</div>

                    {showAddMedications &&
                        <MedicationsModal
                            isClosed={props.isClosed}
                            handleAddMedications={() => handleAddMedications()}
                            filterOptionsMed={filterOptionsMed}
                            showAddMedications={showAddMedications}
                            medOptions={props.medOptions}
                        />
                    }
                    {!showMobileViewTable &&progressNote && progressNote.Medications && progressNote.Medications.length > 0 &&
                    <PlanMedTableWeb
                        columnHeaders={assessmentMedicationsHeadersColumns}
                        assessmentMedications={progressNote.Medications}
                        selectedMedications={selectedMedications}
                        handleCheckboxSelection={handleCheckboxSelection}
                    />}
                    {showMobileViewTable &&progressNote && progressNote.Medications && progressNote.Medications.length > 0 &&
                        <PlanMedTableMobile
                            columnHeaders={assessmentMedicationsHeadersColumns}
                            assessmentMedications={progressNote.Medications}
                            selectedMedications={selectedMedications}
                            handleCheckboxSelection={handleCheckboxSelection}
                        />}
                      
                    <div style={{padding: "15px"}}>You can update you signature in settings.</div>
                    <div style={{display: "flex"}}>
                       {/*// <UpdateSignature showSignaturePad={showSignaturePad} setShowSignaturePad={setShowSignaturePad} buttonText={!signature ? "Add Signature" : "Update Signature"} />*/}
                        <Button
                            variant="contained"
                            color="primary"
                            disabled = {checker(selectedMedications, false)}
                            onClick={() => handleCreatePrescriptionClick()}>
                            Create Prescription
                        </Button>
                        <Button
                            variant="contained" onClick={ () => deleteMedication() }
                            // endIcon={<DeleteIcon />}
                            disabled = {checker(selectedMedications, false)}
                        >
                            Delete Medication
                        </Button>
                    </div>

                </div>

            <br/><br/>
            <div className='assessmentModal-fieldLabel'>Prescriptions: </div>
            {prescriptionsList && prescriptionsList.length > 0 ? prescriptionsList.map((x) => (
                <div key={x.prescription_id}>
                    { !x.deleted ? <ListItem
                        secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                            <DeleteIcon onClick={() => deleteAPrescription(x.prescription_id)}/>
                        </IconButton>
                    }
                    >
                        <ListItemIcon>
                            <MedicationIcon />
                        </ListItemIcon>
                        <ListItemButton key={x.prescription_id} onClick={() => handlePrescriptionLinkClick(x.prescription_id)}>
                            <ListItemText primary={x.prescription_id} />
                        </ListItemButton>
                    </ListItem> : null}
                </div>
            )): <div style={{textAlign : "center"}}>No Prescriptions available.</div>}
            <br/>
            </>
    );
}

export default AssessmentPlanModal;