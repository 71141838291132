import {
    SET_CURR_IMG_URL,
    SET_IMG_SUB,
    SET_PROFILE_INFO,
    SET_API_RESP_MSG,
    SET_RESP_MSG_CLS,
    SET_IS_LOADING,
    SET_ACC_PRF_IMG_SRC,
    SET_PRF_IMG,
    SET_IMG_FILE,
    SET_IMG_FILE_NAME,
    SET_PAGE_LOADING,
    SET_DOCUMENTS,
    IS_PROFILE_VALID,
    SET_LAT,
    SET_LNG,
    SET_RECOMMENDED_ADDRESS, SET_ADDRESS_COMPONENTS, SET_DIALOG
} from "../actions/settingsActions";
import moment from "moment";

const init = {
    profileInfo: {
        name: "",
        middle_name: "",
        family_name: "",
        suffix: "",
        dob: moment(new Date()).format("YYYY-MM-DD"),
        gender: "",
        bio: "",
        bussiness_phone: "",
        speciality: "",
        medical_school: "",
        physician_number: "",
        provider_identifier_number: "",
        bussiness_address1: "",
        bussiness_address2: "",
        bussiness_city: "",
        bussiness_state: "",
        bussiness_zipcode: "",
    },
    api_respMsg:{
        show: false,
        text: "",
        type: ""
    },
    respMsgCls:"success",
    isLoading:false,
    pageLoading: false,
    currImgUrl:"",
    imgSub: "",
    accsetPrfImgSec: "",
    profImg: "",
    imgFile: "",
    imgFileName: "",
    documents: [],
    validProfile: false,
    bussiness_latitude: null,
    bussiness_longitude: null,
    formatted_address: null,
    address_components: null,
    showFormattedAddressDialog: false,
};

const settingsReducer = (state = init, action) => {
    switch (action.type) {
        case SET_CURR_IMG_URL: {
            return { ...state, currImgUrl: action.payload };
        }
        case SET_IMG_SUB: {
            return { ...state, imgSub: action.payload };
        }
        case SET_PROFILE_INFO: {
            return { ...state, profileInfo: action.payload };
        }
        case SET_API_RESP_MSG: {
            return { ...state, api_respMsg: action.payload };
        }
        case SET_RESP_MSG_CLS: {
            return { ...state, respMsgCls: action.payload };
        }
        case SET_IS_LOADING: {
            return { ...state, isLoading: action.payload };
        }
        case SET_PAGE_LOADING: {
            return { ...state, pageLoading: action.payload };
        }
        case SET_ACC_PRF_IMG_SRC: {
            return { ...state, accsetPrfImgSec: action.payload };
        }
        case SET_PRF_IMG: {
            return { ...state, profImg: action.payload };
        }
        case SET_IMG_FILE: {
            return { ...state, imgFile: action.payload };
        }
        case SET_IMG_FILE_NAME: {
            return { ...state, imgFileName: action.payload };
        }
        case SET_DOCUMENTS: {
            return {...state, documents: action.payload};
        }
        case IS_PROFILE_VALID: {
            return {...state, validProfile: action.payload};
        }
        case SET_LAT: {
            return {...state, bussiness_latitude:action.payload};
        }
        case SET_LNG: {
            return {...state, bussiness_longitude:action.payload};
        }
        case SET_RECOMMENDED_ADDRESS: {
            return {...state, formatted_address:action.payload};
        }
        case SET_ADDRESS_COMPONENTS: {
            return {...state, address_components:action.payload};
        }
        case SET_DIALOG: {
            return {...state, showFormattedAddressDialog:action.payload};
        }
        default:
            return state;
    }
};

export default settingsReducer;