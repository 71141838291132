import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';
import {Link, useNavigate} from "react-router-dom";

import {getCaseTickets} from "../../ducks/actionCreators/casesActionCreator";
import {ROLES} from "../../Main";
import {useDispatch, useSelector} from "react-redux";
import {useUserRole} from "../../hooks/useUserRole";
import "./Home.css";
import {Button} from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        height: '85vh',
    },
    card: {
        marginTop: "100px",
        maxWidth: 120,
        minWidth: 120,
        minHeight: 120,
        padding: theme.spacing(2),
        margin: theme.spacing(2),
        backgroundColor: '#f5f5f5'
    },
}));


const Home = () => {
    const classes = useStyles();

    const userRoles = useUserRole();


    const navigate = useNavigate();

    const dispatch = useDispatch();
    const {cases} = useSelector(state => state.cases);
    const {profileInfo} = useSelector(state => state.settings);

    useEffect( () => {
        dispatch(getCaseTickets());
    }, []);

    const openCases = cases && cases.filter(function (x) {
        return x.status !== 3;
    })

    const handleCardClick = (cardNumber) => {
        if(cardNumber === 1) {
            navigate("/cases")
        } else if(cardNumber === 2) {
            navigate("/myReports")
        }
    };

        if(userRoles && userRoles[0] === ROLES.UNAPPROVED_DOCTOR && profileInfo?.user_reg_status === "new_account") {
            return <div style={{textAlign: "center", height: "80vh", marginTop: "150px"}}>
                <Typography variant="h5" component="h2">
                    You must first fill out your information and request access to Eagle intelligent Health.
                </Typography>
                <Link style={{ textDecoration: 'underline', color: 'green', cursor: 'pointer' , fontSize: "20px"}} to="/physicianProfile"><b>Click the link to request access.</b></Link>
                </div>
        }
        else if(userRoles && userRoles[0] === ROLES.UNAPPROVED_DOCTOR && profileInfo?.user_reg_status === "denied") {
            return <div style={{textAlign: "center", height: "80vh", marginTop: "150px"}}>
                <Typography variant="h5" component="h2">
                    Your previous request was denied. Please create a new request.
                    <Link style={{ textDecoration: 'underline', color: 'green', cursor: 'pointer' , fontSize: "20px"}} to="/createNewRequest"><b>Click to create a new request.</b></Link>
                </Typography>
            </div>
        }
        else if(userRoles && userRoles[0] === ROLES.UNAPPROVED_OPERATOR ) {
            return <div style={{textAlign: "center", height: "80vh", marginTop: "150px"}}>
                <Typography variant="h5" component="h2">
                    Your request is under review.
                </Typography>
            </div>
        }
        else if(userRoles && userRoles[0] === ROLES.OPERATOR ) {
            return (
                <div className={classes.root}>
                    <div onClick={() => handleCardClick(1)}>
                        <Card className={classes.card}>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    Cases
                                </Typography>
                                <Typography color="textSecondary">
                                    {openCases.length} waiting cases.
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            )
        }
        else {
            return (
                <div id="homeWindow">
                    <Button variant="outlined" style={{border: "1px solid black",
                        background: "#E7FEFF",
                        marginTop: "30px",
                        textDecoration: "underline",
                        paddingRight: "10px",
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        paddingLeft: "10px",
                    }}
                            onClick={() => navigate("/cases")}
                    ><ArrowForwardIosIcon style={{width: "10px", height: "10px"}} />Currently {openCases?.length || 0} Patients Needing Immediate Help</Button>

                    <div>
                    <h4>Welcome, Dr. {profileInfo.family_name}!</h4>
                    <h4>Your Dashboard</h4>
                    Welcome to Eagle Intelligent Health. Empowering you to deliver exceptional care with ease and efficiency.
                    <h4>Quick Actions:</h4>
                    <ul>
                        <li><span className="h4">Access your reports</span></li>
                        <li><span className="h4">Schedule an Appointment:</span>Book a new consultation for your patients.</li>
                        <li><span className="h4">Review Medical Records:</span>Access and update patient records with the latest information.</li>
                    </ul>
                    <h4>Patient Management:</h4>
                    <ul>
                        <li><span className="h4">Search Patient Records:</span>Find and review comprehensive patient profiles.</li>
                        <li><span className="h4">New Patient Intake:</span>Register new patients quickly and efficiently.</li>
                    </ul>
                    <h4>Support and Help:</h4>
                    <ul>
                        <li><span className="h4">Help Center:</span>Access tutorials and FAQs.</li>
                        <li><span className="h4">Contact Support:</span>Get help from our support team.</li>
                    </ul>
                    </div>

                </div>
            )
        }
};

export default Home;