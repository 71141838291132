import { SET_WEEK_DY_MSG, SET_WEEK_DY_ERR, SET_TIME_MSG, SET_TIME_ERR, SET_AVIL_SLOT, ADD_DOC_SDL, SHOW_WEEKPICKER, SEL_ENDDATE, WEEKDAYS_LIST, SHOW_TIME_PICKER, START_HR, START_MIN, END_HOUR, END_TIME, SHOW_SUBMIT, SEL_STARTDATE, SEL_STARTTIME, SEL_ENDTIME, SEL_WEEKDAYS, AVAILABLE, SCH_TITLE, SUB_SCH_MSG, SUB_SCH_ERR} from "../actions/availabilityActions";

const init = {
    avil_slots: [],
    doc_scdl: [],
    showWeekPicker: false,
    weekDaysList: [],
    showTimePicker: false,
    startHour: "00",
    startMinute: "00",
    endHour: "00",
    endMinute: "00",
    showSubmit: false,
    selectedStartTime: "",
    selectedEndTime: "",
    selectedWeekDays: [0, 0, 0, 0, 0, 0, 0],
    selectedStartDate: "",
    selectedEndDate: "",
    availabilitySlots: [],
    schTitl: "Availability",
    available: true,
    sbmtSchMsg: "",
    sbmtScherror: false,
    timeMsg: "",
    timeErr: false,
    setWeekDyMsg: "",
    setWeekDyErr: false
    
};
const avilabilityReducer = (state = init, action) => {
    switch (action.type) {
        case SET_AVIL_SLOT: {
            return { ...state, avil_slots: action.payload };
        }
        case ADD_DOC_SDL: {
            return { ...state, doc_scdl: action.payload };
        }
        case SHOW_WEEKPICKER: {
            return { ...state, showWeekPicker: action.payload };
        }
        case WEEKDAYS_LIST: {
            return { ...state, weekDaysList: action.payload };
        }
        case SHOW_TIME_PICKER: {
            return { ...state, showTimePicker: action.payload };
        }
        case START_HR: {
            return { ...state, startHour: action.payload };
        }
        case START_MIN: {
            return { ...state, startMinute: action.payload };
        } case END_HOUR: {
            return { ...state, endHour: action.payload };
        }
        case END_TIME: {
            return { ...state, endMinute: action.payload };
        }
        case SHOW_SUBMIT: {
            return { ...state, showSubmit: action.payload };
        }
        case SEL_STARTTIME: {
            return { ...state, selectedStartTime: action.payload };
        } case SEL_ENDTIME: {
            return { ...state, selectedEndTime: action.payload };
        }
        case SEL_WEEKDAYS: {
            return { ...state, selectedWeekDays: action.payload };
        }
        case SEL_STARTDATE: {
            return { ...state, selectedStartDate: action.payload };
        }
        case SEL_ENDDATE: {
            return { ...state, selectedEndDate: action.payload };
        }
        case AVAILABLE: {
            return { ...state, available: action.payload };
        } 
        case SCH_TITLE: {
            return { ...state, schTitl: action.payload };
        } 
        case SUB_SCH_MSG: {
            return { ...state, sbmtSchMsg: action.payload };
        }
        case SUB_SCH_ERR: {
            return { ...state, sbmtScherror: action.payload };
        }
        case SET_TIME_MSG: {
            return { ...state, timeMsg: action.payload };
        }
        case SET_TIME_ERR: {
            return { ...state, timeErr: action.payload };
        }
        case SET_WEEK_DY_MSG: {
            return { ...state, weekDyMsg: action.payload };
        }
        case SET_WEEK_DY_ERR: {
            return { ...state, weekDyErr: action.payload };
        }


        
        default:
            return state;
    }
};

export default avilabilityReducer;