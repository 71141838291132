import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";

const TabPanelContent = ({rows, rowsPerPage, page, columnHeaders}) => {
    let navigate = useNavigate();
    return (
        <>
            <Table sx={{ width : "25 ch !important"}}>
                <TableHead>
                    <TableRow>
                        {columnHeaders.map((col) => {
                            return (
                                <TableCell key={col} style={{ fontWeight: "bolder" }}>
                                    {col}
                                </TableCell>)
                        })}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows.length === 0 &&
                        <TableRow>
                            <TableCell colSpan={6} align='center'>No data to display</TableCell>
                        </TableRow>
                    }

                    { (rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                    ).map((row) => {
                        return ( <TableRow
                            onClick={() => {
                                navigate(`/viewRequestDetails/${row.request_id}`) } }
                            key={row.request_id}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 },
                                //     "&:nth-child(even) td" : {
                                //     backgroundColor: "#3daf2c"
                                // },
                                "&:nth-child(odd) td" : {
                                    backgroundColor: "rgba(238,249,243,.8300000000000001)"
                                }
                            }}
                        >
                            <TableCell>
                                {row.request_id}
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{new Date(row.last_updated).toLocaleDateString()}</TableCell>

                        </TableRow> )
                    })}

                </TableBody>
            </Table>
        </>
    );
};

export default TabPanelContent;