import React, { useState, useEffect } from 'react';
import {TextField, Button, Checkbox, FormControlLabel, FormHelperText} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { forgotPassword, isUserLoggedIn, signIn } from "../../ducks/actionCreators/loginActionCreator";
import { setMessage } from "../../ducks/actionCreators/messageActionCreator";
import { SnackbarAlert, Loader } from "@drowl/drowl-react-library";
import { Navigate } from "react-router";
import PhysicianSignUp from "./PhysiciansSignUp";
import './Login.css';
import { ForgotPassword } from "./ForgotPassword";
import { getMyRequest } from "../../ducks/actionCreators/requestActionCreator";
import FirstPasswordReset from "./FirstPasswordReset";
import { useNavigate } from "react-router-dom";


const LoginTemplate = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(true); // Checkbox state with default true

  const [userNameError, setUserNameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [rememberMeError, setRememberMeError] = useState(false);

  const [createDoctorProfile, setCreateDoctorProfile] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.login);
  const messageData = useSelector((state) => state.message);
  const loaderData = useSelector((state) => state.loader);
  const settingsData = useSelector((state) => state.settings);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(isUserLoggedIn());
  }, [dispatch, data.isFirstLogIn]);

  useEffect(() => {
    if (data.isUserAuthenticated) {
      dispatch(getMyRequest());
    }
  }, [data.isLoggedIn, data.isUserAuthenticated, data.isFirstLogIn]);

  const submitLogin = () => {
    if (!userName) {
      setUserNameError(true);
    }
    if (!password) {
      setPasswordError(true);
    }
    if (!rememberMe) {
      setRememberMeError(true);
      // Display an error message or handle the case where the checkbox is not checked.
    }
    if(userName && password && rememberMe) {
      dispatch(signIn(userName, password));
    }

  };

  return (
      <>
        <div>
          {loaderData.loading && <Loader />}
          <div className="loginPage">
            {!createDoctorProfile && !isForgotPassword && !data.isFirstLogIn && <>
              {(data.isUserAuthenticated) && !data.isFirstLogIn ? (
                      <>
                        <Navigate to="/home" />
                      </>) :
                  <div className="loginFields">
                    <SnackbarAlert message={messageData.message.text}
                                   type={messageData.message.type}
                                   isOpen={messageData.message.show}
                                   handleClose={() => dispatch(setMessage({ text: "", type: "", show: false }))} />
                    <TextField
                        fullWidth={true}
                        style={{
                          boxSizing: "border-box"
                        }}
                        id="username"
                        placeholder="Username"
                        error={userNameError}
                        helperText={userNameError && "This field is required"}
                        onChange={(e) => {
                          setUserNameError(false);
                          setUserName(e.target.value)
                        }}
                    />
                    <br /> <br />
                    <TextField
                        fullWidth={true}
                        id="password"
                        placeholder="Password"
                        type="password"
                        error={passwordError}
                        helperText={passwordError && "This field is required"}
                        onChange={(e) => {
                          setPasswordError(false);
                          setPassword(e.target.value)
                        }}
                    />
                    <br />  <br />

                    <Button className="loginButton"
                            variant="contained" onClick={submitLogin} disabled={loaderData.loading}>
                      Login
                    </Button>
                    <br />  <br />
                    <FormControlLabel
                        control={<Checkbox checked={rememberMe} onChange={(e) => {setRememberMeError(false); setRememberMe(e.target.checked)}} />}
                        label="By clicking the Login button above, you confirm that your registration with the relevant health authorities is intact and that you are currently authorised to render these services."
                    />
                    {rememberMeError && <span style={{color: "#d32f2f", fontSize: "12px"}}>This field is required</span>}
                    <br />
                    <Button
                        className='loginButton'
                        color="success"
                        onClick={() => setCreateDoctorProfile(true)}
                    >
                      Physicians Create an Account
                    </Button>
                    <Button
                        className='loginButton'
                        color="success"
                        onClick={() => setIsForgotPassword(true)}
                    >
                      Forgot Password
                    </Button>
                  </div>
              }
            </>}
            {isForgotPassword &&
                <ForgotPassword closePasswordChange={setIsForgotPassword} />
            }
            {createDoctorProfile && <PhysicianSignUp />}
            {data.isFirstLogIn && !isForgotPassword && <div><FirstPasswordReset /></div>}
          </div>
        </div>
      </>
  );
}

export default LoginTemplate;
