import Amplify from "aws-amplify";
import Analytics from "@aws-amplify/analytics";

const analyticsConfig = {
	AWSPinpoint: {
		appId: "026773d7eb744a8f80f1c4eef5d80257",
		region: "us-west-2"
	}
	
};
export default analyticsConfig;
