import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import React from "react";
import {Checkbox, FormControlLabel} from "@mui/material";

const PlanTableWeb = ({columnHeaders, assessmentConditions, selectedConditions, handleCheckboxCondSelection}) => {
    return ( <Table sx={{ width : "20 ch !important", border: "1px solid grey !important", borderBottom: "1px solid grey !important", borderCollapse: "revert"}}>
        <TableHead>
            <TableRow>
                {columnHeaders && columnHeaders.map((col, index) => {
                    return (
                        <TableCell key={index} style={{ fontWeight: "bolder" }}>
                            {col}
                        </TableCell>)
                })}
            </TableRow>
        </TableHead>

        <TableBody >
            { assessmentConditions && assessmentConditions.length> 0 && assessmentConditions.map((condition, index) => {
                return (
                    <TableRow key={index}>
                        <TableCell >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedConditions[index] === true}
                                        onChange={(e) => handleCheckboxCondSelection(e,index)}
                                    />
                                }
                            />
                        </TableCell>
                        <TableCell >{condition.condition}</TableCell>
                        <TableCell>{condition.notes}</TableCell>
                    </TableRow>
                )})
            }
        </TableBody>
    </Table> ) }

export default PlanTableWeb;


