import { API, graphqlOperation } from "aws-amplify";
import * as API_SCHEMA_SUBSCRIPTIONS from "./subscriptions";
import { getCaseTickets, setCases} from "../ducks/actionCreators/casesActionCreator";
import {
    getPatientNotes,
    getPatientSettings,
    getProgressNote,
    getTicketInfo, messageNotificationOnCase, notifyDoctorMessage,
    setComments,
    setNewComment, setPrescriptionList,
    setProgressNote, setTicketId,
    setTicketInfo
} from "../ducks/actionCreators/patientOverviewActionCreator";
import {setAvailabilitySlots} from "../ducks/actionCreators/availabilityActionCreator";
import {getUserSubId} from "../utils";
// import {setLoader} from "../ducks/actionCreators/loaderActionCreator";

// chats subscription
export const subscribeToChat = (ticketId) => async (dispatch, getState) => {
    const userId = await getUserSubId();
    return API.graphql(
        graphqlOperation(API_SCHEMA_SUBSCRIPTIONS.onAddSessionComment, { ticketId: ticketId, otherUserId: userId }),
    ).subscribe({
        next: response => {
            //Add comment to chat
            console.log(response, "response");
            let comments = getState().patientOverview.comments;
            let flag = true;
            for (let i = 0; i < comments.length; i++) {
                if (comments[i]._id === response.value.data.onAddSessionComment.comment._id) {
                    flag = false;
                }
            }

            if (flag) {//Dont add duplicates
                if (ticketId === response.value.data.onAddSessionComment.ticketId) {
                    dispatch(setNewComment(true));
                    dispatch(setComments([...getState().patientOverview.comments,response.value.data.onAddSessionComment.comment]));
                    // this.setState({ comments: [...this.state.comments, response.value.data.onAddSessionComment.comment] });
                }
            }

            setTimeout(() => {
                dispatch(setNewComment(false));
            }, 5000);
        },
        error: e => {
            // TODO display message
            console.error(e);
        },
        complete: () => {
        },
    })
}

export const subscribeToDocNotifications = () => async (dispatch, getState) => {
  return API.graphql(
      graphqlOperation((API_SCHEMA_SUBSCRIPTIONS.onAddSessionCommentDoctors)),
  ).subscribe({
      next: response => {
          if (response.value.data.onAddSessionCommentDoctors.status) {
              //console.log(response.value.data.onAddSessionCommentDoctors, "response.value.data.onAddSessionCommentDoctors");
              dispatch(notifyDoctorMessage(response.value.data.onAddSessionCommentDoctors.comment));
              dispatch(messageNotificationOnCase(response.value.data.onAddSessionCommentDoctors.ticketId))
          }
      },
      error: e => {
          console.error(e);
      },
      complete: () => {
      },
  })}

// zoom call subscription
export const  subscribeToZoom  = (ticketId) => async (dispatch) => {
    return API.graphql(
        graphqlOperation(API_SCHEMA_SUBSCRIPTIONS.onCreateZoomMeeting, { ticketId: ticketId }),
    ).subscribe({
        next: response => {
            if (response.value.data.onCreateZoomMeeting.status) {
                dispatch(setComments(response.value.data.onCreateZoomMeeting.comment));
            }
        },
        error: e => {
            console.error(e);
        },
        complete: () => {
        },
    })
}

// status calls in case progress note section
// export const subscribeToProgressNote = (ticketId) => async (dispatch) => {
//     return API.graphql(
//         graphqlOperation(API_SCHEMA_SUBSCRIPTIONS.onUpdateProgressNote, { ticketId: ticketId }),
//     ).subscribe({
//         next: response => {
//             if (response && response.value && response.value.data && response.value.data.onUpdateProgressNote && response.value.data.onUpdateProgressNote.ticket) {
//                 let progressNote = response.value.data.onUpdateProgressNote.ticket.progressNote.replaceAll('%26', '&');
//                 dispatch(setProgressNote(JSON.parse(progressNote)));
//             }
//         },
//         error: e => {
//             console.error(e);
//         },
//         complete: () => {
//         },
//     })
// }

// status calls in case sub section
// export const subscribeToStatus  = (ticketId, ticket) => async (dispatch) => {
//     return API.graphql(
//         graphqlOperation(API_SCHEMA_SUBSCRIPTIONS.onUpdateTicketStatus, { ticketId: ticketId }),
//     ).subscribe({
//         next: response => {
//             if (ticket && ticket._id === response.value.data.onUpdateTicketStatus.ticket._id) {
//                 dispatch(setTicketInfo(response.value.data.onUpdateTicketStatus.ticket))
//             }
//             dispatch(modifyCases(response.value.data.onUpdateTicketStatus.ticket));
//         },
//         error: e => {
//             console.error(e);
//         },
//         complete: () => {
//         },
//     })
// }

// dispatch(getCaseTickets());

// onstartSession to get cases, add new ticket to the list of cases;

const modifyCases = (ticket) => async (dispatch, getState) =>{
    let n = getState().cases.cases.slice();
    let reopened = false;
    for (let i = 0; i < n.length; i++) {
        // Update Case that are already open
        if (n[i]._id === ticket._id) {
            n[i] = ticket;
        } else if (n[i]._id !== ticket._id && ticket.status !== 3) {
            // A ticket status changed from not closed that doesn't exist in cases, thus reopened case
            // dispatch(getCaseTickets());
            reopened = true;
        }
    }
    if (!reopened) {
        dispatch(setCases(n));
    }

    if (getState().patientOverview.ticket && getState().patientOverview.ticketId === ticket._id) {
        dispatch(setTicketInfo(ticket))
    }
}

// calls all tickets, session updates
export const  subscribeToTickets = () => async (dispatch, getState) => {
    return API.graphql(
        graphqlOperation(API_SCHEMA_SUBSCRIPTIONS.onstartSession, {}),
    ).subscribe({
        next: response => {
            // if(getState().login.doctorAssociatedGrps.includes(response.value.data.onstartSession.group)) {
                const newCase = response.value.data.onstartSession.ticket
                const cases =  {...getState().cases.cases, newCase}
                dispatch(setCases(cases))
            // }
        },
        error: e => {
            console.error(e);
        },
        complete: () => {
        },
    })
}

// export const subscribeToTicket = (ticketId) => async (dispatch) => {
//     // console.log("isGettingCalled");
//     return API.graphql(
//         graphqlOperation(API_SCHEMA_SUBSCRIPTIONS.onUpdateTicketUsers, { ticketId: ticketId }),
//     ).subscribe({
//         next: response => {
//             dispatch(getTicketInfo(ticketId));
//         },
//         error: e => {
//             console.error(e);
//         },
//         complete: () => {
//         },
//     })
// }

// adding and removing availabilities for doc in calender
export const subscribeToDctrAddAval = (uId) => async (dispatch, getState) => {
    return API.graphql(
        graphqlOperation(API_SCHEMA_SUBSCRIPTIONS.onAddDoctorAvailability, {id: uId}),
    ).subscribe({
        next: response => {
            //Add doc avail
            if (response.value.data.onAddDoctorAvailability.status) {
                const newAvSlot = response.value.data.onAddDoctorAvailability.available_slot;
                let allSlots = getState().avilability.availabilitySlots;
                allSlots.push(newAvSlot);
                allSlots = allSlots.sort((x, y) => x.start_date - y.start_date);
                allSlots = allSlots.filter((x) =>
                    x.end_date >= new Date().getTime()
                )
                dispatch(setAvailabilitySlots(allSlots));
            }
            // this.getDoctorAvailabilities();
        },
        error: e => {
            console.error(e);
        },
        complete: () => {
        },
    })
}

export const subscribeToDctrRemoveAval = (uId) => async (dispatch, getState) => {
    return API.graphql(
        graphqlOperation(API_SCHEMA_SUBSCRIPTIONS.onRemoveDoctorAvailability, { id: uId }),
    ).subscribe({
        next: response => {
            //Add doc avail
            if (response.value.data.onRemoveDoctorAvailability.status) {
                const allSlots = getState().avilability.availabilitySlots;
                let ind = "";
                ind = allSlots.find(function (item, i) {
                    if (item._id === response.value.data.onRemoveDoctorAvailability.slot_id) {
                        // ind=i;
                        return i;
                    }
                });
                allSlots.splice(ind, 1);
                dispatch(setAvailabilitySlots(allSlots));
            }
        },
        error: e => {
            console.error(e);
        },
        complete: () => {
        },
    })
}

// schedules for calender availabilities
export const subscrieToAddDoctorsSchedule = () => async (dispatch, getState) => {
    return API.graphql(
        graphqlOperation(API_SCHEMA_SUBSCRIPTIONS.onAddDoctorSchedule, {}),
    ).subscribe({
        next: response => {
            if (response.value.data.onAddDoctorSchedule.status) {
                if (getState().patientOverview.ticket && getState().patientOverview.ticket._id === response.value.data.onAddDoctorSchedule.ticket._id) {
                    dispatch(setTicketInfo(response.value.data.onAddDoctorSchedule.ticket));
                }
            }
            dispatch(modifyCases(response.value.data.onAddDoctorSchedule.ticket))
        },
        error: e => {
            console.error(e);
        },
        complete: () => {
        },
    })
}

// overall status changes and ticket asginee status
export const subscribeToAllStatus = () => async (dispatch) => {
    return API.graphql(
        graphqlOperation(API_SCHEMA_SUBSCRIPTIONS.onUpdateTicketDoctors, {}),
    ).subscribe({
        next: response => {
            if(response && response.value.data.onUpdateTicketDoctors.status) {
                if(response && response.value.data.onUpdateTicketDoctors.progressNoteEdited) {
                    dispatch(getProgressNote(response.value.data.onUpdateTicketDoctors.ticket._id))
                }
                dispatch(setComments(response.value.data.onUpdateTicketDoctors.ticket.comments));
                dispatch(setTicketId(response.value.data.onUpdateTicketDoctors.ticket._id));
                // dispatch(getPatientSettings(response.value.data.onUpdateTicketDoctors.ticket.owner));
                dispatch(getPatientNotes(response.value.data.onUpdateTicketDoctors.ticket.notes));
                dispatch(setPrescriptionList(response.value.data.onUpdateTicketDoctors.ticket.prescriptions))
            }
            // dispatch(modifyCases(response.value.data.onUpdateTicketDoctors.ticket));
        },
        error: e => {
            console.error(e);
        },
        complete: () => {
        },
    })
}