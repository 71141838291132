import React from "react";
import "../Stats.css";
import {useSelector} from "react-redux";
import Table from "../../Table/index";
import DoctorInfo from "../Overview/DoctorInfo";
import {TablePagination} from "@mui/material";

const PaymentDetail = () => {

    const statsData = useSelector(state => state.statsReducer);

    const columnHeaders = ["Patient", "Case ID", "Date", "Time", "Amount"];

    const rowHeaders = ["patient", "case_id", "date", "timestamp", "amount"];

    return (
        <div className="textBox pageWindow" id="statsWindow">
            <DoctorInfo type="ADMIN" title="PAYMENT DETAIL"/>
            <div style={{display: "flex"}}>
                <div style={{marginLeft: "20px", marginRight: "20px", padding: "10px"}}> <b>Total Paid:</b> R 500,00</div>
                <div style={{ padding: "10px"}}> <b>Payment ID:</b> 234546546</div>
            </div>
            <Table
                columnHeaders={columnHeaders}
                rows={statsData.paymentDetails}
                rowHeaders={rowHeaders}
            />
            {/*<TablePagination*/}
            {/*    className="tableContainer"*/}
            {/*    rowsPerPageOptions={[10, 15]}*/}
            {/*    component="div"*/}
            {/*    count={statsData && statsData.stats && statsData.stats.tickets.length}*/}
            {/*    rowsPerPage={rowsPerPage}*/}
            {/*    page={page}*/}
            {/*    onPageChange={handleChangePage}*/}
            {/*    onRowsPerPageChange={handleChangeRowsPerPage}*/}
            {/*/>*/}
        </div>
    )
}

export default PaymentDetail;