import React, { useState } from 'react';
import {
    TextField,
    FormControl,
    Select,
    MenuItem,
    Button,
    Typography,
    Container,
    FormHelperText, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from '@mui/material';
import {submitContactForm} from "../../ducks/actionCreators/contactFormActionCreator";
import {useDispatch, useSelector} from "react-redux";
import {setMessage} from "../../ducks/actionCreators/messageActionCreator";

const ContactForm = () => {
    const [name, setName] = useState('');
    const [contactInfo, setContactInfo] = useState('');
    const [helpType, setHelpType] = useState('');
    const [msg, setMsg] = useState('');

    const [errors, setErrors] = useState({
        name: false,
        contactInfo: false,
        helpType: false,
        msg: false,
    });

    const dispatch = useDispatch();

    const messageData = useSelector(state => state.message);

    const {message} = messageData;

    const handleNameChange = (e) => {
        setName(e.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, name: false }));
    };

    const handleContactInfoChange = (e) => {
        setContactInfo(e.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, contactInfo: false }));
    };

    const handleHelpTypeChange = (e) => {
        setHelpType(e.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, helpType: false }));
    };

    const handleMessageChange = (e) => {
        setMsg(e.target.value);
        setErrors((prevErrors) => ({ ...prevErrors, msg: false }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate the form before submission
        const newErrors = {
            name: !name,
            contactInfo: !contactInfo,
            helpType: !helpType,
            msg: !msg,
        };

        if (newErrors.name || newErrors.contactInfo || newErrors.helpType || newErrors.msg) {
            setErrors(newErrors);
            return;
        }

        dispatch(submitContactForm(name,contactInfo,helpType,msg))

        // Clear form fields after submission
        setName('');
        setContactInfo('');
        setHelpType('');
        setMsg('');
        setErrors({
            name: false,
            contactInfo: false,
            helpType: false,
            msg: false,
        });
    };

    return (
        <Container maxWidth="sm">
            {message && message.show && <Dialog
                open={message.show}
                onClose={() => dispatch(setMessage({show: false}))}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <p style={{color: message.type === "error" ? "red" : "green"}}>{(message.type).toUpperCase()} </p>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p style={{padding: "10px"}}>{message.text} </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => dispatch(setMessage({show: false}))}>Close</Button>
                </DialogActions>
            </Dialog> }
            <Typography variant="h4" gutterBottom>Contact Us</Typography>
            <form onSubmit={handleSubmit}>
                <FormControl fullWidth margin="normal" error={errors.name}>
                    <TextField placeholder="Name" value={name} onChange={handleNameChange} />
                    {errors.name && <FormHelperText>This field is required.</FormHelperText>}
                </FormControl>
                <FormControl fullWidth margin="normal" error={errors.contactInfo}>
                    <TextField
                        placeholder="WhatsApp # or Email"
                        value={contactInfo}
                        onChange={handleContactInfoChange}
                    />
                    {errors.contactInfo && <FormHelperText>This field is required.</FormHelperText>}
                </FormControl>
                <FormControl fullWidth margin="normal" error={errors.helpType}>
                    <Select value={helpType} onChange={handleHelpTypeChange}>
                        <MenuItem value="">Select an option</MenuItem>
                        <MenuItem value="app">I need help with the app or website</MenuItem>
                        <MenuItem value="comment">I have a comment/suggestion</MenuItem>
                        <MenuItem value="bug">I would like to report a bug</MenuItem>
                        <MenuItem value="billing">I have a question about payments/billing</MenuItem>
                    </Select>
                    {errors.helpType && <FormHelperText>This field is required.</FormHelperText>}
                </FormControl>
                <FormControl fullWidth margin="normal" error={errors.msg}>
                    <TextField
                        placeholder="Message"
                        multiline
                        rows={4}
                        value={msg}
                        onChange={handleMessageChange}
                    />
                    {errors.msg && <FormHelperText>This field is required.</FormHelperText>}
                </FormControl>
                <Button variant="contained" color="primary" type="submit">
                    Submit
                </Button>
            </form>
        </Container>
    );
};

export default ContactForm;
