import { BASE_URL, getCurrentUserToken, getCurrentUserId } from "../../utils";
import { setLoader } from "./loaderActionCreator";
import { setMessage } from "./messageActionCreator";

export const submitContactForm = (name,contactInfo,helpType,message) => async (dispatch) => {
    dispatch(setLoader(true));
    const token = await getCurrentUserToken();
    await fetch(`${BASE_URL}/DoctorAction/doctorContactForm`, {
        "method": "POST",
        "headers": {
            "Content-Type": "application/json"
        },
        "body": JSON.stringify({
            "token": token,
            "name" : name,
            "contact" : contactInfo,
            "reason" : helpType,
            "message" : message
        })
    })
        .then(data => data.json())
        .then(response => {
            if (!response.status) {
                dispatch(setMessage({
                    text: "Unable to submit your request at the moment. Please try again later",
                    type: "error",
                    show: true
                }));
            } else {
                dispatch(setMessage({
                    text: "Request has been sent successfully",
                    type: "success",
                    show: true
                }));
            }
            dispatch(setLoader(false));
        }).catch(error => {
            dispatch(setMessage({
                text: error.message ?? "Unable to get user settings.",
                type: "error",
                show: true
            }));
            dispatch(setLoader(false));
        });
};