import React from "react";
import {Avatar, Link} from "@mui/material";
import logo from './image001.png';
import "./Help.css";
import ContactForm from "./ContactForm";


const Help = () => {
    const [showForm, setShowForm] = React.useState(false);

    const handleEmailLinkClick = () => {
        setShowForm(true);
    };
    return (
        <div className="textBox" id="helpWindow" >
            <h2>Need Additional Technical Support?</h2>

            <p> Thank you for using Eagle Intelligent Health! We are available for any questions or support you need. </p>

            <p> You input is valuable to us! We are seeking your suggestions on ways to continuously improve the doctor platform. </p>

            <p> Let us know of additional features you suggest or if you come across any bugs. </p>

            <div style={{display: "flex"}}>
                <img src={logo} alt={logo} className="img"></img>
                <div className="content">
                    <ContactForm />
                    <p style={{marginLeft: "30px"}}> You can also schedule a Zoom call with Bekka for live one-to-one technical support. </p>

                    <p style={{marginLeft: "30px"}}> Follow this link to book a time that works best for you: </p>

                    <Link style={{marginLeft: "30px"}} color="primary" variant="body1" href="https://calendly.com/bekka-drowl/eagle-intelligent-health" target="_blank"> https://calendly.com/bekka-drowl/eagle-intelligent-health. </Link>
                   <p></p>
                    <p style={{marginLeft: "30px"}}>Feel free to check out our <Link color="primary" style={{marginRight: "-7px"}} variant="body1" href="/faqs" target="_blank">FAQ</Link>section.</p>

                </div>

            </div>
        </div>
    )
}

export default Help;