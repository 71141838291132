import {SET_MESSAGE} from "../actions/messageActions";

const init = {
	message: {
		text: "",
		show: false,
		type: ""
	}
};

const messageReducer = (state = init, action) => {
	switch (action.type) {
	case SET_MESSAGE : {
		return {...state, message: {text: action.payload.text,
			type: action.payload.type,
			show: action.payload.show}
		};
	}
	default:
		return state;
	}
};

export default messageReducer;