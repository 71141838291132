/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const allComments = /* GraphQL */ `
  query AllComments(
    $token: String!
    $ticketId: ID!
    $type: String!
    $limit: Int!
    $last_comment_time: String!
    $latest: Boolean!
  ) {
    allComments(
      token: $token
      ticketId: $ticketId
      type: $type
      limit: $limit
      last_comment_time: $last_comment_time
      latest: $latest
    ) {
      status
      message
      alert_title
      comments {
        _id
        id
        image
        comment
        owner
        timestamp
        meetingId
        type
        document
        file_name
        document_title
        __typename
      }
      problem
      __typename
    }
  }
`;
export const getTickets = /* GraphQL */ `
  query GetTickets(
    $token: String!
    $limit: String
    $last_session_end_time: String
    $status: String
  ) {
    getTickets(
      token: $token
      limit: $limit
      last_session_end_time: $last_session_end_time
      status: $status
    ) {
      status
      alert_title
      message
      tickets {
        _id
        status
        uid
        owner
        subject
        assignee
        assignee_image
        priority
        timestamp
        problem
        progressNotePdf
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        chiefComplaint
        additionalInformation
        planOfTreatment
        conditionsEntered {
          condition
          notes
          __typename
        }
        medicationsEntered {
          medication
          dosage
          instructions
          numPills
          __typename
        }
        allergiesEntered {
          allergy
          severity
          __typename
        }
        symptomsGroup {
          category
          symptoms
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getUserTickets = /* GraphQL */ `
  query GetUserTickets(
    $token: String!
    $other_user_id: String!
    $limit: Int!
    $last_session_end_time: String!
    $user: Boolean
  ) {
    getUserTickets(
      token: $token
      other_user_id: $other_user_id
      limit: $limit
      last_session_end_time: $last_session_end_time
      user: $user
    ) {
      status
      alert_title
      message
      tickets {
        _id
        status
        uid
        owner
        subject
        assignee
        assignee_image
        priority
        timestamp
        problem
        progressNotePdf
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        chiefComplaint
        additionalInformation
        planOfTreatment
        conditionsEntered {
          condition
          notes
          __typename
        }
        medicationsEntered {
          medication
          dosage
          instructions
          numPills
          __typename
        }
        allergiesEntered {
          allergy
          severity
          __typename
        }
        symptomsGroup {
          category
          symptoms
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getDoctorAttendedTickets = /* GraphQL */ `
  query GetDoctorAttendedTickets(
    $token: String!
    $id: String!
    $limit: Int!
    $last_session_end_time: String!
  ) {
    getDoctorAttendedTickets(
      token: $token
      id: $id
      limit: $limit
      last_session_end_time: $last_session_end_time
    ) {
      status
      alert_title
      message
      tickets {
        _id
        status
        uid
        owner
        subject
        assignee
        assignee_image
        priority
        timestamp
        problem
        progressNotePdf
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        chiefComplaint
        additionalInformation
        planOfTreatment
        conditionsEntered {
          condition
          notes
          __typename
        }
        medicationsEntered {
          medication
          dosage
          instructions
          numPills
          __typename
        }
        allergiesEntered {
          allergy
          severity
          __typename
        }
        symptomsGroup {
          category
          symptoms
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
export const getTicketInfo = /* GraphQL */ `
  query GetTicketInfo($token: String!, $ticketId: ID!, $user: Boolean) {
    getTicketInfo(token: $token, ticketId: $ticketId, user: $user) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const getTicketHistory = /* GraphQL */ `
  query GetTicketHistory($token: String!, $ticketId: ID!) {
    getTicketHistory(token: $token, ticketId: $ticketId) {
      status
      message
      alert_title
      history {
        _id
        id
        image
        action
        owner
        timestamp
        title
        description
        __typename
      }
      __typename
    }
  }
`;
export const getDoctorsInGroup = /* GraphQL */ `
  query GetDoctorsInGroup($token: String!, $groupId: String) {
    getDoctorsInGroup(token: $token, groupId: $groupId) {
      status
      message
      alert_title
      doctors {
        id
        name
        family_name
        image
        __typename
      }
      __typename
    }
  }
`;
export const getUsersByDoctors = /* GraphQL */ `
  query GetUsersByDoctors($token: String!, $dob: String, $last_name: String) {
    getUsersByDoctors(token: $token, dob: $dob, last_name: $last_name) {
      status
      message
      alert_title
      users {
        id
        name
        family_name
        middle_name
        dob
        email
        phone_number
        __typename
      }
      __typename
    }
  }
`;
export const getNotifications = /* GraphQL */ `
  query GetNotifications($token: String!) {
    getNotifications(token: $token) {
      status
      alert_title
      message
      notifications {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      __typename
    }
  }
`;
export const getDoctorSchedules = /* GraphQL */ `
  query GetDoctorSchedules(
    $token: String!
    $id: String!
    $start_time: String!
    $end_time: String!
  ) {
    getDoctorSchedules(
      token: $token
      id: $id
      start_time: $start_time
      end_time: $end_time
    ) {
      status
      message
      alert_title
      schedules {
        _id
        id
        ticketId
        title
        start_time
        end_time
        available
        __typename
      }
      id
      __typename
    }
  }
`;
export const getDoctorAvailabilities = /* GraphQL */ `
  query GetDoctorAvailabilities($token: String!, $other_user_id: ID!) {
    getDoctorAvailabilities(token: $token, other_user_id: $other_user_id) {
      status
      message
      alert_title
      available_slots {
        _id
        id
        start_hour
        start_minute
        end_hour
        end_minute
        start_time
        end_time
        start_date
        end_date
        time_zone
        SUN
        MON
        TUE
        WED
        THU
        FRI
        SAT
        available
        __typename
      }
      __typename
    }
  }
`;
export const getAvailableTimeSlotsForAssigning = /* GraphQL */ `
  query GetAvailableTimeSlotsForAssigning(
    $getAvailableTimeSlotsInputDTO: GetAvailableTimeSlotsInputDTO!
  ) {
    getAvailableTimeSlotsForAssigning(
      getAvailableTimeSlotsInputDTO: $getAvailableTimeSlotsInputDTO
    ) {
      status
      message
      alert_title
      available_slots {
        time
        doctors {
          id
          name
          family_name
          image
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
