import React from "react";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {convertCsDte, getButtonForStatus} from "../../utils/utlis";
import { useNavigate } from "react-router-dom";
import {useSelector} from "react-redux";
import NotificationBell from "../Chat/NotificationBell";


const TabPanelContent = ({rows, rowsPerPage, page, columnHeaders}) => {
    const  {newCommentReceived} = useSelector((state) => state.patientOverview);
    let navigate = useNavigate();
    return (
        <>
            <Table sx={{ width : "25 ch !important"}}>
                <TableHead>
                    <TableRow>
                        {columnHeaders.map((col) => {
                            return (
                                <TableCell key={col} style={{ fontWeight: "bolder" }}>
                                     {col}
                                </TableCell>)
                        })}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows.length === 0 &&
                        <TableRow>
                            <TableCell colSpan={6} align='center'>No data to display</TableCell>
                        </TableRow>
                    }

                    { (rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                    ).map((row) => {
                        const buttonType = getButtonForStatus(row.status);
                       return ( <TableRow
                            onClick={() => {
                                navigate(`/patientOverview/${row._id}`) } }
                            key={row.uid}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 },
                            //     "&:nth-child(even) td" : {
                            //     backgroundColor: "#3daf2c"
                            // },
                                "&:nth-child(odd) td" : {
                                    backgroundColor: "rgba(238,249,243,.8300000000000001)"
                                }
                            }}
                        >

                               <TableCell>
                                   {row.uid}
                               </TableCell>
                               <TableCell ><button className={buttonType.caseBtnClass}> {buttonType.caseStatusBtntext}</button></TableCell>
                               <TableCell>
                                   {convertCsDte(row.timestamp)}
                               </TableCell>
                               <TableCell>{row.subject}</TableCell>
                               <TableCell>{row.assignee || "No Provider"}</TableCell>
                           <TableCell>{localStorage.getItem(row._id) > 0 ? <NotificationBell notificationCount={localStorage.getItem(row._id)} /> : null}</TableCell>

                        </TableRow> )
                    })}

                </TableBody>
            </Table>
        </>
    );
};

export default TabPanelContent;