import React, {useState, useEffect} from "react";
// import "../PaymentDetail/Stats.css";
import {useDispatch, useSelector} from "react-redux";
import {Button, IconButton, InputAdornment, TablePagination, TextField} from "@mui/material"
import SelectionList from "../Overview/SelectionList";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import DoctorInfo from "../Overview/DoctorInfo";
import moment from "moment";
import {getAdminStats, getDoctorStats} from "../../../ducks/actionCreators/docStatsActionCreator";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DateRangeIcon from "@mui/icons-material/DateRange";
import 'bootstrap-daterangepicker/daterangepicker.css';
import Table from "../../Table";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import {getCurrentUserId} from "../../../utils";

const SORT_BY_FIELDS = [
    { label: "First Name", value: "First Name" },
    { label: "Last Name", value: "Last Name" },
    { label: "Number of Visits", value: "Number of Visits"},
    { label: "Amount Owed", value: "Amount Owed"},
    { label: "Amount Earned", value: "Amount Earned"},
];

const DEFAULT_DATE_RANGE = [moment().subtract(29, "days").toDate(), moment().toDate()];

const CombinedStatsDetail = ({pageType}) => {

    const [rows, setRows] = useState([]);
    const [sortValue, setSortField] = useState("First Name") ;
    const [searchText, setSearchText] = useState([]) ;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortOrder, setSortOrder] = useState("asc");
    const [sortBy, setSortBy] = useState("First Name");

    const [showFilterOptions, setShowFilterOptions] = useState(false);

    const statsData = useSelector(state => state.statsReducer);

    const {stats} = statsData;

    const {tickets, doctors} = stats;

    const dispatch = useDispatch()

    const columnHeaders = ["Doctor", "Number of Visits", "Amount Owed", "Amount Earned"];

    const rowHeaders = ["doctor", "num_visits", "amt_owed", "amt_earned"];

    const [showMobileViewTable, setShowMobileViewTable] = useState(window.innerWidth < 600);

    const [dateRange, setDateRange] = useState({
        from_date: moment(DEFAULT_DATE_RANGE[0]).format("YYYY-MM-DD"),
        to_date: moment(DEFAULT_DATE_RANGE[1]).format("YYYY-MM-DD"),
        label: moment(DEFAULT_DATE_RANGE[0]).format("YYYY-MM-DD") + " to " + moment(DEFAULT_DATE_RANGE[1]).format("YYYY-MM-DD")
    });

    const handleDateRangeChange = async (start, end) => {
        setDateRange({
            from_date: start.format("YYYY-MM-DD"),
            to_date: end.format("YYYY-MM-DD"),
            label: start.format("YYYY-MM-DD") + " to " + end.format("YYYY-MM-DD")
        });

        const params = {
            from_date: start.format("YYYY-MM-DD"),
            to_date: end.format("YYYY-MM-DD"),
        };
        dispatch(getAdminStats(params));
    };

    useEffect(() => {
        window.scrollTo(0,0);
        if(doctors && doctors.length>0) {
            setRows(doctors);
        }
    }, [doctors]);

    // useEffect(() => {
    //     const params = {
    //         from_date: moment(DEFAULT_DATE_RANGE[0]).format("YYYY-MM-DD"),
    //         to_date: moment(DEFAULT_DATE_RANGE[1]).format("YYYY-MM-DD"),
    //     };
    //     dispatch(getDoctorStats(params));
    // },[]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const sortData = (sortBy, sortOrder, rowsToSort) => {
        let itemsToSort = [...rowsToSort];
        let sortedItems = [];
        let compareFn ;

        switch (sortBy) {
            case "First Name":
                compareFn = (i, j) => {
                    if (i.name < j.name) {
                        return sortOrder === "asc" ? -1 : 1;
                    } else {
                        if (i.name > j.name) {
                            return sortOrder === "asc" ? 1 : -1;
                        } else {
                            return 0;
                        }
                    }
                };
                break;
            case "Last Name" :
                compareFn = (i, j) => {
                    if (i.family_name < j.family_name) {
                        return sortOrder === "asc" ? -1 : 1;
                    } else {
                        if (i.family_name > j.family_name) {
                            return sortOrder === "asc" ? 1 : -1;
                        } else {
                            return 0;
                        }
                    }
                };
                break;
            case "Number of Visits" :
                compareFn = (i, j) => {
                    if (Number(i.num_visits) < Number(j.num_visits)) {
                        return sortOrder === "asc" ? -1 : 1;
                    } else {
                        if (Number(i.num_visits) > Number(j.num_visits)) {
                            return sortOrder === "asc" ? 1 : -1;
                        } else {
                            return 0;
                        }
                    }
                };
                break;
            case "Amount Owed" :
                compareFn = (i, j) => {
                    if (i.num_owed < j.num_owed) {
                        return sortOrder === "asc" ? -1 : 1;
                    } else {
                        if (i.num_owed > j.num_owed) {
                            return sortOrder === "asc" ? 1 : -1;
                        } else {
                            return 0;
                        }
                    }
                };
                break;
            case "Amount Earned" :
                compareFn = (i, j) => {
                    if (i.amt_earned < j.amt_earned) {
                        return sortOrder === "asc" ? -1 : 1;
                    } else {
                        if (i.amt_earned > j.amt_earned) {
                            return sortOrder === "asc" ? 1 : -1;
                        } else {
                            return 0;
                        }
                    }
                };
                break;
            default:
                break;
        }
        sortedItems = itemsToSort.sort(compareFn);
        return sortedItems;

    };

    const requestSort = (pSortBy, rowsToSort) => {

        let sortingOrder = sortOrder;
        let sortedBy = sortBy;

        if (pSortBy === sortedBy) {
            sortingOrder = sortingOrder === "asc" ? "desc" : "asc";
        } else {
            sortedBy = pSortBy;
            sortingOrder = "asc";
        }

        const sortedItems = sortData(sortBy, sortOrder, rowsToSort);
        setSortBy(sortedBy);
        setSortOrder(sortingOrder);
        setRows(sortedItems);
    };

    return (
        <div className="textBox pageWindow" id="statsWindow">
            <DoctorInfo type="OVERVIEW" title="DETAIL"/>
            {/*<br/> <h2>Recent Telemedicine Visits </h2>*/}
            <br/>
            <Button
                size="large"
                style={{float: "right"}}
                startIcon={<FilterAltIcon />}
                onClick={() => {
                    setShowFilterOptions(!showFilterOptions);
                }}>
            </Button>

            {showFilterOptions &&
                <div>
                    <div >
                        <div className="filterBox">
                            <DateRangePicker
                                initialSettings={{
                                    startDate: moment(dateRange.from_date).toDate(),
                                    endDate: moment(dateRange.to_date).toDate(),
                                    ranges: {
                                        "Today": [moment().toDate(), moment().toDate()],
                                        "Yesterday": [
                                            moment().subtract(1, "days").toDate(),
                                            moment().subtract(1, "days").toDate()
                                        ],
                                        "Last 7 Days": [
                                            moment().subtract(6, "days").toDate(),
                                            moment().toDate()
                                        ],
                                        "Last 30 Days": [
                                            moment().subtract(29, "days").toDate(),
                                            moment().toDate()
                                        ],
                                        "This Month": [
                                            moment().startOf("month").toDate(),
                                            moment().endOf("month").toDate()
                                        ],
                                        "Last Month": [
                                            moment().subtract(1, "month").startOf("month").toDate(),
                                            moment().subtract(1, "month").endOf("month").toDate()
                                        ]
                                    }
                                }}
                                onCallback={ handleDateRangeChange} >
                                <div
                                    className="col-xs-9 col-md-5 col-sm-6 form-control dateRangePicker datePickerSearch"
                                >
                                    <span><DateRangeIcon /></span>
                                    <span>{dateRange.label}</span>
                                </div>
                            </DateRangePicker>
                            {/*<TextField*/}
                            {/*    className="searchBox"*/}
                            {/*    placeholder="Search"*/}
                            {/*    value={searchText}*/}
                            {/*    onChange={(e) => setSearchText(e.target.value)}*/}
                            {/*    InputProps={{*/}
                            {/*        endAdornment: <InputAdornment position="end"> <IconButton> <PersonSearchIcon /> </IconButton> </InputAdornment>,*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <span style={{marginLeft: "10px"}}></span>
                            <SelectionList
                                classname="dropdown"
                                options={SORT_BY_FIELDS}
                                selectedValue={sortValue}
                                setSelectedValue={(e) => setSortField(e.target.value)}
                                defaultTitle="SORT BY"
                                sortField={() => requestSort(sortValue, rows)}
                                sortOrder={sortOrder}
                                sortBy={sortBy}
                            />
                        </div>
                    </div>
                </div> }

            <Table
                columnHeaders={columnHeaders}
                rowHeaders={rowHeaders}
                alignRows=""
                rows={rows}
                rowsPerPage={rowsPerPage}
                page={page}
                requestSort={requestSort}
                sortOrder={sortOrder}
                sortBy={sortBy}
                rowClick={true}
            />
            {/*{ !showMobileViewTable ? <TableWebTickets*/}
            {/*        columnHeaders={columnHeaders}*/}
            {/*        rows={statsData && statsData.stats && statsData.stats.tickets}*/}
            {/*        rowsPerPage={rowsPerPage}*/}
            {/*        page={page}*/}
            {/*        // requestSort={requestSort}*/}
            {/*        // sortOrder={sortOrder}*/}
            {/*        // sortBy={sortBy}*/}
            {/*    /> :*/}
            {/*<TableMobileTickets*/}
            {/*    columnHeaders={columnHeaders}*/}
            {/*    rows={statsData && statsData.stats && statsData.stats.tickets}*/}
            {/*    rowsPerPage={rowsPerPage}*/}
            {/*    page={page}*/}
            {/*    // requestSort={requestSort}*/}
            {/*    // sortOrder={sortOrder}*/}
            {/*    // sortBy={sortBy}*/}
            {/*/>}*/}
            {/* <br/>*/}

            {rows && rows.length > 0 &&  <TablePagination
                className="tableContainer"
                rowsPerPageOptions={[10, 15]}
                component="div"
                count={rows && rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> }
        </div>
    )
}

export default CombinedStatsDetail;