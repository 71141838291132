import { GET_PROVIDERS, SET_SCHEDULE_SLOTS, SET_SCHEDULE_TELEMED, SET_TICKET_STATUS, SET_CHANGE_PROVIDER_STATUS, SET_CASE_HISTORY } from "../actions/caseActions";

const init = {
    providers: [],
    slotSchedules: [],
    telemedSlotStatus: null,
    ticketStatus: false,
    providerChangeStatus: null,
    ticketHistoryList: []
};

const caseReducer = (state = init, action) => {
    switch (action.type) {
        case GET_PROVIDERS: {

            return { ...state, providers: action.payload };
        }
        case SET_SCHEDULE_SLOTS: {
            return { ...state, slotSchedules: action.payload };
        }
        case SET_SCHEDULE_TELEMED: {
            return { ...state, telemedSlotStatus: action.payload };
        }
        case SET_TICKET_STATUS: {
            return { ...state, ticketStatus: action.payload };
        }
        case SET_CHANGE_PROVIDER_STATUS: {
            return { ...state, providerChangeStatus: action.payload };
        }
        case SET_CASE_HISTORY: {
            return { ...state, ticketHistoryList: action.payload };
        }
        default:
            return state;
    }
};

export default caseReducer;