import {getCurrentUserToken} from "../../utils";
import {API, graphqlOperation} from "aws-amplify";
import * as API_SCHEMA_MUTATIONS from "../../graphql/mutations";
import {SET_ZOOM_URL} from "../actions/videoActions";
import {setMessage} from "./messageActionCreator";
import {setLoader} from "./loaderActionCreator";
import {getTicketInfo} from "./patientOverviewActionCreator";

export const setZoomUrl = (data) => ({ type: SET_ZOOM_URL, payload: data });

export const startVideoChat = (ticketId, ticket)  => async(dispatch) => {
    dispatch(setLoader(true));
    const token = await getCurrentUserToken();
    const owner = ticket.owner;

        try {
            const res = await API.graphql(graphqlOperation(API_SCHEMA_MUTATIONS.createZoomMeeting, {
                zoomMeeting: {
                    token: token,
                    ticketId: ticketId,
                    userId: owner
                }
            }));

                if (res && res.data?.createZoomMeeting?.start_url) {
                    await dispatch(getTicketInfo(ticketId));
                    dispatch(setZoomUrl(res.data.createZoomMeeting.start_url));
                    window.open(res.data.createZoomMeeting.start_url, "_blank")
                } else {
                    dispatch(setMessage(res.data.createZoomMeeting.message));
                }
            dispatch(setLoader(false));

        }
        catch (e) {
            dispatch(setMessage("Something went wrong. Please try again later!"));
            dispatch(setLoader(false));
        }

};