import React, {useEffect, useState} from "react";
import {
    TextField,
    Button,
    Grid,
    Select,
    MenuItem
} from "@mui/material";
import {
    setPhysicianRequestRes, makePhysicianApprovalRequest
} from "../../ducks/actionCreators/requestActionCreator";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import {useDispatch, useSelector} from "react-redux";
import {Storage} from "aws-amplify";
import IconButton from "@mui/material/IconButton";
import {getUserSubId} from "../../utils";
import {createUUID} from "../../utils";
import {DOCUMENTS_LIST} from "../../utils/constants";
import {useNavigate} from "react-router-dom";
import AlertModal from "../Admin/AlertModal";
import {Alert} from "@mui/lab";
import Loading from "../Loading/Loading";
import {Loader} from "@drowl/drowl-react-library";
import {setLoader} from "../../ducks/actionCreators/loaderActionCreator";

const FileToUpload = ()  => {
    const [file, setFile] = React.useState([]);
    const [docName, setDocName] = useState([]);

    const [documents, setDocuments] = useState(1);
    const [alert, setAlert] = useState({show: false, message: "", type: ""})
    const [submitAlert, setSubmitAlert] = useState({show: false, message: "", type: ""})

    const dispatch = useDispatch();

    const requestData = useSelector((state) => state.requestData);
    const loader = useSelector((state) => state.loader);

    const {makeRequestResponse} = requestData;

    const navigate = useNavigate();

    // Handles file upload event and updates state
    const handleUpload = (event, i) => {
        setSubmitAlert({show: false, message: "", type: ""});
        setAlert({type: "", message: "", show: false});
        const tempFile = [...file];
        tempFile[i] = event.target.files[0];
        setFile(tempFile);
    }

    const uploadDocuments = async () => {
        dispatch(setLoader(true));
        setSubmitAlert({show: false, message: "", type: ""});
        setAlert({type: "", message: "", show: false});
            const userId = await getUserSubId();
            const uuid =  createUUID();

        // const values = ['Proof of Practice Address *', 'Proof of Residence Address *',
        //     'Medical Registration Card *', 'The front of South African ID *', 'The back of South African ID *'];

        const values = ['Medical Registration Card *', 'The front of South African ID *', 'The back of South African ID *'];

        // let errUploading = false;




        const multipleExist = values.every(value => {
            // docName[i] = docName[i].replace('*', '');
            // let docuName = docName[i].split(' ').join('_');
            // value = value.replace('*', '');
            // console.log(value, docName);
            return value && docName.includes(value);
        });


        const multipleExist1 = file.every(fle => {
            return fle && fle.size !== 0;
        })


        if(multipleExist && multipleExist1) {
            for (let i = 0; i < documents ; i++) {
                docName[i] = docName[i].replace('*', '');
                let docuName = docName[i].split(' ').join('_');
                const fileType = file[i].type.split("/")[1];
                let newImgS3loc ;
                newImgS3loc = "providers/" + userId + "/" +  uuid + "/"+  docuName + "." + fileType;
                if(fileType !== "pdf" && docName[i] === "Other"){
                    setSubmitAlert({show: true, message: "You can only upload pdf documents.", type: "error"});
                }
                try {
                    await Storage.put(newImgS3loc, file[i]);
                }
                catch (err) {
                    dispatch(setLoader(false));
                    file[i] = 0;
                    setSubmitAlert({show: true, message: "Error trying to upload a document, please try agan later.", type: "error"});
                }
            }
            dispatch(setLoader(false));
            if(multipleExist1) {
                dispatch(makePhysicianApprovalRequest(uuid));
            }
        } else {
            setSubmitAlert({show: true, message: "Please submit the mandatory documents", type: "error"});
            dispatch(setLoader(false));
        }



    }

    useEffect(() => {
        if(makeRequestResponse && makeRequestResponse.status === "error") {
            setAlert({type: "error", message: "Something went wrong, please try again later", show: true});
        }
        if(makeRequestResponse && makeRequestResponse.status === true) {
            setAlert({type: "success", message: makeRequestResponse.message, show: true});
            dispatch(setPhysicianRequestRes(""));
        }
        if(makeRequestResponse && makeRequestResponse === false) {
            setAlert({type: "error", message: makeRequestResponse.message, show: true});
            dispatch(setPhysicianRequestRes(""));
        }
    }, [makeRequestResponse])

   const addDocument = () => {
       setDocuments(documents + 1);
   }


    const rows = [];
    const objectRow = (i) => (
        <Grid container spacing={{ xs: 1, md: 0 }} >
            <Grid item  md={2} />
            <Grid item  xs={12} md={2} > Document {i + 1} </Grid>
        <Grid item xs={12} md={4}>
            <Select id="gender"
                    labelme="gender"
                    className="form-control genderSelect"
                    onChange={(e) => { const tempdocs = [...docName]; tempdocs[i] = e.target.value; setDocName(tempdocs) }}
                    value={docName[i]}
                    style={{ background: 'white', width : "89%" }}
            >
                {DOCUMENTS_LIST.map(x =>  <MenuItem value={x.label} style={{ background: 'white' }} >{x.label}</MenuItem>)}
            </Select>
        </Grid>
        <Grid item xs={12} md={4}>
            <TextField type="file"  onChange={() => handleUpload(event,i)}  />
        </Grid>

            <div style={{paddingTop: "20px", marginBottom: "25px"}}></div>
        </Grid>);

    for (let i = 0; i < documents ; i++) {
        rows.push(objectRow(i));
    }

    return (
        <>
        <> {loader.loading && <Loader /> } </>
        <div id="settingsWindow" className="pageWindow">

            {submitAlert.show && <Alert severity={submitAlert.type} style={{width: "94%"}} >
                {submitAlert.message}
            </Alert> }
            {alert.show &&
                <AlertModal openModal={alert.show} closeModal={()=> {
                    setAlert({show: false, message: "", type: ""});

                }
                }
                            message={alert}
                            pathToNavigate="/request"
                >
                </AlertModal>
            }
                <h2> Upload documents </h2>
            <br/>
                {rows}
            <Grid>
                <Grid item xs={12} md={2}>
                <IconButton color="primary" aria-label="add more documents" component="label" onClick={addDocument}>
                    <AddCircleOutlineRoundedIcon />
                    <p style={{ fontSize: "15px", paddingLeft: "10px"}}>Add more.</p>
                </IconButton>
                </Grid>
            </Grid>

            <Button variant="contained" onClick={uploadDocuments}> Submit Request for Access </Button>

        </div>
            </>
    );
}

export default FileToUpload;

