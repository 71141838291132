import React, {useState, useEffect} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    TextField,
    Grid,
    MenuItem
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
    updateProgressNote
} from "../../ducks/actionCreators/patientOverviewActionCreator";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import {Alert} from "@mui/lab";


const AllergiesModal = ({handleAddAllergies, showAddAllergies, isClosed}) => {

    const [allergies, setAllergies] = useState({
        allergy : "",
        severity: ""
    });
    const [assessmentAllergies, setAssessmentAllergies] = useState([]);
    const [error, setError] = useState();

    // const [showAddMedications, setShowAddMedications] = useState(false);

    const ticketID = useParams();
    const dispatch = useDispatch();
    const patientOverviewData = useSelector(state => state.patientOverview);

    let { ticket, progressNote} = patientOverviewData;

    useEffect(() => {
        setAssessmentAllergies(progressNote && progressNote.allergiesEntered || []);
    }, [progressNote && progressNote.allergiesEntered]);

    const updatedAssessmentConditions = (updatedData) => {
        dispatch(updateProgressNote(updatedData, ticket._id));
    };


    const handleAddAllergyButtonClick = async() => {
        if(!allergies.allergy) {
            setError({show: true, message: "Enter an allergy to proceed.", type: "error"});
        }
        else {
            const updatedData = [...assessmentAllergies, allergies];
            let assessmentUpdate = {
                ...progressNote,
                'allergiesEntered': updatedData,
            };

            try {
                await updatedAssessmentConditions(assessmentUpdate);
                handleAddAllergies();
            } catch (e) {
                console.error(e);
                return;
            }
        }
    }

    return (
        <Dialog open={showAddAllergies} className="assessmentModal">
            <DialogTitle>
                Add Allergy
                <IconButton
                    onClick={()=> handleAddAllergies()}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>

                {error && error.show &&  <Alert severity={error.type} style={{width: "94%"}} onClose={() => setError({show:false, message: "", type: ""})}>
                    {error.message}
                </Alert> }

                <div >
                    <div>
                        <div className='assessmentModal-formGroup'>
                            <div className='assessmentModal-fieldLabel' >Allergies:</div>
                            <TextField
                                style={{ width: '90%' }}
                                value={allergies.allergy}
                                variant="outlined"
                                onChange={(event) => {
                                    setAllergies({...allergies, allergy: event.target.value})
                                    setError({show:false, message: "", type: ""})
                                }}
                            />
                        </div>

                    </div>
                    <div className='assessmentModal-formGroup'>
                        <div className='assessmentModal-fieldLabel' >Severity:</div>
                        <TextField
                            select
                            disabled={isClosed}
                            style={{ width: '90%' }}
                            variant="outlined"
                            value={allergies.severity}
                            onChange={(event) => setAllergies({...allergies, severity : event.target.value})}
                        >
                            <MenuItem value="mild">Mild</MenuItem>
                            <MenuItem value="moderate">Moderate</MenuItem>
                            <MenuItem value="severe">Severe</MenuItem>
                        </TextField>

                    </div>
                </div>
                <div style={{marginBottom: "40px"}} />
            </DialogContent>

            <DialogActions>
                {!isClosed &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAddAllergyButtonClick()}>
                        Add
                    </Button>
                }
                <Button variant="contained" onClick={() => handleAddAllergies()}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default AllergiesModal;
