import { GET_CASES, GET_CLOSED_CASES, GET_USER_CASES, SET_END_OF_USER_TICKETS } from "../actions/casesActions";

const init = {
    cases: [],
    closedCases: [],
    userTickets: [],
    isEndOfUserTickets: false
};

const casesReducer = (state = init, action) => {
    switch (action.type) {
        case GET_CASES: {
            return { ...state, cases: action.payload };
        }
        case GET_CLOSED_CASES: {
            return { ...state, closedCases: action.payload };
        }
        case GET_USER_CASES: {
            return { ...state, userTickets: action.payload };
        }
        case SET_END_OF_USER_TICKETS: {
            return { ...state, isEndOfUserTickets: action.payload};
        }
        default:
            return state;
    }
};

export default casesReducer;