import React, { useEffect, useState, useRef } from 'react';
import {IconButton, MenuItem,InputAdornment, TextField} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import './Chat.css';
import { useDispatch, useSelector } from "react-redux";
import {
    addComment,
    messageNotificationOnCase,
    notifyDoctorMessage
} from "../../ducks/actionCreators/patientOverviewActionCreator";
import { Loader } from "@drowl/drowl-react-library";
import {useUserRole} from "../../hooks/useUserRole";
import {ROLES} from "../../Main";
import { makeStyles } from '@material-ui/core/styles';
import SelectableMessages from "../SelectableMessages";
import {useParams} from "react-router-dom";

const useStyles = makeStyles({
    root: {

        '& .MuiOutlinedInput-root': {
            '&:focus': {
                outline: 'none',
            },
            '&.Mui-focused fieldset': {
                border: 'none',
            },
            '&:hover fieldset': {
                border: 'none',
            },
            '& fieldset': {
                border: 'none',
            },
        },
    },
});


const allowedOperatorMsgs = [
    {label: "Thank You.", value: "Thank You."},
    {label: "A doctor will be with you shortly.", value: "A doctor will be with you shortly."},
    {label: "Can you explain more about your problem.", value: "Can you explain more about your problem."},
    {label: "Is this an emergency.", value: "Is this an emergency."}
]

const allowedOperatorMsgs1 = ["Thank You.",
    "A doctor will be with you shortly.",
    "Can you explain more about your problem.",
    "Is this an emergency."
]


const Chat = () => {

    const userRoles = useUserRole();
    const classes = useStyles();

    const [closedTicket, setClosedTicket] = useState(false);
    const [chatMessage, setChatMessage] = useState("");
    const [canSendChat, setCanSendChat] = useState(false);
    const settingsData = useSelector((state) => state.settings);

    const {profileInfo} = settingsData;

    const [inputText, setInputText] = useState("");

    const dispatch = useDispatch();
    const chatRef = useRef(null);

    const patientOverviewData = useSelector(state => state.patientOverview);
    const loader = useSelector((state) => state.loader);
    const {ticket, comments} = patientOverviewData;

    const data = useSelector((state) => state.login);
    const  {newCommentReceived} = useSelector((state) => state.patientOverview);


    const operatorMessages = [
        {label: "Intro",
            value: `Thank you for using Eagle Intelligent Health! My name is ${profileInfo.name} ${profileInfo.family_name}, I will be working to connect you with a doctor shortly.`},
        {label: "Please hold",
            value: "Thank you for your patience. We are experiencing longer than usual wait times. We will be connecting you with a doctor shortly. "
        },
    ]

const doctorMessages = [
    {label: "Intro",
        value: `Hello! I am Dr. ${profileInfo.name} ${profileInfo.family_name}. I will be helping you today. Please meet me on Zoom for your consultation.`},
    {label: "Intro",
        value: `Hello! I am Dr. ${profileInfo.name} ${profileInfo.family_name}. I will be helping you today. Can you tell me more about the symptoms you are experiencing?`
    },
    {label: "Sick Note",
        value: "Can I help provide you a sick note today?"},
    {label: "Closing",
        value: "Is there anything else I can help you with today before I close our chat?"},
    {label: "Closing",
        value: "Thank you for using Eagle Intelligent Health. I am closing this case now. If you would like to connect with a doctor again, please start a new telemedicine call and we will be happy to see you again!"},
]

    useEffect(() => {
        window.scrollTo(0,0);
        setClosedTicket(ticket.status === 3);
    }, [ticket]);

    useEffect(() => {
        dispatch(notifyDoctorMessage(null));
        localStorage.getItem(ticket._id);
        localStorage.setItem(ticket._id, 0);
        dispatch(messageNotificationOnCase(null))
    }, [newCommentReceived]);


    const handleInputTextChange = (event) => {
        setInputText(event.target.value);
    }
    // useEffect(() => {
    //     if(chatRef.current){
    //         chatRef.current.scrollIntoView();
    //     }
    // }, [comments]);

    //Decode if Applicable
    const decodeComment = (comment) => {
        try {

            return decodeURIComponent(atob(comment));
        } catch (e) {
            return comment;
        }
    }
    //Decode if Applicable
    const encodeComment = (comment) => {
        try {
            return btoa(encodeURIComponent(comment));
        } catch (e) {
            return comment;
        }
    }
    //Encode if Applicable
    const addCommentCall = (comment) => {
        dispatch(addComment(ticket._id, encodeComment(comment)));
    }


    const timeSinceFormatted = (dateTime) => {
        const sec_millis = 1000;
        const min_millis = 60 * sec_millis;
        const hour_millis = 60 * min_millis;

        let now = new Date().getTime();
        let offSet = (new Date(dateTime)).getTimezoneOffset();
        let dateTimeLocally = dateTime + (offSet);

        let dateTimeSince = now - dateTimeLocally;

        // console.log(dateTimeSince, "dateTimeSince")

        const diffMillis = now - dateTime;
        const seconds = Math.floor(diffMillis / 1000);
        const secondsPart = seconds % 60;
        const minutes = Math.floor(seconds / 60);
        const minutesPart = minutes % 60;
        const hoursPart = Math.floor(minutes / 60);

        if (dateTimeSince < min_millis) {
            return "Just now";
        } else if (dateTimeSince < 2 * min_millis) {
            return "A minute ago";
        } else if (dateTimeSince < 50 * min_millis) {
            return Math.round(dateTimeSince / min_millis) + " minutes ago";
        } else if (dateTimeSince < 120 * min_millis) {
            return "An hour ago at" + new Date(dateTimeLocally).toLocaleTimeString();
        }
        else if (dateTimeSince < 24 * hour_millis) {
            return Math.round(dateTimeSince / hour_millis) + " hours ago at " + new Date(dateTimeLocally).toLocaleTimeString();
        }
        else if (dateTimeSince < 48 * hour_millis) {
            return "Yesterday at " + new Date(dateTimeLocally).toLocaleTimeString();
        } else {
            //const timestamp = 1687192732393; // Replace with your timestamp

            const date = new Date(dateTime);

            const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
            const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

            return formattedDate
        }


    };

    const loggedInUser = data?.userData?.attributes?.sub;
      if (!loggedInUser) {
        return <div></div>;
      }


      return (
          <>
          <div style={{display: "flex"}}>
            <div style={{width: "70%"}}>
                <> {loader.loading && <Loader /> } </>
              <div className="chatWin">
                {/* Message List  */}

                <div>
                  <div id='conversationContainer' className='conversation'>
                    {comments && comments.length>0 && comments.map((chatcomment, index) => {
                      const name = chatcomment.owner;
                      const comment = decodeComment(chatcomment.comment);
                      // const date = this.getTimesince(new Date(Number(chatcomment.timestamp)).toLocaleString());
                      const img = chatcomment.image;

                      let commentBubleSec = "commentLeftBublSec";
                      let chatcommentSec = "commentLeftSec";
                      if (loggedInUser != null && chatcomment.id === loggedInUser) {
                        commentBubleSec = "commentRightBublSec";
                        chatcommentSec = "commentRightSec"
                      }

                      if (chatcomment.type === "informational") {
                        return (

                            <>
                                <div key={index} className="infrmtnlPrb" >
                                    {comment}
                                </div>
                                <div className="chatCommentDate">{timeSinceFormatted(Number(chatcomment.timestamp))}</div>
                            </>
                        )
                      }
                      else {
                        return (
                            <div key={index} className="commentSec" >
                              <div className={chatcommentSec}>
                                <div className={commentBubleSec}>
                                  <div className="chatUserInfoSec"><img src={img} className="chatProfImg" alt="patient" /><span className="chatProfNme">{name}</span></div>
                                  <div className="commentBox">
                                    <div ref={chatRef} className="commentText">{comment}</div>
                                    <div className="chatCommentDate">{timeSinceFormatted(Number(chatcomment.timestamp))}</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        )
                      }

                    })}
                  </div>
                </div>

                {/* Message Text Box Area  */}
                {!closedTicket &&
                <>{userRoles && userRoles[0] === ROLES.OPERATOR ?

                    <div className='chatTextBox'>
                        <div className='chatTextBoxContainer'>
                            <TextField
                                variant="outlined"
                                placeholder='Enter Message...'
                                style={{width: '90%'}}
                                value={chatMessage}
                                onChange={(e) => setChatMessage(e.target.value)}
                                select
                                InputProps={{
                                    endAdornment:<>
                                        {/*<TextField*/}
                                        {/*    className={classes.root}*/}
                                        {/*    style={{width: "50%", padding: "10px"}}*/}
                                        {/*    // variant="standard"*/}
                                        {/*    value={inputText}*/}
                                        {/*    onChange={(e) => setInputText(e.target.value)}/>*/}
                                        <IconButton
                                            aria-label="search"
                                            onClick={() => {
                                                if (chatMessage !== "") {
                                                    addCommentCall(chatMessage);
                                                    setCanSendChat(false);
                                                    setTimeout(() => {
                                                        setCanSendChat(true);
                                                    }, 2500);
                                                }
                                                setChatMessage("");
                                            }
                                            }>
                                            <SendIcon fontSize='large'/>
                                        </IconButton>
                                    </>

                                }}>
                            >
                                {allowedOperatorMsgs.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </TextField>

                           {/* <Autocomplete
                                value={chatMessage}
                                onChange={(event, newValue) => {
                                    setChatMessage(newValue);
                                }}
                                inputValue={inputText}
                                onInputChange={(event, newInputValue) => {
                                    setInputText(newInputValue);
                                }}
                                id="controllable-states-demo"
                                options={allowedOperatorMsgs1}
                                sx={{ width: 300 }}
                                renderInput={(params) => <TextField {...params}  />}
                            />*/}
                        </div>
                    </div> :
                    <div className='chatTextBox'>
                        <div className='chatTextBoxContainer'>
                            <>
                                <TextField
                                variant="outlined"
                                placeholder='Enter Message...'
                                style={{width: '90%'}}
                                multiline
                                value={chatMessage}
                                onChange={(e) => setChatMessage(e.target.value)}
                                InputProps={{
                                    endAdornment:
                                        <>
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="search"
                                                    onClick={() => {
                                                        if (chatMessage !== "") {
                                                            addCommentCall(chatMessage);
                                                            setCanSendChat(false);
                                                            setTimeout(() => {
                                                                setCanSendChat(true);
                                                            }, 2500);
                                                        }
                                                        setChatMessage("");
                                                    }
                                                    }>
                                                    <SendIcon fontSize='large'/>
                                                </IconButton>
                                            </InputAdornment>
                                        </>
                                }}>
                            </TextField>

                                </>
                        </div>
                    </div>
                }</>
                }
              </div>
            </div>

              <div style={{position: "absolute", right: "5.5%", width: "24%"}}>
                  <div style={{ marginTop: "20px", fontWeight: "bold" , fontSize: "18px", color: "grey"}}> Click to add to chat </div>
                  <SelectableMessages messages={ userRoles && userRoles[0] === ROLES.OPERATOR ? operatorMessages : doctorMessages} setChatMessage={setChatMessage}/>
              </div>
                  </div>

          </>
      );

}

export default Chat;
