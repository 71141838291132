export const SET_AVIL_SLOT = "SET_AVIL_SLOT";
export const ADD_DOC_SDL = "ADD_DOC_SDL";
export const SHOW_WEEKPICKER = "SHOW_WEEKPICKER";
export const WEEKDAYS_LIST = "WEEKDAYS_LIST";
export const SHOW_TIME_PICKER = "SHOW_TIME_PICKER";
export const START_HR = "START_HR";
export const START_MIN = "START_MIN";
export const END_HOUR = "END_HOUR";
export const END_TIME = "END_TIME";
export const SHOW_SUBMIT = "SHOW_SUBMIT";
export const SEL_STARTTIME = "SEL_STARTTIME";
export const SEL_ENDTIME = "SEL_ENDTIME";
export const SEL_WEEKDAYS = "SEL_WEEKDAYS";
export const SEL_STARTDATE = "SEL_STARTDATE";
export const SEL_ENDDATE = "SEL_ENDDATE";
export const SCH_TITLE = "SCH_TITLE";
export const AVAILABLE = "AVAILABLE";
export const SUB_SCH_ERR = "SUB_SCH_ERR";
export const SUB_SCH_MSG = "SUB_SCH_MSG";
export const SET_TIME_MSG = "SET_TIME_MSG";
export const SET_TIME_ERR = "SET_TIME_ERR";
export const SET_WEEK_DY_MSG = "SET_TIME_ERR";
export const SET_WEEK_DY_ERR = "SET_TIME_ERR";
