import React, {useEffect, useState} from "react";
import {
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    TextField,
    Button,
    Grid, Box, Tabs, Tab, TablePagination
} from "@mui/material";
import NoteIcon from "@mui/icons-material/Note";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {
    adminApprovePhysicianRequest,
    getDocument, getProfileHistory, setAdminApprovalRes, setAdminDenialRes, setDocumentLink
} from "../../ducks/actionCreators/requestActionCreator";
import DenialModal from "./DenialModal";
import TabPanel from "../Cases/TabPanel";
import Settings from "../Settings/Settings";
import AlertModal from "./AlertModal";
import {Loader} from "@drowl/drowl-react-library";

const RequestDetails = ({page}) => {
    const requestData = useSelector((state) => state.requestData);

    const dispatch = useDispatch();

    const {reqId} = useParams();

    const navigate = useNavigate();

    const data = page === "history" ? requestData && requestData.adminRequestsHistory.find(x => x.request.request_id === reqId).request
        : requestData && requestData.adminRequests.find(x => x.request_id === reqId);

    const [showDenialModal, setShowDenialModal] = useState(false);
    const [tab, setTab] = useState(0);
    const [loader, setLoader] = useState(false);

    const {approveRequestResponse, denyRequestResponse, documentLink} = requestData;

    const [alert, setAlert] = useState({show: false, message: "", type: ""})

    const loaderData = useSelector((state) => state.loader);
    const urlParams = useParams();

    useEffect(() => {
        if(documentLink) {
            window.open(documentLink, "_target");
        }
        dispatch(setDocumentLink(""));
    }, [documentLink]);

    useEffect(() => {
             if(page === "docDetails") {
                dispatch(getProfileHistory(urlParams.reqId));
            }
             // else if(page !== "docDetails" && tab === 0) {
             //     dispatch(getProfileHistory(data.id));
             // }

    }, [page, tab]);

    useEffect(() => {
        if(approveRequestResponse && approveRequestResponse === "error") {
            dispatch(setAdminApprovalRes(""));
            setShowDenialModal(false);
            setAlert({message: "Something went wrong, please try again later", show: true, type: "success"})
            setLoader(false);
        }
        if(approveRequestResponse && approveRequestResponse.status) {
            dispatch(setAdminApprovalRes(""));
            setShowDenialModal(false);
            setAlert({message: approveRequestResponse.message, show: true, type: "success"})
            setLoader(false);
        }
        if(!approveRequestResponse && approveRequestResponse.status) {
            setShowDenialModal(false);
            setAlert({show: true, message: approveRequestResponse.message, type: "error"})
            dispatch(setAdminApprovalRes(""));
            setLoader(false);
        }
        if(denyRequestResponse && denyRequestResponse === "error") {
            setShowDenialModal(false);
            setAlert({show: true, message: "Something went wrong, please try again later", type: "error"})
            dispatch(setAdminDenialRes(""));
            setLoader(false);
        }
        if(!denyRequestResponse && denyRequestResponse.status) {
            setShowDenialModal(false);
            setAlert({show: true, message: denyRequestResponse.message, type: "error"})
            dispatch(setAdminDenialRes(""));
            setLoader(false);
        }
        if(denyRequestResponse && denyRequestResponse.status) {
            setShowDenialModal(false);
            setAlert({show: true, message: denyRequestResponse.message, type: "success"})
            dispatch(setAdminDenialRes(""));
            setLoader(false);
        }
    }, [approveRequestResponse, denyRequestResponse])

    const approveRequest = (reqId, id) => {
        setLoader(true);
        setAlert({status: false,message: "", type: ""})
        dispatch(adminApprovePhysicianRequest(reqId, id))
    }

    const denyRequest = () => {
        setAlert({status: false,message: "", type:""})
        setShowDenialModal(true);
    }

    const viewDocument = (url, id) => {
        dispatch(getDocument(url, id));
    }

    const onTabChange = (e, newTab) => {
        setTab(newTab);
    }

    return (
        <div id="settingsWindow" className={page !== "docDetails" && "pageWindow" }>
            <div>
                {alert.show &&
                    <AlertModal openModal={alert.show} closeModal={()=> {
                        setAlert({show: false, message: "", type: ""});
                    }}
                                message={alert}
                                pathToNavigate="/viewRequests"
                    >
                    </AlertModal>
                }

                {(loader || loaderData.loading) && <Loader />}

                {page !== "docDetails" && <div className="edtProfTitlSec" style={{display: "flex"}}>
                    <div className="pagetitl" style={{flex: "1"}}><b>Name:</b> {data && data.name} {data && data.family_name}</div>
                    <div className="pagetitl" style={{flex: "1"}}><b>Request Date:</b> {data && new Date(data.last_updated).toLocaleDateString()}</div>
                </div>}
                <br />
                <Box sx={{ width: '100%' , border:  `${page !== "docDetails" ? '1px solid green' : ""}`} }>
                    {page !== "docDetails" && <Box sx={{ width: '100%' , borderBottom: 1, borderColor: 'divider' }} className="tabCss">
                        <Tabs value={tab} onChange={onTabChange} aria-label="case tabs"  variant="fullWidth" id="cases" TabIndicatorProps={{
                            style: {
                                backgroundColor: "#6aaf7fad",
                            }
                        }}>
                            <Tab label="Profile" className="leftTab" />
                            <Tab label="Documents" className="rightTab" />
                        </Tabs>
                    </Box> }

                    { page !== "docDetails" &&
                        <TabPanel value={tab} index={0}>
                            <Settings doctorProfileInfo={page === "history" ?  requestData && requestData.profileHistory : data} disabled={true}/>
                        </TabPanel> }
                    {page === "docDetails" &&  <Settings doctorProfileInfo= {requestData.profileHistory} disabled={true}/>}
                    {page !== "docDetails" && <TabPanel value={tab} index={1}>
                       <div className="pageWindow">

                           <div className="edtProfTitlSec"><div className="pagetitl">Documents</div></div>

                           <Grid item  xs={12} md={8} >
                               {data && data.documents.map(document => {
                                   return(
                                       <ListItem >
                                       <ListItemButton key={document.id} onClick={() => viewDocument(document.url, data.id)}>
                                           <ListItemIcon>
                                               <NoteIcon />
                                           </ListItemIcon>
                                           <ListItemText primary={document.title} />
                                       </ListItemButton>
                                   </ListItem>
                                   )
                               })}
                               <br/> <br/>

                               {page !== "history" ? <><Button variant="contained" onClick={() => approveRequest(data.request_id, data.id)}> Approve </Button>
                               <Button variant="contained" onClick={() => denyRequest()}> Deny </Button> </>: null }

                               {showDenialModal &&
                                   <DenialModal
                                       openModal={showDenialModal}
                                       closeModal={() => setShowDenialModal(false)}
                                       id={data.id}
                                       setLoader={setLoader}
                                       loader={loader}
                                   />
                               }
                           </Grid><br/>

                       </div>
                    </TabPanel> }
                </Box>
            </div>
        </div>
    )
}

export default RequestDetails;
