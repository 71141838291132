import React, {useState} from "react";
import {FAQS} from "./FaqNewContent"
import "../Faqs/Faqs.css";
import {Link, Button} from "@mui/material";


const Popup = ({faq}) => {
    return (
        <div style={{width: "100%", textAlign: "justify"}}>
            <video src={faq.link} controls autoPlay style={{width: "100%", height: "100%"}}/>

            {faq.text1 && <p>{faq.text1}</p>}
            {faq.text2 && <p>{faq.text2}</p>}
            {faq.text3 && <p>{faq.text3}</p>}
            {faq.text4 && <p>{faq.text4}</p>}
            {faq.text5 && <p>{faq.text5}</p>}
            {faq.text6 && <p>{faq.text6}</p>}
        </div>
    )
}

const FaqNew = () => {

    const [isPopupOpen, setPopupOpen] = useState();

    const togglePopup = (title) => {

        if(isPopupOpen === title) {
            setPopupOpen();
        } else {
            setPopupOpen(title);
        }

    };

    return (
        <div id="faqs"  style={{display: "flex", marginLeft: "10%", marginRight: "10%", height: "80vh"}}>
            {FAQS.map((section) => {
                return Object.keys(section).map((subsection) => {
                    return (
                        <div>
                                <h4 style={{textAlign: "center"}} className="adjustMargin"> {subsection} </h4>

                            <div style={{width : "100%" }}> {section[subsection].map((faq) => {
                                return (
                                    <div>
                                        <Button onClick={() => togglePopup(faq.displayTitle)}>{faq.displayTitle}</Button>
                                        {isPopupOpen === faq.displayTitle && <Popup faq={faq}/>}
                                    </div>


                                )
                            })}</div>

                        </div>
                    )

                });
            })}
        </div>
    )

}

export default FaqNew;