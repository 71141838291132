import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import './Settings.css';
import './../Search/Search.css';
import profPic from '../../assets/images/profile-picture-edit.png';
import {
  setCurrImgUrl,
  setIsLoading,
  changProfPicApiCall,
  updtCurrImg,
  saveSettingsInfo,
  setImgFileName,
  setImgFile,
  setApiRespMsg,
  isProfileDataValid,
  validateAddress,
  setRecommenedAddress,
  setFormattedAddressDialogue,
  addDoctorSignature,
} from "../../ducks/actionCreators/settingsActionCreator"
import {
  Grid,
  Button,
  TextField,
  Select,
  FormControl,
  MenuItem,
  Stack,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography, Dialog, DialogTitle, IconButton, DialogContent, DialogActions
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getCurrentAuthUser } from "../../utils/utlis";
import {Storage} from "aws-amplify";
import { Loader } from  "@drowl/drowl-react-library";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {setMessage} from "../../ducks/actionCreators/messageActionCreator";
import {setStep} from "../../ducks/actionCreators/loginActionCreator";
import {SA_LANGUAGES} from "../../utils/constants";
import {Alert} from "@mui/lab";
import FormattedAddress from "./FormattedAddress";
import {useUserRole} from "../../hooks/useUserRole";
import {ROLES} from "../../Main";
import SignaturePad from "../SignaturePad";
import {setPrescriptionListSuccess} from "../../ducks/actionCreators/patientOverviewActionCreator";
import CloseIcon from "@mui/icons-material/Close";
import UpdateSignature from "../SignaturePad/UpdateSignature";


const Settings = ({disabled, doctorProfileInfo, view}) => {

  const userRoles = useUserRole();

  const dispatch = useDispatch();

  const navigate = useNavigate()
  const data = useSelector((state) => state.settings);
  const {validProfile} = data;

  const api_respMsg = data.api_respMsg;
  const respMsgCls = data.respMsgCls;
  const currImgUrl = data.currImgUrl;
  const imgSub = data.imgSub;
  const imgFile = data.imgFile;
  const imgFileName = data.imgFileName;
  let newImgS3loc = data.newImgS3loc;


  const [dateVal, setDateVal] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const {profileInfo, formatted_address, showFormattedAddressDialog} = data;

  const [showSignaturePad, setShowSignaturePad] = useState(false);

  const [profileData, setProfileData] = useState({
    name: "",
    middle_name: "",
    family_name: "",
    suffix: "",
    dob: moment(new Date()).format("YYYY-MM-DD"),
    gender: "",
    bio: "",
    bussiness_phone: "",
    speciality: "",
    medical_school: "",
    physician_number: "",
    phone_number: "",
    email: "",
    practice_name: "",
    south_africa_id: "",
    provider_identifier_number: "",
    bussiness_address1: "",
    bussiness_address2: "",
    bussiness_city: "",
    bussiness_state: "",
    bussiness_country: "",
    bussiness_zipcode: "",
    residence_address1: "",
    residence_address2: "",
    residence_city: "",
    residence_state: "",
    residence_zipcode: "",
    languages: [],
    signature: ""
  });

  let errConfig;

  if(userRoles && userRoles[0] === ROLES.OPERATOR) {
    errConfig = {
      name: false,
      family_name:false,
      dob: false,
      gender: false,
      bio: false,
      practice_name: false,
      south_africa_id: false,
      bussiness_address1: false,
      bussiness_city: false,
      bussiness_state: false,
      bussiness_zipcode: false,
      residence_address1: false,
      residence_city: false,
      residence_state: false,
      residence_zipcode: false,
      languages: false
    }
  } else {
    errConfig = {
      name: false,
          family_name:false,
        dob: false,
        gender: false,
        bio: false,
        speciality: false,
        medical_school: false,
        practice_name: false,
        south_africa_id: false,
        physician_number: false,
        provider_identifier_number: false,
        bussiness_address1: false,
        bussiness_city: false,
        bussiness_state: false,
        bussiness_zipcode: false,
        residence_address1: false,
        residence_city: false,
        residence_state: false,
        residence_zipcode: false,
        languages: false
    }
  }
  const [errors, setErrors] = useState(errConfig);

  useEffect(() => {
    setDateVal(data.dob);
  }, [data && data.dob])

  useEffect(() => {
    dispatch(setApiRespMsg({
      show: false,
      text: "",
      type: ""
    }))
    dispatch(setRecommenedAddress(null))
  }, [])

  const checkKeys = (testObj) => {
    return {
      name: testObj.name || "",
      middle_name: testObj.middle_name || "",
      family_name: testObj.family_name || "",
      suffix: testObj.suffix || "",
      dob: testObj.dob || moment(new Date()).format("YYYY-MM-DD"),
      gender: testObj.gender || "",
      bio: testObj.bio || "",
      bussiness_phone: testObj.bussiness_phone || "",
      speciality: testObj.speciality || "",
      practice_name: testObj.practice_name || "",
      south_africa_id: testObj.south_africa_id || "",
      medical_school: testObj.medical_school || "",
      physician_number: testObj.physician_number || "",
      provider_identifier_number: testObj.provider_identifier_number || "",
      bussiness_address1: testObj.bussiness_address1 || "",
      bussiness_address2: testObj.bussiness_address2 || "",
      bussiness_city: testObj.bussiness_city || "",
      bussiness_state: testObj.bussiness_state || "",
      bussiness_zipcode: testObj.bussiness_zipcode || "",
      residence_address1: testObj.residence_address1 || "",
      residence_address2: testObj.residence_address2 || "",
      residence_city: testObj.residence_city || "",
      residence_state: testObj.residence_state || "",
      residence_zipcode: testObj.residence_zipcode || "",
      languages: testObj.languages || [],
      phone_number: testObj.phone_number || "",
      email: testObj.email || "",
      signature: testObj.signature || "",
    }
  }

  useEffect(() => {
    // dispatch(isProfileDataValid(false));
    if(profileInfo && Object.keys(profileInfo).length > 0) {
      setProfileData(checkKeys(profileInfo));
    }
    if(doctorProfileInfo && Object.keys(doctorProfileInfo).length > 0) {
      setProfileData(checkKeys(doctorProfileInfo));
    }
    // console.log(doctorProfileInfo,profileInfo, "doctorProfileInfo");
  }, [profileInfo, doctorProfileInfo])

  const changeHandler = (event, field) => {
    dispatch(setApiRespMsg({
      show: false,
      text: "",
      type: ""
    }))
    if(field === "bussiness_address1" || field === "bussiness_address2" || field === "bussiness_city" || field === "bussiness_state" || field === "bussiness_zipcode") {
      dispatch(setFormattedAddressDialogue(false));
    }
    const tempInfo = {...profileData};
    tempInfo[field] = event.target.value;
    setProfileData({...tempInfo});
    const tempErrs = {...errors};
    if(field in tempErrs) {
      tempErrs[field] = false;
    }
    setErrors(tempErrs);
  }

  const handleChange = (newValue) => {
    dispatch(setApiRespMsg({
      show: false,
      text: "",
      type: ""
    }))
    setErrors({...errors, dob: false});
    setProfileData({...profileData, dob: newValue});
  };

  const handleLangChange = (event, index) => {
    dispatch(setApiRespMsg({
      show: false,
      text: "",
      type: ""
    }))
    const temp = [...profileData.languages];
    if(!profileData.languages.includes(event.target.name) && event.target.checked){
      setErrors({...errors, languages: false});
      temp[index] = event.target.name;
      setProfileData({...profileData, languages: temp});
    } else {
      setErrors({...errors, languages: false});
      const deleteIndex = profileData.languages.indexOf(event.target.name);
      temp.splice(deleteIndex, 1) ;
      setProfileData({...profileData, languages: temp});
    }
  }

  const updtPrfImg = async (event) => {
    let img = null;
    if (event.target.value !== "") {

      img = event.target.files[0];
      dispatch(setImgFile(event.target.files[0]));
      dispatch(setImgFileName("providers/" + imgSub + "/" + event.target.files[0].name));
      let reader = new FileReader();

      reader.onloadend = () => {
        dispatch(setCurrImgUrl(reader.result));
      }
      reader.readAsDataURL(img)
    }
    else {
      dispatch(setImgFile(""));
      dispatch(setImgFileName(""));
      let user = await getCurrentAuthUser();
      if (user.signInUserSession.idToken.payload["custom:image_url"] !== undefined && user.signInUserSession.idToken.payload["custom:image_url"] !== "") {
        dispatch(setCurrImgUrl(user.signInUserSession.idToken.payload["custom:image_url"]));
      }
    }
  }

  const upldProfImg = (imgfl, imgnm) => {
    dispatch(setIsLoading(true));
    Storage.put(imgnm, imgfl, {
      contentType: 'image/png'
    })
      .then(result => {
        newImgS3loc = "public/" + imgnm;
        dispatch(changProfPicApiCall(newImgS3loc));
      }
      )
      .catch(err => {
        dispatch(updtCurrImg());
        dispatch(setIsLoading(false));
      }
      );
  }

  const onNextButtonClick = () => {
    dispatch(setMessage({text: "", type: "", show: false} ));
    if(validProfile) {
      dispatch(setStep(1));
    }
  };

  const updateSignature = () => {
    dispatch(addDoctorSignature())
  }

  const accountForm = async () => {
    const tempErrors = {...errors};

    for (const key in errors) {
      if(!profileData[key] || (key === "languages" && profileData[key].length === 0)) {
        tempErrors[key] = true;
      }
    }
    setErrors(tempErrors);


    const isEmpty = Object.values(tempErrors).every(x => x === false);

    if(isEmpty) {
      await dispatch(validateAddress(profileData.bussiness_address1,profileData.bussiness_address2,profileData.bussiness_city, profileData.bussiness_state, profileData.business_zipcode));

      dispatch(setIsLoading(true));
      // dispatch(setApiRespMsg(""));

      if (imgFile != null && imgFile !== "" && imgFile !== undefined) {
        upldProfImg(imgFile, imgFileName);
        dispatch(setIsLoading(true));
      }

      dispatch(saveSettingsInfo(profileData));
    }
    //dispatch(isProfileDataValid(false));
  };
  const enteredAddress = `${profileData.bussiness_address1 && profileData.bussiness_address1+","}${profileData.bussiness_address2 && profileData.bussiness_address2+","} ${profileData.bussiness_city && profileData.bussiness_city+","} ${profileData.bussiness_state} ${profileData.bussiness_zipcode}`

  return (
      <>
      <FormattedAddress
          showDialog={!showFormattedAddressDialog && formatted_address && (formatted_address !== enteredAddress)}
          profileData={profileData}
          setProfileData={setProfileData}
      />
      <div id={disabled ? "" : "settingsWindow"} className="pageWindow" >
        {(data.isLoading || data.pageLoading) ? <Loader /> :
        <FormControl style={{width : "100%" }}>
          <div>
                <>{api_respMsg.show &&  <Alert severity={api_respMsg.type } style={{width: "94%" }} onClose={() =>
                    dispatch(setApiRespMsg({
                      show: false,
                      text: "",
                      type: ""
                    }))
                }> {api_respMsg.text} </Alert> }</>


            <div className="edtProfTitlSec"><div className="pagetitl">Profile</div></div>
            <br />
            {!disabled && <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <br />
              <div>
        
                {currImgUrl ? <img src={currImgUrl} className="stngsPrfImg" alt="profile" /> :
                    <img src={profPic} className="stngsPrfImg" alt="profile" /> }
                <Button
                  variant="contained"
                  component="label"
                  className="imgStyle"
                  startIcon={<CameraAltIcon aria-label="upload picture" />}
                >
                  Edit
                  <input hidden accept="image/*" type="file" onChange={updtPrfImg} />
                </Button>
              </div>
            </Grid> }
          </div>
          <div>
          <br />
            <Grid>

              {/*{!approvalRequest &&*/}
              {/*   <div style={{display: "flex"}}>*/}
              {/*     <p style={{color: "red", marginRight: "50px"}}>Not Approved for TeleHealth</p>*/}
              {/*     <Button style={{padding: "10px", margin: "5px"}} variant="contained" color="primary" onClick={() => navigate("/createNewRequest")}>Submit For Approval</Button>*/}
              {/*   </div>  }*/}

              {/* Personal email, personal phone number, South African ID# API calls to be added! */}
              <form className="form-horizontal pIfrm"  name="accform">
                <Typography variant="h6">Unshared Information</Typography>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Personal Email:
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography>{profileData.email}</Typography>       
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Personal Phone Number:
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Typography>{profileData.phone_number}</Typography>
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    South African ID #: <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                        disabled={disabled}
                        value={profileData.south_africa_id}
                        className='advancedField fieldWithSettngs'
                        variant="outlined"
                        error={errors.south_africa_id}
                        onChange={(e) =>  changeHandler(e, "south_africa_id")}
                        helperText={errors.south_africa_id && "This is a required field"}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Residence Address line 1 <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                        disabled={disabled}
                        value={profileData.residence_address1}
                        className='advancedField fieldWithSettngs'
                        variant="outlined"
                        onChange={(e) =>  changeHandler(e, "residence_address1")}
                        error={errors.residence_address1}
                        helperText={errors.residence_address1 && "This is a required field"}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Residence Address line 2
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                        disabled={disabled}
                        value={profileData.residence_address2}
                        className='advancedField fieldWithSettngs'
                        variant="outlined"
                        onChange={(e) =>  changeHandler(e, "residence_address2")}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Residence City <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                        disabled={disabled}
                        value={profileData.residence_city}
                        className='advancedField fieldWithSettngs'
                        variant="outlined"
                        onChange={(e) =>  changeHandler(e, "residence_city")}
                        error={errors.residence_city}
                        helperText={errors.residence_city && "This is a required field"}
                        maxLength="32"
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Residence State <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                        disabled={disabled}
                        value={profileData.residence_state}
                        className='advancedField fieldWithSettngs'
                        variant="outlined"
                        onChange={(e) =>  changeHandler(e, "residence_state")}
                        error={errors.residence_state}
                        helperText={errors.residence_state && "This is a required field"}
                        maxLength="32"
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Residence ZIP	Code <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                        disabled={disabled}
                        value={profileData.residence_zipcode}
                        className='advancedField fieldWithSettngs'
                        variant="outlined"
                        onChange={(e) =>  changeHandler(e, "residence_zipcode")}
                        error={errors.residence_zipcode}
                        helperText={errors.residence_zipcode && "This is a required field"}
                        maxLength="32"
                    />
                  </Grid>
                </Grid><br/>
              <Typography variant="h6">Shared Information</Typography>
              <br />
              <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4} >
                    First Name<sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      value={profileData.name}
                      className='advancedField fieldWithSettngs'
                      variant="outlined"
                      onChange={(e) => {
                        changeHandler(e, "name");
                      }}
                      disabled={disabled}
                      error={errors.name}
                      helperText={errors.name && "This is a required field"}
                    />
                  </Grid>
                </Grid><br/>
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4} >
                    Middle Name
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      value={profileData.middle_name}
                      className='advancedField fieldWithSettngs'
                      variant="outlined"
                      disabled={disabled}
                      onChange={(e) =>
                          changeHandler(e, "middle_name")}
                      maxLength="32"
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4} >
                    Last Name<sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      value={profileData.family_name}
                      className='advancedField fieldWithSettngs'
                      variant="outlined"
                      disabled={disabled}
                      onChange={(e) => {
                       changeHandler(e, "family_name")
                      }}
                      error={errors.family_name}
                      helperText={errors.family_name && "This is a required field"}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Suffix
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      value={profileData.suffix}
                      className='advancedField fieldWithSettngs'
                      disabled={disabled}
                      variant="outlined"
                      onChange={(e) => changeHandler(e, "suffix")}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Date of Birth<sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {/* <TextField
                      value={dob}
                      className='advancedField'
                      variant="outlined"
                      style={{ background: 'white', width: '30vw' }}
                      onChange={(e) => dispatch(setDOB(e.target.value))}
                      required
                    > */}
                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                      <Stack spacing={3}>
                        <DesktopDatePicker
                          toolbarPlaceholder="MM/DD/YYYY"
                          inputFormat="MM/DD/YYYY"
                          value={profileData.dob}
                          onChange={handleChange}
                          renderInput={(params) => <TextField {...params}
                                                              error={errors.dob}
                                                              helperText={errors.dob && "This is a required field"}
                                                              disabled={disabled}
                            value={profileData.dob}
                                                              className='advancedField fieldWithSettngs'
                            variant="outlined"
                          />
                        }
                        />
                      </Stack>
                    </LocalizationProvider>
                    {/* </TextField> */}
                    {/*<DatePicker*/}
                    {/*    label="Basic example"*/}
                    {/*    value={dateVal}*/}
                    {/*    onChange={handleChange}*/}
                    {/*    renderInput={(params) => <TextField {...params} />}*/}
                    {/*/>*/}

                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Gender <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <span id="gendererror" className="help-block"> </span>
                    <FormControl style={{ background: 'white' }} fullWidth>
                      <Select id="gender"
                        labelme="gender"
                        className="form-control genderSelect "
                              style={{textAlign : "left"}}
                        onChange={(e) => changeHandler(e, "gender")}
                              disabled={disabled}
                        value={profileData.gender}
                      >
                        <MenuItem value={"Female"} style={{ background: 'white', width: '20vw' }} >Female</MenuItem>
                        <MenuItem value={"Male"} style={{ background: 'white', width: '20vw' }}>Male</MenuItem>
                        <MenuItem value={"Other"} style={{ background: 'white', width: '20vw' }}>Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <br />

                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Bio <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      value={profileData.bio}
                      multiline
                      disabled={disabled}
                      minRows={4}
                      className='advancedField fieldWithSettngs'
                      variant="outlined"
                      onChange={(e) => changeHandler(e, "bio")}
                      maxLength="400"
                      error={errors.bio}
                      helperText={errors.bio && "This is a required field"}
                    />
                  </Grid>
                </Grid>
                <br />

              <br />
                { userRoles && userRoles[0] !== ROLES.OPERATOR && <><Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Speciality <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      value={profileData.speciality}
                      disabled={disabled}
                      className='advancedField fieldWithSettngs'
                      variant="outlined"
                      onChange={(e) => changeHandler(e, "speciality")}
                      maxLength="32"
                      error={errors.speciality}
                      helperText={errors.bio && "This is a required field"}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Medical School <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                        disabled={disabled}
                      value={profileData.medical_school}
                        className='advancedField fieldWithSettngs'
                      variant="outlined"
                      onChange={(e) =>  changeHandler(e, "medical_school")}
                      error={errors.medical_school}
                      helperText={errors.medical_school && "This is a required field"}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Medical License Number <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                        disabled={disabled}
                        value={profileData.provider_identifier_number}
                        className='advancedField fieldWithSettngs'
                        variant="outlined"
                        onChange={(e) =>  changeHandler(e, "provider_identifier_number")}
                        error={errors.provider_identifier_number}
                        helperText={errors.provider_identifier_number && "This is a required field"}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Physician Phone Number <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                        disabled={disabled}
                      value={profileData.physician_number}
                        className='advancedField fieldWithSettngs'
                      variant="outlined"
                      onChange={(e) =>  changeHandler(e, "physician_number")}
                      error={errors.physician_number}
                      helperText={errors.physician_number && "This is a required field"}
                    />
                  </Grid>
                </Grid>
                <br /></> }
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4} >
                    What languages do you speak?
                    (You will see patients that speak these languages)
                    <sup className="reqSupStyln">*</sup>
                    {/*(You will see patients that speak these languages)*/}
                  </Grid>
                  <Grid item xs={12} md={8} wrap>
                    <div className="languageTab">
                      {SA_LANGUAGES.map((x,index) =>{
                        return <>
                          <FormControlLabel
                              control={
                                <Checkbox
                                    disabled={disabled}
                                    checked={profileData && profileData.languages && profileData.languages.length >0 && profileData.languages[index]}
                                    onChange={(event) => handleLangChange(event, index)} name={x} />
                              }
                              label={x}
                          >
                          </FormControlLabel>
                        </>
                      }

                      )}
                      {errors.languages &&  <FormHelperText style={{color: "#d32f2f"}}> {"This is a required field"}</FormHelperText> }
                    </div>
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Practice Name <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                        disabled={disabled}
                        value={profileData.practice_name}
                        className='advancedField fieldWithSettngs'
                        variant="outlined"
                        error={errors.practice_name}
                        helperText={errors.practice_name && "This is a required field"}
                        onChange={(e) =>  changeHandler(e, "practice_name")}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Practice Address line 1 <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                        disabled={disabled}
                        value={profileData.bussiness_address1}
                        className='advancedField fieldWithSettngs'
                        variant="outlined"
                        onChange={(e) =>  changeHandler(e, "bussiness_address1")}
                        error={errors.bussiness_address1}
                        helperText={errors.bussiness_address1 && "This is a required field"}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Practice Address line 2
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                        disabled={disabled}
                        value={profileData.bussiness_address2}
                        className='advancedField fieldWithSettngs'
                        variant="outlined"
                        onChange={(e) =>  changeHandler(e, "bussiness_address2")}
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Practice City <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                        disabled={disabled}
                        value={profileData.bussiness_city}
                        className='advancedField fieldWithSettngs'
                        variant="outlined"
                        onChange={(e) =>  changeHandler(e, "bussiness_city")}
                        error={errors.bussiness_city}
                        helperText={errors.bussiness_city && "This is a required field"}
                        maxLength="32"
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Practice State/Province <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                        disabled={disabled}
                        value={profileData.bussiness_state}
                        className='advancedField fieldWithSettngs'
                        variant="outlined"
                        onChange={(e) =>  changeHandler(e, "bussiness_state")}
                        error={errors.bussiness_state}
                        helperText={errors.bussiness_state && "This is a required field"}
                        maxLength="32"
                    />
                  </Grid>
                </Grid>
                <br />
                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Practice Postal	Code <sup className="reqSupStyln">*</sup>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                        disabled={disabled}
                        value={profileData.bussiness_zipcode}
                        className='advancedField fieldWithSettngs'
                        variant="outlined"
                        onChange={(e) =>  changeHandler(e, "bussiness_zipcode")}
                        error={errors.bussiness_zipcode}
                        helperText={errors.bussiness_zipcode && "This is a required field"}
                        maxLength="32"
                    />
                  </Grid>
                </Grid>
                <br/>

                <Grid container spacing={{ xs: 1, md: 4 }} >
                  <Grid item xs={12} md={4}>
                    Signature
                  </Grid>
                  <Grid item xs={12} md={8}>
                    {profileData.signature ? <>
                    <img src={`data:image/png;base64,${profileData.signature}`} alt="Base64 Signature" />
                      <br/>
                      <UpdateSignature showSignaturePad={showSignaturePad} setShowSignaturePad={setShowSignaturePad} accountForm={accountForm}/>
                    </>:
                        <SignaturePad /> }
                  </Grid>
                </Grid>

                <br />
                <Grid>

                  {!disabled && <div className="form-group btn-cntr">
                    <Grid container spacing={{ xs: 1, md: 4 }} >
                      <Grid item xs={12} md={4}>
                       <></>
                      </Grid>
                      <Grid item xs={12} md={8} >
                        <div  className='buttonContainer'>
                          <Button
                              variant="contained"
                              style={{float: "left !important", fontWeight: "bold", marginRight: "20px", marginBottom: "20px"}}
                              onClick={accountForm}
                          >Save</Button>
                          {view === "physicianProfile" && <Button
                              style={{float: "right !important", backgroundColor: "#3daf2c", color: "white" , fontWeight: "bold", marginBottom: "20px"}}
                              variant="contained"
                              disabled={validProfile === false}
                              onClick={onNextButtonClick}> Add documents to request access </Button>}
                        </div>

                      </Grid>
                    </Grid>

                    <br></br>
                  </div> }
                </Grid>
              </form>
            </Grid>
          </div>
        </FormControl> }
      </div>
      </>
  );
}


export default Settings;
