import React from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { useNavigate } from "react-router-dom";
import {Avatar, IconButton} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import WarningIcon from '@mui/icons-material/Warning';
import {green, red, yellow} from "@mui/material/colors";


const TableWeb = ({columnHeaders, rowHeaders, rows, rowsPerPage, page, alignRows, rowClick}) => {

    const navigate = useNavigate();
    return(
        <div className="textBox">
            <Table>
                <TableHead>
                    <TableRow>
                        {columnHeaders.map((col, index) => {
                            return (
                                <TableCell key={index} style={{ fontWeight: "bolder" }} align={alignRows[index] || "left"}>
                                     <span>{(col === "doctor") && <Avatar src="" />}
                                         {/*{(rowHeader === "status") && <Avatar src="" />}*/}
                                         <span> {col}</span></span>
                                   {/*{col}*/}
                                </TableCell>)
                        })}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows && rows.length === 0 &&
                        <TableRow>
                            <TableCell colSpan={6} align='center'>No data to display</TableCell>
                        </TableRow>
                    }

                    { (rows && rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                    ).map((row) => {
                        return (

                            <TableRow
                                onClick={rowClick ? () => { navigate(`/doctorStats/${row.id}`)} : null }
                                key={row.id}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 },
                                    //     "&:nth-child(even) td" : {
                                    //     backgroundColor: "#3daf2c"
                                    // },
                                    "&:nth-child(odd) td" : {
                                        backgroundColor: "rgba(238,249,243,.8300000000000001)"
                                    }
                                }}
                            >
                                {rowHeaders.map((rowHeader, index) => {
                                    return (
                                        <TableCell key={row.id} align={alignRows[index] || "left"}>
                                            <span style={{display: "inline-block"}}>{(rowHeader === "doctor") && <Avatar src="" sx={{width: "20px", height: "20px"}}/>}</span>
                                            <span style={{display: "inline-block"}}>{
                                                (rowHeader === "user_reg_status") && row[rowHeader] === "approved" && <IconButton> <CheckCircleIcon style={{ color: green[500] }}/></IconButton>}
                                                {(rowHeader === "user_reg_status") && row[rowHeader] === "pending" && <IconButton> <WarningIcon style={{ color: yellow[700] }}/></IconButton>}
                                                {(rowHeader === "user_reg_status") && row[rowHeader] === "denied" && <IconButton> <DoNotDisturbIcon style={{ color: red[500] }}/></IconButton>}
                                            {/*{(rowHeader === "status") && <Avatar src="" />}*/}
                                            {/*    <span style={{padding: "10px"}}> {row[rowHeader]} </span>*/}
                                            </span>
                                            {/*{(rowHeader === "amt_owed") && <span style={{marginLeft: "10px"}}>${row.name} || {row.amt_owed} </span> }*/}
                                            {(rowHeader === "doctor") && <span style={{marginLeft: "10px"}}>{`${row.name} ${row.family_name}`} </span> }
                                            {(rowHeader === "name") && <span style={{marginLeft: "10px"}}>{`${row.first_name} ${row.last_name}`} </span> }
                                            {(rowHeader !== "name" && rowHeader !== "doctor") && <span style={{marginLeft: "10px"}}>{`${row[rowHeader]}`} </span> }


                                        </TableCell>)
                                })}
                            </TableRow> )
                    })}

                </TableBody>
            </Table>
        </div>
    )
}

export default TableWeb;


