import { CREATE_PATIENT, PATIENT_MESSAGE } from "../actions/createPatientActions";

const init = {
    createPatientRes: {},
    alert: {
        show: false,
        type: "",
        message: ""
    }
};

const createPatientReducer = (state = init, action) => {
    switch (action.type) {
        case CREATE_PATIENT: {
            return { ...state, createPatientRes: action.payload };
        }
        case PATIENT_MESSAGE: {
            return { ...state, alert: action.payload };
        }
        default:
            return state;
    }
};

export default createPatientReducer;