import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Grid, TextField, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
    forgotPassword,
    forgotPasswordSubmit,
    setCodeDeliveryDetails
} from "../../ducks/actionCreators/loginActionCreator";
import { setMessage } from "../../ducks/actionCreators/messageActionCreator";
import "../Login/Login.css";
import {Alert} from "@mui/lab";
import {Navigate} from "react-router-dom";

export const ForgotPassword = ({ closePasswordChange }) => {
    const [email, setEmail] = useState("");
    const [code, setCode] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const dispatch = useDispatch();

    const data = useSelector((state) => state.login);
    const messageData = useSelector((state) => state.message);

    // Send Verification Code to user's email
    const sendCode = (e) => {
        e.preventDefault();
        dispatch(forgotPassword(email));
    };

    const resetPassword = (e, email) => {
        e.preventDefault();
        dispatch(forgotPasswordSubmit(email, code, password, confirmPassword));
    };

    const returnToLogIn = () => {
        closePasswordChange();
    };

    useEffect(() => {
        dispatch(setCodeDeliveryDetails({}));
    }, []);

    return (
        <div style={{ padding: "20px" }}>
            <h2 className="windowTitl" style={{marginBottom:"9%"}}>Forgot Password</h2>

            {messageData.message.show && messageData.message.type === "success" && <Navigate to="/successPassword"/> }

            {messageData.message.show && messageData.message.type === "error" && <Alert severity={messageData.message.type} onClose={() => dispatch(setMessage({text: "", type:"", show: false}))} >
                {messageData.message.text}
            </Alert> }

            {Object.keys(data.codeDeliveryDetails).length === 0 &&
                <form onSubmit={sendCode}>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center">
                        <Grid item>
                            <TextField
                                value={email}
                                className="resetPasswordTextField"
                                variant='outlined'
                                placeholder='Email'
                                onChange={(event) => {
                                    setEmail(event.target.value);
                                }} />
                        </Grid>
                        <br/>
                        <Grid item>
                            <Button type='submit'
                                    variant='contained'
                                    className='actionBtn'
                                    style={{ background: "#5fc050", color: "white" }}>
                                Send Code
                            </Button>
                        </Grid>
                        <br/>
                    </Grid>

                </form>
            }

            {Object.keys(data.codeDeliveryDetails).length !== 0 &&
                <form onSubmit={(e) => resetPassword(e,email)}>
                    <div>
                        {`If you have a verified ${data.codeDeliveryDetails && data.codeDeliveryDetails.AttributeName &&
                        data.codeDeliveryDetails.AttributeName.replaceAll("_", " ")} 
                		with us you will reviece a verification code to 
                		${data.codeDeliveryDetails && data.codeDeliveryDetails.Destination}.`}
                    </div>
                    <br/>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center">

                        {/* Verification Code */}
                        <Grid item>
                            <TextField
                                value={code}
                                className="resetPasswordTextField"
                                variant='outlined'
                                placeholder='Verification Code'
                                onChange={(event) => {
                                    setCode(event.target.value);
                                }} />
                        </Grid>
                        {/* Password  */}
                        <br/>
                        <Grid item>
                            <TextField
                                type={showPassword ? "text" : "password"}
                                value={password}
                                className="resetPasswordTextField"
                                variant='outlined'
                                placeholder='Password'
                                onChange={(event) => {
                                    setPassword(event.target.value.trim());
                                }}
                                InputProps={{
                                    endAdornment:
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                }} />
                        </Grid>
                        <br/>
                        {/* Confirm Password */}
                        <Grid item>
                            <TextField
                                type={showConfirmPassword ? "text" : "password"}
                                value={confirmPassword}
                                className="resetPasswordTextField"
                                variant='outlined'
                                placeholder='Confirm Password'
                                onChange={(event) => {
                                    setConfirmPassword(event.target.value.trim());
                                }}
                                InputProps={{
                                    endAdornment:
                                        <IconButton
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                            edge="end"
                                        >
                                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                }} />
                        </Grid>

                    </Grid>
                    <br/>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center">

                        <Grid item>
                            <Button
                                type='submit'
                                    className='actionBtn'
                                    variant='contained'
                                    style={{ background: "#5fc050", color: "white" }}>
                                Submit
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={sendCode}
                                    className='actionBtn'
                                    variant='contained'
                                    style={{ background: "grey", color: "white" }}>
                                Resend Code
                            </Button>
                        </Grid>
                    </Grid>

                </form>
            }

            <Button className='returnToLogIn' onClick={returnToLogIn}>
                Log In
            </Button>
        </div>
    );
};

