import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import "./style.css"
import {Button} from "@mui/material";
import {addDoctorSignature} from "../../ducks/actionCreators/settingsActionCreator";
import {useDispatch, useSelector} from "react-redux";

const SignaturePad = ({setShowSignaturePad, accountForm}) => {
    const sigCanvasRef = useRef(null);

    const handleClear = () => {
        sigCanvasRef.current.clear();
    };

    const dispatch = useDispatch();

    const handleSave = () => {
        const signatureData = sigCanvasRef.current.toDataURL();
        const image = new Image();
        image.src = signatureData;

        const base64Signature = signatureData.toString().replace(/^data:(.*,)?/, "");;
        // setSignature(base64Signature);
        dispatch(addDoctorSignature(base64Signature, setShowSignaturePad));
        dispatch(accountForm());
        // You can send `signatureData` to your server or process it as needed.
    };

    return (
        <div>
            <SignatureCanvas
                ref={sigCanvasRef}
                penColor="black"
                canvasProps={{ width: 375, height: 200, className: 'signature-canvas' }}
            />
            <div>
                <Button  onClick={handleClear} variant="contained"
                        color="grey" style={{marginRight: "10px"}}>Clear</Button>
                <Button variant="contained"
                    color="primary" onClick={handleSave}>Save</Button>
            </div>
        </div>
    );
};

export default SignaturePad;