import React, { useState } from "react";
import {Navigate, Link, useNavigate} from "react-router-dom";
import { TextField, Button, IconButton  } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import { SnackbarAlert } from "../common/SnackbarAlert";
import {
    completeNewPassword,
    isPasswordChangeRequired,
    isUserLoggedIn
} from "../../ducks/actionCreators/loginActionCreator";
import { useSelector, useDispatch } from "react-redux";
import {Alert} from "@mui/lab";
import {setMessage} from "../../ducks/actionCreators/messageActionCreator";

export default function FirstPasswordReset(props) {
    const [newPassword, setNewPassword] = useState("");
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const data = useSelector((state) => state.login);
    const messageData = useSelector((state) => state.message);

    const changePassword =  (userData) => {
        dispatch(isUserLoggedIn());
        dispatch(completeNewPassword(userData, password, newPassword));
    };

    return (
        <div>
            {!data.isFirstLogIn ? <Navigate to={"/physicianProfile"} replace/> :
                <>
                    {messageData.message.show && <Alert
                        style={{width: "90%"}}
                        onClose={() => dispatch(setMessage({text: "", error: "", show: false}))}
                        severity={messageData.message.type}
                    >{messageData.message.text}</Alert> }

                    <div>
                        <h3>Change Password</h3>
                        {/* Password */}
                        <div>
                            <TextField
                                type={showNewPassword ? "text" : "password"}
                                className="resetPasswordTextField"
                                placeholder="Password"
                                variant="outlined"
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value.trim())}
                                InputProps={{
                                    endAdornment:
                                        <IconButton
                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                            edge="end"
                                        >
                                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                }}
                            />
                        </div>
                        <br/>
                        {/* Confirm Password */}
                        <div>
                            <TextField
                                type={showPassword ? "text" : "password"}
                                className="resetPasswordTextField"
                                placeholder="Confirm Password"
                                variant="outlined"
                                value={password}
                                onChange={(e) => setPassword(e.target.value.trim())}
                                InputProps={{
                                    endAdornment:
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                }}
                            />
                        </div>
                        {/* Update Buttons  */}
                        <br/>
                        <div>
                            <Button variant="contained"
                                    onClick={() => changePassword(data.userData)}
                                    className='actionBtn'
                                    style={{
                                        backgroundColor: "rgb(61, 175, 44)",
                                        color: "white"
                                    }}
                                    id="updatePasswordBtn"
                            >
                                Update
                            </Button>
                            <Button variant="contained" onClick={() => {dispatch(isPasswordChangeRequired(false)); }} className='actionBtn'>
                                Cancel
                            </Button>
                        </div>

                    </div>
                </> }
        </div>
    );
}

