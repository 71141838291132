import {BASE_URL, getCurrentUserToken} from "../../utils";
import {GET_REQUESTS, DOCUMENT_LINK, GET_ADMIN_REQUESTS, CREATE_PHYSICIAN_REQUEST, ADMIN_APPROVAL,
    ADMIN_DENIAL, GET_ADMIN_REQUESTS_HISTORY, SET_PROFILE_HISTORY} from "../actions/requestActions";
import {setLoader} from "./loaderActionCreator";
import {setMessage} from "./messageActionCreator";
import {Analytics} from "aws-amplify";

export const setMyRequests = (data) => ({type: GET_REQUESTS, payload: data})
export const setDocumentLink = (data) => ({type: DOCUMENT_LINK, payload: data})
export const setAdminRequests = (data) => ({type: GET_ADMIN_REQUESTS, payload: data})
export const setPhysicianRequestRes = (data) => ({type: CREATE_PHYSICIAN_REQUEST, payload: data})
export const setAdminApprovalRes = (data) => ({type: ADMIN_APPROVAL, payload: data})
export const setAdminDenialRes = (data) => ({type: ADMIN_DENIAL, payload: data})
export const setAdminRequestsHistory = (data) => ({type: GET_ADMIN_REQUESTS_HISTORY, payload: data})
export const setProfileHistory = (data) => ({type: SET_PROFILE_HISTORY, payload: data})


export const getDocument = (url, otherId) => async (dispatch) => {
    dispatch(setLoader(true));
    const token =  await getCurrentUserToken();

    fetch(BASE_URL+ "/DoctorAction/getDocument",{
        "method":"GET",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "token": token,
            "url": url,
            "otherUserId": otherId
        }
    }).then(res => res.json()).then(result => {
        dispatch(setDocumentLink(result.url))
        dispatch(setLoader(false));
    }).catch((err) => {
        console.log(err);
        dispatch(setLoader(false));
    })

}

export const getMyRequest = () => async(dispatch) => {
    dispatch(setLoader(true));
    const token =  await getCurrentUserToken();

    fetch(BASE_URL+ "/DoctorAction/getMyRequests",{
        "method":"GET",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "token": token,
        }
    }).then(res => res.json()).then(result => {
        if(result && result.status) {
            dispatch(setMyRequests(result.request))
        }
        else {
            dispatch(setMyRequests([]))
        }
        dispatch(setLoader(false));
    }).catch((err) => {
        console.log(err);
        dispatch(setMyRequests([]))
        dispatch(setLoader(false));
        dispatch(setMessage({type: "error", text: "Something went wrong, Please try again later."}))
    })

}

export const getAdminRequests = () => async(dispatch) => {
    dispatch(setLoader(true));
    const token =  await getCurrentUserToken();

    fetch(BASE_URL+ "/DoctorAction/getAdminRequests",{
        "method":"GET",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "token": token,
        }
    }).then(res => res.json()).then(result => {
        if(result && result.status) {
            dispatch(setAdminRequests(result.requests))
        }
        else {
            dispatch(setAdminRequests([]))
        }
        dispatch(setLoader(false));
    }).catch((err) => {
        console.log(err);
        dispatch(setAdminRequests([]))
        dispatch(setLoader(false));
        dispatch(setMessage({type: "error", text: "Something went wrong, Please try again later."}))
    })
}


export const getAdminRequestsHistory = () => async(dispatch) => {
    dispatch(setLoader(true));
    const token =  await getCurrentUserToken();

    fetch(BASE_URL+ "/DoctorAction/getDoctorRequestsHistory",{
        "method":"GET",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "token": token,
        }
    }).then(res => res.json()).then(result => {
        if(result && result.status) {
            dispatch(setAdminRequestsHistory(result.requests))
        }
        else {
            dispatch(setAdminRequestsHistory([]))
        }
        dispatch(setLoader(false));
    }).catch((err) => {
        console.log(err);
        dispatch(setAdminRequestsHistory([]))
        dispatch(setLoader(false));
        dispatch(setMessage({type: "error", text: "Something went wrong, Please try again later."}))
    })
}

export const getProfileHistory = (id) => async(dispatch) => {
    dispatch(setLoader(true));
    const token =  await getCurrentUserToken();

    fetch(BASE_URL+ "/DoctorAction/getDoctorProfile",{
        "method":"GET",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "token": token,
            "id": id
        }
    }).then(res => res.json()).then(result => {
        if(result && result.status) {
            dispatch(setProfileHistory(result))
        }

        dispatch(setLoader(false));
    }).catch((err) => {
        dispatch(setLoader(false));
        dispatch(setMessage({type: "error", text: "Something went wrong, Please try again later."}))
    })
}


export const makePhysicianApprovalRequest = (uuid) => async(dispatch) => {
    dispatch(setLoader(true));
    const token =  await getCurrentUserToken();

    fetch(BASE_URL+ "/DoctorAction/makePhysicianApprovalRequest",{
        "method":"POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "token": token,
        },
        "body": JSON.stringify({
            "s3Bucket":"drowl-users-direct-upload",
            "requestUUID": uuid
        })
    }).then(res => res.json()).then(async (result) => {
        dispatch(setPhysicianRequestRes(result));
        dispatch(setLoader(false));
        await Analytics.record({
            screen: "providerRequest", datetime: new Date().toLocaleString()
        });
    }).catch((err) => {
        console.log(err);
        dispatch(setPhysicianRequestRes("error"));
        dispatch(setLoader(false));
        dispatch(setMessage({type: "error", text: "Something went wrong, Please try again later."}))
    })
}

export const adminDenyPhysicianRequest = (req_id,docId, reason) => async(dispatch) => {
    dispatch(setLoader(true));
    const token =  await getCurrentUserToken();

    fetch(BASE_URL+ "/DoctorAction/adminDenyPhysicianRequest",{
        "method":"POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "token": token,
        },
        "body": JSON.stringify({
            "doctor_id":docId,
            "request_id":req_id,
            "reason":reason
        })
    }).then(res => res.json()).then(result => {
        dispatch(setAdminDenialRes(result));
        dispatch(setLoader(false));
    }).catch((err) => {
        console.log(err);
        dispatch(setAdminDenialRes("error"));
        dispatch(setLoader(false));
        dispatch(setMessage({type: "error", text: "Something went wrong, Please try again later."}))
    })
}

export const adminApprovePhysicianRequest = (req_id, docId) => async(dispatch) => {
    // dispatch(setLoader(true));
    const token =  await getCurrentUserToken();

    fetch(BASE_URL+ "/DoctorAction/adminApprovePhysicianRequest",{
        "method":"POST",
        "headers": {
            "content-type": "application/json",
            "accept": "application/json",
            "token": token,
        },
        "body": JSON.stringify({
            "doctor_id": docId,
            "request_id":req_id
        })
    }).then(res => res.json()).then(result => {
        dispatch(setAdminApprovalRes(result));
        // dispatch(setLoader(false));
    }).catch((err) => {
        dispatch(setAdminApprovalRes("error"));
        // dispatch(setLoader(false));
        dispatch(setMessage({type: "error", text: "Something went wrong, Please try again later."}))
    })
}