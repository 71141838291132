import React, {useState, useEffect, useMemo} from 'react';
import './ProgressNote.css'
import PatientInfo from './PatientInfo';
import PrimaryDiagnosis from './PrimaryDiagnosis';
import ReviewOfSystems from './ReviewOfSystems';
import AssessmentPlanNew from './AssesmentPlanNew';
import {TextField, Grid } from '@mui/material';
import {useDispatch, useSelector} from "react-redux";
import { initAssessmentPlanFromClickDefault, initPhysicalExam, initReviewOfSystems } from "./ProgressNoteDefaults";
import Conditions from "./Conditions";
import Medications from "./Medications";
import {
  getSymptomsHistory,
  setPatientDocumentLink,
  updateProgressNote
} from "../../ducks/actionCreators/patientOverviewActionCreator";
import {convertDate} from "../../utils/utlis";
import {Loader} from "@drowl/drowl-react-library";
import Allergies from "./Allergies";
import {setDocumentLink} from "../../ducks/actionCreators/requestActionCreator";

const ProgressNote = () => {

  const patientOverviewData = useSelector(state => state.patientOverview);

  const { progressNote, ticket, patientInfo, symptomsHistory, patientDocumentLink } = patientOverviewData;

  const {user_info} = patientInfo;

  const [initAssessmentPlanFromClick, setInitAssessmentPlanFromClick] = useState(initAssessmentPlanFromClickDefault);
  const [primaryInfo, setPrimaryInfo] = useState();

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();


  useEffect(() => {
    // unmount
    dispatch(getSymptomsHistory(ticket.owner));
    return () => {
      setInitAssessmentPlanFromClick(initAssessmentPlanFromClickDefault);


    }
  }, [ticket.owner]);

  useEffect(() => {
    setLoader(true);
  const fullName =  `${ user_info && user_info.name || ''} ${user_info && user_info.family_name || ''}`

      setPrimaryInfo({
        'fullName': progressNote && progressNote.fullName ,
        'dob': convertDate(progressNote && progressNote.dob ),
        'dateOfTelemedicine': convertDate(progressNote && progressNote.dateOfTelemedicine)
      })

      progressNote.physicalExam = (progressNote && progressNote.physicalExam) || initPhysicalExam;
      progressNote.reviewOfSystems = (progressNote && progressNote.reviewOfSystems) || initReviewOfSystems;
      progressNote.assessmentAndPlan = (progressNote && progressNote.assessmentAndPlan) || [];

      if(Object.keys(progressNote).length > 3) {
        setLoader(false);
    }

  }, [progressNote, user_info]);


  // // An Update was made to the full name, date of birth or telemedicine visit date.
  const updatedPrimaryInfo = (updatedData) => {
    const updateProgressNoteVals = {
      ...progressNote,
      ...updatedData
    };

    dispatch(updateProgressNote(updateProgressNoteVals, ticket._id));
  };

  // // An Update was made to the review of systems section.
  const updatedReviewOfSystemsSection = (section, updatedData) => {

    const updatedReviewOfSystemsSection = { ...progressNote.reviewOfSystems };
    updatedReviewOfSystemsSection[section] = updatedData;
    const updateProgressNoteVals = {
      ...progressNote,
      reviewOfSystems: {
        ...updatedReviewOfSystemsSection
      }
    };
    dispatch(updateProgressNote(updateProgressNoteVals, ticket._id));
  };
  //
  // // An Update was made to the review of systems section.
  const updatedPatientInfoSection = (updatedData) => {
    // replace resting heart rate to beats per minure since this is what it's refered to as in
    // in database.
    // updatedData['Beats per Minute'] = updatedData['Resting Heart Rate'] ?? '';
    // delete updatedData['Resting Heart Rate'];

    const updateProgressNoteVals = {
      ...progressNote,
      ...updatedData
    };

    dispatch(updateProgressNote(updateProgressNoteVals, ticket._id));
  }
  //
  // // An Update was made to the items in primary diagnois section.
  const updatedPrimaryDiagnosis = (updatedData) => {
    const updateProgressNoteVals = {
      ...progressNote,
      ...updatedData
    };

    dispatch(updateProgressNote(updateProgressNoteVals, ticket._id));
  };
  //
  // // An Update was made to the items in primary diagnois section.
  const updatedPlanOfTreatment = (updatedData) => {
    const updateProgressNoteVals = {
      ...progressNote,
      ...updatedData
    };
    dispatch(updateProgressNote(updateProgressNoteVals, ticket._id));
  };

  // // An Update was made to the items in primary diagnois section.
  const updatedAssessmentAndPlan = (updatedData) => {
    const updateProgressNoteVals = {
      ...progressNote,
      'assessmentAndPlan': updatedData
    };
    dispatch(updateProgressNote(updateProgressNoteVals, ticket._id));
  };

  // // Add Assessment Plan after a user clicks from past medical history
  const addAssessmentPlan = (icdcode, diagnosed) => {
    //  Only init new assessment plan from click if ticket isn't closed
    if (ticket.status !== 3) {
      setInitAssessmentPlanFromClick({ ...initAssessmentPlanFromClick, 'diagnosis': icdcode + " " + diagnosed });
    }
    document.getElementById("medicationTable").scrollIntoView();
  }


  return (
    <div id="progressNoteWindow">
      <> {loader ? <Loader /> :
      <div id="progress-note">
        <h1 className="windowTitle">Patient Progress Note</h1>

        {/* Primary Info  */}
        <Grid>
        {primaryInfo && primaryInfo.fullName && <Grid container spacing={4} >
            <Grid item className='section-subgroup marginLeft' xs={4} md={3} >Full Name:</Grid>
            <Grid item className='section-subgroup'>
              <TextField
                  value={primaryInfo?.fullName ?? ''}
                  variant="outlined"
                  className="textFieldWidth"
                  onChange={(e) => setPrimaryInfo({...primaryInfo, 'fullName': e.target.value })}
                  onBlur={() => updatedPrimaryInfo(primaryInfo)}
                  disabled={true}
              />
            </Grid>
          </Grid> }
          <br/>
          <Grid container spacing={4}>
            <Grid item className='section-subgroup marginLeft' xs={4} md={3} >Date Of Birth:</Grid>
        <Grid item className='section-subgroup'>
            <TextField
              value={primaryInfo?.dob ?? ''}
              variant='outlined'
              className="textFieldWidth"
              onChange={(e) => setPrimaryInfo({...primaryInfo, 'dob':
                convertDate(e.target.value) })}
              onBlur={() => updatedPrimaryInfo(primaryInfo)}
              disabled={true}

            />
        </Grid>
          </Grid>

          <br/>
          <Grid container spacing={4} >
            <Grid item className='section-subgroup marginLeft' xs={4} md={3}>Telemedicine Visit Date:</Grid>
            <Grid item className='section-subgroup' >
            <TextField
              value={(primaryInfo && primaryInfo.dateOfTelemedicine) ?? ''}
              variant="outlined"
              placeholder='YYYY-MM-DD'
              className="textFieldWidth"
              onChange={(e) => {
                setPrimaryInfo({ ...primaryInfo, 'dateOfTelemedicine': e.target.value })
              }}
              onBlur={() => updatedPrimaryInfo(primaryInfo)}
              disabled={true}
            />
            </Grid>
          </Grid>
        </Grid>

        {/* Primary Diagnosis */}
        <div className="form-group">
          <div className="pn-subtitle">Primary Diagnosis</div>
          <PrimaryDiagnosis updatedPrimaryDiagnosis={updatedPrimaryDiagnosis} />
        </div>


        <Conditions addAssessmentPlan={addAssessmentPlan}/>
        <Medications />
        <Allergies />

        {/* Patient Info  */}
        <div className="form-group">
          <div className="pn-subtitle">Patient Info:</div>

          <PatientInfo
            updatedPatientInfoSection={updatedPatientInfoSection}
          />
        </div>

        {/* Review of Systems  */}
        <div className="form-group">
          <div className="pn-subtitle">Review of Systems:</div>
          {/* Loop through all review of systems and create the check boxes */}
          {progressNote && progressNote.reviewOfSystems ?
            <div>
              {Object.entries(progressNote.reviewOfSystems).map(([key, value], index) => {
                return <ReviewOfSystems
                  key={key}
                  reviewOfSystemsSection={value}
                  updatedReviewOfSystemsSection={updatedReviewOfSystemsSection}
                  index={index + 1}
                  title={key}
                  symptomsHis= {symptomsHistory && Object.keys(symptomsHistory).length > 0 ? symptomsHistory[key] : {}}
                />
              })}
              <br />
            </div>
            :
            <div> Nothing to review in section </div>}
        </div>

        {/* Assessment Plan, Plan of Treatment  */}
        <div className="form-group">
          <div className="pn-subtitle">Assessment and Plan:</div>
          <AssessmentPlanNew
            planOfTreatment={progressNote && progressNote.planOfTreatment}
            updatedPlanOfTreatment={updatedPlanOfTreatment}
            assessmentAndPlan={progressNote && progressNote.assessmentAndPlan}
            updatedAssessmentAndPlan={updatedAssessmentAndPlan}
            isClosed={ticket && (ticket.status === 3)}
            initNewMed={initAssessmentPlanFromClick} />
        </div>
      </div> } </>
    </div >
  );
}

export default ProgressNote;
