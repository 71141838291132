import React, {useEffect, useState, useRef} from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Tab, Tabs, Paper,Badge} from '@mui/material';
import { useParams } from "react-router-dom";
import { useIsNewMessage } from '../hooks/useIsNewMessage';
import './PatientOverview.css';
import {useDispatch, useSelector} from "react-redux";
import {
    getDocNote,
    getPatientChrOfTicket, getPatientDocuments,
    getTicketInfo,
    setProgressNote,
} from "../../ducks/actionCreators/patientOverviewActionCreator";
import {
    subscribeToChat,
    subscribeToAllStatus,
    subscribeToZoom
} from "../../graphql/graphqlSubscriptions"
import {getUserCases, setEndOfUserTickets, setUserTickets} from "../../ducks/actionCreators/casesActionCreator";
import { useNavigate } from "react-router-dom";
import {PatientDropdown} from "./PatientDropdown";
import CasesTimeline from "../CasesTimeline/CasesTimeline";
import Contact from "../Contact/Contact";
import ProgressNote from "../ProgressNote/ProgressNote";
import {getConditionsList, getMedicationList} from "../../ducks/actionCreators/medsCondsActionCreator";
import Chat from "../Chat/Chat";
import Case from "../Case/Case";
import "../../Main.css";
import PatientHeader from "../PatientHeader/PatientHeader";
import {Loader} from "@drowl/drowl-react-library";
import {Alert} from "@mui/lab";
import {setMessage} from "../../ducks/actionCreators/messageActionCreator";
import {ROLES} from "../../Main";
import {useUserRole} from "../../hooks/useUserRole";
import PatientDocuments from "../PatientDocuments/PatientDocuments";

const TabPages = {
    Paitent: 'patient',
    ProgressNote: 'progressNote',
    CaseInfo: 'caseInfo',
    Chat: 'chat',
    VideoChat: 'videoChat',
    Case: 'case'
}

export const CaseStatues = {
    OPEN: 0,
    INPROGRESS: 1,
    SCHEDULED: 2,
    CLOSED: 3,
    ASSESSING: 4,
    WAITINGFORGP: 5

};

// styling
const default_tab_item = {
    color: 'grey',
    minWidth: 10
};
const active_tab_item = {
    background_color: 'lightgrey',
    color: '#379437',
    minWidth: 10
};

// let chatSub, pgSub, zoomSub, statusSub;

let SUBSCRIPTIONS = {};

const PatientOveriew = ({tabToShow}) => {

    const userRoles = useUserRole();
    const [tab, setTab] = useState(((userRoles && userRoles[0] === ROLES.OPERATOR) || tabToShow === "chat") ? TabPages.Chat : TabPages.ProgressNote);
    const [open, setOpen] = useState(false);
    const [paitentSelect, setPaitentSelect] = useState("");
    const paitentRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
    const loaderData = useSelector((state) => state.loader);
    const messageData = useSelector((state) => state.message);
    const { isNewMessage, viewedNewMessage, newMessage } = useIsNewMessage();

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const ticketID = useParams();

    const patientOverviewData = useSelector(state => state.patientOverview);
    const casesData = useSelector(state => state.cases);

    const {userTickets} = casesData;

    const { patientCHR, comments, ticket } = patientOverviewData;

    const [loader, setLoader] = useState(false);


    const openTicket = async () => {
        // try {
        await dispatch(getTicketInfo(ticketID.ticket, comments));

            if(tab === TabPages.Chat) {
                SUBSCRIPTIONS["chat"] =  await dispatch(subscribeToChat(ticketID.ticket));
            }
            else if( tab === TabPages.ProgressNote) {
                // SUBSCRIPTIONS["pn"] = await dispatch(subscribeToAllStatus(ticketID.ticket));
                // await dispatch(subscribeToAllStatus());
            }
            // else if(tab === TabPages.Case) {
            //     SUBSCRIPTIONS["status"] = await dispatch(subscribeToStatus(ticketID.ticket));
            // }
            SUBSCRIPTIONS["zoom"] = await dispatch(subscribeToZoom(ticketID.ticket));
            // await dispatch(subscribeToTicket(ticketID.ticket));


        // } catch (e) {
        //     navigate("/error");
        // }
    }

   const unsubscribeFromAll = async () => {
        for (let key in SUBSCRIPTIONS) {
            try {
                SUBSCRIPTIONS[key].unsubscribe();
                delete SUBSCRIPTIONS[key];
            } catch (e) {
                console.error(e);
            }
        }
    }

    useEffect(() => {
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
        }, 2000);
        setTab(userRoles[0] === ROLES.OPERATOR  || tabToShow === "chat" ? TabPages.Chat : TabPages.ProgressNote);
    }, [ticketID.ticket])

    useEffect(() => {
        function handleWindowResize() {
            setIsMobile(window.innerWidth < 600);
        };
        window.addEventListener("resize", handleWindowResize);

    }, []);

    useEffect(   () => {
        dispatch(setProgressNote({}));
        if(tab === TabPages.Paitent) {
            dispatch(getPatientChrOfTicket(ticketID.ticket));
        }
        openTicket();
        return (() => {
            unsubscribeFromAll();
        });
    }, [tab, ticketID.ticket]);

    useEffect(() => {

        if(tab === TabPages.ProgressNote) {
            dispatch(getMedicationList());
            dispatch(getConditionsList());
        }
        if(tab === TabPages.Paitent) {
            if (paitentSelect === 'snapshot' && isMobile && ticket?.owner) {
                openSnapshot();
            }
            if (paitentSelect === 'allCases') {
                getUserTickets();
            }
            else {
                if (userTickets && userTickets.length > 0) {
                    dispatch(setEndOfUserTickets(false));
                    dispatch(setUserTickets());
                }
            }
        }

    }, [paitentSelect]);

    useEffect(() => {
        if(tab === TabPages.Paitent && paitentSelect === 'allDocuments') {
            dispatch(getPatientDocuments(ticket.owner));
        }
    }, [ticket.owner, paitentSelect])

    useEffect(() => {
        dispatch(getDocNote(ticket.owner, "2", ""))
    }, []);

    const openSnapshot = () => {
        if (patientCHR) {
            try {
                let pdfWindow = window.open();
                // pdfWindow.location = props.patientCHR;
                pdfWindow.document.write("<div><iframe width='100%' height='100%' src='data:application/pdf;base64," + patientCHR + "'></iframe></div>");
            } catch (e) {
                console.error(e);
            }
        }
    }

    const getUserTickets = (last_session_end_time) => {
        const ticketLimit = 50;
        dispatch(getUserCases(ticket.owner,ticketLimit, last_session_end_time ?? new Date().getTime()));
    };

    const isActive = (isActiveTab) => {
        return isActiveTab ? active_tab_item : default_tab_item;
    };

    const handleChange = (event, selectedTab) => {
        if (selectedTab === TabPages.Paitent) {
            // todo remove settab for this case when continue work
            setTab('patient');
            event.preventDefault();
            return;
        }
        setOpen(false);
        setTab(selectedTab);
    };

    const handleOpen = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl( null );
    };

    const handleSelect = (event, selectedItem) => {
        handleClose();
        setPaitentSelect(selectedItem);
        setTab('patient');
    };


    const handleLoadMoreTickets = async () => {
        if (userTickets && userTickets.length > 0) {
            const lastTicket = userTickets[userTickets.length - 1];
            await getUserTickets(lastTicket.timestamp);
        }
    };

    const patientOverViewTabs = [
        {
            icon:<ArrowDropDownIcon/>,
            iconPosition:"end",
            label:"patient",
            value:"patient",
            onClick:handleOpen,
            className: "tab-item",
            additionalStyles: isActive(tab === TabPages.Paitent),
            roles: [ ROLES.ADMIN, ROLES.APPROVED_DOCTOR],
        },
        {
            icon:null,
            iconPosition: "",
            label:"Progress Note",
            value:"progressNote",
            onClick:null,
            additionalStyles: isActive(tab === TabPages.ProgressNote),
            className: "tab-item",
            roles: [ ROLES.ADMIN, ROLES.APPROVED_DOCTOR],
        },
        {
            icon:null,
            iconPosition: "",
            label: <Badge invisible={!isNewMessage}
                         variant='dot'
                         color="secondary"
                         overlap='rectangular'
                         anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                Chat
            </Badge>,
            value:"chat",
            onClick:null,
            additionalStyles: isActive(tab === TabPages.Chat),
            className: "tab-item",
            roles: [ ROLES.ADMIN, ROLES.APPROVED_DOCTOR, ROLES.OPERATOR],
        },
        {
            icon:null,
            iconPosition: "",
            label:"Case",
            value:"case",
            onClick:null,
            additionalStyles: isActive(tab === TabPages.Case),
            className: "tab-item",
            roles: [ ROLES.ADMIN, ROLES.APPROVED_DOCTOR],
        },
    ]

    const filteredTabItems = patientOverViewTabs.filter(
        (item) => { return item.roles.includes(userRoles[0]) }
        // && item.permissions.every((perm) => roles[userRoles].includes(perm))
    );


    return (
        <span>
                <div className="patientOverviewWindow">
                     <> {loaderData.loading && <Loader /> } </>
                    {messageData.message.show && <>
                        <Alert severity={messageData.message.type} style={{width: "94%" }} onClose={() => dispatch(setMessage({
                            text: "",
                            type: "",
                            show: false
                        }))}
                        >{messageData.message.text} </Alert>
                        <br/>
                        <br/>
                    </> }
                   <div style={{ position: "sticky", zIndex: 10, top: "80px", overflow: "initial", backgroundColor: "white"}}>
                     <PatientHeader view="patientOverview" tab={tab}/>
                    {/*        /!* Patient Overview Tabs *!/*/}
                            <Paper square className='patient-overview-tabs'>
                                <Tabs
                                    noderef={paitentRef}
                                    value={tab}
                                    onChange={handleChange}
                                    variant="fullWidth"
                                    TabIndicatorProps={{style: {background: '#379437', fontColor: '#379437'}}}
                                >
                                    {filteredTabItems.map((item) => (
                                        <Tab
                                            icon={item.icon}
                                            iconPosition={item.iconPosition}
                                            label={item.label}
                                            value={item.value}
                                            onClick={item.onClick}
                                            additionalStyles={item.additionalStyles}
                                            className={item.className}
                                        />
                                    ))}
                                </Tabs>
                            </Paper>

                        </div>

                    {/*</div>*/}

                    {/* Menu dropdown for "patient" */}
                    <PatientDropdown open={Boolean(anchorEl)} handleClose={handleClose} handleSelect={handleSelect}
                        // ref={paitentRef}
                                     anchorEl={anchorEl}/>

                    {/* Render Current Tab Contents */}
                    <div id="tab-contents-id" className="tab-contents">
                        {tab === 'patient' &&
                            <div>
                                {paitentSelect === 'snapshot' &&
                                    <div>
                                        {patientCHR ?
                                            <div>
                                                {isMobile ?
                                                    <div>
                                                        <div>Opened Snapshot in new tab. Ensure pop-up blocker is
                                                            enabled
                                                            for DrOwl
                                                        </div>
                                                    </div>
                                                    :
                                                    <div id="documents">
                                                        <iframe src={"data:application/pdf;base64," + patientCHR}
                                                                title="patient info pdf" id="iframePI"/>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            <div>
                                                There is no snapshot for this patient at this time.
                                            </div>
                                        }
                                    </div>
                                }
                                {paitentSelect === 'allCases' &&
                                    <CasesTimeline
                                        handleLoadMoreTickets={handleLoadMoreTickets}
                                    />
                                }
                                {paitentSelect === 'contact' &&
                                    <Contact/>
                                }
                                {paitentSelect === 'allDocuments' &&
                                    <PatientDocuments/>
                                }
                                {!paitentSelect &&
                                    <div>Waiting for select...</div>
                                }
                            </div>
                        }

                        {/*            /!**/}
                        {/*    case "providers": {*/}
                        {/*                return (<div> Providers</div>);*/}
                        {/*            }*/}
                        {/*    case "prescriptions": {*/}
                        {/*                return (<div>Prescriptions</div>);*/}
                        {/*            } *!/*/}
                        {/*        </div>*/}
                        {/*    }*/}
                        {/*    /!*{tab === 'caseInfo' &&*!/*/}
                        {/*    /!*    <div>TODO this will soon have information</div>*!/*/}
                        {/*    /!*}*!/*/}
                        {tab === 'progressNote' &&
                            <ProgressNote ticket={ticket}/>
                        }
                        {tab === 'chat' &&
                            <Chat/>
                        }
                        {tab === 'case' &&
                            <Case/>
                        }

                    </div>

                </div>

        </span >
    );


}

export default PatientOveriew;