export const initReviewOfSystems = {
    General: {
        "Weight Loss or Gain": false,
        "Fatigue": false,
        "Fever or Chills": false,
        "Weakness": false,
        "Trouble Sleeping": false,
    },
    Skin: {
        "Rashes": false,
        "Lumps": false,
        "Itching": false,
        "Dryness": false,
        "Color Changes": false,
        "Hair and Nail Changes": false,
    },
    Head: {
        "Headache": false,
        "Head Injury": false,
    },
    Ears: {
        "Decreased Hearing": false,
        "Ringing in Ears": false,
        "Earache": false,
        "Drainage": false,
    },
    Eyes: {
        "Vision": false,
        "Pain": false,
        "Redness": false,
        "Blurry or Double Vision": false,
    },
    Nose: {
        "Stuffiness": false,
        "Discharge": false,
        "Itching": false,
        "Hay Fever": false,
        "Nosebleeds": false,
        "Sinus Pain": false,
    },
    Throat: {
        "Teeth": false,
        "Gums": false,
        "Bleeding": false,
        "Dentures": false,
        "Sore Tongue": false,
        "Dry Mouth": false,
        "Sore Throat": false,
        "Hoarseness": false,
        "Thrush": false,
        "Non-healing Sores": false,
    },
    Neck: {
        "Lumps": false,
        "Swollen Glands": false,
        "Pain": false,
        "Stiffness": false,
    },
    Respiratory: {
        "Cough": false,
        "Sputum": false,
        "Coughing up Blood": false,
        "Shortness of Breath": false,
        "Wheezing": false,
        "Painful Breathing": false,
    },
    Cardiovascular: {
        "Chest Pain or Discomfort": false,
        "Tightness": false,
        "Palpitations": false,
        "Shortness of Breath with Activity": false,
        "Difficulty Breathing Lying Down": false,
        "Swelling": false,
        "Sudden Awaking from sleep with shortness of breath": false,
    },
    Gastrointestinal: {
        "Swallowing Difficulties": false,
        "Heartburn": false,
        "Change in Appetite": false,
        "Nausea": false,
        "Change in Bowel Habits": false,
        "Rectal Bleeding": false,
        "Constipation": false,
        "Diarrhea": false,
        "Jaundice": false,
    },
    Urinary: {
        "Frequency": false,
        "Urgency": false,
        "Burning or Pain": false,
        "Blood in Urine": false,
        "Incontinence": false,
        "Change in Urinary Strength": false,
    },
    Vascular: {
        "Calf pain with Walking": false,
        "Leg Cramping": false,
    },
    Musculoskeletal: {
        "Muscle or Joint Pain": false,
        "Stiffness": false,
        "Back Pain": false,
        "Redness of Joints": false,
        "Swelling of Joints": false,
        "Trauma": false,
    },
    Neurologic: {
        "Dizziness": false,
        "Fainting": false,
        "Seizures": false,
        "Weakness": false,
        "Numbness": false,
        "Tingling": false,
        "Tremor": false,
    },
    Endocrine: {
        "Head or Cold Intolerance": false,
        "Sweating": false,
        "Frequent Urination": false,
        "Thirst": false,
        "Change in Appetite": false,
    },
    Hematologic: {
        "Ease of Bleeding": false,
    },
    Psychiatric: {
        "Nervousness": false,
        "Depression": false,
        "Memory Loss": false,
        "Stress": false,
    },

};

export const initPhysicalExam = {
    General: {},
    Eyes: {},
    Cardiovascular: {},
    Respiratory: {},
    Gastrointestinal: {},
};

export const initAssessmentPlanFromClickDefault = {
    'diagnosis': '',
    'medicine': '',
    'dosage': '',
    'instructions': '',
    'isPrescription': false,
    'noOfPills': '',
    'noOfRefills': '',
    'prescriptionId': ''
};
