/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onUpdateTicketDoctors = /* GraphQL */ `
  subscription OnUpdateTicketDoctors {
    onUpdateTicketDoctors {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const onUpdateTicketsByUser = /* GraphQL */ `
  subscription OnUpdateTicketsByUser($userId: String) {
    onUpdateTicketsByUser(userId: $userId) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const onUpdateTicketUsers = /* GraphQL */ `
  subscription OnUpdateTicketUsers($ticketId: String) {
    onUpdateTicketUsers(ticketId: $ticketId) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const onstartSession = /* GraphQL */ `
  subscription OnstartSession {
    onstartSession {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const onUpdateTicketStatus = /* GraphQL */ `
  subscription OnUpdateTicketStatus($ticketId: String) {
    onUpdateTicketStatus(ticketId: $ticketId) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const onAssignTicketToDoctor = /* GraphQL */ `
  subscription OnAssignTicketToDoctor($ticketId: String) {
    onAssignTicketToDoctor(ticketId: $ticketId) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const onUpdateProgressNote = /* GraphQL */ `
  subscription OnUpdateProgressNote($ticketId: String!) {
    onUpdateProgressNote(ticketId: $ticketId) {
      status
      message
      alert_title
      ticketId
      group
      userId
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      notification {
        _id
        id
        group
        action
        description
        title
        owner
        timestamp
        __typename
      }
      progressNoteEdited
      __typename
    }
  }
`;
export const onAddSessionComment = /* GraphQL */ `
  subscription OnAddSessionComment($ticketId: String!) {
    onAddSessionComment(ticketId: $ticketId) {
      status
      message
      alert_title
      comment {
        _id
        id
        image
        comment
        owner
        timestamp
        meetingId
        type
        document
        file_name
        document_title
        __typename
      }
      ticketId
      user
      __typename
    }
  }
`;
export const onAddSessionCommentDoctors = /* GraphQL */ `
  subscription OnAddSessionCommentDoctors {
    onAddSessionCommentDoctors {
      status
      message
      alert_title
      comment {
        _id
        id
        image
        comment
        owner
        timestamp
        meetingId
        type
        document
        file_name
        document_title
        __typename
      }
      ticketId
      user
      __typename
    }
  }
`;
export const onDeleteSessionComment = /* GraphQL */ `
  subscription OnDeleteSessionComment($ticketId: String!) {
    onDeleteSessionComment(ticketId: $ticketId) {
      status
      alert_title
      message
      __typename
    }
  }
`;
export const onCreateZoomMeeting = /* GraphQL */ `
  subscription OnCreateZoomMeeting($ticketId: String!) {
    onCreateZoomMeeting(ticketId: $ticketId) {
      status
      message
      alert_title
      start_url
      join_url
      id
      host_signature
      join_signature
      comment {
        _id
        id
        image
        comment
        owner
        timestamp
        meetingId
        type
        document
        file_name
        document_title
        __typename
      }
      ticketId
      __typename
    }
  }
`;
export const onUpdateDoctorSchedule = /* GraphQL */ `
  subscription OnUpdateDoctorSchedule {
    onUpdateDoctorSchedule {
      status
      message
      alert_title
      schedule {
        _id
        id
        ticketId
        title
        start_time
        end_time
        available
        __typename
      }
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      id
      __typename
    }
  }
`;
export const onAddDoctorSchedule = /* GraphQL */ `
  subscription OnAddDoctorSchedule {
    onAddDoctorSchedule {
      status
      message
      alert_title
      schedule {
        _id
        id
        ticketId
        title
        start_time
        end_time
        available
        __typename
      }
      ticket {
        _id
        status
        uid
        subject
        group
        priority
        timestamp
        start_time
        end_time
        schedule_id
        total_tickets
        assignee
        assignee_image
        owner
        progressNote
        comments {
          _id
          id
          image
          comment
          owner
          timestamp
          meetingId
          type
          document
          file_name
          document_title
          __typename
        }
        prescriptions {
          prescription_id
          medication
          dosage
          instructions
          numPills
          numRefills
          deleted
          file_name
          __typename
        }
        problem
        notes {
          note_id
          title
          deleted
          file_name
          note_content
          __typename
        }
        documents {
          documentId
          title
          documentType
          file_name
          deleted
          __typename
        }
        __typename
      }
      id
      __typename
    }
  }
`;
export const onAddDoctorAvailability = /* GraphQL */ `
  subscription OnAddDoctorAvailability($id: String) {
    onAddDoctorAvailability(id: $id) {
      status
      message
      alert_title
      id
      available_slot {
        _id
        id
        start_hour
        start_minute
        end_hour
        end_minute
        start_time
        end_time
        start_date
        end_date
        time_zone
        SUN
        MON
        TUE
        WED
        THU
        FRI
        SAT
        available
        __typename
      }
      __typename
    }
  }
`;
export const onRemoveDoctorAvailability = /* GraphQL */ `
  subscription OnRemoveDoctorAvailability($id: String) {
    onRemoveDoctorAvailability(id: $id) {
      status
      message
      alert_title
      id
      slot_id
      __typename
    }
  }
`;
export const onAddUserConnection = /* GraphQL */ `
  subscription OnAddUserConnection($key: String) {
    onAddUserConnection(key: $key) {
      status
      alert_title
      message
      key
      __typename
    }
  }
`;
