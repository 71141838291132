import React, {useState} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Grid} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {createOrDeleteNote} from "../../ducks/actionCreators/patientOverviewActionCreator";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Alert} from "@mui/lab";
import UpdateSignature from "../SignaturePad/UpdateSignature";
import {Loader} from "@drowl/drowl-react-library";

const NotesModal = (props) => {
    const [note, setNote] = useState({
        title: `Note${new Date().getTime()}`,
        absentDates: '',
        firstConsultationDt: '',
        secondConsultationDt: '',
        sickStDate: '',
        sickEndDt: '',
        fitForDuty: '',
        natureOfIllness: ''
    });

    const [noteError, setNoteError] = useState(false);
    const [noteErrorMsg, setNoteErrorMsg] = useState(false);

    const [showSignaturePad, setShowSignaturePad] = useState(false);

    const data = useSelector((state) => state.settings);
    const loaderData = useSelector((state) => state.loader);

    const {profileInfo} = data;
    const {signature} = profileInfo;

    const ticketID = useParams();
    const dispatch = useDispatch();
    const handleNoteCreation = async() => {
        let noteInput;
        if( !signature
            // && note.firstConsultationDt && note.secondConsultationDt && note.sickStDate && note.sickEndDt && note.fitForDuty && note.natureOfIllness
        ) {
            setNoteError(true);
            setNoteErrorMsg("Please add and save your signature to continue.")

        }
        // else if(!note.absentDates || !note.firstConsultationDt || !note.secondConsultationDt || !note.sickStDate || !note.sickEndDt || !note.fitForDuty || !note.natureOfIllness || !signature) {
        //     noteInput = null;
        //     setNoteError(true);
        //     setNoteErrorMsg("Please fill in the fields to continue.")
        // }

        else {
            // if(note.absentDates) {
            //     noteInput = `<p> <b>Date(s) absent from work as reported by the patient:</b> ${note.absentDates} </p> <br/>`
            // }
            //
            // if(note.firstConsultationDt) {
            //     noteInput = `${noteInput} <p> <b>Date of first consultation:</b> ${note.firstConsultationDt} </p> <br/>`
            // }
            //
            // if(note.secondConsultationDt) {
            //     noteInput = `${noteInput} <p> <b>Date of second consultation:</b> ${note.secondConsultationDt}</p> <br/>`
            // }
            //
            // if(note.sickStDate && note.sickEndDt) {
            //     noteInput = `${noteInput} <p> <b>According to my knowledge / as I was informed he/she was unfit for work from :</b> ${note.sickStDate} <b>to</b> ${note.sickEndDt}</p> <br/>`
            // }
            //
            // if(note.fitForDuty) {
            //     noteInput = `${noteInput} <p> <b>Fit for duty:</b> ${note.fitForDuty} </p> <br/>`
            // }
            //
            // if(note.natureOfIllness) {
            //     noteInput = `${noteInput} <p> <b>Nature of Illness:</b> ${note.natureOfIllness} </p>`
            // }

             noteInput = `<br/> <p> <b>Date(s) absent from work as reported by the patient:</b> ${note.absentDates} </p> <br/>
                           <p> <b>Date of first consultation:</b> ${note.firstConsultationDt} </p> <br/>
                           <p> <b>Date of second consultation:</b> ${note.secondConsultationDt}</p> <br/>
                           <p> <b>According to my knowledge / as I was informed he/she was unfit for work from :</b> ${note.sickStDate} <b>to</b> ${note.sickEndDt}</p> <br/>
                           <p> <b>Fit for duty:</b> ${note.fitForDuty} </p> <br/>
                           <p> <b>Nature of Illness:</b> ${note.natureOfIllness} </p>`
            if(noteInput) {
                const encodedData = btoa(noteInput);
                await dispatch(createOrDeleteNote(ticketID.ticket, encodedData, "", false, note.title, signature));
            }
        }
    }

    const handleNoteChange = (key, newValue) => {
        const tempNote = { ...note };
        tempNote[key] = newValue;
        setNote({ ...tempNote });
    };
    return (
        <Dialog open={props.showModal} className="assessmentModal">
            <> {loaderData.loading && <Loader /> } </>
            <DialogTitle>
                Create Certificate
                <IconButton
                    onClick={()=> props.handleNotesClick()}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>

                {noteError &&  <Alert severity="error" style={{width: "94%"}} onClose={() => setNoteError(false)}>
                    {noteErrorMsg}
                </Alert> }

                <div className='assessmentModal-formGroup' style={{display: "flex"}}>
                    <div className="noteLabel">Note Title: </div>
                    <TextField
                        disabled={props.isClosed}
                        className="noteTextBox"
                        variant="standard"
                        value={note?.title ?? ""}
                        onChange={(event) => handleNoteChange('title', event.target.value)}
                    />
                </div>

                <div className='assessmentModal-formGroup' style={{display: "flex"}}>
                    <div className="noteLabel" style={{flex: 2}}>Date(s) absent from work as reported by the patient: </div>
                    <span style={{flex: 1}}><TextField
                        fullWidth={true}
                        disabled={props.isClosed}
                        className="noteTextBox"
                        variant="standard"
                        value={note?.absentDates ?? ''}
                        onChange={(event) => handleNoteChange('absentDates', event.target.value)}
                    />
                    </span>
                </div>

                <div className='assessmentModal-formGroup' style={{display: "flex"}}>
                    <div className="noteLabel">Date of first consultation: </div>
                    <TextField
                        disabled={props.isClosed}
                        className="noteTextBox"
                        variant="standard"
                        value={note?.firstConsultationDt ?? ''}
                        onChange={(event) => handleNoteChange('firstConsultationDt', event.target.value)}
                    />
                </div>

                <div className='assessmentModal-formGroup' style={{display: "flex"}}>
                    <div className="noteLabel">Date of second consultation: </div>
                    <TextField
                        disabled={props.isClosed}
                        className="noteTextBox"
                        style={{ flex: "1"}}
                        variant="standard"
                        value={note?.secondConsultationDt ?? ''}
                        onChange={(event) => handleNoteChange('secondConsultationDt', event.target.value)}
                    />
                </div>

                <div className='assessmentModal-formGroup'  style={{ display: "flex"}}>
                    <div className="noteLabel">According to my knowledge / as I was informed he/she was unfit for work from :
                       <div  style={{ display: "flex"}} >
                           <TextField
                               disabled={props.isClosed}
                               className="noteTextBox"
                               variant="standard"
                               value={note.sickStDate ?? ''}
                               onChange={(event) => handleNoteChange('sickStDate', event.target.value)}
                           />
                            to

                           <TextField
                               disabled={props.isClosed}
                               className="noteTextBox"
                               variant="standard"
                               value={note?.sickEndDt ?? ''}
                               onChange={(event) => handleNoteChange('sickEndDt', event.target.value)}
                           />
                       </div>
                    </div>
                </div>

                <div className='assessmentModal-formGroup'  style={{ display: "flex"}}>
                    <div className='noteLabel'>Fit for duty: </div>
                    <TextField
                        disabled={props.isClosed}
                        className="noteTextBox"
                        variant="standard"
                        value={note?.fitForDuty ?? ''}
                        onChange={(event) => handleNoteChange('fitForDuty', event.target.value)}
                    />
                </div>

                <div className='assessmentModal-formGroup'  style={{ display: "flex"}}>
                    <div className='noteLabel'>Nature of Illness: </div>
                    <TextField
                        disabled={props.isClosed}
                        style={{ flex: "1", flexGrow: "1"}}
                        className="noteTextBox"
                        variant="standard"
                        value={note?.natureOfIllness ?? ''}
                        onChange={(event) => handleNoteChange('natureOfIllness', event.target.value)}
                    />
                </div>

                {/*<div className='assessmentModal-formGroup'>*/}
                {/*    <div className='assessmentModal-fieldLabel'>Digital Signature:</div>*/}
                {/*    {useExisting && <img src={`data:image/png;base64,${signature}`} alt="Base64 Signature" />}*/}
                {/*</div>*/}

                {/*{showSignaturePad && <Update}*/}

            </DialogContent>

            <div style={{display: "flex", justifyContent: "center",
                alignItems: "center", marginBottom : "20px"}}>
            {/*<Button*/}
            {/*    disabled={!signature}*/}
            {/*    variant="contained"*/}
            {/*    color="primary"*/}
            {/*    onClick={() => handleExistingSignature()}>*/}
            {/*    Use Existing Signature*/}
            {/*</Button>*/}
            {/*    <UpdateSignature showSignaturePad={showSignaturePad} setShowSignaturePad={setShowSignaturePad} buttonText={!signature ? "Add Signature" : "Update Signature"} />*/}
                You can update you signature in settings.

            </div>


            <DialogActions>
                {!props.isClosed &&
                    <Button
                        // disabled={true}
                        variant="contained"
                        color="primary"
                        onClick={() => handleNoteCreation()}>
                        Create
                    </Button>
                }
                <Button variant="contained"
                        onClick={() => props.handleNotesClick()}
                >
                    Cancel
                </Button>
            </DialogActions>

        </Dialog>
    )
}

export default NotesModal;
