import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import {convertCsDte, getButtonForStatus} from "../../utils/utlis";
import { useNavigate } from "react-router-dom";

const TabPanelContentMobile = ({ rows, rowsPerPage, page, columnHeaders }) => {
    let navigate = useNavigate();
    return (
        <Table>
            {/* No Data  */}
            {rows.length === 0 &&
                <TableRow>
                    <TableCell colSpan={6} align='center'>No data to display</TableCell>
                </TableRow>
            }

            {(rowsPerPage > 0
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rows
            ).map((row) => {
                const buttonType = getButtonForStatus(row.status);
               return ( <TableBody key={row.uid} className="mobileTableRow" onClick={() => {
                   navigate(`/patientOverview/${row._id}`) } }
                                   sx={{ "&:last-child td, &:last-child th": { border: 0 },
                                       //     "&:nth-child(even) td" : {
                                       //     backgroundColor: "#3daf2c"
                                       // },
                                       "&:nth-child(odd) td" : {
                                           backgroundColor: "rgba(238,249,243,.8300000000000001)"
                                       }
                                   }}
               >
                    <TableRow>
                        <TableCell variant='head'> {columnHeaders[0]} </TableCell>
                        <TableCell>
                            {row.uid}
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell variant='head'>{columnHeaders[1]}</TableCell>
                        <TableCell>
                            <button className={buttonType.caseBtnClass}> {buttonType.caseStatusBtntext}</button>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell variant='head'>{columnHeaders[2]}</TableCell>
                        <TableCell>{convertCsDte(row.timestamp)}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell variant='head'>{columnHeaders[3]}</TableCell>
                        <TableCell>{row.subject}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell variant='head'>{columnHeaders[4]}</TableCell>
                        <TableCell>{row.assignee || "No Provider"}</TableCell>
                    </TableRow>

                </TableBody>)

            })}
        </Table>
    );
};

export default TabPanelContentMobile;