import React from 'react';
import {Button, Modal, Typography, Box, Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';

const ConfirmActionModal = ({open, close, confirm}) => {
    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle>
                <b>Confirm Status Change</b>
            </DialogTitle>
            <DialogContent>
                <div className='deleteAssessmentTextContaioner' style={{marginTop: "20px"}}>
                    If you change this case status from "Scheduled", it will remove the scheduled visit and send a notification to the user.
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" onClick={confirm}>Continue</Button>
                <Button variant="contained" onClick={close}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );

    // return (<div>Confirmation</div>)
}

export default ConfirmActionModal;