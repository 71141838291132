export const ETHNICITY_LIST = [
    { value: "Decline to answer", label: "Decline to answer" },
    { value: "Black South African", label: "Black South African" },
    { value: "White", label: "White" },
    { value: "Coloured", label: "Coloured" },
    { value: "Indian", label: "Indian" },
    { value: "Foreign National", label: "Foreign National" }
];

export const GENDER_LIST = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" }
];

export const DOCUMENTS_LIST = [
    { value: "proofPracticeAddress", label: "Proof of Practice Address"} ,
    { value: "proofResidenceAddress", label: "Proof of Residence Address" },
    { value: "medicalRegistrationCard", label: "Medical Registration Card *" },
    { value: "southAfricanIDFront", label: "The front of South African ID *"},
    { value: "southAfricanIDFrontBack", label: "The back of South African ID *"},
    { value: "Other", label: "Other"}
]

export const MEDICAL_DOCUMENTS_LIST = [
    { value: "labResult", label: "Lab Result"} ,
    { value: "imaging", label: "Imaging" },
    { value: "medicalRecord", label: "Medical Record" },
    { value: "Other", label: "Other"}
]

export const SA_LANGUAGES = [
    "Afrikaans",
    "English",
    "Hindi",
    "Ndebele",
    "Sepedi",
    "Sotho",
    "Swati",
    "Tsonga",
    "Tswana",
    "Venda",
    "Xhosa",
    "Zulu"
]
