import React from "react";
import "../Stats.css";
import {useSelector} from "react-redux";
import Table from "../../Table/index";
import DoctorInfo from "../Overview/DoctorInfo";

const AmountOwed = () => {

    const statsData = useSelector(state => state.statsReducer);

    const columnHeaders = ["Patient", "Case ID", "Date", "Time", "Amount"];

    const rowHeaders = ["patient", "case_id", "date", "timestamp", "amount"];

    return (
        <div className="textBox pageWindow" id="statsWindow">
            <DoctorInfo type="ADMIN"  title="AMOUNT OWED" />
            <Table
                columnHeaders={columnHeaders}
                rows={statsData.amountOwed}
                rowHeaders={rowHeaders}
                alignRows=""
            />
        </div>
    )
}

export default AmountOwed;