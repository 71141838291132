import {Button, Dialog, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SignaturePad from "./index";
import React, {useEffect} from "react";

const UpdateSignature = ({showSignaturePad, setShowSignaturePad, buttonText, accountForm}) => {
    return (
        <>
            <Button
                variant="contained"
                style={{
                    float: "left !important",
                    // marginLeft: "20px",
                    // marginRight: "20px",
                    // marginBottom: "20px"
                }}
                onClick={() => setShowSignaturePad(true)}
            > {buttonText ? buttonText : "Update Signature"} </Button>

            <Dialog open={showSignaturePad} className="assessmentModal">
                <DialogTitle>
                    {buttonText ? buttonText : "Update Signature"}
                    <IconButton
                        onClick={() => setShowSignaturePad(false)}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>

                <DialogContent>
                    <div className='assessmentModal-formGroup'>
                        <div className='assessmentModal-fieldLabel'>Digital Signature:</div>
                        <SignaturePad setShowSignaturePad={setShowSignaturePad} accountForm={accountForm}/>
                    </div>

                    <div style={{marginBottom: "40px"}}/>
                </DialogContent>

            </Dialog>
        </>)
}

export default UpdateSignature;