import {GET_DOCTOR_LIST, GET_STATS, GET_SEARCH_RESULT, GET_ADMIN_REPORTS} from "../actions/docStatsActions";

const init = {
    stats: {},
    adminDocList: [],
    doctorList: [],
    searchList: [],
    amountOwed: [{
        patient: "Jen Marie",
        case_id: "23462834",
        date: "2022-10-16",
        timestamp: "9:00 am",
        amount: "R 200"
    },
        {
            patient: "Aries Phill",
            case_id: "876234567",
            date: "2022-10-15",
            timestamp: "8:00 am",
            amount: "R 200"
        },
        {
            patient: "Jen Marie",
            case_id: "23462834",
            date: "2022-10-14",
            timestamp: "11:00 am",
            amount: "R 200"
        },
        {
            patient: "Aries Phill",
            case_id: "876234567",
            date: "2022-10-13",
            timestamp: "1:00 pm",
            amount: "R 700"
        },
        {
            patient: "Jen Marie",
            case_id: "23462834",
            date: "2022-10-10",
            timestamp: "9:00 pm",
            amount: "R 100"
        }],
    paymentDetails: [
        {
            patient: "Aries Phill",
            case_id: "876234567",
            date: "2022-10-13",
            timestamp: "1:00 pm",
            amount: "R 900"
        },
        {
            patient: "Jen Marie",
            case_id: "23462834",
            date: "2022-10-10",
            timestamp: "9:00 pm",
            amount: "R 300"
        }
    ],
    amountPaid: [{
        date: "2022-10-16",
        payment_id: "23462834",
        amount: "R 20000"
    },
        {
            date: "2022-10-15",
            payment_id: "876234567",
            amount: "R 200"
        },
        {
            date: "2022-10-15",
            payment_id: "23462834",
            amount: "R 8000"
        },
        {
            date: "2022-10-15",
            payment_id: "876234567",
            amount: "R 2000"
        },
    ]

};

const statsReducer = (state = init, action) => {
    switch (action.type) {
        case GET_STATS: {
            return { ...state, stats: action.payload };
        }
        case GET_DOCTOR_LIST: {
            return { ...state, doctorList: action.payload };
        }
        case GET_SEARCH_RESULT: {
            return { ...state, searchList: action.payload };
        }
        case GET_ADMIN_REPORTS: {
            return { ...state, adminDocList: action.payload };
        }
        default:
            return state;
    }
};

export default statsReducer;