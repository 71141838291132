import {Button, IconButton, Checkbox, FormControlLabel} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateProgressNote} from "../../ducks/actionCreators/patientOverviewActionCreator";

{/* Current Conditions */}
const Conditions = ({addAssessmentPlan}) => {
    const patientOverviewData = useSelector(state => state.patientOverview);
    const { progressNote, ticket } = patientOverviewData;
    // let  { diagnosis } = progressNote;

    const [currentConditions, setCurrentConditions] = useState(progressNote && progressNote.diagnosis || []);
    const [isEdittingConditions, setIsEdittingConditions] = useState(false);

    useEffect(() => {
       setCurrentConditions(progressNote && progressNote.diagnosis);
    },[progressNote.diagnosis])

    const dispatch = useDispatch();

    const cancelEdit = () => {
      // revert back to the incoming progress note.
          let result = [];

          for (let i = 0; i < currentConditions.length; i++) {
            let curCond = { ...diagnosis[i] };

            if (curCond.hasOwnProperty('doctor_deleted')) {
              curCond.doctor_deleted = getInitialStateOfCondStatus(currentConditions)[i];
            } else if (curCond.hasOwnProperty('deleted')) {
              curCond.deleted = getInitialStateOfCondStatus(currentConditions)[i];
            }
            result.push(curCond);
          }
          setCurrentConditions([...result]);
          setIsEdittingConditions(false);
    }

    const getInitialStateOfCondStatus = (data) => {
      // set the inital state on if a med is deleted or not.
      let result = [];
      for (let i = 0; i < data.length; i++) {
        let curCond = { ...data[i] };
        if (curCond.doctor_deleted) {
          result.push(curCond.doctor_deleted);
        } else if (curCond.deleted) {
          result.push(curCond.deleted);
        } else {
          result.push(false);
        }
      }

      return result
    };

    const handleCurrentConditionsChange = (i, isChecked) => {
      const temp = [...currentConditions];
      temp[i].doctor_deleted = !isChecked; // isChecked true then doctor_delete is false
      setCurrentConditions(temp);
    };

    const updateCurrentConditions = () => {
      const updateProgressNoteVals = {
        ...progressNote,
        'diagnosis': currentConditions
      };

      dispatch(updateProgressNote(updateProgressNoteVals, ticket._id));
      setIsEdittingConditions(false);
      getInitialStateOfCondStatus(currentConditions);
    };

    return (
        <>
            {(currentConditions && currentConditions.length >0 && Object.keys(currentConditions).length !== 0) &&
                <div className="form-group">
                    <div className="pn-subtitle" style={{display: "flex"}}>
                        Current Conditions:
                        {/*{ticket.status !== 3 && !isEdittingConditions && <> (Click to edit) </>}*/}
                        {/*{ticket.status !== 3 && <div> { isEdittingConditions ?*/}
                        {/*    <>*/}
                        {/*        <Button variant="contained" color='primary'*/}
                        {/*            onClick={updateCurrentConditions} style={{ marginLeft: '5px' , color: "white", padding: "5px" }}>*/}
                        {/*            Save*/}
                        {/*        </Button>*/}
                        {/*        <Button variant="contained" color='secondary'*/}
                        {/*            onClick={() => cancelEdit('diagnosis')}*/}
                        {/*                style={{ color: "white", padding: "5px" }}*/}
                        {/*        >*/}
                        {/*            Cancel*/}
                        {/*        </Button>*/}
                        {/*    </>*/}
                        {/*    :*/}
                        {/*    <IconButton onClick={() => setIsEdittingConditions(true)} color="primary">*/}
                        {/*        <EditIcon fontSize="medium" style={{ marginTop: "-9px"}} />*/}
                        {/*    </IconButton>*/}
                        {/*} </div> }*/}
                    </div>
                    <ul>
                        {currentConditions.map((x, index) => {
                            return (
                                <li
                                    key={index}
                                    className={`subject-text dignsdAddtoPln ${x.deleted || x.doctor_deleted ? 'patientDelete' : ''}`}
                                    onClick={() => { if(!isEdittingConditions) addAssessmentPlan(x.icd_code, x.report_title); }}
                                >
                                    {x.icd_code} {x.report_title}

                                    {isEdittingConditions && !x.deleted &&
                                                <Checkbox
                                                    color='primary'
                                                    checked={!x.doctor_deleted ?? true}
                                                    onChange={() => handleCurrentConditionsChange(index, ('doctor_deleted' in x ? x.doctor_deleted : false))}
                                                />
                                    }
                                </li>
                            );
                        })}
                    </ul>
                </div>
            }
        </>
    )
}

export default Conditions;
