import React from 'react';
import './Header.css'
import { Grid } from '@mui/material';


const LoginHeader = () => {

    return (
      <div className='headerContainer'>
        <Grid container
              direction="row"
              alignItems="center"
              wrap='nowrap'
              id='header-grid-container'
        >
            <Grid item xs={8} className='headerLogo'>
              <img className='headerLogoImg' src={"../../assets/logos/EagleIntelligentHealth_Logo_500x213.png"}/>
            </Grid>
        </Grid>
      </div>
    );

}

export default LoginHeader;
