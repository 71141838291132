import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    openPatientDocument, setPatientDocumentLink
} from "../../ducks/actionCreators/patientOverviewActionCreator";
import {ListItem, ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import NoteIcon from "@mui/icons-material/Note";
import "./PatientDocuments.css";

export default function PatientDocuments() {
    const { ticket, patientDocuments, patientDocumentLink } = useSelector(state => state.patientOverview);
    const dispatch = useDispatch();

    useEffect(() => {
        if(patientDocumentLink) {
            window.open(patientDocumentLink, "_target");
        }
        dispatch(setPatientDocumentLink(""));
    }, [patientDocumentLink]);

    const viewDoc = (url) => {
        dispatch(openPatientDocument(url, ticket.owner));
    }

    return (
      <div className="patientDocs">
          <Typography variant={"h5"}>Patient Documents</Typography>
          {patientDocuments && patientDocuments.length >0 ? patientDocuments.map((x) => (
              <>
                  { !x.deleted ? <ListItem>
                      <ListItemIcon>
                          <NoteIcon />
                      </ListItemIcon>
                      <ListItemButton key={x.document_id} onClick={() => viewDoc(x.url)}>
                          <ListItemText primary={x.title} />
                      </ListItemButton>
                  </ListItem> : null }
              </>
          )) : <p style={{marginTop: "50px"}}><Typography variant={"h6"}>No patient documents found.</Typography> </p>}
      </div>
    );
}