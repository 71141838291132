import React, {useState, useEffect} from 'react';
import './ProgressNote.css';
import TextField from '@mui/material/TextField';
import startCase from 'lodash/startCase';
import {useSelector} from "react-redux";

export default function PrimaryDiagnosis(props) {
    const patientOverviewData = useSelector(state => state.patientOverview);
    const { progressNote, ticket } = patientOverviewData;
    // const { chiefComplaint, historyOfPresentIllness } = progressNote;
    const [ info, setInfo ] = useState();

    useEffect(() => {
        setInfo({
            "Chief Complaint": progressNote && progressNote.chiefComplaint,
            "Additional Information (Optional)": progressNote && progressNote.historyOfPresentIllness
        } ?? {})
    },[progressNote])

    // Handle change as user types in the text fields.
    const handleChange = (event) => {
        const eventName = event.target.name;
        const newInfo = { ...info, [eventName]: event.target.value };
        setInfo(newInfo);
    };

    // Save Changes after a user is no longer focused in a text field
    const saveChange = () => {
        const updateData = {
            chiefComplaint: info["Chief Complaint"],
            historyOfPresentIllness: info["Additional Information (Optional)"]
        }
        props.updatedPrimaryDiagnosis(updateData);
    }

    return (
        <div>
            {info && Object.entries(info).map(([key, value]) => {
                return (
                    <div className='form-group' key={key}>
                        <div className="section-subgroup-whole-line">{(key) + ':'}</div>
                        <TextField
                            value={info[key]}
                            name={key}
                            variant="outlined"
                            minRows={4}
                            multiline={true}
                            className="multipleLine"
                            style={{ background: 'white', marginBottom: '15px'}}
                            onChange={handleChange}
                            onBlur={saveChange}
                            disabled={ticket.status === 3}
                        />
                    </div>
                );

            })}
        </div>
    );
}