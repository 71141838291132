import { SET_CAL_COMP } from "../actions/provCalendarActions";

const init = {
	Cal_Comp_List: []
};

const provCalendarReducer = (state = init, action) => {
	switch (action.type) {

		case SET_CAL_COMP: {
			return {
				...state, Cal_Comp_List: action.payload
			};
		}
		default:
			return state;
	}
};

export default provCalendarReducer;