import React, { useEffect, useState } from 'react';
import './ProgressNote.css';
import { TextField, ListItemButton, ListItemText, ListItem, ListItemIcon } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AssessmentPlanModal from './AssessmentPlanModal';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {useSelector, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {
    getPrescription, setPrescription,
    setUpdatePresccriptionStatus,
    updatePrescriptions,
    getNotes, setNotes, createOrDeleteNote, setNoteCreationStatus, getTeleMedDoc,
    addOrDeleteTelevistDocument, viewTelemedDoc, setUploadDocStatus
} from "../../ducks/actionCreators/patientOverviewActionCreator";
import NotesModal from "./NotesModal";
import NoteIcon from '@mui/icons-material/Note';
import UploadModal from "./UploadModal";

export default function AssessmentPlanNew(props) {
    // const [showMobileViewTable, setShowMobileViewTable] = useState(window.innerWidth < 600);
    const patientOverviewData = useSelector(state => state.patientOverview);
    const { progressNote, noteResp, notes, teleDocsList, teleMedDoc, upldDocStatus } = patientOverviewData;
    // const  { assessmentAndPlan : plan } = progressNote;

    const medsConds = useSelector(state => state.medsConds);

    const [assessmentAndPlan, setAssessmentAndPlan] = React.useState(progressNote && progressNote.assessmentAndPlan);
    const icdOptions = medsConds && medsConds.conditionsList.length > 0 && medsConds.conditionsList.map(option => option.replaceAll('\r', ''));
    const medOptions = medsConds && medsConds.medicationsList.length>0 && medsConds.medicationsList.map(option => option.replaceAll('\r', ''));

    const [planOfTreatment, setPlanOfTreatment] = React.useState(props.planOfTreatment ?? '');
    const medicationTableColumns = ['Diagnosis', 'Medication', 'Dosage', 'Instructions', 'Rx'];


    const [showModal, setShowModal] = useState(false);
    const [showNotesModal, setShowNotesModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [assessment, setAssessment] = useState();

    const [showUploadModal, setShowUploadModal] = useState(false);

    const [showConfirmActionModal, setShowConfirmActionModal] = useState(false);
    const [deleteAssessment, setDeleteAssessment] = useState();

    const ticketID = useParams();

    const dispatch = useDispatch();

    const { prescriptionResp , updatePrescStatus, ticket, noteCreationStatus} = patientOverviewData;

    // useEffect(() => {
    //     function handleWindowResize() {
    //         setShowMobileViewTable(window.innerWidth < 600);
    //     };
    //     window.addEventListener("resize", handleWindowResize);
    // }, []);

    useEffect(() => {
        setAssessmentAndPlan(progressNote && progressNote.assessmentAndPlan);
    },[progressNote]);

    useEffect(() => {
        if (prescriptionResp && prescriptionResp.status) {
            setTimeout(() => {
                window.open(prescriptionResp.prescription, '_blank');
            })
            dispatch(setPrescription(null));
        }

    }, [prescriptionResp && prescriptionResp.status])

    useEffect(() => {
        if (teleMedDoc && teleMedDoc.status) {
            setTimeout(() => {
                window.open(teleMedDoc.patientDocument, '_blank');
            })
            dispatch(viewTelemedDoc(null));
        }

    }, [teleMedDoc && teleMedDoc.status])


    useEffect(() => {
        if(updatePrescStatus === "error") {
            dispatch(setUpdatePresccriptionStatus(""));
            throw 'Error: deleting prescription';
        }
    }, [updatePrescStatus])

    useEffect(() => {
        if(noteCreationStatus === "success") {
            setShowNotesModal(false);
            dispatch(setNoteCreationStatus(""));
        }
    }, [noteCreationStatus])

    useEffect(() => {
        if(noteResp && noteResp.status) {
            setTimeout(() => {
                window.open(noteResp.note, '_blank');
            })
            dispatch(setNotes(null));
        }
    }, [noteResp && noteResp.status])

    useEffect(() => {
        if (props.initNewMed.diagnosis !== '') {
            setAssessment([-1, props.initNewMed], handleShowModal());
        }
    }, [props.initNewMed]);

    // Save progress note after plan of treatment changes
    const savePlanOfTreatmentUpdate = () => {
        props.updatedPlanOfTreatment({ 'planOfTreatment': planOfTreatment });
    };

    // Remove assessment
    const removeAssessmentPlan = async (idx) => {
        const deleteAssessment = [...assessmentAndPlan];
        if (deleteAssessment[idx]?.isPrescription) {
            try {
                // delete prescription
                dispatch(updatePrescriptions(ticketID.ticket, deleteAssessment[idx], true));

                // if (!response?.data?.createOrDeletePrescription?.status) {
                //     // throw error but don't delete assessment
                //     throw response?.data?.createOrDeletePrescription?.message ?? 'Error: deleting prescrition';
                // }
            } catch (e) {
                console.error('error', e.errors[0]?.message ?? e);
                return;
            }
        }

        // Remove assessment from the assessment plan in the progress note
        deleteAssessment.splice(idx, 1);
        props.updatedAssessmentAndPlan(deleteAssessment.length > 0 ? deleteAssessment : []);
        setAssessmentAndPlan(deleteAssessment.length > 0 ? deleteAssessment : []);
    };

    // View a prescription in a new tab
    const handlePrescriptionLinkClick = async (assessment) => {
        if (assessment?.isPrescription && assessment?.prescriptionId) {
            try {
                dispatch(getPrescription(ticketID.ticket, assessment.prescriptionId));
            } catch (e) {
                console.error(e);
            }
        }

    };

    // ------------ Modal functions ------------

    // Edit assessment plans after a single edit to assessment
    const editAssessmentPlans = (idx, newVal) => {
        const copyAssessment = [...assessmentAndPlan];
        copyAssessment[idx] = newVal;

        props.updatedAssessmentAndPlan([...copyAssessment]);
        setAssessmentAndPlan([...copyAssessment]);
    };

    // Add a new plan to assessment
    const addNewAssessmentPlan = (assessment) => {
        props.updatedAssessmentAndPlan(assessment);
        setAssessmentAndPlan(assessment);
    };


    // handle add assessment button click
    const handleAddAssessmentClick = () => {
        setIsEdit(false);
        handleShowModal();
    };

    const handleNotesClick = () => {
        setShowNotesModal(!showNotesModal);
    };
    // show modal
    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleUploadDocClick = (value) => {
        if(value) {
            setShowUploadModal(value)
        }
        else {
            setShowUploadModal(!showUploadModal)
        }
    }

    // close modal
    const handleCloseModal = () => {
        setIsEdit(false);
        setShowModal(false);
    };

    // show confirm action modal
    const handleShowConfirmActionModal = (index, assessment) => {
        setDeleteAssessment([index, assessment]);
        setShowConfirmActionModal(true);
    };

    // close confirm action modal
    const handleCloseConfirmActionModal = () => {
        setShowConfirmActionModal(false);
    };

    const viewNote = (noteId) => {
        dispatch(getNotes(ticketID.ticket, noteId));
    }

    const deleteDocClick = async(documentId) => {
        dispatch(addOrDeleteTelevistDocument(ticketID.ticket, "",documentId, "", "", true, "delete"));
    }

    const viewDoc = (documentId) => {
        dispatch(getTeleMedDoc(ticketID.ticket, documentId));
    }

    const deleteNoteClick = async(noteId) => {
        await dispatch(createOrDeleteNote(ticketID.ticket, "", noteId, true));
    }

    return (
        <div>
            <AssessmentPlanModal showModal={showModal}
                                 handleCloseModal={handleCloseModal}
                                 editAssessmentPlans={editAssessmentPlans}
                                 addNewAssessmentPlan={addNewAssessmentPlan}
                                 assessment={assessment}
                                 isEdit={isEdit}
                                 isClosed={props.isClosed}
                                 icdOptions={icdOptions}
                                 medOptions={medOptions} />

            {/* Plan of Treatment */}
            <div className="form-group" style={{ marginTop: "15px"}}>
                <div className="section-subgroup-whole-line">Plan of Treatment:</div>
                <TextField
                    value={planOfTreatment}
                    variant="outlined"
                    minRows={3}
                    multiline={true}
                    className="multipleLine"
                    style={{background:'white'}}
                    onChange={(event) => setPlanOfTreatment(event.target.value)}
                    onBlur={savePlanOfTreatmentUpdate}
                    disabled={props.isClosed} />
            </div>

            <div className="section-subgroup-whole-line">Upload Documents:
                {!props.isClosed &&
                    <IconButton onClick={() => handleUploadDocClick()}>
                        <AddCircleOutlineIcon className='addPrescription' fontSize="large" />
                    </IconButton>
                }
            </div>

            <UploadModal
                showModal={showUploadModal}
                handleUploadDocClick={handleUploadDocClick}
                // onSubmit={submitDocuments}
            />

            {teleDocsList && teleDocsList.length >0 && teleDocsList.map((x) => (
                <>
                    { !x.deleted ? <ListItem secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                            <DeleteIcon onClick={() => deleteDocClick(x.documentId)}/>
                        </IconButton>
                    }>
                        <ListItemIcon>
                            <NoteIcon />
                        </ListItemIcon>
                        <ListItemButton key={x.note_id} onClick={() => viewDoc(x.documentId)}>
                            <ListItemText primary={x.title} />
                        </ListItemButton>
                    </ListItem> : null }
                </>
            ))}

            <br/><br/>

            <div className="section-subgroup-whole-line">Notes for Patient:
                {!props.isClosed &&
                    <IconButton onClick={() => handleNotesClick()}>
                        <AddCircleOutlineIcon className='addPrescription' fontSize="large" />
                    </IconButton>
                }
            </div>

            <NotesModal showModal={showNotesModal}
                        handleNotesClick={handleNotesClick}
            />

            {notes && notes.length >0 && notes.map((x) => (
                <>
                    { !x.deleted ? <ListItem secondaryAction={
                        <IconButton edge="end" aria-label="delete">
                            <DeleteIcon onClick={() => deleteNoteClick(x.note_id)}/>
                        </IconButton>
                    }>
                        <ListItemIcon>
                            <NoteIcon />
                        </ListItemIcon>
                        <ListItemButton key={x.note_id} onClick={() => viewNote(x.note_id)}>
                            <ListItemText primary={x.title} />
                        </ListItemButton>
                    </ListItem> : null }
                </>
            ))}
        </div>

    );
}