import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import store from "./ducks/store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { Loader } from "@drowl/drowl-react-library";
import IdleComponent from "./components/IdleComponent";

import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://43cd075dd6201acd21adceb7025cc30f@o4506871224139776.ingest.us.sentry.io/4507381087731712",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

let persistor = persistStore(store);

const Theme = createTheme({
    palette: {
      primary: {
        main: '#29b95',
      },
      secondary: {
        main: grey[600],
      },
    },
  });

const root = createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <PersistGate loading={<Loader />} persistor={persistor}>
             {/*<React.StrictMode>*/}
                <ThemeProvider theme={Theme}>
                    <IdleComponent />
                    <App />
                </ThemeProvider>
             {/*</React.StrictMode>*/}
        </PersistGate>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
