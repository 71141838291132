import React from 'react';
import './Footer.css'
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const Footer = () => {
    const data = useSelector((state) => state.login);
    const location = useLocation();
  return (
         <>
             { data && data.isUserAuthenticated && location.pathname !== "/shareFile" &&
                 <div className="footer-container">
                     <div className="copyright">© {new Date().getFullYear()} Eagle Intelligent Health, LLC. All rights reserved.</div>
                 </div> }
             {location.pathname === "/shareFile" &&
                 <div className="footer-container">
                     <div className="copyright">© {new Date().getFullYear()} Eagle Intelligent Health, LLC. All rights reserved.</div>
                 </div> }
         </>
  );
}


export default Footer;
