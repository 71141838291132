export const CHART_COLORS = ["#add8e6", "#0fabd2", "#8ab9f1", "#87cefa", "#0093afbf", "#4682b4", "#48D1CC", "#4169e1"];
export const COLOR_GRADIENT = ["#60efff", "#00e3ff", "#00d7ff", "#00c9ff", "#00bbff", "#00acff", "#009cff", "#008bff", "#0077ff", "#0061ff"];

export const KEY_LOOKUP = {
    "Gender": "gender",
    "Medications": "medicine",
    "Conditions": "condition",
    "Ethnicities": "ethnicity",
    "Ages": "age_group"
};

export const NORMAL_FONT_SIZE = 10;
export const TITLE_FONT_SIZE = 17;

const getOptions = (title, legend) => {
    return {
        responsive: true,
        plugins: {
            title: {
                display: true,
                font: {
                    size: TITLE_FONT_SIZE
                },
                text: title
            },
            legend: legend
        },
        tooltips: {
            titleFontSize: NORMAL_FONT_SIZE,
            bodyFontSize: NORMAL_FONT_SIZE

        }
    };
};

export const getChartOptions = (type, title) => {
    if (type === "bar") {
        let legend = {
            display: false,
            labels: {
                font: {
                    size: NORMAL_FONT_SIZE
                },
                fullSize: false,
                padding: {
                    left: 10,
                    right: 10
                }
            }
        };
        return {
            ...getOptions(title, legend),
            scales: {
                x: {
                    suggestedMin: 0,
                    suggestedMax: 10
                },
                y: {
                    suggestedMin: 0,
                    suggestedMax: 10
                }
            },
            tooltips: {
                titleFontSize: NORMAL_FONT_SIZE,
                bodyFontSize: NORMAL_FONT_SIZE,
                stepSize: 1
            }
        };
    } else if (type === "pie" || type === "doughnut") {
        let legend = {
            labels: {
                font: {
                    size: NORMAL_FONT_SIZE
                }
            }
        };
        return {
            ...getOptions(title, legend),
            elements: {
                arc: {
                    borderWidth: 0
                }
            }
        };
    } else if (type === "horizontalBar") {
        let legend = {
            display: false,
            font: {
                size: NORMAL_FONT_SIZE
            },
            fullSize: false,
            padding: {
                left: 10,
                right: 10
            }
        };
        return {
            ...getOptions(title, legend),
            scales: {
                x: {
                    suggestedMin: 0,
                    suggestedMax: 5
                }
            },
            indexAxis: "y",
            elements: {
                bar: {
                    borderWidth: 2
                }
            }
        };
    } else if (type === "line") {
        let legend = {
            display: false,
            labels: {
                font: {
                    size: NORMAL_FONT_SIZE
                },
                fullSize: false,
                padding: {
                    left: 10,
                    right: 10
                }
            }
        };
        return {
            ...getOptions(title, legend),
            scales: {
                y:
                    {
                        beginAtZero: true,
                        scaleIntegersOnly: true,
                        // precision: 0,
                        // min: 0,
                        // max:  3000,
                        // stepSize: 500,
                        suggestedMin: 0,
                        suggestedMax: 10
                    }
            },
            tooltips: {
                titleFontSize: NORMAL_FONT_SIZE,
                bodyFontSize: NORMAL_FONT_SIZE,
                stepSize: 100 ,
                scaleOverride: true,
                scaleIntegersOnly: true
            }
        };
    }
};