import React, {useState, useEffect} from "react";
import {
    Stepper,
    Step,
    StepLabel,
    Box,
    Alert,
    Button,
    TextField,
    FormControlLabel,
    FormHelperText,
    FormControl,
    Tooltip,
    InputAdornment
} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import {
    signUpUser,
    confirmAndSignUp,
    resendSignUpConfirmationCode,
    setStep, confirmSignUpStatus, isUserLoggedIn
} from "../../ducks/actionCreators/loginActionCreator";
import {setMessage} from "../../ducks/actionCreators/messageActionCreator";
import {useNavigate, useSearchParams} from "react-router-dom";
import {setAdminApprovalRes, setAdminDenialRes} from "../../ducks/actionCreators/requestActionCreator";
import {Analytics} from "aws-amplify";

const PhysicianSignUp = () => {
    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState(false);
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [agreeTerms, setAgreeTerms] = useState(false);
    const [phone, setPhone] = useState("");
    const [phoneError, setPhoneError] = useState(false);
    const [code, setCode] = useState("");
    const [codeError, setCodeError] = useState(false);
    const [agreeTermsError, setAgreeTermsError] = useState(false);
    const [error, setError] = useState(false);

    const dispatch = useDispatch();

    const data = useSelector((state) => state.login);
    const messageData = useSelector((state) => state.message);

    const {signupStatus} = data;

    const steps = [
        "Create an account",
        "Verify your account"
    ];

    const [searchParams] = useSearchParams();

    useEffect(() => {
        // const analytics = async() => {
        //     await Analytics.record({
        //         screen: "providerSignUp", datetime: new Date().toLocaleString()
        //     });
        // };
        // analytics();
        dispatch(setMessage({text: "", type: "", show: false}));
        dispatch(setStep(0));
    }, [dispatch]);


    // useEffect(async () => {
    //     if(signupStatus && signupStatus === "success") {
    //         dispatch(confirmSignUpStatus(""));
    //         navigate("/physicianProfile", { replace: true })
    //         await Analytics.record({
    //             screen: "providerSignUp", datetime: new Date().toLocaleString()
    //         });
    //     }
    //     if(signupStatus && signupStatus === "error") {
    //         setError({status: true, message: "Something went wrong, please try again later. "})
    //     }
    // }, [signupStatus])

    const onSubmitSignup = (userName) => {
        if(!code) {
            setCodeError(true);
        } else {
            dispatch(confirmAndSignUp(userName, code, password, "signup"));
        }
    };

    const resendCode = (userName) => {
        dispatch(resendSignUpConfirmationCode(userName));
    };

    const validateStep1 = () => {
        if(!firstName) {
            setFirstNameError(true);
        }
        if(!lastName) {
            setLastNameError(true);
        }
        if(!email) {
            setEmailError(true);
        }
        if(!password) {
            setPasswordError(true);
        }
        if(!phone) {
            setPhoneError(true);
        }

        return !!(firstName && lastName && email && password && phone);
    };

    const onNextButtonClick = () => {
        dispatch(setMessage({text: "", type: "", show: false} ));
        let stepValidated = false;
        if(data.activeStep === 0) {
            stepValidated = validateStep1();
            if(stepValidated) { dispatch(signUpUser(password, email, "+27" + phone, firstName, lastName)) }
        }
    };
    const onPreviousButtonClick = () => {
        dispatch(setMessage({text: "", type: "", show: false}));
        dispatch(setStep(0));
    };

    return (
        <>
            <div >
                {/*<img className="imgClass"  src="/assets/logos/EagleIntelligentHealth_Logo_500x213.png" alt="Eagle Intelligent Health Logo"></img>*/}
            <div style={{marginBottom: "40px"}}>
                <h2 className="windowTitl" style={{textAlign: "center"}}>REQUEST ACCESS</h2>
                {/*<div> {messageData.message.show && <NotificationMessage type={messageData.message.type} text={messageData.message.text} /> } </div>*/}
                <Box sx={{ width: "100%" }}>
                    <Stepper activeStep={data.activeStep} alternativeLabel>
                        {steps.map(label => (
                            <Step sx={{
                                "& .MuiStepLabel-root .Mui-completed": {
                                    color: "#3daf2c !important"
                                },
                                "& .MuiStepLabel-root .Mui-active": {
                                    color: "#3daf2c !important"
                                }
                            }} key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                </Box>

                <br/>



                { data.activeStep === 0 &&
                    <Box sx={{ width: "60%", marginLeft: "auto", marginRight: "auto"}}>
                    <br/>

                        {messageData.message.show && <>
                        <Alert severity={messageData.message.type} style={{width: "94%" }} onClose={() => dispatch(setMessage({
                            text: "",
                            type: "",
                            show: false
                        }))}
                        >{messageData.message.text} </Alert>
                            <br/>
                            <br/>
                        </> }

                    <TextField
                        inputProps={{
                            autoComplete: "off"
                        }}
                        fullWidth={true}
                        type="text"
                        value={firstName}
                        placeholder="First Name *"
                        onChange={(e) => { setFirstNameError(false); setFirstName(e.target.value); } }
                        error={firstNameError}
                        helperText={firstNameError && "This field is required."}
                    />
                    <br/><br/>
                    <TextField
                        inputProps={{
                            autoComplete: "off"
                        }}
                        fullWidth={true}
                        type="text"
                        value={middleName}
                        placeholder="Middle Name"
                        onChange={(e) => setMiddleName(e.target.value)} />
                    <br/>
                    <br/>
                    <TextField
                        inputProps={{
                            autoComplete: "off"
                        }}
                        fullWidth={true}
                        type="text"
                        value={lastName}
                        placeholder="Last Name *"
                        error={lastNameError}
                        helperText={lastNameError && "This field is required."}
                        onChange={(e) => { setLastNameError(false); setLastName(e.target.value); } }/>

                    <br/><br/>

                        <TextField
                            inputProps={{
                                autoComplete: "off"
                            }}
                            fullWidth={true}
                            type="text"
                            value={email}
                            placeholder="Email *"
                            onChange={(e) => { setEmailError(false) ; setEmail(e.target.value); } }
                            error={emailError}
                            helperText={emailError && "This field is required."}
                        />
                        <br/><br/>
                    <TextField
                        inputProps={{
                            autocomplete: "off",
                        }}
                        autoComplete="off"
                        fullWidth={true}
                        type="text"
                        value={phone}
                        placeholder="Mobile Number(+279999999999) *"
                        onChange={(e) => { setPhoneError(false) ; setPhone(e.target.value); } }
                        error={phoneError}
                        helperText={emailError && "This field is required."}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">+27</InputAdornment>,
                        }}
                    />
                        <br/><br/>
                    <TextField
                        inputProps={{
                            autocomplete: "new-password",
                        }}
                        autoComplete="new-password"
                                style={{marginRight: "15px"}}
                                fullWidth={true}
                                type="password"
                                value={password}
                                placeholder="Password *"
                                onChange={(e) => { setPasswordError(false); setPassword(e.target.value); } }
                                error={passwordError}
                                helperText={passwordError && "This field is required."}
                            />

                    <br/><br/>
                    <Button
                        style={{float: "right", backgroundColor: "#3daf2c", color: "white" , fontWeight: "bold"}}
                        variant="contained"
                        onClick={onNextButtonClick}> Next </Button>
                    <br/><br/>
                    {/*<Button style={{fontSize: "16px", color: "#3daf2c", fontWeight: "bold"}} onClick={() => navigate(0)}> Login </Button>*/}

                </Box> }

                { data.activeStep === 1 && <Box sx={{ width: "60%", marginLeft: "auto", marginRight: "auto"}}>
                    <br/>
                    {messageData.message.show && <>
                        <Alert severity={messageData.message.type} style={{width: "94%" }} onClose={() => dispatch(setMessage({
                            text: "",
                            type: "",
                            show: false
                        }))}
                        >{messageData.message.text} </Alert>
                        <br/>
                        <br/>
                    </> }
                    <p>Verification code has been sent to your email address.</p>
                    <TextField
                        inputProps={{
                            autoComplete: "off"
                        }}
                        fullWidth={true}
                        type="password"
                        value={code}
                        placeholder="Code *"
                        onChange={(e) => { setCodeError(false); setCode(e.target.value); } }
                        error={codeError}
                        helperText={codeError && "This field is required."}
                    />
                    <br/>
                    <Button variant="text"
                            style={{float: "left", color: "#3daf2c"}}
                            onClick={() => resendCode(data.userName)}>
                        Resend Code
                    </Button>
                    <br /><br />
                    <Button variant="contained" style={{ float: "right", backgroundColor: "#3daf2c", color: "white"}} onClick={() => onSubmitSignup(data.userName)}> Submit </Button>
                </Box> }
            </div>
            </div>
        </>
    );
};
export default PhysicianSignUp;