import {
    LOGIN_SUCCESS,
    IS_USER_AUTHENTICATED,
    SET_USER_DATA,
    SET_STEP,
    SET_USERNAME,
    CONFIRM_SIGN_UP,
    SET_USER_GROUP,
    SET_SIGNUP_STATUS, SET_CODE_DELIVERY_DETAILS,
    NEW_PASSWORD_REQUIRED, SET_DOCTOR_GRPS
} from "../actions/loginActions";
import awsConfig from '../../config/aws';
import { Auth } from 'aws-amplify';
import { setMessage } from "./messageActionCreator";
import { setLoader } from "./loaderActionCreator";
import storage from 'redux-persist/lib/storage'
import {BASE_URL, createUUID, getCognitoGroups, getHeader} from "../../utils";
import {validateConfirmPassword, validatePassword} from "../../utils/utlis";
import {persistor} from "../store";



Auth.configure(awsConfig);

export const loginSuccess = (data) => ({ type: LOGIN_SUCCESS, payload: data });

export const isUserAuthenticated = (data) => ({
    type: IS_USER_AUTHENTICATED,
    payload: data
});

export const setUserData = (data) => ({
    type: SET_USER_DATA,
    payload: data
});

export const setStep = (data) => ({
    type: SET_STEP,
    payload: data
});

export const confirmSignUpStatus = (data) => ({
    type: CONFIRM_SIGN_UP,
    payload: data
});

export const setUserName = (data) => ({
    type: SET_USERNAME,
    payload: data
});

export const setUserGroup = (data) => ({
    type: SET_USER_GROUP,
    payload: data
});

export const setDoctorAssociatedGrps = (data) => ({
    type: SET_DOCTOR_GRPS,
    payload: data
})

export const setConfirmPhysicianSignupStatus = (data) => ({type: SET_SIGNUP_STATUS, payload: data})
export const setCodeDeliveryDetails = (data) => ({
    type: SET_CODE_DELIVERY_DETAILS, payload: data
});

export const isPasswordChangeRequired = (data) => ({ type: NEW_PASSWORD_REQUIRED, payload: data });

export const signIn = (userName, password, page) => async (dispatch) => {
    dispatch(setLoader(true));
    Auth.signIn(userName, password).then(response => {
        if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
            dispatch(isPasswordChangeRequired(true));
            dispatch(setUserData(response));
        }
        else {
            dispatch(isPasswordChangeRequired(false));
        }
        //dispatch(loginSuccess(true));
        // getCognitoGroups().then(res => {
        //
        //     dispatch(setUserGroup(res))
        // });
        dispatch(isUserLoggedIn(page));

        dispatch(setLoader(false));

    }).catch(err => {
        dispatch(setMessage({ text: err.message, type: "error", show: true }));
        dispatch(setLoader(false));
        dispatch(isUserAuthenticated(false));
    });

};

export const signOut = () => async (dispatch) => {
    await Auth.signOut();
    // await persistStore(store).purge();

    // await persistor.purge();
    persistor.pause();
    persistor.flush().then(() => {
        return persistor.purge();
    });
    dispatch(loginSuccess(false));
    dispatch(isUserAuthenticated(false));
    storage.removeItem('persist:root')
};

export const isUserLoggedIn = (page) => async (dispatch) => {
    dispatch(setLoader(true));
    Auth.currentAuthenticatedUser().then(response => {
        dispatch(isUserAuthenticated(true));
        if(page === "signup") {
            window.location.reload();
        }
        // dispatch(getDoctorAssociatedGroups(true));
        dispatch(setUserData(response));
        dispatch(setLoader(false));
    }).catch(err => {
        dispatch(setLoader(false));
        dispatch(isUserAuthenticated(false));
        //dispatch(loginSuccess(false));
    });
};

export const getDoctorAssociatedGroups = () => async (dispatch) => {
try{
    dispatch(setLoader(true));
    const header = await getHeader();

    const requestOptions = {
        method: 'GET',
        headers: header,
        redirect: 'follow'
    }
    const response = await fetch(BASE_URL + "/TeleMedicineDrOwl/getDoctorAssociatedGroups", requestOptions);
    const res = await response.json();
    if(res.status) {
        dispatch(setDoctorAssociatedGrps(res.groups));
    }
}catch (e) {
    dispatch(setLoader(false));
}
}

export const signUpUser = (password, email, phoneNumber, firstName, lastName) => async (dispatch) => {
    dispatch(setLoader(true));
    const userName = createUUID();

    Auth.signUp({
        "username": userName,
        "password": password,
        "attributes": {
            "email": email,
            "phone_number": phoneNumber,
            "name": firstName,
            "family_name": lastName
        }
    })
        .then((response) => {
            dispatch(setUserName(userName));
            dispatch(setStep(1));
            dispatch(setLoader(false));
        })
        .catch((error) => {
            dispatch(setMessage({ text: error.message ?? "An error occured signing you up.", type: "error", show: true }));
            dispatch(setLoader(false));
        });
};

export const confirmAndSignUp = (userName, confirmationCode,password, page) => async (dispatch) => {
    dispatch(setLoader(true));
     Auth.confirmSignUp(userName, confirmationCode)
        .then(() => {
            dispatch(confirmSignUpStatus("success"));
            dispatch(setMessage({ text: "You've successfully sign up for DrOwl", type: "success", show: true }));
            dispatch(signIn(userName,password, page));
            dispatch(setLoader(false));
        })
        .catch(err => {
            dispatch(setMessage({ text: err.message, type: "error", show: true }));
            // dispatch(setConfirmSignupStatus(data));
            dispatch(confirmSignUpStatus("error"));
            dispatch(setLoader(false));
        });
};

export const resendSignUpConfirmationCode = (username) => async (dispatch) => {
    dispatch(setLoader(true));
     Auth.resendSignUp(username)
        .then((response) => {
            // dispatch(setCodeDeliveryDetails(response.CodeDeliveryDetails));
            dispatch(setMessage({ text: "Resent confirmation code", type: "success", show: true }));
            dispatch(setLoader(false));
        })
        .catch(err => {
            dispatch(setMessage({ text: err.message, type: "error", show: true }));
            dispatch(setLoader(false));
        });
};

export const forgotPassword = (email) => async (dispatch) => {
    dispatch(setLoader(true));
    await Auth.forgotPassword(email).then(response => {
        dispatch(setLoader(false));
        if(response){
            //dispatch(setEmail(email));
            dispatch(setCodeDeliveryDetails(response.CodeDeliveryDetails));
        }
        dispatch(setLoader(false));
    }).catch(err => {
        const message = err.message ?? "An error occurred. Please check the email you have provided";
        dispatch(setMessage({text: message, type: "error", show: true}));
        dispatch(setLoader(false));
    });
};

export const forgotPasswordSubmit = (email, code, password, confirmPassword) => async (dispatch) => {
    dispatch(setLoader(true));
    const result = validateConfirmPassword(password, confirmPassword);
    if (result === true){
        dispatch(setLoader(true));
        Auth.forgotPasswordSubmit(email, code, password).then(response => {
            if(response === "SUCCESS"){
                dispatch(setMessage({text: "Password updated successfully", type: "success", show: true}));
            }
            else {
                dispatch(setMessage({text: "Something went wrong. Please try again later.",
                    type: "error", show: true}));
            }
            dispatch(setLoader(false));
        }).catch(err => {
            const message = err.message ?? "Verify code provided";
            dispatch(setMessage({text: message, type: "error", show: true}));
            dispatch(setLoader(false));
        });
    } else {
        dispatch(setMessage({text: result, type: "error", show: true}));
    }

};

export const completeNewPassword = (user, password, confirmPassword) => async (dispatch) => {
    const result = validateConfirmPassword(password, confirmPassword);
    if(result === true) {
        dispatch(setLoader(true));
        Auth.completeNewPassword(user, password).then(response => {
            if(response && response.challengeParam
                && Object.keys(response.challengeParam.userAttributes).length !== 0) {
                // dispatch(setMessage({text: "Password updated successfully", type: "success", show: true}));
                dispatch(isPasswordChangeRequired(false));
            } else {
                dispatch(setMessage({text: "Something went wrong. Please try again later.",
                    type: "error", show: true}));
            }
            dispatch(setLoader(false));
            dispatch(isPasswordChangeRequired(false));
        }).catch(err => {
            const message = `Error : ${err.code}  ${err.message}`;
            dispatch(setMessage({text: message, type: "error", show: true}));
            dispatch(setLoader(false));
        });
    } else {
        dispatch(setMessage({text: result, type: "error", show: true}));
    }
};

export const changePassword = (user, prevPassword, password, confirmPass) => async (dispatch) => {
    const result = validateConfirmPassword(password, confirmPass);
    if(result === true) {
        dispatch(setLoader(true));
        Auth.changePassword(user, prevPassword, password).then(response => {
            if(response === "SUCCESS") {
                dispatch(setMessage({text: "Password updated successfully", type: "success", show: true}));
            } else {
                dispatch(setMessage({text: "Something went wrong. Please try again later.",
                    type: "error", show: true}));
            }
            dispatch(setLoader(false));
        }).catch(err => {
            const message = `Error : ${err.code}  ${err.message}`;
            dispatch(setMessage({text: message, type: "error", show: true}));
            dispatch(setLoader(false));
        });
    } else {
         dispatch(setMessage({text: result, type: "error", show: true}));
    }
};
