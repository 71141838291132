import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {Checkbox, FormControlLabel} from "@mui/material";

const PlanTableMobile = ({columnHeaders, assessmentConditions, selectedConditions, handleCheckboxCondSelection})=> (
    <Table sx={{border: "1px solid grey", borderBottom: "1px solid grey !important", borderCollapse: "revert"}}>

        {assessmentConditions && assessmentConditions.map((condition, index) => {
            return (<TableBody key={index} >
                <TableRow>
                    <TableCell variant='head'> {columnHeaders[0]} </TableCell>
                    <TableCell>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={selectedConditions[index]}
                                    onChange={(e) => handleCheckboxCondSelection(e,index)}
                                />
                            }
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell variant='head'> {columnHeaders[1]} </TableCell>
                    <TableCell>
                        {condition.condition}
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell variant='head'>{columnHeaders[2]}</TableCell>
                    <TableCell>
                        {condition.notes}
                    </TableCell>
                </TableRow>

            </TableBody>)
        })}
    </Table> )

export default PlanTableMobile;