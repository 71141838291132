
import  React, {useEffect, useState, useRef} from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import {
    Button,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Link,
    TextareaAutosize,
    Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import NoteIcon from "@mui/icons-material/Note";
import {
    getNotes,
    getPrescription, getTeleMedDoc,
    getTicketInfo,
    setNotes, setPrescription, viewTelemedDoc,
    addNewDocNote, getDocNote, setDoctorAddedNotesStatus
} from "../../ducks/actionCreators/patientOverviewActionCreator";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, Navigate} from "react-router-dom";
import "./CustomTimeline.css";


const CustomTimelineItem = ({ leftContent, rightContent }) => {
    return (
        <TimelineItem>
            <TimelineOppositeContent color="textSecondary">
                {leftContent}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>

                {rightContent}
            </TimelineContent>
        </TimelineItem>
    );
};



const LeftComponent = ({ticket}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleViewChartClick = async () => {
        await dispatch(getTicketInfo(ticket._id));
        navigate(`/viewChat/${ticket._id}`)
    }


    const timestamp = ticket.timestamp; // Replace with your timestamp

    const date = new Date(Number(timestamp));
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const dateString = date.toLocaleDateString('en-US', options);
    const timeString = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });

    return (
        <>
            <p className="text-color" style={{marginTop: "-4px", marginLeft: "-4px"}}>{dateString}</p>
            <p className="text-color">{timeString}</p>
            {ticket.type === "ticket" && <p className="text-color"><span>Case</span>: {ticket.uid}</p>}
            {ticket.type === "ticket" && <Link className="text-color" style={{fontWeight: "450", fontSize: "1rem"}} component="button"  variant="body2" onClick={handleViewChartClick}> View Chat</Link> }
            <br/>
            <br/>
            <br/>
        </>
    )
}

const RightComponent = ({ticket,showAddNote, isMobile}) => {

    const dispatch = useDispatch();
    const [showMore, setShowMore] = useState(false);
    const handleToggle = () => {
        if(showMore) {
            dispatch(getDocNote(ticket.owner, "100", ""))
        }
        setShowMore(!showMore);
    };

    const viewNote = (noteId) => {
        try {
            dispatch(getNotes(ticket._id, noteId));
        } catch (e) {
            console.error(e);
        }
    }

    const handlePrescriptionLinkClick = (prescriptionId) => {
        try {
            dispatch(getPrescription(ticket._id, prescriptionId));
        } catch (e) {
            console.error(e);
        }
    };

    const viewDoc = (documentId) => {
        try {
        dispatch(getTeleMedDoc(ticket._id, documentId));
        } catch (e) {
            console.error(e);
        }
    }

    const modifiedConditions = ticket && ticket.conditionsEntered.map(({condition}) => {
        const parts = condition.split(' '); // Split string into array of parts
        const code = parts[0]; // First part is the code
        const description = parts.slice(1).join(' '); // Combine remaining parts as the description
        return { code, description }; // Return object with code and description
    });

    const modifiedMedications = ticket && ticket.medicationsEntered.map((med) => {
        return `${med.medication} , ${med.dosage} - ${med.instructions}`
    });

    // const [showMore1, setShowMore1] = useState(false);
    const linesToShow = showMore ? ticket.chiefComplaint.split('\n') : ticket.chiefComplaint.split('\n').slice(0, 3);

    const handleShowMoreClick = () => {
        setShowMore(!showMore);
    };

    return (
        <>
            <Typography variant="h6" style={{marginTop: "-12px", marginBottom: "15px"}}>{ticket.assignee}</Typography>

            {ticket?.chiefComplaint && <div style={{display: "flex", marginBottom: "10px"}}>
                <div style={{flex: "1"}}><b>Chief Complaint:</b> </div>
                <div style={{flex: isMobile? 1: 8}}>{linesToShow.map((line, index) => (
                <span key={index}>{line}</span>
                ))}</div>
            </div> }

            {/*{ticket?.chiefComplaint && <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>*/}
            {/*    <Button onClick={handleShowMoreClick} className="button-container">*/}
            {/*        <a className="underline button-text">{showMore1 ? 'less' : 'more'}</a>*/}
            {/*    </Button>*/}
            {/*</div> }*/}

            {showMore ? <>
                {ticket?.additionalInformation && <p><b>Additional Information</b> {ticket.additionalInformation} </p> }

                {ticket?.symptomsGroup && <div >
                    {ticket.symptomsGroup.map((symptom) =>  (
                        <div style={{display: "flex"}}>
                        <div style={{flex: 1}}><b>{symptom.category}: </b></div>
                        <div style={{flex: isMobile? "1": "8", marginTop: "-15px"}}>{symptom.symptoms.map(x => <p>{x}</p>)} </div>
                        </div>))} </div>}


                {ticket?.conditionsEntered && <div> {modifiedConditions && modifiedConditions.map((cond) =>  <div style={{display: "flex"}}>
                    <div style={{flex: 1}}><b>{cond.code} </b> </div>
                    <div style={{flex: isMobile? "1": "8"}}> {cond.description}</div>
                </div>)
                } </div>}

                <div style={{display: "flex"}}>
                    <div style={{flex: 1}}>{ticket?.prescriptions && ticket.prescriptions.length >0 &&
                    <div style={{ display: 'flex'}}>{ticket.prescriptions.map((x) => (
                    <div key={x.prescription_id}>
                        { !x.deleted ?
                            <p key={x.prescription_id} onClick={() => handlePrescriptionLinkClick(x.prescription_id)}>
                                <Link> <b>{x.prescription_id}</b> </Link>
                            </p> : null}
                    </div>
                    )) }
                    </div>
                    }</div> <div style={{flex: isMobile? "1": "8"}}>{ticket?.medicationsEntered && <div> {modifiedMedications && modifiedMedications.map((med) =>  <p>{med}</p>)
                } </div>}</div> </div>

                {ticket?.notes && ticket.notes.length >0 &&
                <div style={{ display: 'flex'}}>
                    <div style={{ flex: 1, marginTop: "15px", fontWeight: "600" }}>Sick Notes : </div>
                    <div style={{ flex: isMobile? "1": "8" }}>{ticket.notes.map((x) => (
                        <>
                            { !x.deleted ?
                                <p><Link key={x.note_id} onClick={() => viewNote(x.note_id)}>
                                    {x.title}
                                </Link></p>
                                : null }
                        </>
                    ))}
                    </div>
                </div>}

                {ticket?.documents && ticket.documents.length >0 &&
                    <div style={{ display: 'flex'}}>
                        <div style={{ flex: 1, marginTop: "15px", fontWeight: "600" }}>Documents : </div>
                        <div style={{ flex: isMobile?"1": "8" }}> {ticket.documents.map((x, index) => (
                            <div key={x.document_id}>
                                { !x.deleted ?
                                    <p onClick={() => viewDoc(x.documentId)}>
                                        {x.documentType} : <Link> {x.title} </Link>
                                    </p> : null}
                            </div>))}
                        </div>
                </div>}

                {ticket?.planOfTreatment && <div style={{display: "flex"}}> <div style={{flex: "1"}}><b>Plan of Treatment : </b></div> <div style={{flex: isMobile? "1": "8"}}>{ticket.planOfTreatment}</div></div>} </> : null }
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Button onClick={handleToggle} className="button-container">
                    <a className="underline button-text">{showMore ? 'less' : 'more'}</a>
                </Button>
            </div>

        </>
    )
}

const CustomTimeline = ({ items }) => {
    const [showAddNote, setShowAddNote] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [doctorNote, setDoctorNote] = useState("");

    const handleChange = () => {
        setDoctorNote(event.target.value);
    }

    const onAddNoteClick = () => {
        setShowAddNote(!showAddNote);
    }
    const dispatch = useDispatch();


    const {userTickets} = useSelector(state => state.cases);
    const {noteResp, prescriptionResp, teleMedDoc, doctorAddedNotes, ticket, docAddedNoteStatus } = useSelector(state => state.patientOverview);

    const newUserTickets = userTickets.map(obj => ({ ...obj, type: "ticket" }));

    const  newDocAddNotes = doctorAddedNotes.map(obj => ({ ...obj, type: "note" }))

    const newDataList = [...newUserTickets, ...newDocAddNotes];

    const sorted_list = newDataList.sort((a, b) => b.timestamp - a.timestamp);

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(window.innerWidth < 768);
    }, [window.innerWidth]);

    useEffect(() => {
        if(noteResp && noteResp.status) {
            window.open(noteResp.note, '_blank');
            dispatch(setNotes(null));
        }

    }, [noteResp && noteResp.status])

    useEffect(() => {
        if(docAddedNoteStatus) {
           setShowAddNote(false);
        }
        dispatch(setDoctorAddedNotesStatus(null));

    }, [docAddedNoteStatus])

    useEffect(() => {
        if (prescriptionResp && prescriptionResp.status) {
            window.open(prescriptionResp.prescription, '_blank');
            dispatch(setPrescription(null));
        }

    }, [prescriptionResp && prescriptionResp.status])

    useEffect(() => {
        if (teleMedDoc && teleMedDoc.status) {
            setTimeout(() => {
                window.open(teleMedDoc.patientDocument, '_blank');
            })
            dispatch(viewTelemedDoc(null));
        }

    }, [teleMedDoc && teleMedDoc.status])

    const handleItemClick = (index) => {
        if (selectedItem === index) {
            setSelectedItem(null);
        } else {
            setSelectedItem(index);
        }
    };

    const handleAddNote = () => {
        dispatch(addNewDocNote(ticket.owner,doctorNote,false));
    }

    return (
        <>
            <div style={{display: "flex", marginTop: "20px"}}>
                <div style={{marginLeft: "-35px", flex: 1}}><Button onClick={onAddNoteClick}> <span className="underline text-color">+ Add Note </span></Button></div>
           <div style={{marginLeft: "-30px", flex: 4}}>{showAddNote &&
               <>{/*<Typography variant="h6" style={{marginTop: "-12px", marginBottom: "22px"}}>{ticket.assignee}</Typography>*/}
                   <TextareaAutosize
                       className="textareaAutosize"
                       rowsMin={3}
                       placeholder="Enter doctor notes here"
                       value={doctorNote}
                       style={{width: "87%"}}
                       onBlur={handleAddNote}
                       onChange={handleChange}
                   />
                   <br/>
                   <br/>
                   <br/>
               </>
           }</div> </div>
        <Timeline
            id="timeLine"
            align="alternate"
            sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.1,
                },
            }}
        >
            {sorted_list.map((item, index) => {
                if (item.type === "ticket") {
                    return <CustomTimelineItem
                        ticket={item}
                        onAddNoteClick={() => handleItemClick(index)}
                        showAddNote={showAddNote}
                        key={index}
                        position={index % 2 === 0 ? 'left' : 'right'}
                        leftContent={<LeftComponent ticket={item}/>}
                        rightContent={<RightComponent ticket={item} showAddNote={selectedItem === index}
                                                      isMobile={isMobile} selectedItem={selectedItem}/>}
                    />
                } else {
                    return (
                        // <div id="noteTimeline">
                            <TimelineItem>
                                <TimelineOppositeContent color="textSecondary">
                                    <LeftComponent ticket={item}/>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    <div style={{marginTop: "-10px"}}><b>Note:</b> {item.note}</div>
                                </TimelineContent>
                            </TimelineItem>
                        // </div>
                        )
                }
            })}
        </Timeline>
            </>
    );
};

export default CustomTimeline;
