import React , {useState, useEffect } from "react";
import {TextField, Typography, Link, Button, DialogActions, DialogContentText, DialogTitle, Dialog, DialogContent} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {shareEmail} from "../../ducks/actionCreators/shareActionCreator";
import {useParams} from "react-router-dom";
import {setMessage} from "../../ducks/actionCreators/messageActionCreator";
import {useLocation} from "react-router";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

const ShareDocument = () => {

	const [email, setEmail] = useState("");

	const dispatch = useDispatch();

	const urlParams = useLocation().search;

	const sharedData = useSelector(state => state.message);

	const {message} = sharedData;

	const {type} = message;

	const id = new URLSearchParams(urlParams).get("id");

	const sendEmail = () => {
		dispatch(shareEmail(id, email));
	};

	useEffect(() => {
		dispatch(setMessage({show: false}));
	},[]);

	return(
		<div className="shareDocument" >
			<br/><br/>

			{message && message.show && <Dialog
				open={message.show}
				onClose={() => dispatch(setMessage({show: false}))}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<p style={{color: type === "error" ? "red" : "green"}}>{(message.type).toUpperCase()} </p>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{message.text}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => dispatch(setMessage({show: false}))}>Close</Button>
				</DialogActions>
			</Dialog> }
			<h3 style={{marginBottom: "20px", fontWeight: "bold"}}>Email a shared document from Eagle Intelligent Health</h3>

			<Typography style={{marginBottom: "10px"}}>
				A user has shared a document with you from Eagle Intelligent Health. To receive the document, please enter your email address:
			</Typography>
			<TextField variant="outlined"
					   fullWidth={true}
					   placeholder="Enter your email"
					   value={email} onChange={(e)=> setEmail(e.target.value)}/>
			<div style={{marginBottom: "10px"}}></div>
			<Button variant="contained" onClick={sendEmail}>Send </Button>

			<div style={{marginBottom: "30px"}}></div>
			<Typography>Eagle Intelligent Health is a secure telemedicine platform that makes it simple for users to start telemedicine calls with skilled physicians in South Africa. <Link href="https://eih.onelink.me/LyE2/doctors">Download Eagle Intelligent Health
			</Link> today  to start a telemedicine visit for you or a loved one from anywhere in South Africa and
				receive digital prescriptions and digital work sick notes.
			</Typography>
			<div style={{marginBottom: "30px"}}></div>
			<Typography>
				<b>Notice:</b> The document shared with you by a user may contain information that is confidential.
				It is not intended for transmission to, or receipt by, anyone else. It should not be copied or forwarded to any unauthorized persons.
			</Typography>
		</div>
	);

};



export default ShareDocument;