import React from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";


const SuccessPasswordChange = () => {

    const navigate =  useNavigate();

    return <div className="pageWindow" >
{/*<br/><br/><br/><br/><br/><br/>*/}
        <CheckCircleIcon fontSize="large" sx={{color: "green", marginTop: "150px", width: "70px", height: "70px"}}/>
        <h1><b>Success!</b></h1>
        <p style={{fontSize: "18px"}}>Your password has been changed.</p>
        <br/>
        <Button variant="contained" onClick={() => navigate("/login")}>Done</Button>
    </div>
}

export default SuccessPasswordChange;