import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import React from "react";
import {Button, Checkbox, FormControlLabel} from "@mui/material";

const AllergiesTableWeb = ({columnHeaders, rows, selectedAllergies, handleCheckboxSelection}) => {

    return ( <Table id="medicationTable" sx={{ width : "20 ch !important", border: "1px solid grey !important", borderBottom: "1px solid grey !important", borderCollapse: "revert"}}>
        <TableHead>
            <TableRow>
                {columnHeaders && columnHeaders.map((col, index) => {
                    return (
                        <TableCell key={index} style={{ fontWeight: "bolder" }}>
                            {col}
                        </TableCell>)
                })}
            </TableRow>
        </TableHead>

        <TableBody >
            { rows && rows.length> 0 && rows.map((allergy, index) => {
                return (
                    <TableRow key={index}>
                        <TableCell >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedAllergies[index] ===  true}
                                        onChange={(e) => handleCheckboxSelection(e,index)}
                                    />
                                }
                            />

                        </TableCell>
                        <TableCell >{allergy.allergy}</TableCell>
                        <TableCell >{allergy.severity}</TableCell>
                    </TableRow>
                )})
            }
        </TableBody>
    </Table> ) }

export default AllergiesTableWeb;
