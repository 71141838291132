import { Auth, Storage } from 'aws-amplify';
import Amplify from 'aws-amplify';
import awsS3Config from '../../config/awsS3.js';
import {
  SET_CURR_IMG_URL,
  SET_IMG_SUB,
  SET_PROFILE_INFO,
  SET_API_RESP_MSG,
  SET_RESP_MSG_CLS,
  IS_PROFILE_VALID,
  SET_IS_LOADING,
  SET_ACC_PRF_IMG_SRC,
  SET_PRF_IMG,
  SET_IMG_FILE,
  SET_IMG_FILE_NAME,
  SET_NEW_IMG,
  SET_PAGE_LOADING,
  SET_DOCUMENTS,
  SET_LAT,
  SET_LNG,
  SET_RECOMMENDED_ADDRESS, SET_ADDRESS_COMPONENTS,
    SET_DIALOG
} from "../actions/settingsActions";
import {BASE_URL, getCurrentUserToken} from "../../utils";
import moment from "moment";

Amplify.configure(awsS3Config);

export const setCurrImgUrl = (data) => ({ type: SET_CURR_IMG_URL, payload: data });
export const setImgSub = (data) => ({ type: SET_IMG_SUB, payload: data });
export const setProfileInfo = (data) => ({ type: SET_PROFILE_INFO, payload: data });
export const setApiRespMsg = (data) => ({ type: SET_API_RESP_MSG, payload: data });
export const setRespMsgCls = (data) => ({ type: SET_RESP_MSG_CLS, payload: data });
export const setIsLoading = (data) => ({ type: SET_IS_LOADING, payload: data });
export const setPageLoading = (data) => ({ type: SET_PAGE_LOADING, payload: data });
export const setAccsetPrfImgSec = (data) => ({ type: SET_ACC_PRF_IMG_SRC, payload: data });
export const setProfImg = (data) => ({ type: SET_PRF_IMG, payload: data });
export const setImgFile = (data) => ({ type: SET_IMG_FILE, payload: data });
export const setImgFileName = (data) => ({ type: SET_IMG_FILE_NAME, payload: data });
export const setNeewImgS3loc = (data) => ({ type: SET_NEW_IMG, payload: data });
export const setDocuments = (data) => ({ type: SET_DOCUMENTS, payload: data});
export const isProfileDataValid = (data) => ({ type: IS_PROFILE_VALID, payload: data})
export const setLat = (data) => ({ type: SET_LAT, payload: data})
export const setLng = (data) => ({ type: SET_LNG, payload: data})
export const setRecommenedAddress = (data) => ({ type: SET_RECOMMENDED_ADDRESS, payload: data})
export const setAddressComponents = (data) => ({ type: SET_ADDRESS_COMPONENTS, payload: data})
export const setFormattedAddressDialogue = (data) => ({ type: SET_DIALOG, payload:data})

const formatDate = (argdate) =>
  {
    let month = '' + (argdate.getMonth() + 1);
    let day = '' + argdate.getDate();
    let year = argdate.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
export const getProfileImg = () => async (dispatch) =>
{
  dispatch(setPageLoading(true));
  try {
  const user = await Auth.currentAuthenticatedUser();
  if(user.signInUserSession.idToken.payload["custom:image_url"]!==undefined && user.signInUserSession.idToken.payload["custom:image_url"]!=="")
  {
    dispatch(setCurrImgUrl(user.signInUserSession.idToken.payload["custom:image_url"]));
  }
  dispatch(setImgSub(user.attributes.sub));
  const ApiUrl=BASE_URL+"/UserInfo/getDoctorInfoSettings";
  fetch(ApiUrl,{
    "method":"GET",
    "headers": {
      "content-type": "application/json",
      "accept": "application/json",
      "token": user.signInUserSession.accessToken.jwtToken
    }
  }).then(response => response.json())
    .then(response =>
    {
      let doctrInfo=response.doctor_info;
      let modDob=doctrInfo.dob;
      modDob=formatDate(new Date(modDob));
      dispatch(setProfileInfo(response.doctor_info));
      dispatch(setPageLoading(false));
      dispatch(setDocuments(doctrInfo.my_documents))
    })
  .catch(err => {
    dispatch(setApiRespMsg({show:true, text:err, type: "error"}));
    dispatch(setRespMsgCls("error"));
    dispatch(setPageLoading(false));
  });
} catch (e){
  dispatch(setPageLoading(false));
  }
}

export const updateProfImge = () => async (dispatch) => {
    const user = await Auth.currentAuthenticatedUser();
    if (user.signInUserSession.idToken.payload["custom:image_url"] !== undefined) {
      dispatch(setProfImg(user.signInUserSession.idToken.payload["custom:image_url"]));
    }
}

export const refreshSessionCognforImgUlpd = () => async (dispatch) =>
{
  dispatch(setIsLoading(true));
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
      // const { idToken, refreshToken, accessToken } = session.idToken;
      dispatch(updateProfImge());
      dispatch(setIsLoading(false));
    });
  } catch (e) {
    dispatch(setAccsetPrfImgSec(e));
    dispatch(setIsLoading(false));
  }
}

export const updtCurrImg = () => async (dispatch) =>
  {
    const user = await Auth.currentAuthenticatedUser();
    if(user.signInUserSession.idToken.payload["custom:image_url"]!==undefined && user.signInUserSession.idToken.payload["custom:image_url"]!=="")
    {
      dispatch(setCurrImgUrl(user.signInUserSession.idToken.payload["custom:image_url"]));
    }
  }

export const changProfPicApiCall= (imgS3loc) => async (dispatch) =>
  {
    dispatch(setIsLoading(true));
    dispatch(setAccsetPrfImgSec);
    const user = await Auth.currentAuthenticatedUser();
    const ApiUrl= BASE_URL+"/UserAction/changeProfilePictureDoctorS3?s3location="+imgS3loc;
    fetch(ApiUrl,{
      "method":"POST",
      "headers": {
        "content-type": "application/json",
        "accept": "application/json",
        "token": user.signInUserSession.idToken.jwtToken
      }
    }).then(response => response.json())
      .then(response =>
      {
        if(response.status)
        {
          dispatch(refreshSessionCognforImgUlpd());
          dispatch(setIsLoading(false));
        }
        else
        {
          dispatch(setAccsetPrfImgSec("For uploading image "+response.message));
          updtCurrImg();
          setIsLoading(false);
        }

      })
    .catch(err => {
      dispatch(setApiRespMsg({show:true, text:err, type: "error"}));
      dispatch(setRespMsgCls("error"));
      dispatch(updtCurrImg());
      dispatch(setIsLoading(false));
    });
  }

  export const addDoctorSignature = (signature, setShowSignaturePad) => async(dispatch) => {
    dispatch(setIsLoading(true));
    const token = await getCurrentUserToken();
    const ApiUrl= BASE_URL+"/DoctorAction/addDoctorSignature";
    fetch(ApiUrl,{
      "method":"POST",
      "headers": {
        "content-type": "application/json"
      },
      "body": JSON.stringify({
        "token": token,
        "imageContent" : signature
      })
    }).then(response => response.json())
        .then(response => {
              if (response.status) {
                dispatch(setIsLoading(false));
                setShowSignaturePad(false);
              }
            }
        )

  }

  export const validateAddress = (add1, add2, city, state, zip) => async(dispatch) =>{
  const address = add1 + "," + add2 + "," + city + "," + state + "," + zip;
    try {
      const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=AIzaSyAHuNjzOjJCJNd0THQ0eDDoBsT-XAvmrFg`
      );
      const res = await response.json();
      if (res.status === "OK") {

          const { lat, lng } = res.results[0].geometry.location;
          dispatch(setLat(lat));
          dispatch(setLng(lng));
          const formattedAddress = res.results[0].formatted_address;
        const formattedAddressWithoutCountry = formattedAddress
            .split(", ")
            .slice(0, -1)
            .join(", ");
          dispatch(setRecommenedAddress(formattedAddressWithoutCountry));
          dispatch(setAddressComponents(res.results[0].address_components));
        }
       else {
        dispatch(setIsLoading(false));
        dispatch(setApiRespMsg({text:"Unable to find the address. Please try again.", show: true, type: "error"}))
      }
    } catch (error) {
      dispatch(setIsLoading(false));
      dispatch(setApiRespMsg({text:"Unable to find the address. Please try again.", show: true, type: "error"}))
      console.error("Error fetching geocoding data:", error);
    }
  };

export const saveSettingsInfo = (profileData) => async(dispatch, getState) => {


  const token = await getCurrentUserToken();
  let ApiUrl = BASE_URL + "/UserAction/doctorProfileSettings";

  const profData = {...profileData, dob: moment(new Date(profileData.dob)).format("YYYY-MM-DD")}
  // delete profData.provider_identifier_number;
  fetch(ApiUrl, {
    "method": "POST",
    "headers": {
      "content-type": "application/json"
    },
    "body": JSON.stringify({
      token: token,
      name: profData.name,
      middle_name: profData.middle_name,
      family_name: profData.family_name,
      suffix: profData.suffix,
      dob: profData.dob,
      gender: profData.gender,
      bio: profData.bio,
      bussiness_phone: profData.bussiness_phone,
      speciality: profData.speciality,
      medical_school: profData.medical_school,
      physician_number: profData.physician_number,
      south_africa_id: profData.south_africa_id,
      practice_name: profData.practice_name,
      provider_identifier_number: profData.provider_identifier_number,
      bussiness_address1: profData.bussiness_address1,
      bussiness_address2: profData.bussiness_address2,
      bussiness_city: profData.bussiness_city,
      bussiness_state: profData.bussiness_state,
      bussiness_zipcode: profData.bussiness_zipcode,
      residence_address1: profData.residence_address1,
      residence_address2: profData.residence_address2,
      residence_city: profData.residence_city,
      residence_state: profData.residence_state,
      residence_zipcode: profData.residence_zipcode,
      languages: profData.languages,
      bussiness_latitude: getState().settings.bussiness_latitude,
      bussiness_longitude: getState().settings.bussiness_longitude

    })
  }).then(response => response.json())
      .then(response => {
        if (response.status) {
          dispatch(setApiRespMsg({show:true, text:response.message, type: "success"}));
          dispatch(setRespMsgCls("success"));
          dispatch(setIsLoading(false));
          dispatch(isProfileDataValid(true));
          dispatch(getProfileImg());
        } else {
          dispatch(setApiRespMsg({show:true, text:response.message, type: "error"}));
          dispatch(setRespMsgCls("error"));
          dispatch(setIsLoading(false));
          dispatch(isProfileDataValid(false));
        }
      })
      .catch(err => {
        dispatch(setApiRespMsg({show:true, text:err, type: "error"}));
        dispatch(setRespMsgCls("error"));
        dispatch(setIsLoading(false));

      });
}