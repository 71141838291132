import React from "react";
import "../Stats.css";
import DoctorsList from "./DoctorsList";

const AdminStats = () => {
    return (
        <div className="textBox pageWindow" id="statsWindow">
            <DoctorsList />
        </div>)
}

export default AdminStats;