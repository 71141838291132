import React, {useState, useEffect} from "react";
import {Button, Card, CardContent, Grid, TextField, Typography} from "@mui/material";
import "../Stats.css";
import {getDoctorStats} from "../../../ducks/actionCreators/docStatsActionCreator";
import {useDispatch, useSelector} from "react-redux";
import {Charts} from "./Charts";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DoctorInfo from "./DoctorInfo"
import Table from "../../Table/index";
import 'bootstrap-daterangepicker/daterangepicker.css';
import { useNavigate } from "react-router-dom";
import {getCurrentUserId, getUserSubId} from "../../../utils";

const DEFAULT_DATE_RANGE = [moment().subtract(29, "days").toDate(), moment().toDate()];

const DoctorReports = () => {

    const [dateRange, setDateRange] = useState({
        from_date: moment(DEFAULT_DATE_RANGE[0]).format("YYYY-MM-DD"),
        to_date: moment(DEFAULT_DATE_RANGE[1]).format("YYYY-MM-DD"),
        label: moment(DEFAULT_DATE_RANGE[0]).format("YYYY-MM-DD") + " to " + moment(DEFAULT_DATE_RANGE[1]).format("YYYY-MM-DD")
    });

    const [rows, setRows] = useState([]);

    const dispatch = useDispatch()

    const statsData = useSelector(state => state.statsReducer);

    const navigate = useNavigate();

    const handleDateRangeChange = async (start, end) => {
        setDateRange({
            from_date: start.format("YYYY-MM-DD"),
            to_date: end.format("YYYY-MM-DD"),
            label: start.format("YYYY-MM-DD") + " to " + end.format("YYYY-MM-DD")
        });
        const params = {
            from_date: start.format("YYYY-MM-DD"),
            to_date: end.format("YYYY-MM-DD"),
            assignee_id: await getCurrentUserId()
        };
        dispatch(getDoctorStats(params));
    };

    const {stats} = statsData;

    console.log(stats, "stats");
    const {tickets} = stats;


    useEffect( () => {

        const asyncFunc = async() => {

            const params = {
                from_date: moment(DEFAULT_DATE_RANGE[0]).format("YYYY-MM-DD"),
                to_date: moment(DEFAULT_DATE_RANGE[1]).format("YYYY-MM-DD"),
                assignee_id:await getCurrentUserId()
            };
            dispatch(getDoctorStats(params));
        }
        asyncFunc();

    },[]);

    useEffect( () => {
        const compareFn = (i, j) => (i.date - j.date)
        if(tickets && tickets.length>0){
            tickets.sort(compareFn).reverse();
            setRows(tickets);
        } else {
            setRows(tickets);
        }

    }, [tickets]);

    const columnHeaders = ["Patient", "Case Id", "Date", "Time", "Amount"];

    const rowHeaders = ["name", "case_id", "date", "time", "amount"];

    return (
        <div
            className="textBox pageWindow" id="statsWindow"
        >
            <DoctorInfo type="DOCTOR" title="OVERVIEW"/>
            <br/>
            <div>
                <div className="filterBox">
                    <DateRangePicker
                        initialSettings={{
                            startDate: moment(dateRange.from_date).toDate(),
                            endDate: moment(dateRange.to_date).toDate(),
                            ranges: {
                                "Today": [moment().toDate(), moment().toDate()],
                                "Yesterday": [
                                    moment().subtract(1, "days").toDate(),
                                    moment().subtract(1, "days").toDate()
                                ],
                                "Last 7 Days": [
                                    moment().subtract(6, "days").toDate(),
                                    moment().toDate()
                                ],
                                "Last 30 Days": [
                                    moment().subtract(29, "days").toDate(),
                                    moment().toDate()
                                ],
                                "This Month": [
                                    moment().startOf("month").toDate(),
                                    moment().endOf("month").toDate()
                                ],
                                "Last Month": [
                                    moment().subtract(1, "month").startOf("month").toDate(),
                                    moment().subtract(1, "month").endOf("month").toDate()
                                ]
                            }
                        }}
                        onCallback={ handleDateRangeChange} >
                        <div
                            className="col-xs-9 col-md-5 col-sm-6 form-control dateRangePicker datePickerSearch"
                        >
                            <span><DateRangeIcon /></span>
                            <span>{dateRange.label}</span>
                        </div>
                    </DateRangePicker>

                    {/*<TextField*/}
                    {/*    className="searchBox"*/}
                    {/*    placeholder="Search"*/}
                    {/*    value={searchText}*/}
                    {/*    onChange={(e) => setSearchText(e.target.value)}*/}
                    {/*    InputProps={{*/}
                    {/*        endAdornment: <InputAdornment position="end"> <IconButton> <PersonSearchIcon /> </IconButton> </InputAdornment>,*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <span style={{marginLeft: "10px"}}></span>
                    {/*<SelectionList*/}
                    {/*    classname="dropdown"*/}
                    {/*    options={SORT_BY_FIELDS}*/}
                    {/*    selectedValue={sortValue}*/}
                    {/*    setSelectedValue={(e) => setSortField(e.target.value)}*/}
                    {/*    defaultTitle="SORT BY"*/}
                    {/*    sortField={() => requestSort(sortValue, rows)}*/}
                    {/*    sortOrder={sortOrder}*/}
                    {/*    sortBy={sortBy}*/}
                    {/*/>*/}
                </div>
            </div>
            <br/>
            <div className="chartConfigContainer">
                <br/>
                <Card variant="outlined" sx={{width: { xs: "100%", md: "45%" } }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                           Amount Owed:
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            R 0
                        </Typography>
                    </CardContent>
                </Card>

                <Card variant="outlined" sx={{ width: { xs: "100%", md: "45%" } }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14, fontWeight: "bold" }} color="text.secondary" gutterBottom>
                            Amount Paid This Year:
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            R 0
                        </Typography>
                    </CardContent>
                </Card>
            </div>

            <Charts />

            <br/> <br/>
            <h4>RECENT TICKETS</h4>
            <Table columnHeaders={columnHeaders} rowHeaders={rowHeaders} rows={rows} rowsPerPage={5} page={0}
                   alignRows=""/>
            <Button onClick={() => navigate("/doctorReportsDetail")}>Show More</Button>
            <br/>
        </div>)
}

export default DoctorReports;