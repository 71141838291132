import {
    GET_CASES,
    GET_CLOSED_CASES,
    GET_USER_CASES,
    SET_END_OF_USER_TICKETS
} from "../actions/casesActions";
import { setMessage } from "./messageActionCreator";
import { setLoader } from "./loaderActionCreator";
import { API, graphqlOperation } from "aws-amplify";
import * as API_SCHEMA_QUERIES from "../../graphql/queries";
import { getCurrentUserToken } from "../../utils";
import * as API_SCHEMA_SUBSCRIPTIONS from "../../graphql/subscriptions";

export const setCases = (data) => ({ type: GET_CASES, payload: data });
export const setClosedCases = (data) => ({ type: GET_CLOSED_CASES, payload: data });
export const setUserTickets = (data) => ({ type: GET_USER_CASES, payload: data });
export const setEndOfUserTickets = (data) => ({ type: SET_END_OF_USER_TICKETS, payload: data });

export const getCaseTickets = () => async (dispatch) => {
    dispatch(setLoader(true));
    const token = await getCurrentUserToken();
    try {
        const response = await API.graphql(graphqlOperation(API_SCHEMA_QUERIES.getTickets, { token: token, status: "-1", limit: -1 })) ;
        dispatch(setCases(response.data.getTickets.tickets));
        dispatch(setLoader(false));
        return response;
    } catch (e) {
        dispatch( setMessage({ text: "Something went wrong, please try again later", type: "error", show: true}) );
        dispatch(setLoader(false));
    }
};

export const getClosedTickets = () => async (dispatch) => {
    dispatch(setLoader(true));
    const token = await getCurrentUserToken();
    try {
        const response = await API.graphql(graphqlOperation(API_SCHEMA_QUERIES.getTickets, { token: token, status: "3", limit: -1 }));
        dispatch(setClosedCases(response.data.getTickets.tickets));
        dispatch(setLoader(false));
    } catch (e) {
        dispatch( setMessage({ text: "Something went wrong, please try again later", type: "error", show: true}) );
        dispatch(setLoader(false));
    }
}

export const getUserCases = (user, limit, last_session_end_time) => async (dispatch) => {
    dispatch(setLoader(true));
    const token = await getCurrentUserToken();
    const sendJson = {
        token: token,
        other_user_id: user,
        limit: 50,
        user: false,
        last_session_end_time: last_session_end_time
    };
    try {
        const response = await API.graphql(graphqlOperation(API_SCHEMA_QUERIES.getUserTickets, sendJson));
        if(response && response.data && response.data.getUserTickets && response.data.getUserTickets.tickets && response.data.getUserTickets.tickets.length < limit) {
            dispatch(setEndOfUserTickets(true));
        };
        dispatch(setUserTickets(response.data.getUserTickets.tickets));
        dispatch(setLoader(false));
    } catch (e) {
        dispatch( setMessage({ text: "Something went wrong, please try again later", type: "error", show: true}) );
        dispatch(setLoader(false));
    }
}


