import {SET_DOCTORS_INGROUP, SET_PATIENT_RESULT, SET_SEARCH_RESULT, SET_SEARCH_BUTTON_CLICK} from "../actions/searchActions";
import {setLoader} from "./loaderActionCreator";
import {API, graphqlOperation} from "aws-amplify";
import * as API_SCHEMA_QUERIES from "../../graphql/queries";
import {BASE_URL, getCurrentUserToken, getHeader} from "../../utils";
import {formatDateOfBirth, formatDateSlashes} from "../../utils/utlis";
import {setMessage} from "./messageActionCreator";

export const setSearchResult = (data) => ({ type: SET_SEARCH_RESULT, payload: data });
export const setPatientResult = (data) => ({ type: SET_PATIENT_RESULT, payload: data });
export const setDoctorsInGroup = (data) => ({ type: SET_DOCTORS_INGROUP, payload: data });
export const setSearchButtonClicked = (data) => ({ type: SET_SEARCH_BUTTON_CLICK, payload: data });

export const getDoctorsInGroup = () => async (dispatch) => {
    let token = await getCurrentUserToken();
    try {
        const temp = await API.graphql(graphqlOperation(API_SCHEMA_QUERIES.getDoctorsInGroup, { token: token, groupId: '5f46f9cd024bfc00db1a1a33' }));
        dispatch(setDoctorsInGroup(temp.data.getDoctorsInGroup.doctors));
    } catch (e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
    }
};

const getTeleMedTicket = async (paramVals) => {
    await getCurrentUserToken();
    const header = await getHeader();

    const requestOptions = {
        method: 'GET',
        headers: header,
        redirect: 'follow'
    };
    const searchParams = {};
    Object.entries(paramVals).forEach(element => {
        if (element[1].length > 0) {
            if (element[0] === 'patient_dob') {
                element[1] = formatDateOfBirth(element[1]);
            }
            searchParams[element[0]] = element[1];
        }
    });
    const params = new URLSearchParams(searchParams);
    const response = await fetch(BASE_URL + `/drowl_search/getTeleMedTicket?${params.toString()}`,
        requestOptions);

    return await response.json();
}

export const getSearchResults = (searchParams) => async (dispatch) => {
    dispatch(setLoader(true));
    let response = {};
    try {
        response = await getTeleMedTicket(searchParams);
        // setResults(response);;
        dispatch(setSearchResult(response));
        dispatch(setLoader(false));
    } catch (e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
        dispatch(setLoader(false));
    }
}

const getPatientsDetails = (paramVals) => async(dispatch) => {
    await getCurrentUserToken();
    const header = await getHeader();

    const requestOptions = {
        method: 'GET',
        headers: header,
        redirect: 'follow'
    }

    const findPatientParams = {};

    Object.entries(paramVals).forEach(([key, value]) => {
        if (value.length > 0) {
            if (key === 'dob') {
                value = formatDateSlashes(value);
                // key = 'dob';
            }
            findPatientParams[key] = value;
        }
    });
    const params = new URLSearchParams(findPatientParams);
    return await fetch(BASE_URL + `/UserInfo/findPatient?${params.toString()}`,
        requestOptions)
        .then((res) => res.json())
        .then(res => res).catch((e) => {
            dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
                type: "error", show: true}));
        })
}

export const getPatients = (params) => async (dispatch) => {
    dispatch(setLoader(true));
    try {
        const response = await dispatch(getPatientsDetails(params));
        if (response && response.status === false) {
            dispatch(setPatientResult([]));
        } else {
            dispatch(setPatientResult(response));
        }
    } catch (e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
    }
}




