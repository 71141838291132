import React, {useState, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

function SessionTimeoutDialog({ open, onLogout, onContinue, countdown }) {

    useEffect(() => {
        if (countdown === 0) {
            onLogout(); // Automatically log out after the countdown
        }
    }, [countdown, onLogout]);

    return (
        <Dialog open={open}>
            <DialogContent>
                <DialogContentText>
                    Your session is about to expire. Do you want to continue your session?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onLogout} color="primary">
                    Logout
                </Button>
                <Button onClick={onContinue} color="primary">
                    Continue Session
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SessionTimeoutDialog;