import React from "react";
import "../Stats.css";
import {useSelector} from "react-redux";
import Table from "../../Table/index";
import DoctorInfo from "../Overview/DoctorInfo";

const AmountPaid = () => {

    const statsData = useSelector(state => state.statsReducer);

    const columnHeaders = ["Date", "Payment ID", "Amount"];

    const rowHeaders = ["date", "payment_id", "amount"];

    return (
        <div className="textBox pageWindow" id="statsWindow">
            <DoctorInfo type="ADMIN" title="AMOUNT PAID"/>
            <Table
                columnHeaders={columnHeaders}
                rows={statsData.amountPaid}
                rowHeaders={rowHeaders}
                alignRows=""
            />
        </div>
    )
}

export default AmountPaid;