import React, { useState } from 'react';
import {TextField, Button, Container, Typography, Box, Divider} from '@mui/material';
import LoginHeader from "../Header/LoginHeader";

const DemoContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        whatsapp: '',
        comments: ''
    });

    const [errors, setErrors] = useState({
        name: false,
        email: false,
        whatsapp: false,
        comments: false
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const validateWhatsapp = (whatsapp) => {
        const regex = /^[0-9]{10,15}$/;
        return regex.test(whatsapp);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const { name, email, whatsapp, comments } = formData;

        const newErrors = {
            name: !name,
            email: !validateEmail(email),
            whatsapp: !validateWhatsapp(whatsapp),
            comments: !comments
        };

        setErrors(newErrors);

        const hasErrors = Object.values(newErrors).some(error => error);
        if (hasErrors) return;

        const subject = `Request Demo Form Submission`;
        const body = `Name: ${name}\nEmail: ${email}\nWhatsApp: ${whatsapp}\n\nComments:\n${comments}`;

        const mailtoLink = `mailto:info@eagleIntelligent.co.za?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        window.location.href = mailtoLink;
    };

    return (

        <>
            <LoginHeader />
            <br />

            <Divider style={{ border: "3px solid rgb(2, 163, 244)"}}/>

            <br />
        <Container maxWidth="sm">
            <Typography variant="h4" component="h1" gutterBottom>
                Request a Demo
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <TextField
                    placeholder="Your Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    error={errors.name}
                    helperText={errors.name ? 'Name is required' : ''}
                />
                <TextField
                    placeholder="Your Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={errors.email}
                    helperText={errors.email ? 'Enter a valid email' : ''}
                />
                <TextField
                    placeholder="WhatsApp Number"
                    name="whatsapp"
                    value={formData.whatsapp}
                    onChange={handleChange}
                    error={errors.whatsapp}
                    helperText={errors.whatsapp ? 'Enter a valid WhatsApp number (10 digit)' : ''}
                />
                <TextField
                    placeholder="Comments"
                    name="comments"
                    multiline
                    rows={4}
                    value={formData.comments}
                    onChange={handleChange}
                    error={errors.comments}
                    helperText={errors.comments ? 'Comments are required' : ''}
                />
                <Button type="submit" variant="contained" color="primary">
                    Submit
                </Button>
            </Box>
        </Container>
            </>
    );
};

export default DemoContactForm;
