import React from  'react';
import {Grid, TextField} from "@mui/material";

const RowComponent = ({label, onChange, value, required, error, onBlur, disabled, type, options, placeholder }) => {
    return (
            <>
                <Grid container  justifyContent="center">
                    <Grid item className="section-subgroup section-subgroup-middle marginLeft" xs={4} md={2}>
                        <label>{`${label}${required ? "*" : ''}`}:</label>
                    </Grid>
                    <Grid item >
                        {type === "dropdown" ?
                            <TextField
                                select
                                variant="outlined"
                                className='textFieldWidth'
                                style={{width : "180px"}}
                                fullWidth={true}
                                onChange={onChange}
                                SelectProps={{
                                    native: true,
                                }}
                                value={value}
                                error={error}
                                helperText={error && "This is a required field."}
                                disabled={disabled}
                            >
                                <option key={99} value=''>None</option>
                                {options.map((x,index) =>   <option key={index} value={x.value}>{x.label}</option>) }
                            </TextField>
                            :
                        <TextField
                            placeholder={placeholder}
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            style={{width : "180px"}}
                            variant="outlined"
                            className="textFieldWidth"
                            fullWidth={true}
                            error={error}
                            helperText={error && "This is a required field."}
                            disabled={disabled}
                        /> }
                    </Grid>
                </Grid>
                <div style={{marginBottom: "20px"}}></div>
            </>
    )
}
export default RowComponent;