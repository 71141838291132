import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import {FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import {setFormattedAddressDialogue, setRecommenedAddress} from "../../ducks/actionCreators/settingsActionCreator";

function FormattedAddress({showDialog, profileData, setProfileData}) {
    const {formatted_address,address_components} = useSelector((state) => state.settings);
    const [addressRadio, setAddressRadio] = useState("radio2");
    const dispatch =  useDispatch();

    const handleDialogClose = () => {
        dispatch(setRecommenedAddress(null))
    };

    function parseAddressComponents() {
        let addressField1 = "";
        let addressField2 = "";
        let state = "";
        let zipCode = "";
        let city = "";

        address_components.forEach((component) => {
            if (component.types.includes("street_number")) {
                addressField1 += component.short_name;
            }
            if (component.types.includes("route")) {
                addressField1 += " " + component.short_name;
            }
            if (component.types.includes("subpremise")) {
                addressField2 = component.short_name;
            }
            if (component.types.includes("locality")) {
                city = component.short_name;
            }
            if (component.types.includes("administrative_area_level_1")) {
                state = component.short_name;
            }
            if (component.types.includes("postal_code")) {
                zipCode = component.short_name;
            }
        });

        return {
            addressField1,
            addressField2,
            city,
            state,
            zipCode,
        };
    }

    const handleAddressSelect = () => {
        if(addressRadio === "radio2"){
            const newAdd = parseAddressComponents();
            setProfileData({...profileData,
                bussiness_address1: newAdd.addressField1,
                bussiness_address2: newAdd.addressField2,
                bussiness_city: newAdd.city,
                bussiness_state: newAdd.state,
                bussiness_zipcode: newAdd.zipCode
            })
        } else {
            dispatch(setFormattedAddressDialogue(true));
        }
        handleDialogClose();
    }

    return (
            <Dialog open={showDialog} onClose={handleDialogClose}>
                <div style={{marginLeft: "auto", marginRight: "auto", marginTop: "30px", marginBottom: "30px", width: "80%"}}>
                <div>
                    <Typography variant={"h6"}>Confirm your address</Typography>
                    <Typography>Review the recommended changes</Typography>
                </div>
                <DialogContent>
                    <RadioGroup value={addressRadio} onChange={(e) => setAddressRadio(e.target.value)}>
                        <div className="radioButton">
                            <FormControlLabel value="radio1" control={<Radio className="radio"/>}  label="What you entered" />
                            <p className="radio">
                                {`${profileData.bussiness_address1 && profileData.bussiness_address1+","}${profileData.bussiness_address2 && profileData.bussiness_address2+","}${profileData.bussiness_city && profileData.bussiness_city+","} ${profileData.bussiness_state} ${profileData.bussiness_zipcode}`}

                                {/*{`${profileData.bussiness_address1}${profileData.bussiness_address2 && ","}${profileData.bussiness_address2},*/}
                                {/* ${profileData.bussiness_city},*/}
                                {/* ${profileData.bussiness_state}*/}
                                {/* ${profileData.bussiness_zipcode}`}*/}

                            </p>
                        </div>
                        <br/>
                        <div className="radioButton">
                        <FormControlLabel value="radio2" control={<Radio />} label="Recommended" ></FormControlLabel>
                        <p className="radio">{formatted_address}</p>
                        </div>
                    </RadioGroup>
                    {/*<div>What you entered</div>*/}
                    {/*<div>Recommended {formatted_address}</div>*/}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} variant="contained" color="gray">
                        Cancel
                    </Button>
                    <Button onClick={() => handleAddressSelect()} variant="contained" color="primary">
                        Confirm
                    </Button>
                </DialogActions>
                </div>
            </Dialog>
    );
}

export default FormattedAddress;