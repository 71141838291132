import React, { useEffect, useRef } from 'react';
import MessageIcon from '@mui/icons-material/Message';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {useNavigate} from "react-router-dom";
import soundfile from "./playful-notification.mp3"
import {useSelector} from "react-redux";
import "./NotificationBar.css";

const MessageAlertWithRing = () => {
    const audioRef = useRef(null);

    const navigate = useNavigate();

    const patientOverviewData = useSelector(state => state.patientOverview);
    const { messageToNotify } = patientOverviewData;

    useEffect(() => {

        if(messageToNotify) {
            // Play the ring sound when the component mounts
            audioRef.current.play();

            // Clean up the audio when the component unmounts
            // return () => {
            //         audioRef.current.pause();
            //         audioRef.current.currentTime = 0;
            // };
        }

    }, []);

    return (
        <div>
            <audio src={soundfile} ref={audioRef} autoPlay preload="auto" />
            {/*<audio ref={audioRef} src="./notification.mp3" /><audio ref={audioRef} src="./notification.mp3" />*/}
            <div>
                <div className="notification-icon">
                    <NotificationsActiveIcon fontSize='large' /><span className="dot"></span>
                </div>
            </div>
        </div>
    );
};

export default MessageAlertWithRing;