import React from 'react';
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineOppositeContent, TimelineDot}  from '@mui/lab';
import { Typography, Paper } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import './CasesTimeline.css';
import { Link } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { useSelector } from "react-redux";
import {Loader} from "@drowl/drowl-react-library";
import CustomTimeline from "../Timeline/CustomTimeline";

const CaseStatuesStyles = [
    {
        key: 0,
        type: 'Open',
        color: '#23a423',
        class: 'caseOpen'
    },
    {
        key: 1,
        type: 'In Progress',
        color: '#d32f2f',
        class: 'caseInprogress'
    },
    {
        key: 2,
        type: 'Scheduled',
        color: '#5ba2da',
        class: 'caseScheduled'
    },
    {
        key: 3,
        type: 'Closed',
        color: '#bdbdbd', // default color 
        class: 'caseClosed' // default color 
    },
    {
        key: 4,
        type: 'Assessing',
        color: '#007bff', // default color
        class: 'caseAssessing' // default color
    },
    {
        key: 5,
        type: 'Waiting for Gp',
        color: '#17a2b8', // default color
        class: 'caseWaitingForGp' // default color
    }
];

const CasesTimeline = ({handleLoadMoreTickets})  => {

    const casesData = useSelector(state => state.cases);
    const loader = useSelector((state) => state.loader);

    const {userTickets, isEndOfUserTickets} = casesData;

    if (!userTickets) {
        return (
            <div>
                <Loading size={40} />
            </div>
        );
    }

    return (

        <CustomTimeline items={userTickets}/>
        // <div>
        //     <> {loader.loading && <Loader /> } </>
        //     <Timeline align="alternate" position="alternate">
        //         {userTickets && userTickets.map((ticket, index) => {
        //             return (
        //                 <TimelineItem key={ticket._id}>
        //                     {/* Time stamp */}
        //                     <TimelineOppositeContent>
        //                         <Typography variant="body2" color="textSecondary">
        //                             {new Date(parseInt(ticket.timestamp)).toDateString()}
        //                         </Typography>
        //                     </TimelineOppositeContent>
        //                     {/* Separator */}
        //                     <TimelineSeparator>
        //                         <TimelineDot style={{ background: `${CaseStatuesStyles[ticket.status].color}` }} />
        //                         <TimelineConnector />
        //                     </TimelineSeparator>
        //                     {/* Timeline paper stamp */}
        //                     <TimelineContent>
        //                         <Paper elevation={3} className='timelineBody'>
        //                             <Link to={`/patientOverview/${ticket._id}`} state={{from: "patientOverview"}} className='timelineLink linkTo'>
        //                                 {/* Title */}
        //                                 <Typography variant="h6" component="h1">
        //                                     {`Case Id: ${ticket.uid}`} <OpenInNewIcon fontSize='small' style={{ marginBottom: '3px' }} />
        //                                 </Typography>
        //
        //                                 {/* Provider */}
        //                                 {ticket.assignee && ticket.assignee.length > 0 ?
        //                                     <Typography className='bold'>Provider: {ticket.assignee}</Typography>
        //                                     :
        //                                     <Typography className='bold'>No Provider Assigned</Typography>
        //                                 }
        //
        //                                 {/* Problem */}
        //                                 <Typography>{ticket.problem}</Typography>
        //
        //                                 {/* Status */}
        //                                 <div className='statusPillContainer'>
        //                                     <div className={`${CaseStatuesStyles[ticket.status].class ?? CaseStatuesStyles[3].class} statusPill`}>
        //                                         {CaseStatuesStyles[ticket.status].type ?? CaseStatuesStyles[3].type}
        //                                     </div>
        //                                 </div>
        //                             </Link>
        //                         </Paper>
        //                     </TimelineContent>
        //                 </TimelineItem>
        //             );
        //         })}
        //     </Timeline>
        //
        //     {isEndOfUserTickets ?
        //         <Typography className='bold' style={{color:'#ef5a5a'}}>End of User Cases</Typography>
        //         :
        //         <Typography className='bold' style={{cursor:'pointer'}} onClick={() => handleLoadMoreTickets()}>Load More</Typography>
        //     }
        //
        // </div>

    );
}

export default CasesTimeline;