import {Button, IconButton, Checkbox, FormControlLabel} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateProgressNote} from "../../ducks/actionCreators/patientOverviewActionCreator";

{/* Current Medications */}
const Medications = () => {
    const patientOverviewData = useSelector(state => state.patientOverview);
    const { progressNote, ticket } = patientOverviewData;
    // let  { medicine } = progressNote;

    const [isEdittingMedications, setIsEdittingMedications] = useState(false);
    const [currentMedications, setCurrentMedications] = useState(progressNote && progressNote.medicine);

    const dispatch = useDispatch();

    useEffect(() => {
            setCurrentMedications(progressNote && progressNote.medicine);
    },[progressNote.medicine]);

    const cancelEdit = () => {
      let result = [];

      for (let i = 0; i < currentMedications.length; i++) {
        let curMed = { ...currentMedications[i] };

        if (curMed.hasOwnProperty('doctor_deleted')) {
          curMed.doctor_deleted = getInitialStateOfMedStatus(currentMedications)[i];
        } else if (curMed.hasOwnProperty('deleted')) {
          curMed.deleted = getInitialStateOfMedStatus(currentMedications)[i];
        }
        result.push(curMed);
      }

      setCurrentMedications([...result]);
      setIsEdittingMedications(false);
    };

    const getInitialStateOfMedStatus = (data) => {
      // set the inital state on if a med is deleted or not.
      const result = [];
      for (let i = 0; i < data.length; i++) {
        let curMed = { ...data[i] };
        if (curMed.doctor_deleted) {
          result.push(curMed.doctor_deleted);
        } else if (curMed.deleted) {
          result.push(curMed.deleted);
        } else {
          result.push(false);
        }
      }

      return result;
    };

    const handleCurrentMedicationsChange = (i, isChecked) => {
      const temp = [...currentMedications];
      temp[i].doctor_deleted = !isChecked; // isChecked true then doctor_delete is false

      setCurrentMedications(temp);
    };

    const updateCurrentMedications = () => {

      const updateProgressNoteVals = {
        ...progressNote,
        'medicine': currentMedications
      };

      dispatch(updateProgressNote(updateProgressNoteVals, ticket._id));
      setIsEdittingMedications(false);
      getInitialStateOfMedStatus(currentMedications);

    };

    return(
        <>
            {(currentMedications !== undefined && Object.keys(currentMedications).length !== 0) &&
                <div className="form-group">
                    <div className="pn-subtitle" style={{display: "flex"}}>
                        Current Medications:
                        {/*{ticket.status !== 3 && !isEdittingMedications && <> (Click to edit) </>}*/}
                        {/*{ ticket.status !== 3 && <> {isEdittingMedications ?*/}
                        {/*    <>*/}
                        {/*        <Button variant="contained" color='primary' onClick={() => updateCurrentMedications()}*/}
                        {/*                style={{ marginLeft: '5px' , color: "white", padding: "5px" }}>*/}
                        {/*            Save*/}
                        {/*        </Button>*/}
                        {/*        <Button variant="contained" color='secondary' onClick={() => cancelEdit('medicine')}*/}
                        {/*                style={{ color: "white", padding: "5px" }}*/}
                        {/*        >*/}
                        {/*            Cancel*/}
                        {/*        </Button>*/}
                        {/*    </>*/}
                        {/*    :*/}
                        {/*    <IconButton onClick={() => setIsEdittingMedications(true)} color="primary">*/}
                        {/*        <EditIcon fontSize="medium" style={{ marginTop: "-9px"}} />*/}
                        {/*    </IconButton>*/}
                        {/*}</> }*/}
                    </div>
                    <ul>
                        {currentMedications.map((x, index) => {
                            return (
                                <li className={`subject-text dignsdAddtoPln ${x.deleted || x.doctor_deleted ? 'patientDelete' : ''}`} key={index} >
                                    {x.medication} {x.form && x.form.length > 0 ? ` by ${x.form}` : ' '} {x.dosage && x.dosage.length > 0 ? `; ${x.dosage}` : ' '}

                                    {isEdittingMedications && !x.deleted &&
                                        <Checkbox
                                            color='primary'
                                            checked={!x.doctor_deleted ?? true}
                                            onChange={() => handleCurrentMedicationsChange(index, ('doctor_deleted' in x ? x.doctor_deleted : false))}
                                        />}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            }
        </>
    )
}

export default Medications;