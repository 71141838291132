import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import loaderReducer from "./loaderReducer";
import messageReducer from "./messageReducer";
import casesReducer from "./casesReducer";
import patientOverviewReducer from "./patientOverviewReducer";
import medsCondsReducer from "./medsCondsReducer";
import videoReducer from "./videoReducer";
import caseReducer from "./caseReducer";
import searchReducer from "./searchReducer";
import provCalendarReducer from "./provCalendarReducer";
import avilabilityReducer from "./availabilityReducer";
import settingsReducer from "./settingsReducer";
import requestReducer from "./requestReducer";
import createPatientReducer from "./createPatientReducer";
import statsReducer from "./docStatsReducer";
import shareReducer from "./shareReducer";

const allReducers = combineReducers({
    login: loginReducer,
    loader: loaderReducer,
    message: messageReducer,
    cases: casesReducer,
    medsConds: medsCondsReducer,
    zoomUrl: videoReducer,
    case: caseReducer,
    patientOverview: patientOverviewReducer,
    search: searchReducer,
    calendar: provCalendarReducer,
    avilability: avilabilityReducer,
    settings: settingsReducer,
    requestData: requestReducer,
    newPatient: createPatientReducer,
    statsReducer: statsReducer,
    sharedData: shareReducer
});

export default allReducers;
