import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {Box, Tab, TablePagination, Tabs} from "@mui/material";
import TabPanelContent from "./TabPanelContent";
import TabPanelContentMobile from "./TabPanelContentMoble";
import {
    getAdminRequests,
    getAdminRequestsHistory,
    setDocumentLink
} from "../../ducks/actionCreators/requestActionCreator";
import TabPanel from "../Cases/TabPanel";
import HistoryTabWeb from "./HistoryTabWeb";
import HistoryTabMobile from "./HistoryTabMobile";

const ViewRequests = () => {

    const [tab, setTab] = useState(0);
    const [showMobileViewTable, setShowMobileViewTable] = useState(window.innerWidth < 600);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const loader = useSelector((state) => state.loader);

    const dispatch = useDispatch();

    const requestData = useSelector((state) => state.requestData);

    useEffect( () => {
        dispatch(setDocumentLink(""));
        if(tab === 0) {
            dispatch(getAdminRequests());
        }
        if(tab === 1) {
            dispatch(getAdminRequestsHistory());
        }

    }, [tab]);


    useEffect(() => {
        function handleWindowResize() {
            setShowMobileViewTable(window.innerWidth < 992);
        };
        window.addEventListener("resize", handleWindowResize);

    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onTabChange = (e, newTab) => {
        setTab(newTab);
        setPage(0);
    }

    const columnHeaders = ["Request ID", "Name", "Email", "Date of Request"];
    const historyColumnHeaders = ["Request ID", "Name", "Email", "Date of Request", "Action By", "Status", "Reason"];

    return (
        <div id="casesWindow" className="pageWindow">
            {/*<> {loader.loading && <Loader /> } </>*/}
            <div className="tcktsSec">
                <h1>Requests</h1>
                <br/>
                <Box sx={{ width: '100%' , border: '1px solid green'}}>
                    <Box sx={{ width: '100%' , borderBottom: 1, borderColor: 'divider' }} className="tabCss">
                        <Tabs value={tab} onChange={onTabChange} aria-label="case tabs"  variant="fullWidth" id="cases" TabIndicatorProps={{
                            style: {
                                backgroundColor: "#6aaf7fad",
                            }
                        }}>
                            <Tab label="Open Requests" className="leftTab" />
                            <Tab label="Closed Requests" className="rightTab" />
                        </Tabs>
                    </Box>
                    <TabPanel value={tab} index={0}>

                        { !showMobileViewTable ? <TabPanelContent
                                columnHeaders={columnHeaders}
                                rows={requestData && requestData.adminRequests}
                                page={page}
                                rowsPerPage={rowsPerPage}
                            /> :
                            <TabPanelContentMobile
                                columnHeaders={columnHeaders}
                                rows={requestData && requestData.adminRequests}
                                page={page}
                                rowsPerPage={rowsPerPage}
                            />
                        }

                        { requestData && requestData.adminRequests && requestData.adminRequests.length > 10 &&
                            <TablePagination
                                className="tableContainer"
                                rowsPerPageOptions={[10, 15]}
                                component="div"
                                count={requestData.adminRequests.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        }

                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                            { !showMobileViewTable ? <HistoryTabWeb
                                    columnHeaders={historyColumnHeaders}
                                    rows={requestData && requestData.adminRequestsHistory}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                /> :
                                <HistoryTabMobile
                                    columnHeaders={historyColumnHeaders}
                                    rows={requestData && requestData.adminRequestsHistory}
                                    page={page}
                                    rowsPerPage={rowsPerPage}
                                />
                            }

                            { requestData && requestData.adminRequestsHistory && requestData.adminRequestsHistory.length > 10 &&
                                <TablePagination
                                    className="tableContainer"
                                    rowsPerPageOptions={[10, 15]}
                                    component="div"
                                    count={requestData.adminRequestsHistory.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            }
                    </TabPanel>
                </Box>
            </div>
        </div>
    );
}


export default ViewRequests;
