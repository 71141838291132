import React, {useEffect, useState} from 'react';
import './ProgressNote.css';
import { TextField, Grid } from '@mui/material';
import {useSelector} from "react-redux";


export default function PatientInfo(props) {
    const patientOverviewData = useSelector(state => state.patientOverview);
    const { progressNote, ticket } = patientOverviewData;
    const initbp1 = progressNote && (progressNote['Blood Pressure'] && progressNote['Blood Pressure'].split('/'));
    const [info, setInfo] = useState();
    const [bp1, setBp1] = useState(initbp1 && initbp1[0] || "");
    const [bp2, setBp2] = useState(initbp1 && initbp1[1] || "");

    useEffect(() => {
        if(progressNote) {
            setInfo({
                'Height': progressNote['Height'] ?? "",
                'Weight': progressNote['Weight'] ?? "",
                'Pulse': (progressNote['Beats per Minute'] || progressNote['Pulse']) ?? "",
                'Oxygen Saturation': progressNote['Oxygen Saturation'] ?? "",
                'Temperature': progressNote['Temperature'] ?? "",
                'Blood Pressure': progressNote['Blood Pressure'] ?? "",
                'Blood Sugar': progressNote['Blood Sugar'] ?? "",
            })
        }

    }, [progressNote])
    // Handle change as user types in the text fields.
    const handleChange = (event) => {
            const eventName = event.target.name;
            const newInfo = { ...info, [eventName]: event.target.value };
            setInfo(newInfo);
    };

    // Save Changes after a user is no longer focused in a text field
    const saveChange = () => {
        props.updatedPatientInfoSection(info);
    }

    return (
        <div>
            {info && Object.entries(info).map(([key, value]) => {
                if(key === "Blood Pressure") {
                    return (
                        <>
                            <Grid container spacing={4} key={key}>
                                <Grid item className="section-subgroup section-subgroup-middle marginLeft" xs={4} md={3}>{key + ':'}</Grid>
                                <Grid item >
                                    <TextField
                                        type="number"
                                        value={bp1 ?? ''}
                                        name={key}
                                        variant="outlined"
                                        className="bpFieldWidth"
                                        onChange={() => setBp1(event.target.value)}
                                        // onBlur={saveChange}
                                        disabled={ticket.status === 3}
                                        // onInput = {(e) =>{
                                        //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)
                                        // }}
                                    />

                                    <span style={{paddingLeft: "10px", paddingRight: "10px"}}> / </span>

                                    <TextField
                                        type="number"
                                        value={bp2 ?? ''}
                                        name={key}
                                        variant="outlined"
                                        className="bpFieldWidth"
                                        onChange={() => setBp2(event.target.value)}
                                        onBlur={(e) => {
                                            info["Blood Pressure"] = bp1 + "/" + bp2;
                                            saveChange()
                                        }}
                                        disabled={ticket.status === 3}
                                        // onInput = {(e) =>{
                                        //     e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,12)
                                        // }}
                                    />
                                </Grid>
                            </Grid>
                            <div style={{marginBottom: "20px"}}></div>
                        </>
                    )
                }
                else return (
                    <>
                    <Grid container spacing={4} key={key}>
                        <Grid item className="section-subgroup section-subgroup-middle marginLeft" xs={4} md={3}>{key + ':'}</Grid>
                        <Grid item >
                            <TextField
                                type="number"
                                value={info[key] ?? ''}
                                name={key}
                                variant="outlined"
                                className="textFieldWidth"
                                onChange={handleChange}
                                onBlur={saveChange}
                                disabled={ticket.status === 3}
                            />
                        </Grid>
                    </Grid>
                        <div style={{marginBottom: "20px"}}></div>
                    </>
                );

            })}
        </div>
    );
}