import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import './Cases.css';
import {getCaseTickets, getClosedTickets} from "../../ducks/actionCreators/casesActionCreator";
import {Tabs, Tab, Box, TablePagination} from "@mui/material";
import TabPanel from "./TabPanel";
import TabPanelContent from "./TabPanelContent";
import TabPanelContentMobile from "./TabPanelContentMobile";
import {
  subscribeToAllStatus, subscribeToDocNotifications,
  subscribeToTickets
} from "../../graphql/graphqlSubscriptions";
import {Loader} from "@drowl/drowl-react-library";
import {useUserRole} from "../../hooks/useUserRole";
import {ROLES} from "../../Main";
import {messageNotificationOnCase} from "../../ducks/actionCreators/patientOverviewActionCreator";

const Cases = () => {

  const userRoles = useUserRole();
  const [tab, setTab] = useState(0);
  const [showMobileViewTable, setShowMobileViewTable] = useState(window.innerWidth < 600);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState("Scheduled");

  const dispatch = useDispatch();
  const casesData = useSelector(state => state.cases);
  const loader = useSelector((state) => state.loader);


  const  {newCommentReceived} = useSelector((state) => state.patientOverview);

  useEffect( () => {

    dispatch(getCaseTickets());
    const asyncFunc = async() => {
      await dispatch(subscribeToTickets());
      //await dispatch(subscribeToAllStatus());
      // await dispatch(subscribeToDocNotifications());
    }
    asyncFunc();
  }, []);


  useEffect(() => {
    function handleWindowResize() {
      setShowMobileViewTable(window.innerWidth < 600);
    };
    window.addEventListener("resize", handleWindowResize);

  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onTabChange = (e, newTab) => {
    setTab(newTab);
    setPage(0);
    if (newTab === 1) {
      dispatch(getClosedTickets());
    }
  }

  const sortData = (sortBy, sortOrder, rowsToSort) => {
    let itemsToSort = [...rowsToSort];
    let sortedItems = [];
    let compareFn = null;

    switch (sortBy) {
      case "#":
        compareFn = (i, j) => {
          if (i.uid < j.uid) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (i.uid > j.uid) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };
        break;
      case "Scheduled" :
        compareFn = (i, j) => {
          if (i.timestamp < j.timestamp) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (i.timestamp > j.timestamp) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };
        break;
      case "Status" :
        compareFn = (i, j) => {
          if (i.status < j.status) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (i.status > j.status) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };
        break;
      case "Provider":
        compareFn = (i, j) => {
          if (i.assignee < j.assignee) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (i.assignee > j.assignee) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };
        break;
      case "Patient" :
        compareFn = (i, j) => {
          if (i.subject < j.subject) {
            return sortOrder === "asc" ? -1 : 1;
          } else {
            if (i.subject > j.subject) {
              return sortOrder === "asc" ? 1 : -1;
            } else {
              return 0;
            }
          }
        };
        break;
      default:
        break;
    }
    sortedItems = itemsToSort.sort(compareFn);
    return sortedItems;

  };

  const requestSort = (pSortBy, rowsToSort) => {
    let sortingOrder = sortOrder;
    let sortedBy = sortBy;

    if (pSortBy === sortedBy) {
      sortingOrder = sortingOrder === "asc" ? "desc" : "asc";
    } else {
      sortedBy = pSortBy;
      sortingOrder = "asc";
    }
    setSortBy(sortedBy);
    setSortOrder(sortingOrder);
  };
    const openCases = casesData && casesData.cases.filter(function (x) {
      return x.status !== 3;
    })

  openCases.sort((a,b) => a.timestamp-b.timestamp);

  useEffect(() => {
    if(!localStorage.getItem(newCommentReceived)) {
      localStorage.setItem(newCommentReceived, 0);
    } else {

      openCases.forEach((c) => {
        if(newCommentReceived === c._id) {
          const newValue = Number(localStorage.getItem(c._id)) + 1;

          localStorage.setItem(newCommentReceived, newValue);
        }
      });
    }
    dispatch(messageNotificationOnCase(null))
  }, [newCommentReceived]);


  useEffect(() => {

    openCases.forEach((c) => {
      const key = c._id;
      const value = localStorage.getItem(key) || 0;
      localStorage.setItem(key, value);
    });

  },[])


   const columnHeaders = ["#", "Status", "Scheduled", "Patient", "Provider", "New Message"];

    return (
      <div id="casesWindow" className="pageWindow">
        <> {loader.loading && <Loader /> } </>
        <div className="tcktsSec">
          <h1>Telemedicine Cases</h1>
          <br/>
          <Box sx={{ width: '100%' , border: '1px solid green'}}>
            <Box sx={{ width: '100%' , borderBottom: 1, borderColor: 'divider' }} className="tabCss">
              <Tabs value={tab} onChange={onTabChange} aria-label="case tabs"  variant="fullWidth" id="cases" TabIndicatorProps={{
                style: {
                  backgroundColor: "#6aaf7fad",
                }
              }}>
                <Tab label="Open Cases" className="leftTab" />
                {userRoles && userRoles[0] !== ROLES.OPERATOR && <Tab label="Closed Cases" className="rightTab" /> }
              </Tabs>
            </Box>
            <TabPanel value={tab} index={0}>
              { !showMobileViewTable ? <TabPanelContent
                      columnHeaders={columnHeaders}
                      rows={openCases}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      // requestSort={requestSort}
                      // sortOrder={sortOrder}
                      // sortBy={sortBy}
                  /> :
                  <TabPanelContentMobile
                      columnHeaders={columnHeaders}
                      rows={openCases}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      // requestSort={requestSort}
                      // sortOrder={sortOrder}
                      // sortBy={sortBy}
                  />
              }
              { openCases?.length > 10 &&
                  <TablePagination
                      className="tableContainer"
                      rowsPerPageOptions={[10, 15]}
                      component="div"
                      count={openCases?.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                  />
              }
            </TabPanel>
            <TabPanel value={tab} index={1}>
              { !showMobileViewTable ? <TabPanelContent
                      columnHeaders={columnHeaders}
                      rows={casesData && casesData.closedCases}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      // requestSort={requestSort}
                      // sortOrder={sortOrder}
                      // sortBy={sortBy}
                  /> :
                  <TabPanelContentMobile
                      columnHeaders={columnHeaders}
                      rows={casesData && casesData.closedCases}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      // requestSort={requestSort}
                      // sortOrder={sortOrder}
                      // sortBy={sortBy}
                  />
              }
              { casesData?.closedCases?.length > 10 &&
                  <TablePagination
                      className="tableContainer"
                      rowsPerPageOptions={[10, 15]}
                      component="div"
                      count={casesData && casesData.closedCases.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                  />
              }
            </TabPanel>
          </Box>
        </div>
      </div>
    );
}


export default Cases;
