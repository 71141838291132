import {
    GET_PROVIDERS,
    SET_SCHEDULE_SLOTS,
    SET_SCHEDULE_TELEMED,
    SET_TICKET_STATUS,
    SET_CHANGE_PROVIDER_STATUS,
    SET_CASE_HISTORY
} from "../actions/caseActions";
import { setLoader } from "./loaderActionCreator";
import {Analytics, API, graphqlOperation} from "aws-amplify";
import * as API_SCHEMA_QUERIES from "../../graphql/queries";
import { getCurrentUserToken } from "../../utils";
import * as API_SCHEMA_MUTATIONS from "../../graphql/mutations";
import {setMessage} from "./messageActionCreator";
import {getTicketInfo} from "./patientOverviewActionCreator";

export const setProviders = (data) => ({ type: GET_PROVIDERS, payload: data });
export const setSlotsToSchedule = (data) => ({ type: SET_SCHEDULE_SLOTS, payload: data });
export const setSchedTelemed = (data) => ({ type: SET_SCHEDULE_TELEMED, payload: data });
export const setTicketStatus = (data) => ({ type: SET_TICKET_STATUS, payload: data});
export const setChangeAsigneeStatus = (data) => ({ type: SET_CHANGE_PROVIDER_STATUS, payload: data});
export const setCaseHistory = (data) => ({ type: SET_CASE_HISTORY, payload: data});

export const getProviders = () => async (dispatch, getState) => {
    dispatch(setLoader(true));
    const groupId =  getState().patientOverview.ticket.group;
    const token = await getCurrentUserToken();
    try {
        const temp = await API.graphql(graphqlOperation(API_SCHEMA_QUERIES.getDoctorsInGroup, { token: token, groupId: groupId }));
        if(temp && temp.data && temp.data.getDoctorsInGroup && temp.data.getDoctorsInGroup.status) {
            dispatch(setProviders(temp.data.getDoctorsInGroup.doctors));
        }
        dispatch(setLoader(false));
        // dispatch(setProviders([
        //     {id: "8a5f19c3-99c2-4615-8462-3713a82a2c0d", name: "Yrud", family_name: "Jxb", image:"https://images.drowl.com/df90434191efe7d072254b42d0a4cbe9/profile_0.jpg" }]
        // ));
    } catch (e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
        dispatch(setLoader(false));
    }
};

export const changeTicketStatus = (status, ticketId) => async(dispatch) => {

    const token = await getCurrentUserToken();
    try {
        const res = await API.graphql(graphqlOperation(API_SCHEMA_MUTATIONS.updateTicketStatus, {
            token: token,
            status: status,
            ticketId: ticketId
        }));

        if(res?.data?.updateTicketStatus?.status) {
            await dispatch(getTicketInfo(ticketId));
            dispatch(setTicketStatus(status));
            dispatch(setLoader(false));
            await Analytics.record({
                screen: "caseStatusChange", status: status
            });
        }


        // return { status: true, ticketStatus: status };
    } catch (e) {
        dispatch(setLoader(false));
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
    }
    // return { status: false };
}

export const  changeTicketAssignee = (id, ticketId) => async (dispatch) => {
    const token = await getCurrentUserToken();
    try {
       const response =  await API.graphql(graphqlOperation(API_SCHEMA_MUTATIONS.assignTicketToDoctor, {
            token: token,
            doctorId: id,
            ticketId: ticketId
        }));

       dispatch(setChangeAsigneeStatus(response.data.assignTicketToDoctor.status ? "success" : "error"));

    } catch (e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
    }
}

export const getAvailableSlotsForScheduling = (data) => async (dispatch, getState) => {
    const token = await getCurrentUserToken();
    const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const start_date = data.start_date;
    const end_date = data.end_date;
    const session_time = data.session_time;
    const doctor_id = data.doctor_id;
    const group_id = getState().patientOverview.ticket.group;

    const getAvailableTimeSlotsInputDTO = {
        token: token,
        time_zone: time_zone,
        session_time: session_time,
        start_date: start_date,
        end_date: end_date,
        doctor_id: doctor_id,
        group_id: group_id
    };
    try {
        const slotsToSchedule = await API.graphql(graphqlOperation(API_SCHEMA_QUERIES.getAvailableTimeSlotsForAssigning, {
            getAvailableTimeSlotsInputDTO
        }));

        dispatch(setSlotsToSchedule(slotsToSchedule.data.getAvailableTimeSlotsForAssigning));

    } catch (e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
    }
}

export const scheduleTelemed = (data) => async(dispatch, getState) => {
    const token = await getCurrentUserToken();
    try {
        const response = await API.graphql(graphqlOperation(API_SCHEMA_MUTATIONS.addDoctorSchedule, {
            token: token,
            id: data.id, // provider id
            ticketId: getState().patientOverview.ticket._id,
            start_time: data.start_time,
            end_time: data.end_time,
            title: data.title
        }));

        dispatch(setSchedTelemed(response.data.addDoctorSchedule.status ? "success" : "error"))

    } catch (e) {
        dispatch(setSchedTelemed("error"))
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
        return e;
    }

};

export const getCaseHistory = () => async(dispatch, getState) => {
    const token = await getCurrentUserToken();

    try {
        const response = await API.graphql(graphqlOperation(API_SCHEMA_QUERIES.getTicketHistory, {
            token: token,
            ticketId: getState().patientOverview.ticketId
        }));

        dispatch(setCaseHistory(response.data.getTicketHistory.history))

    } catch (e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
    }

};