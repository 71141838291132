export const SET_TICKET_INFO = "GET_TICKET_INFO";
export const SET_DOCTOR_INFO = "GET_DOCTOR_INFO";
export const SET_PATIENT_SETTINGS_INFO = "GET_PATIENT_SETTINGS_INFO";
export const SET_COMMENTS = "SET_COMMENTS";
export const SET_NEW_COMMENT = "SET_NEW_COMMENT";
export const SET_PROGRESS_NOTE= "SET_PROGRESS_NOTE";
export const SET_TICKET_ID = "SET_TICKET_ID";
export const SET_PATIENT_CHR = "SET_PATIENT_CHR";
export const SET_PRESCRIPTION = "SET_PRESCRIPTION";
export const UPDATE_PRESCRIPTION_STATUS = "UPDATE_PRESCRIPTION_STATUS";
export const CREATE_PATIENT_TICKET = "CREATE_PATIENT_TICKET";
export const SET_NOTE = "SET_NOTE";
export const GET_NOTES = "GET_NOTES";
export const NOTE_CREATION_STATUS = "NOTE_CREATION_STATUS";
export const SET_PRESCRIPTION_LIST = "SET_PRESCRIPTION_LIST";
export const SET_TELEDOCS_LIST = "SET_TELEDOCS_LIST";
export const VIEW_TELMED_DOC = "VIEW_TELMED_DOC";
export const SET_DOC_UPLOAD_STATUS = "SET_DOC_UPLOAD_STATUS";
export const GET_SYMPTOMS_HISTORY = "GET_SYMPTOMS_HISTORY";
export const GET_PATIENT_DOCUMENTS = "GET_PATIENT_DOCUMENTS";
export const OPEN_PATIENT_DOC_LINK = "OPEN_PATIENT_DOC_LINK";
export const GET_DOC_ADDED_NOTES = "GET_DOC_ADDED_NOTES";
export const DOC_NOTE_ADDED = "DOC_NOTE_ADDED";
export const NOTIFY_MESSAGE= "NOTIFY_MESSAGE";
export const CREATE_PRES_STATUS = "CREATE_PRES_STATUS";
export const NOTIFY_MESSAGE_CASE = "NOTIFY_MESSAGE_CASE";