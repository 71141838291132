import {Link} from "react-router-dom";
import React from "react";
import "./SideMenu.css";

const SideMenuItem = ({linkTo, label, IconToDisplay, onClickHandler, additionalClasses}) => {
    return (
        <span className={additionalClasses}>
            <figure className="fgrSidemenu" onClick={onClickHandler}>
                {onClickHandler === null ?  <Link to={linkTo}>
                    <IconToDisplay className='sideMenuIcon' fontSize='large' />
                    <figcaption>{label}</figcaption>
                </Link> : <>
                    <IconToDisplay className='sideMenuIcon' fontSize='large' />
                    <figcaption>{label}</figcaption></>}
            </figure>
        </span>)
};

export default SideMenuItem;