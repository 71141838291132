import {
    GET_MEDICATIONS,
    GET_CONDITIONS
} from "../actions/medsCondsActions";
import {setMessage} from "./messageActionCreator";
import {setLoader} from "./loaderActionCreator";
import {getCurrentUserToken } from "../../utils";


export const getMedications = (data) => ({ type: GET_MEDICATIONS, payload: data });
export const getConditions = (data) => ({ type: GET_CONDITIONS, payload: data });

export const getMedicationList = () => async (dispatch) => {
    await getCurrentUserToken();
    dispatch(setLoader(true));
    await fetch("https://drowl-documents.s3-us-west-2.amazonaws.com/med_titles.txt" )
        .then((response) => response.text())
        .then(text => {
            const medsList = [];
            text.split("\n").map(str => medsList.push(str.replace("\r", "")));
            dispatch(getMedications(medsList));
            dispatch(setLoader(false));
        })
        .catch(err => {
                dispatch(setMessage({ text: err.message ?? "There was error getting medications.",
                    type: "error", show: true }));
                dispatch(setLoader(false));
            }
        );
};

export const getConditionsList = () => async (dispatch) => {
    await getCurrentUserToken();
    dispatch(setLoader(true));
    await fetch("https://drowl-documents.s3-us-west-2.amazonaws.com/icd_topics_list.txt")
        .then((response) => response.text())
        .then(text => {
                const conditionList = [];
                text.split("\n").map(str => conditionList.push(str));
                dispatch(getConditions({conditionList: conditionList }));
                dispatch(setLoader(false));
            }
        )
        .catch(err => {
                dispatch(setMessage({ text: err.message ?? "There was error getting conditions.",
                    type: "error", show: true }));
                dispatch(setLoader(false));
            }
        );
};