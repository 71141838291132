import React, {useState, useEffect} from  'react';
import {Button } from "@mui/material";
import RowComponent from "./RowComponent";
import {
    createNewPatient,
    setPatientMessage
} from "../../ducks/actionCreators/createPatientActionCreator";
import {useDispatch, useSelector} from "react-redux";
import {validateSAIdNumber} from "../../utils/utlis";
import {ETHNICITY_LIST, GENDER_LIST} from "../../utils/constants";
import {Alert} from "@mui/lab";
import {Loader} from "@drowl/drowl-react-library";

const CreatePatient = () => {

    const initPatientInfo = {
        idNumber: "",
        firstName: "",
        middleName: "",
        lastName: "",
        email: "",
        phoneNumber:"",
        dob: "",
        postalCode: "",
        gender: "",
        ethnicity: ""
    }

    const initErrors = {
        idNumber: false,
        firstName: false,
        middleName: false,
        lastName: false,
        email: false,
        phoneNumber:false,
        dob: false,
        postalCode: false,
        gender: false,
        ethnicity:false
    }

    const [patientInfo, setPatientInfo] = useState(initPatientInfo);
    const [isIdValid, setIdValid] = useState(false);
    const loader = useSelector((state) => state.loader);

    const [errors, setErrors] = useState(initErrors);

    const changeHandler = (event, field) => {
       const tempInfo = {...patientInfo};
        tempInfo[field] = event.target.value;
        setPatientInfo({...tempInfo});
        const tempErrs = {...errors};
        tempErrs[field] = false;
        setErrors(tempErrs);
    }

    const validateId = () => {
        const res = validateSAIdNumber(patientInfo.idNumber);
        const temp = {...patientInfo}
        if(res.isValid) {
            setIdValid(true);
            temp.gender = res.gender;
            temp.dob= res.dob;
        }
        setPatientInfo(temp);
    }

    const dispatch = useDispatch();

    const patientCreated = useSelector(state => state.newPatient);

    const { alert } = patientCreated;

    useEffect(() => {
        dispatch(setPatientMessage({}));
    }, [])

    const createPatientNew = () => {

        if(patientInfo.firstName && patientInfo.lastName && patientInfo.dob && patientInfo.gender && patientInfo.ethnicity && patientInfo.postalCode) {
            dispatch(createNewPatient(patientInfo));
        }
        else {
            const tempErrs = {...errors};
            if(isIdValid && !patientInfo.firstName) {
                tempErrs.firstName = true;
            }
            if(isIdValid &&  !patientInfo.lastName) {
                tempErrs.lastName = true;
            }
            if(isIdValid &&  !patientInfo.dob) {
                tempErrs.dob = true;
            }
            if(isIdValid &&  !patientInfo.gender) {
                tempErrs.gender = true;
            }
            if(isIdValid && !patientInfo.ethnicity) {
                tempErrs.ethnicity = true;
            }
            if(isIdValid && !patientInfo.postalCode) {
                tempErrs.postalCode = true;
            }
            setErrors(tempErrs);
        }

    }

    const clearFields = () => {
        setPatientInfo(initPatientInfo);
        setErrors(initErrors)
    }

    return (
        <div className="pageWindow">
            <> {loader.loading && <Loader /> } </>
            <h1 style={{color: "grey", paddingBottom: "30px"}}>
                Create Patient
            </h1>

            {alert.show && <Alert severity={alert.type} style={{width: "90%", marginBottom: "20px" }} onClose={() => {
                dispatch(setPatientMessage({
                    show: false,
                    type: "",
                    message: ""}))
                clearFields()
            }}> {alert.message}</Alert> }

            <RowComponent
                label="ZA ID Number"
                required
                placeholder="YYMMDDSSSSCAZ"
                onChange={() => changeHandler(event, "idNumber")}
                value={patientInfo.idNumber}
                onBlur={validateId}
                error={errors.idNumber === true}
            />
            <RowComponent
                label="First Name"
                required
                onChange={() => changeHandler(event, "firstName")}
                value={patientInfo.firstName}
                error={errors.firstName === true}
                disabled={!isIdValid}
                helperText={errors.firstName && "This is a required field."}
                 />
            <RowComponent
                label="Middle Name"
                onChange={() => changeHandler(event, "middleName")}
                value={patientInfo.middleName}
                disabled={!isIdValid}
            />
            <RowComponent
                label="Last Name"
                required
                onChange={() => changeHandler(event, "lastName")}
                value={patientInfo.lastName}
                error={errors.lastName}
                disabled={!isIdValid}
                helperText={errors.lastName && "This is a required field."}
            />
            <RowComponent
                label="Email"
                onChange={() => changeHandler(event, "email")}
                value={patientInfo.email}
                disabled={!isIdValid}
            />
            <RowComponent
                label="Mobile Number"
                onChange={() => changeHandler(event, "phoneNumber")}
                value={patientInfo.phoneNumber}
                disabled={!isIdValid}
            />
            <RowComponent
                label="Date of Birth"
                required
                onChange={() => changeHandler(event, "dob")}
                value={patientInfo.dob}
                error={errors.dob}
                disabled={!isIdValid}
                helperText={errors.dob && "This is a required field."}
            />
            <RowComponent
                label="Postal Code"
                required
                onChange={() => changeHandler(event, "postalCode")}
                value={patientInfo.postalCode}
                error={errors.postalCode}
                disabled={!isIdValid}
                helperText={errors.postalCode && "This is a required field."}
            />
            <RowComponent
                type="dropdown"
                label="Gender"
                required
                onChange={() => changeHandler(event, "gender")}
                value={patientInfo.gender}
                options={GENDER_LIST}
                error={errors.gender}
                disabled={!isIdValid}
                helperText={errors.gender && "This is a required field."}
            />
            <RowComponent
                options={ETHNICITY_LIST}
                type="dropdown"
                label="Ethnicity"
                required
                onChange={() => changeHandler(event, "ethnicity")}
                value={patientInfo.ethnicity}
                error={errors.ethnicity}
                disabled={!isIdValid}
                helperText={errors.ethnicity && "This is a required field."}
            />
            <div style={{paddingBottom: "30px"}}></div>
            <Button variant="contained" disabled={!isIdValid} onClick={ () => createPatientNew()}>Continue</Button>
            <Button variant="contained" onClick= {() => clearFields()}>Reset </Button>
        </div>
    )
}
export default CreatePatient;