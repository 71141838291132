import {
    SET_TICKET_INFO,
    SET_PATIENT_SETTINGS_INFO,
    SET_DOCTOR_INFO,
    SET_PROGRESS_NOTE,
    SET_COMMENTS,
    SET_NEW_COMMENT,
    SET_TICKET_ID,
    SET_PATIENT_CHR,
    SET_PRESCRIPTION,
    UPDATE_PRESCRIPTION_STATUS,
    CREATE_PATIENT_TICKET,
    SET_NOTE,
    GET_NOTES,
    NOTE_CREATION_STATUS,
    SET_PRESCRIPTION_LIST,
    SET_TELEDOCS_LIST,
    VIEW_TELMED_DOC,
    SET_DOC_UPLOAD_STATUS, GET_SYMPTOMS_HISTORY, GET_PATIENT_DOCUMENTS, OPEN_PATIENT_DOC_LINK,
    GET_DOC_ADDED_NOTES,
    DOC_NOTE_ADDED, NOTIFY_MESSAGE,
    CREATE_PRES_STATUS,
    NOTIFY_MESSAGE_CASE
} from "../actions/patientOverviewActions";

const init = {
    comments: [],
    initComments: [{ "full_name": "Dr Owl",
        "image": "https://images.drowl.com/telemedicine_images/providers/aProfile_admin@drowl.com.png",
        "email": "admin@drowl.com",
        "date": "2020-11-06T19:37:41.258Z",
        "comment": "<p>How can we help you today?</p>\n",
        "username": "admin@drowl.com"
    },
        { "full_name": "Josie James",
            "image": "https://images.drowl.com/740d8952fbe242675b6401e3562ab346/profile_0.jpg",
            "email": "testaccount8@drowl.com",
            "date": "2020-11-06T19:37:46.672Z",
            "comment": "<p>Hi</p>\n",
            "username": "0a0180b5-fe06-41e9-8bc9-3206ceeb0e4c"
        },
        { "full_name": "Dr Owl",
            "image": "https://images.drowl.com/telemedicine_images/providers/aProfile_admin@drowl.com.png",
            "email": "admin@drowl.com", "date": "2020-11-06T19:37:58.980Z",
            "comment": "<p>hi</p>\n",
            "username": "admin@drowl.com"
        },
        { "full_name": "Josie James",
            "image": "https://images.drowl.com/740d8952fbe242675b6401e3562ab346/profile_0.jpg",
            "email": "testaccount8@drowl.com",
            "date": "2020-11-06T19:38:08.804Z",
            "comment": "<p>Hi</p>\n",
            "username": "0a0180b5-fe06-41e9-8bc9-3206ceeb0e4c"
        }],
    isNewComment: false,
    progressNote: {},
    ticketId: null,
    ticket: {},
    patientInfo: {},
    patientCHR:  null,
    prescriptionResp: null,
    updatePrescStatus:null,
    noteCreationStatus: null,
    createPatientTicketResp: null,
    docAddedNoteStatus: null,
    noteResp: null,
    notes: {},
    prescriptionsList: [],
    teleDocsList: [],
    teleMedDoc: null,
    upldDocStatus: null,
    symptomsHistory: [],
    patientDocuments: [],
    patientDocumentLink: null,
    doctorAddedNotes: [],
    messageToNotify: null,
    prescStatus: false,
    newCommentReceived: null
};

const patientOverviewReducer = (state = init, action) => {
    switch (action.type) {
        case SET_TICKET_INFO: {
            return { ...state, ticket: action.payload };
        }
        case SET_PATIENT_SETTINGS_INFO: {
            return { ...state, patientInfo: action.payload };
        }
        case SET_NEW_COMMENT: {
            return { ...state, isNewComment: action.payload };
        }
        case SET_COMMENTS: {
            return { ...state, comments: action.payload };
        }
        case SET_PROGRESS_NOTE: {
            return { ...state, progressNote: action.payload };
        }
        case SET_TICKET_ID: {
            return { ...state, ticketId: action.payload };
        }
        case SET_PATIENT_CHR: {
            return { ...state, patientCHR: action.payload };
        }
        case SET_PRESCRIPTION: {
            return { ...state, prescriptionResp: action.payload };
        }
        case SET_NOTE: {
            return { ...state, noteResp: action.payload };
        }
        case UPDATE_PRESCRIPTION_STATUS: {
            return { ...state, updatePrescStatus: action.payload };
        }
        case NOTE_CREATION_STATUS: {
            return { ...state, noteCreationStatus: action.payload };
        }
        case CREATE_PATIENT_TICKET: {
            return { ...state, createPatientTicketResp: action.payload };
        }
        case GET_NOTES: {
            return { ...state, notes: action.payload };
        }
        case SET_PRESCRIPTION_LIST: {
            return { ...state, prescriptionsList: action.payload };
        }
        case SET_TELEDOCS_LIST: {
            return { ...state, teleDocsList: action.payload };
        }
        case VIEW_TELMED_DOC: {
            return { ...state, teleMedDoc: action.payload };
        }
        case SET_DOC_UPLOAD_STATUS: {
            return { ...state, upldDocStatus: action.payload };
        }
        case GET_SYMPTOMS_HISTORY: {
            return {...state, symptomsHistory: action.payload};
        }
        case GET_PATIENT_DOCUMENTS: {
            return {...state, patientDocuments: action.payload};
        }
        case OPEN_PATIENT_DOC_LINK: {
            return {...state, patientDocumentLink: action.payload};
        }
        case GET_DOC_ADDED_NOTES: {
            return {...state, doctorAddedNotes: action.payload};
        }
        case DOC_NOTE_ADDED: {
            return {...state, docAddedNoteStatus: action.payload};
        }
        case NOTIFY_MESSAGE: {
            return {...state, messageToNotify: action.payload};
        }
        case CREATE_PRES_STATUS: {
            return { ...state, prescStatus: action.payload };
        }
        case NOTIFY_MESSAGE_CASE: {
            return { ...state, newCommentReceived: action.payload};
        }
        default:
            return state;
    }
};

export default patientOverviewReducer;