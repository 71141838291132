import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Avatar, Divider} from "@mui/material";
import {useParams} from "react-router-dom";

const DoctorInfo = ({title, hideInfo, type}) => {

    const statsData = useSelector(state => state.statsReducer);

    const {doctorList} = statsData;
    // statsData &&  statsData.doc
    const urlParams = useParams();

    const [activeDoc, setActiveDoc] = useState([]);

    useEffect(() => {
       const doc =  doctorList.filter((list) => list.id === urlParams.doctorId);

        setActiveDoc(doc);
    }, []);


   return (
       <>
           {/*<Divider />*/}
           <h3> {type} REPORTS - {title} </h3>
           <div style={{paddingBottom: "5px"}}></div>
           {type !== "DOCTOR" && activeDoc.length >0 && <div className="doctorInfo">
               <div className="avatarInfo">
                   {!hideInfo &&
                       <a href={`/viewDocDetails/${activeDoc[0].id}`} >
                       <Avatar src="" style={{ marginTop: "auto", marginBottom: "5px"}} sx={{ width: 60, height: 60 }}/>
                   </a>
               }
                   {!hideInfo && <h4 style={{ marginTop: "auto", marginBottom: "auto"}}>{`${activeDoc[0].name} ${activeDoc[0].family_name}`}</h4> }
               </div>

               <div className="doctorDetail">
                   {/*<Divider />*/}
                   {/*<div style={{paddingTop: "5px"}}></div>*/}
                   {/*<h3 style={{float: "left"}}> {title} </h3>*/}
                   {/*<div style={{paddingBottom: "55px"}}></div>*/}
                   {/*<Divider />*/}
                   {!hideInfo && <div style={{display: "flex", marginTop: "30px", marginBottom: "30px", textAlign: "center"}}>
                       <div style={{flex: "1"}}> <div style={{display: "flex", flexDirection: "column"}}><b>Number of Visits</b><div>{`${activeDoc[0].num_visits}`}</div> </div></div>
                       <div style={{flex: "1"}}> <div style={{display: "flex", flexDirection: "column"}}><b>Amount Owed</b><div>{`R ${activeDoc[0].num_owed}`}</div> </div></div>
                       <div style={{flex: "1"}}><div style={{display: "flex", flexDirection: "column"}}><b>Amount Paid</b> <div>{`R ${activeDoc[0].amt_paid}`}</div> </div></div>

                   </div> }
               </div>
</div> }
           {!hideInfo && <Divider /> }
       </>

       )
}

export default DoctorInfo;