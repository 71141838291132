import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import React from "react";
import {Button, Checkbox, FormControlLabel} from "@mui/material";

const PlanMedTableWeb = ({columnHeaders, assessmentMedications, selectedMedications, handleCheckboxSelection}) => {

    return ( <Table id="medicationTable" sx={{ width : "20 ch !important", border: "1px solid grey !important", borderBottom: "1px solid grey !important", borderCollapse: "revert"}}>
        <TableHead>
            <TableRow>
                {columnHeaders && columnHeaders.map((col, index) => {
                    return (
                        <TableCell key={index} style={{ fontWeight: "bolder" }}>
                            {col}
                        </TableCell>)
                })}
            </TableRow>
        </TableHead>

        <TableBody >
            { assessmentMedications && assessmentMedications.length> 0 && assessmentMedications.map((medication, index) => {
                return (
                    <TableRow key={index}>
                        <TableCell >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={!!selectedMedications[index]}
                                        onChange={(e) => handleCheckboxSelection(e,index)}
                                        disabled = {!!medication && medication.prescription_id}
                                    />
                            }
                            />

                        </TableCell>
                        <TableCell >{medication && medication.medication}</TableCell>
                        <TableCell >{medication && medication.dosage}</TableCell>
                        <TableCell >{medication && medication.numPills}</TableCell>
                        <TableCell>{medication && medication.instructions}</TableCell>
                        <TableCell>{medication && medication.prescription_id}</TableCell>
                        {/*<TableCell><Button*/}
                        {/*    variant="contained" onClick={ () => deleteMedication() } endIcon={<DeleteIcon />}*/}
                        {/*    disabled = {medication && medication.prescription_id}*/}
                        {/*>*/}
                        {/*</Button>*/}
                        {/*</TableCell>*/}
                    </TableRow>
                )})
            }
        </TableBody>
    </Table> ) }

export default PlanMedTableWeb;

// import * as React from 'react';
// import { DataGrid } from '@mui/x-data-grid';

// const columns = [
//     // { field: 'id', headerName: 'ID', width: 70 },
//     { field: 'medication', headerName: 'Medication', width: 250 },
//     { field: 'dosage', headerName: 'Dosage', width: 90 },
//     { field: 'numPills', headerName: 'Quantity', width: 90 },
//     { field: 'instructions', headerName: 'Notes', width: 250 },
//     {
//         field: 'prescription_id',
//         headerName: 'Rx',
//         width: 90,
//         // valueGetter: (params) =>
//         //     `${params.row.firstName || ''} ${params.row.lastName || ''}`,
//     },
// ];
//
// export default function PlanMedTableWeb({assessmentMedications, setSelectedMedications}) {
//     return (
//         <div style={{ height: 400, width: '100%' }}>
//             <DataGrid
//                 rows={assessmentMedications}
//                 columns={columns}
//                 pageSize={5}
//                 rowsPerPageOptions={[5]}
//                 checkboxSelection
//                 onSelectionModelChange={(ids) => {
//                     const selectedIDs = new Set(ids);
//                     const selectedRows = assessmentMedications.filter((row) =>
//                         selectedIDs.has(row.id)
//                     );
//                     setSelectedMedications(selectedRows);
//                 }}
//             />
//         </div>
//     );
// }
