import React, { useState, useEffect } from "react";
import "../Stats.css";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../Table/index";
import {getDoctorList, searchDoctor} from "../../../ducks/actionCreators/docStatsActionCreator";
import {Button, IconButton, InputAdornment, MenuItem, Select, TablePagination, TextField} from "@mui/material";
import SelectionList from "./SelectionList";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DoctorInfo from "./DoctorInfo";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import moment from "moment";

const SORT_BY_FIELDS = [
    { label: "First Name", value: "First Name" },
    { label: "Last Name", value: "Last Name" },
    { label: "Number of Visits", value: "Number of Visits"},
    { label: "Amount Owed", value: "Amount Owed"},
    { label: "Amount Paid", value: "Amount Paid"},
];

const FILTER_BY_FIELDS = [
    {label: "new_account" , value: "new_account"},
    {label: "approved" , value: "approved"},
    {label: "denied", value: "denied"},
    {label: "pending", value:"pending"},
    {label: "all", value: "all"}
];
const DEFAULT_DATE_RANGE = [moment().subtract(29, "days").toDate(), moment().toDate()];

const DoctorsList = () => {
    const [rows, setRows] = useState([]);
    const [sortValue, setSortField] = useState("First Name") ;
    const [searchText, setSearchText] = useState([]) ;
    const [filterValue, setFilterValue] = useState(FILTER_BY_FIELDS[3].label);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortOrder, setSortOrder] = useState("asc");
    const [sortBy, setSortBy] = useState("Number of Visits");
    const [rowHeaders, setRowHeaders] = useState([]);


    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [showSubFilterOptions, setShowSubFilterOptions] = useState(false);

    const statsData = useSelector(state => state.statsReducer);

    const {doctorList, searchList} = statsData;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const columnHeaders = ["Doctor", <>Onboarding Status <Button
        size="large"
        style={{float: "right", marginLeft: "-40px", marginTop: "-10px"}}
        startIcon={<FilterAltIcon />}
        onClick={() => {
            setShowSubFilterOptions(!showSubFilterOptions);
        }}>
    </Button>
        {showSubFilterOptions && <SelectionList
        type="filter"
        classname="dropdown"
        options={FILTER_BY_FIELDS} selectedValue={filterValue} setSelectedValue={(e) => setFilterValue(e.target.value)}
        defaultTitle="FILTER BY"/> }
    </>, "Number of Visits", "Amount Owed (Current)", "Amount Paid (This year)" ];

    let rowHdrs = ["doctor", "user_reg_status", "num_visits", "num_owed", "amt_paid"];
    const alignRows = ["left", "center", "center", "right", "right"];

    const dispatch = useDispatch();


    useEffect(() => {
        const params = {
                    from_date: moment(DEFAULT_DATE_RANGE[0]).format("YYYY-MM-DD"),
                    to_date: moment(DEFAULT_DATE_RANGE[1]).format("YYYY-MM-DD"),
                };
                dispatch(getDoctorList(params));
    }, [])

    useEffect(() => {
        let result;
        if(filterValue === "all") {
            result = doctorList;
        }
        else {
            result = doctorList.filter((list) => list.user_reg_status === filterValue);
        }
        setRowHeaders(rowHdrs);
        setRows(result);
    }, [filterValue, doctorList])

    useEffect( () => {
        if(searchList && searchList.length>0){
            setRowHeaders(["doctor", "user_reg_status", "num_visits", "amt_owed", "amt_paid"]);
            setRows(searchList);
        }
    }, [searchList]);


    const searchForDoc = () => {
        dispatch(searchDoctor({keywords:searchText}));
    }

    const sortData = (sortBy, sortOrder, rowsToSort) => {
        let itemsToSort = [...rowsToSort];
        let sortedItems = [];
        let compareFn ;

        switch (sortBy) {
            case "First Name":
                compareFn = (i, j) => {
                    if (i.name < j.name) {
                        return sortOrder === "asc" ? -1 : 1;
                    } else {
                        if (i.name > j.name) {
                            return sortOrder === "asc" ? 1 : -1;
                        } else {
                            return 0;
                        }
                    }
                };
                break;
            case "Last Name" :
                compareFn = (i, j) => {
                    if (i.family_name < j.family_name) {
                        return sortOrder === "asc" ? -1 : 1;
                    } else {
                        if (i.family_name > j.family_name) {
                            return sortOrder === "asc" ? 1 : -1;
                        } else {
                            return 0;
                        }
                    }
                };
                break;
            case "Number of Visits" :
                compareFn = (i, j) => {
                    if (Number(i.num_visits) < Number(j.num_visits)) {
                        return sortOrder === "asc" ? -1 : 1;
                    } else {
                        if (Number(i.num_visits) > Number(j.num_visits)) {
                            return sortOrder === "asc" ? 1 : -1;
                        } else {
                            return 0;
                        }
                    }
                };
                break;
            case "Amount Owed" :
                compareFn = (i, j) => {
                    if (i.num_owed < j.num_owed) {
                        return sortOrder === "asc" ? -1 : 1;
                    } else {
                        if (i.num_owed > j.num_owed) {
                            return sortOrder === "asc" ? 1 : -1;
                        } else {
                            return 0;
                        }
                    }
                };
                break;
            case "Amount Paid" :
                compareFn = (i, j) => {
                    if (i.amt_paid < j.amt_paid) {
                        return sortOrder === "asc" ? -1 : 1;
                    } else {
                        if (i.amt_paid > j.amt_paid) {
                            return sortOrder === "asc" ? 1 : -1;
                        } else {
                            return 0;
                        }
                    }
                };
                break;
            default:
                break;
        }
        sortedItems = itemsToSort.sort(compareFn);
        return sortedItems;

    };

    const requestSort = (pSortBy, rowsToSort) => {
        let sortingOrder;
        let sortedBy;

        if (sortBy === sortValue) {
            sortingOrder = sortOrder === "asc" ? "desc" : "asc";
        } else {
            sortedBy = sortValue;
            sortingOrder = "asc";
        }

        const sortedItems = sortData(sortBy, sortOrder, rows);
        setSortBy(sortedBy);
        setSortOrder(sortingOrder);
        setRows(sortedItems);
    };

    return (
        <>
            <DoctorInfo type="ADMIN" title="LIST OF DOCTORS" hideInfo={true}/>
            <br/>
            <Button
                size="large"
                style={{float: "right"}}
                startIcon={<FilterAltIcon />}
                onClick={() => {
                setShowFilterOptions(!showFilterOptions);
            }}>
            </Button>

            {showFilterOptions &&
                <>
                    <SelectionList
                        classname="dropdown"
                        options={SORT_BY_FIELDS}
                        selectedValue={sortValue}
                        setSelectedValue={(e) => setSortField(e.target.value)}
                        defaultTitle="Sort By"
                        sortField={requestSort}
                        sortOrder={sortOrder}
                        sortBy={sortBy}
                    />
                    <TextField
                        className="searchBox"
                        placeholder="Find a Doctor"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end"> <IconButton onClick={searchForDoc}> <PersonSearchIcon /> </IconButton> </InputAdornment>,
                        }}
                    />
                   <SelectionList
                       type="filter"
                       classname="dropdown"
                       options={FILTER_BY_FIELDS} selectedValue={`Filter Status By: ${filterValue}`} setSelectedValue={(e) => setFilterValue(e.target.value)}
                       defaultTitle="FILTER BY"/>
                </>
            }
            <Table
                columnHeaders={columnHeaders}
                rowHeaders={rowHeaders}
                rows={rows}
                page={page}
                alignRows={alignRows}
                rowsPerPage={rowsPerPage}
                requestSort={requestSort}
                sortOrder={sortOrder}
                sortBy={sortBy}
                rowClick={true}
            />
            {rows && rows.length > 0 && <TablePagination
                className="tableContainer"
                rowsPerPageOptions={[10, 15]}
                component="div"
                count={rows && rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            /> }
        </>
    )
}

export default DoctorsList;