import React, {useState, useEffect} from "react";
import {Stepper, Step, StepLabel, Box, Alert, Button, TextField, FormControlLabel, FormHelperText, FormControl, Tooltip} from "@mui/material";
import {useSelector, useDispatch} from "react-redux";
import {
    setStep
} from "../../ducks/actionCreators/loginActionCreator";
import {setMessage} from "../../ducks/actionCreators/messageActionCreator";
import Settings from "./Settings";
import FileUpload from "./FileUpload";
import {getProfileImg, isProfileDataValid} from "../../ducks/actionCreators/settingsActionCreator";
import {DOCUMENTS_LIST} from "../../utils/constants";

const SettingsTabView = () => {

    const [requiredProfileData, setRequiredProfileData] = useState("");

    const dispatch = useDispatch();
    const data = useSelector((state) => state.login);
    const settingsData = useSelector((state) => state.settings);

    // const navigate = useNavigate();
    //
    // const {profileInfo} = settingsData;

    const steps = [
        "Profile Settings",
        "Create request for access"
    ];

    // useEffect(() => {
    //     dispatch(isUserLoggedIn());
    // }, []);


    useEffect(() => {
        dispatch(setMessage({text: "", type: "", show: false}));
        dispatch(setStep(0));
    }, [dispatch]);

    const onPreviousButtonClick = () => {
        dispatch(isProfileDataValid(false ));
        dispatch(setStep(0));

    }

    //const isEmpty = settingsData && settingsData.profileInfo.user_reg_status !== "approved" && requiredProfileData && Object.keys(requiredProfileData).length > 0 && Object.values(requiredProfileData).some(x => x === null || x === '');

    return (
        <>
            <div>
                <div>
                    {/*<div> {messageData.message.show && <NotificationMessage type={messageData.message.type} text={messageData.message.text} /> } </div>*/}
                    <Box sx={{ width: "100%" }}>
                        <Stepper activeStep={data.activeStep} alternativeLabel>
                            {steps.map(label => (
                                <Step sx={{
                                    "& .MuiStepLabel-root .Mui-completed": {
                                        color: "#3daf2c !important"
                                    },
                                    "& .MuiStepLabel-root .Mui-active": {
                                        color: "#3daf2c !important"
                                    }
                                }} key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>

                    <br/>

                    { data.activeStep === 0 &&
                        <Box sx={{ width: "100%", marginLeft: "auto", marginRight: "auto"}}>
                            <br/>
                            <div style={{width : "60%", marginLeft: "auto",  marginRight: "auto"}} >
                                <b>  Eagle Intelligent Health is an EMR and telemedicine technology that is FREE for the Physicians to use*. </b>
                                <br/>
                                <b>You do not have access to the Eagle Intelligent Health EMR system at this time.
                                    In order to obtain access, please fill out the required fields below and then add the required documents. Once we verify that you are a licensed physician in South Africa, you will be given access.  We will send you an email when there is a change in your status.
                                   </b>
                                <br/>
                                <b>* Subject to Our Terms and Conditions</b>
                            <br/>
                            <br/>
                            </div>
                            <Settings view="physicianProfile"/>
                            <br/><br/>
                            {/*<Button style={{fontSize: "16px", color: "#3daf2c", fontWeight: "bold"}} onClick={() => navigate(0)}> Login </Button>*/}
                        </Box> }

                    { data.activeStep === 1 && <Box sx={{ width: "90%", marginLeft: "auto", marginRight: "auto"}}>
                        <FileUpload
                            documentList={DOCUMENTS_LIST}
                            buttonTitle="Submit Request for Access"
                        />
                        {/*<Button*/}
                        {/*    style={{float: "right", backgroundColor: "#3daf2c", color: "white" , fontWeight: "bold"}}*/}
                        {/*    variant="contained"*/}
                        {/*    onClick={onPreviousButtonClick}> Previous </Button>*/}
                        <br/><br/>
                    </Box> }
                </div>
            </div>
        </>
    );
};
export default SettingsTabView;