import React, {useState, useEffect} from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Grid} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
    updateProgressNote
} from "../../ducks/actionCreators/patientOverviewActionCreator";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";


const ConditionsModal = ({handleAddConditions, showAddConditions, icdOptions, filterOptionsIcd, isClosed}) => {

    const [conditions, setConditions] = useState({
        condition : "",
        notes: ""
    });
    const [assessmentConditions, setAssessmentConditions] = useState([]);

    // const [showAddMedications, setShowAddMedications] = useState(false);

    const ticketID = useParams();
    const dispatch = useDispatch();
    const patientOverviewData = useSelector(state => state.patientOverview);

    let { ticket, progressNote} = patientOverviewData;

    useEffect(() => {
        setAssessmentConditions(progressNote && progressNote.Conditions || []);
    }, [progressNote]);

    const updatedAssessmentConditions = (updatedData) => {
        dispatch(updateProgressNote(updatedData, ticket._id));
    };


    const handleAddCondButtonClick = async() => {
        const updatedData = [...assessmentConditions, conditions];
        let assessmentUpdate = {
            ...progressNote,
            'Conditions': updatedData,
        };

        try {
            await updatedAssessmentConditions(assessmentUpdate);
            handleAddConditions();
        } catch (e) {
            console.error(e);
            return;
        }
    }

    return (
        <Dialog open={showAddConditions} className="assessmentModal">
            <DialogTitle>
                Add Condition
                <IconButton
                    onClick={()=> handleAddConditions()}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>

                {/*{noteError &&  <Alert severity="error" style={{width: "94%"}} onClose={() => setNoteError(false)}>*/}
                {/*    Please fill in the fields to continue.*/}
                {/*</Alert> }*/}

                <div >
                    <div>
                       <div className='assessmentModal-formGroup'>
                            <div className='assessmentModal-fieldLabel' >Conditions:</div>
                           <Autocomplete
                                disabled={isClosed}
                                style={{ width: '90%' }}
                                value={conditions.condition}
                                freeSolo
                                options={icdOptions}
                                filterOptions={filterOptionsIcd}
                                getOptionLabel={(option) => option}
                                onBlur={(event) => setConditions({...conditions, condition : event.target.value})}
                                renderInput={(params) => <TextField {...params} className='progressNoteTextField' variant="outlined" />}
                            />
                        </div>

                    </div>
                    <div className='assessmentModal-formGroup'>
                        <div className='assessmentModal-fieldLabel' >Notes:</div>
                        <TextField
                            disabled={isClosed}
                            style={{ width: '90%' }}
                            variant="outlined"
                            value={conditions.notes}
                            minRows={5}
                            multiline={true}
                            onChange={(event) => setConditions({...conditions, notes : event.target.value})}
                        />
                    </div>
                </div>
                <div style={{marginBottom: "40px"}} />
            </DialogContent>

            <DialogActions>
                {!isClosed &&
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAddCondButtonClick()}>
                        Add
                    </Button>
                }
                <Button variant="contained" onClick={() => handleAddConditions()}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ConditionsModal;
