import React, {useState, useEffect} from "react";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import {DesktopDateRangePicker, LocalizationProvider} from "@material-ui/pickers";
import {Button, Grid, TextField} from "@mui/material";
import "../Stats.css";
import {getDoctorStats} from "../../../ducks/actionCreators/docStatsActionCreator";
import {useDispatch, useSelector} from "react-redux";
import {formatDateSlashes} from "../../../utils/utlis";
import {Charts} from "./Charts";
import LastFiveTeleMedPatients from "./LastFiveTeleMedPatients";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DoctorInfo from "./DoctorInfo"
// import 'bootstrap/dist/css/bootstrap.css';
// you will also need the css that comes with bootstrap-daterangepicker
import 'bootstrap-daterangepicker/daterangepicker.css';
import Table from "../../Table";
import {useNavigate} from "react-router-dom";
import {useParams} from "react-router-dom";

const DEFAULT_DATE_RANGE = [moment().subtract(29, "days").toDate(), moment().toDate()];

const OverviewStats = () => {

    const [dateRange, setDateRange] = useState({
        from_date: moment(DEFAULT_DATE_RANGE[0]).format("YYYY-MM-DD"),
        to_date: moment(DEFAULT_DATE_RANGE[1]).format("YYYY-MM-DD"),
        label: moment(DEFAULT_DATE_RANGE[0]).format("YYYY-MM-DD") + " to " + moment(DEFAULT_DATE_RANGE[1]).format("YYYY-MM-DD")
    });
    const [rows, setRows] = useState([]);

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const urlParams = useParams();

    const statsData = useSelector(state => state.statsReducer);

    const {stats} = statsData;

    const {tickets} = stats;

    // const handleDateRangeChange = (start, end) => {
    //     setDateRange({
    //         from_date: start.format("YYYY-MM-DD"),
    //         to_date: end.format("YYYY-MM-DD"),
    //         label: start.format("YYYY-MM-DD") + " to " + end.format("YYYY-MM-DD")
    //     });
    //     const params = {
    //         from_date: moment(dateRange.from_date).format("YYYY-MM-DD"),
    //         to_date: moment(dateRange.to_date).format("YYYY-MM-DD")
    //     };
    //     dispatch(getDoctorStats(params));
    // };

    useEffect( () => {
        const compareFn = (i, j) => (i.date - j.date)
        if(tickets && tickets.length>0){
            tickets.sort(compareFn).reverse();
            setRows(tickets);
        }
    }, [tickets]);

    useEffect(() => {
        const params = {
            from_date: moment(DEFAULT_DATE_RANGE[0]).format("YYYY-MM-DD"),
            to_date: moment(DEFAULT_DATE_RANGE[1]).format("YYYY-MM-DD"),
            assignee_id: urlParams.doctorId
        };
        dispatch(getDoctorStats(params));
    },[]);

    const columnHeaders = ["Patient", "Case Id", "Date", "Time", "Amount"];

    const rowHeaders = ["name", "case_id", "date", "time", "amount"];

    return (
        <div
            className="textBox pageWindow" id="statsWindow"
        >
            <DoctorInfo type="ADMIN" title="PROVIDER DETAILS"/>
            {/*<div*/}
            {/*    className="datePickerContainer"*/}
            {/*     style={{bottom: "30px !important", marginTop: "30px"}}*/}
            {/*>*/}
            {/*    <div style={{float: "left"}}>*/}
            {/*        <div style={{display: "flex"}}>*/}
            {/*            <div style={{marginLeft: "40px"}}></div>*/}
            {/*            <DateRangePicker*/}
            {/*                initialSettings={{*/}
            {/*                    startDate: moment(dateRange.from_date).toDate(),*/}
            {/*                    endDate: moment(dateRange.to_date).toDate(),*/}
            {/*                    ranges: {*/}
            {/*                        "Today": [moment().toDate(), moment().toDate()],*/}
            {/*                        "Yesterday": [*/}
            {/*                            moment().subtract(1, "days").toDate(),*/}
            {/*                            moment().subtract(1, "days").toDate()*/}
            {/*                        ],*/}
            {/*                        "Last 7 Days": [*/}
            {/*                            moment().subtract(6, "days").toDate(),*/}
            {/*                            moment().toDate()*/}
            {/*                        ],*/}
            {/*                        "Last 30 Days": [*/}
            {/*                            moment().subtract(29, "days").toDate(),*/}
            {/*                            moment().toDate()*/}
            {/*                        ],*/}
            {/*                        "This Month": [*/}
            {/*                            moment().startOf("month").toDate(),*/}
            {/*                            moment().endOf("month").toDate()*/}
            {/*                        ],*/}
            {/*                        "Last Month": [*/}
            {/*                            moment().subtract(1, "month").startOf("month").toDate(),*/}
            {/*                            moment().subtract(1, "month").endOf("month").toDate()*/}
            {/*                        ]*/}
            {/*                    }*/}
            {/*                }}*/}
            {/*                onCallback={ handleDateRangeChange} >*/}
            {/*                <div*/}
            {/*                    className="col-md-5 col-sm-6 form-control dateRangePicker"*/}
            {/*                >*/}
            {/*                    <span style={{marginRight: "10px"}}><DateRangeIcon /></span>*/}
            {/*                    <span>{dateRange.label}</span>*/}
            {/*                </div>*/}
            {/*            </DateRangePicker>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<br/><br/>*/}
            <Charts />
            <br/><br/>
            <h4>RECENT TICKETS</h4>
            <Table columnHeaders={columnHeaders} rowHeaders={rowHeaders} rows={rows} rowsPerPage={5} page={0}
                   alignRows="" rowClick={false}/>
            <Button onClick={() => navigate(`/lastestTickets/${urlParams.doctorId}`)}>Show More</Button>
            <br/>
            {/*{statsData && statsData.stats && statsData.stats.tickets.length >0 && <LastFiveTeleMedPatients /> }*/}
    </div>)
}

export default OverviewStats;