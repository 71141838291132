import React from 'react';
import './Login.css';
import LoginHeader from "../Header/LoginHeader";
import LoginTemplate from "./LoginTemplate";
import {Divider, Link} from "@mui/material";
import {useNavigate} from "react-router-dom";

const Login = () => {

    const navigate = useNavigate()

    const openFormInNewTab = (event) => {
        event.preventDefault();
        navigate("/requestDemo")
    };

    return (
        <>
            <LoginHeader />

            <br />

            <Divider style={{ border: "3px solid rgb(2, 163, 244)"}}/>

            <div className="loginBox">
                <div className="subLoginBox">
                    <span style={{flex: "3.5", marginRight: "30px", textAlign: "justify"}}>

                        <h3>Welcome to Eagle Intelligent Health!</h3>

                <br/>
                <h4>Revolutionizing Healthcare in South Africa</h4>

                <span className="h4" >Eagle Intelligent Health</span> is at the forefront of transforming healthcare in South Africa through our state-of-the-art telemedicine software and proprietary Electronic Health Records (EHR) system. Our innovative solutions bridge the gap between patients and healthcare providers, ensuring accessible, efficient, and high-quality care for everyone, regardless of location.
                <ul>
                    <li><span className="h4">Advanced Electronic Health Record and Telemedicine Technology</span></li>
                    <li><span className="h4">Wise-EHR Platform</span>: A comprehensive system integrating telemedicine, appointment management, and more. Doctors can conduct virtual consultations, enhancing accessibility and continuity of care.</li>
                    <li><span className="h4">On-Demand Appointments</span>: An "Uber for Doctors" model, where patients get medical appointments on demand and doctors receive
                        complete medical records for each visit.</li>
                </ul>
                    </span>
                    <span style={{flex: "3"}}>
                        <LoginTemplate />
                    </span>
                </div>

                <h4>Innovative Features for Doctors</h4>
                Our platform includes a suite of tools designed to support healthcare professionals:
                <ul>
                    <li><span className="h4">Electronic Sick Notes</span>: Issuing sick notes via virtual consultations, reducing clinic overcrowding and wait times.</li>
                    <li><span className="h4">Electronic Prescriptions</span>: Securely sending prescriptions directly to pharmacies, enhancing reliability and reducing delays.</li>
                </ul>
                <h4>Join Us in Transforming Healthcare</h4>

                Experience the future of healthcare with Eagle Intelligent Health. Our technology not only streamlines the workflow for doctors but also significantly enhances the patient experience, fostering a more connected and efficient healthcare ecosystem.


                <h4><a className="h4" href="#" onClick={openFormInNewTab}>Want to know more? Schedule a Demo </a></h4>


            </div>

        </>
    );
}

export default Login;
