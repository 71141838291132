import React, { useState } from 'react';

// Function to determine if there is a new message and if it's been viewed yet or not. 
export function useIsNewMessage() {
    const [isNewMessage, setIsNewMessage] = useState(false);

    // user viewed a new message
    const viewedNewMessage = () => {
        setIsNewMessage(false);
    };

    // There is a new message
    const newMessage = () => {
        setIsNewMessage(true);
    };

    return { isNewMessage, viewedNewMessage, newMessage };
}