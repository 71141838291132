import { SET_LOADER, SET_COMPONENT_LOADER, CASE_STATUS_LOADER, PROVIDER_STATUS_LOADER } from "../actions/loaderActions";
import {NEW_PASSWORD_REQUIRED, SET_CODE_DELIVERY_DETAILS} from "../actions/loginActions";

const init = {
	loading: false,
	componentLoading: false,
	caseStatusLoading: false,
	providerStatusLoading: false
};

const loaderReducer = (state = init, action) => {
	switch (action.type) {
		case SET_LOADER: {
			return { ...state, loading: action.payload };
		}
		case SET_COMPONENT_LOADER: {
			return { ...state, componentLoading: action.payload };
		}
		case CASE_STATUS_LOADER: {
			return { ...state, caseStatusLoading: action.payload };
		}
		case PROVIDER_STATUS_LOADER: {
			return { ...state, providerStatusLoading: action.payload };
		}
		case SET_CODE_DELIVERY_DETAILS : {
			return {...state, codeDeliveryDetails: action.payload};
		}
		case NEW_PASSWORD_REQUIRED:
			return {...state, isFirstLogIn: action.payload };
		default:
			return state;
	}
};

export default loaderReducer;