import React from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';

const NotificationBell = ({ notificationCount }) => {
    return (
        <Badge badgeContent={notificationCount} color="error">
            <NotificationsIcon />
        </Badge>
    );
};

export default NotificationBell;