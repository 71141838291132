import {
   SET_ZOOM_URL
} from "../actions/videoActions";

const init = {
    zoomUrl: ""
};

const videoReducer = (state = init, action) => {
    switch (action.type) {

        case SET_ZOOM_URL: {
            return {...state, zoomUrl: action.payload};
        }
        default:
            return state;
    }
};
export default videoReducer;