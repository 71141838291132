import React, { useEffect, useState } from 'react';
import {
    Button,
    Radio,
    FormControl,
    FormControlLabel,
    FormLabel,
    RadioGroup,
    Box,
    Typography,
    Modal,
    IconButton,
    Tabs,
    Tab,
    Grid,
    DialogTitle, DialogContent, DialogActions, Dialog
} from '@mui/material';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CloseIcon from '@mui/icons-material/Close';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import 'semantic-ui-css/semantic.min.css'
import './Case.css';
import { Alert } from '@mui/lab';
import {useSelector, useDispatch} from "react-redux";
import {
    getAvailableSlotsForScheduling,
    scheduleTelemed,
    setSchedTelemed, setSlotsToSchedule
} from "../../ducks/actionCreators/caseActionCreator";
import TabPanel from "../Cases/TabPanel";
import { useParams } from "react-router-dom";

function ScheduleTelemedicineModal(props) {

    const [timeSlotDuration, setTimeSlotDuration] = useState();
    const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
    const [tab, setTab] = useState(0);
    const [finalSlotBT, setFinalSlotBT] = useState({}); // the final slot that will be chosen
    const [slotBT, setSlotBT] = useState(null); // slot by time slot
    const [finalSlotBP, setFinalSlotBP] = useState({}); // the final slot that will be chosen
    const [slotBP, setSlotBP] = useState(null); // slot by provider

    const [alert, setAlert] = useState({ alert: '', severity: '' });
    const [isSubmitting, setIsSubmitting] = useState(false); // disable fields if submitting

    const caseData =  useSelector(state => state.case);

    const dispatch =  useDispatch();

    const {providers, slotSchedules, telemedSlotStatus} = caseData;
    const ticketID = useParams();

    // init provider select
    useEffect(() => {
        (async () => {
            if (props.showScheduleModal) {
                await getAvailability();
            } else {
                resetSelectedSlot();
                setIsSubmitting(false);
            }
        })()
    }, [props.showScheduleModal]);
    //
    // handle change in time slot radio button change for schedule telemedicine
    const handleTimeSlotChange = (e) => {
        setTimeSlotDuration(e.target.value);
    };
    //
    // // After time slot duration changes
    useEffect(() => {
        (async () => {
            if (timeSlotDuration) {
                resetSelectedSlot();
                await getAvailability();
            }
        })()

    }, [timeSlotDuration]);

    useEffect(() => {
        (async () => {
                await getAvailability();
        })()

    }, [startDate]);

    useEffect(() => {
            if(telemedSlotStatus === "success"){
                const successMsg = "Scheduled successfully for the day" ;
                // ${startDate.toDateString()} for ${timeSlotDuration} minutes`;
                // ${convertTimeSlotTime(appointData.start_time)}
                    setAlert({ alert: successMsg, severity: 'success' });
                    setTimeout(() => { props.handleCloseScheduleModal();
                        dispatch(setSchedTelemed("")) ; setAlert({alert: "", severity: ""});
                    },  3000);

                } else if (telemedSlotStatus === "error") {
                    setAlert({ alert: "Something went wrong", severity: 'error' });
                    setIsSubmitting(false);
                    dispatch(setSchedTelemed(""));
                    setTimeout(() => {
                     props.handleCloseScheduleModal(); setAlert({alert: "", severity: ""}); }, 3000);
                }

    },[telemedSlotStatus])
    //
    // // handle change on date picker for schedule telemedicine
    const handleStartDateChange = async (date) => {
        setStartDate(date);
        resetSelectedSlot();
    };
    //
    // // get Availability after start date is selected
    const getAvailability = async (doctorId) => {
        let start_date = startDate ? (new Date(startDate).setHours(0, 0, 0, 0)) : new Date().setHours(0, 0, 0, 0);
        let end_date = start_date + 86400000; // 24-hrs or next day
        let data = {};
        if (tab === 0) {
            data = {
                start_date: start_date,
                end_date: end_date,
                session_time: timeSlotDuration,
                doctor_id: ""
            };
        } else {
            data = {
                start_date: start_date,
                end_date: end_date,
                session_time: timeSlotDuration,
                doctor_id: doctorId
            };
        }

        try {
            dispatch(getAvailableSlotsForScheduling(data));

        } catch (e) {
            console.error(e)
        }
    };
    //
    // // convert millisecond date to readable military time format
    const convertTimeSlotTime = (time) => {
        const dateFVal = new Date(parseInt(time));
        const timeHour = (dateFVal.getHours() < 10 ? '0' : '') + dateFVal.getHours();
        const timeMinute = (dateFVal.getMinutes() < 10 ? '0' : '') + dateFVal.getMinutes();
        const actTime = timeHour + ":" + timeMinute;

        return actTime;
    };
    //
    // // reset selected slot after time slot duration changes or the date changes
    const resetSelectedSlot = () => {
        // setAvailablityBT([]); // available time slot by time slot
        setFinalSlotBT({}); // the final slot that will be chosen
        setSlotBT(null); // slot by time slot
        // setAvailablityBP([]); // available time slot by provider
        setFinalSlotBP({}); // the final slot that will be chosen
        setSlotBP(null); // slot by provider
    };
    //
    const submitAppointment = async (type) => {
        setIsSubmitting(true);
        const appointData = {
            start_time: '',
            end_time: '',
            title: "ScheduledTelemedicine",
            id: '', // provider id
        };

        if (type === 'byTimeSlot') {
            appointData.id = finalSlotBT.provider;
            appointData.start_time = finalSlotBT.time;
            appointData.end_time = parseInt(finalSlotBT.time) + (parseInt(timeSlotDuration) * 60 * 1000) + "";
        } else if (type === 'byProviders') {
            appointData.id = finalSlotBP.provider;
            appointData.start_time = finalSlotBP.time;
            appointData.end_time = parseInt(finalSlotBP.time) + (parseInt(timeSlotDuration) * 60 * 1000) + "";
        }

        try {
            dispatch(scheduleTelemed(appointData, ticketID,startDate ));
        } catch (e) {
            console.error(e);
            setAlert({ alert: e.message, severity: 'error' });
            setIsSubmitting(false);
        }

    };

    const onTabChange = (e, newTab) => {
        setTab(newTab);
        setSlotsToSchedule([]);
    }

    // const style = {
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: 500,
    //     bgcolor: 'background.paper',
    //     border: '2px solid #000',
    //     boxShadow: 24,
    //     p: 4,
    // };

    return (
        // <Modal
        //     open={}
        //     onClose={(_, reason) => {
        //         if (reason !== "backdropClick") {
        //             props.handleCloseScheduleModal();
        //         }
        //     }}
        //     aria-labelledby="modal-modal-title"
        //     aria-describedby="modal-modal-description"
        // >
        //
        //
        // </Modal>

    <Dialog open={props.showScheduleModal} className="assessmentModal">
        <DialogTitle>
            <Grid container>
                <Typography id="modal-modal-title" component="h2">
                    Schedule Telemedicine
                </Typography>
            </Grid>
            <Grid>
                <IconButton onClick={() => {  props.handleCloseScheduleModal(); }}>
                    <CloseIcon />
                </IconButton>
            </Grid>

        </DialogTitle>
        <DialogContent >
            {/*<DialogTitle>*/}
            {/*    <Grid></Grid>*/}
            {/*    <Grid>*/}
            {/*        <IconButton*/}
            {/*            justifyContent="flex-end"*/}
            {/*            alignItems="flex-end"*/}
            {/*            style={{float: "right"}}*/}
            {/*            onClick={()=> closeModal()}*/}
            {/*        >*/}
            {/*            <CloseIcon />*/}
            {/*        </IconButton>*/}
            {/*    </Grid>*/}

            {/*</DialogTitle>*/}

            {/* Alert Messages  */}
            {/*<Snackbar*/}

            {/*    // open={alert.alert.length > 0}*/}
            {/*    open={true}*/}
            {/*    // anchorOrigin={{*/}
            {/*    //     vertical: 'bottom',*/}
            {/*    //     horizontal: 'center',*/}
            {/*    // }}*/}
            {/*    autoHideDuration={116000}*/}
            {/*    onClose={() => setAlert({ alert: '', severity: 'success' })}>*/}
            {/*    <Alert severity={alert.severity ?? ''} >*/}
            {/*        {alert.alert}*/}
            {/*    </Alert>*/}
            {/*</Snackbar>*/}

            {alert.alert.length > 0 &&  <Alert severity={alert.severity ?? ''} onClose={() => setAlert({ alert: '', severity: 'success' })}>
                {alert.alert}
            </Alert> }

            <div className="timeSlotSession">
                <FormControl className='timeSlotForm' component="fieldset">
                    <FormLabel className='timeSlotLabel' style={{marginRight: "10px"}}>Time Slot</FormLabel>
                    <RadioGroup className='timeSlotRadioGroup' aria-label="timeSlotDuration" value={timeSlotDuration} onChange={handleTimeSlotChange}>
                        <FormControlLabel value="30" control={<Radio  />} label="30-minute" disabled={isSubmitting} />
                        <FormControlLabel value="60" control={<Radio  />} label="1 hour" disabled={isSubmitting} />
                    </RadioGroup>
                </FormControl>
            </div>

            {timeSlotDuration &&
                <div>
                    <div className='datePickerSchedule'>
                        <DatePicker minDate={new Date()} value={startDate} onChange={handleStartDateChange} inline disabled={isSubmitting} />
                    </div>

                    <Tabs value={tab} onChange={onTabChange} id="scheduleModal" aria-label="basic tabs example" TabIndicatorProps={{
                        style: {
                            backgroundColor: "#6aaf7fad",
                        }
                    }}>
                        <Tab label="Schedule based on TimeSlots" wrapped />
                        <Tab label="Schedule based on Providers" wrapped />
                    </Tabs>

                    <TabPanel value={tab} index={0}>
                        <div className="tbSecTitles">Available Slots</div>
                        {slotSchedules && slotSchedules.available_slots && slotSchedules.available_slots.length === 0 && <div  style={{textAlign : "center"}} >No availablity on this date</div>}
                        {slotSchedules && slotSchedules.available_slots && slotSchedules.available_slots.map((slot, index) => {
                            return (
                                <Button
                                    disabled={isSubmitting}
                                    className={`slotBtn ${slot.time === slotBT?.time ? "actvSltBtn" : null}`}
                                    key={index}
                                    onClick={() => {
                                        setFinalSlotBT({ ...finalSlotBT, time: slot.time });
                                        setSlotBT(slot);
                                    }}
                                >
                                    {convertTimeSlotTime(slot.time)}
                                </Button>
                            )
                        })
                        }

                        {slotBT &&
                            <div >
                                <div className="tbSecTitles">Available Providers</div>
                                <select
                                    style={{ width: "100%"}}
                                    disabled={isSubmitting}
                                    className="form-control"
                                    defaultValue=""
                                    id="BOTSlots"
                                    onChange={(e) => {
                                        setFinalSlotBT({ ...finalSlotBT, provider: e.target.value });
                                    }}>
                                    <option value="" disabled>Select Provider</option>
                                    {slotBT.doctors.map((provider) => {
                                        return (
                                            <option key={provider.id} value={provider.id}> {`${provider.name} ${provider.family_name}`}</option>
                                        );
                                    })
                                    }
                                </select>
                            </div>
                        }

                        <br />
                        {finalSlotBT && finalSlotBT.provider && finalSlotBT.time &&
                            <div style={{textAlign: "center"}} >
                                <Button
                                    variant="contained"
                                    disabled={isSubmitting}
                                    onClick={() => submitAppointment('byTimeSlot')}
                                    startIcon={<EventAvailableIcon />}>
                                    Schedule Telemedicine
                                </Button>
                            </div>
                        }

                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <div style={{ width: "100%"}}>
                            <div className="tbSecTitles">Available Providers</div>
                            {providers &&
                                <select
                                    style={{ width: "100%"}}
                                    disabled={isSubmitting}
                                    className="form-control"
                                    defaultValue=""
                                    id="BOTSlots"
                                    value={finalSlotBP.provider ?? ""}
                                    onChange={async (e,) => {
                                        setFinalSlotBP({ ...finalSlotBP, provider: e.target.value });
                                        await getAvailability(e.target.value);
                                    }}
                                >
                                    <option value="" disabled>Select Provider</option>
                                    {providers.map((provider) => {
                                        return (
                                            <option key={provider.id} value={provider.id}> {`${provider.name} ${provider.family_name}`}</option>
                                        );
                                    })}
                                </select>
                            }
                            {finalSlotBP.provider &&
                                <div className="form-group">
                                    <div className="tbSecTitles">Available Slots</div>
                                    {slotSchedules && slotSchedules.available_slots && slotSchedules.available_slots.length === 0 && <div style={{textAlign : "center"}}>No availablity for provider</div>}

                                    {slotSchedules && slotSchedules.available_slots && slotSchedules.available_slots.map((slot, index) => {
                                        return (
                                            <Button
                                                disabled={isSubmitting}
                                                className={`slotBtn ${slot.time === slotBP?.time ? "actvSltBtn" : null}`}
                                                key={index}
                                                onClick={() => {
                                                    setFinalSlotBP({ ...finalSlotBP, time: slot.time });
                                                    setSlotBP(slot);
                                                }}>
                                                {convertTimeSlotTime(slot.time)}
                                            </Button>
                                        )
                                    })
                                    }
                                </div>
                            }
                            <br />
                            {finalSlotBP && finalSlotBP.provider && finalSlotBP.time &&
                                <div style={{textAlign: "center"}} >
                                    <Button
                                        variant="contained"
                                        disabled={isSubmitting}
                                        onClick={() => submitAppointment('byProviders')}
                                        startIcon={<EventAvailableIcon />}>
                                        Schedule Telemedicine
                                    </Button>

                                </div>
                            }
                        </div>
                    </TabPanel>
                </div>
            }
        </DialogContent>
    </Dialog>
    );
}

export default ScheduleTelemedicineModal;