import {SET_COMPONENT_LOADER, SET_LOADER, CASE_STATUS_LOADER, PROVIDER_STATUS_LOADER} from "../actions/loaderActions";

export const setLoader = (data) => {
	return{
	type: SET_LOADER,
	payload: data
}};
export const setComponentLoader = (data) => ({
	type: SET_COMPONENT_LOADER,
	payload: data
});
export const setCaseStatusLoader = (data) => ({
	 type: CASE_STATUS_LOADER,
	 payload: data
});
export const setProviderStatusLoader = (data) => ({
	type: PROVIDER_STATUS_LOADER,
	payload: data
});