import { BASE_URL, getCurrentUserToken, getCurrentUserId } from "../../utils";
import { setLoader } from "./loaderActionCreator";
import { setMessage } from "./messageActionCreator";

export const shareEmail = (id, toEmail) => async (dispatch) => {
	dispatch(setLoader(true));
	await fetch(`${BASE_URL}/UserAction/shareUserDocumentViaEmailWithId`, {
		"method": "POST",
		"headers": {
			"Content-Type": "application/json"
		},
		"body": JSON.stringify({
			"uniqueId" : id,
			"toEmail" : toEmail
			// "toUserName" : "Ganesh Reddy Jakka"
		})
	})
		.then(data => data.json())
		.then(response => {
			if (!response.status) {
				dispatch(setMessage({
					text: "Unable to share the document at the moment. Please ask the patient to re-share the document.",
					type: "error",
					show: true
				}));
			} else {
				dispatch(setMessage({
					text: "Document has been sent to the email successfully",
					type: "success",
					show: true
				}));
			}
			dispatch(setLoader(false));
		}).catch(error => {
			dispatch(setMessage({
				text: error.message ?? "Unable to get user settings.",
				type: "error",
				show: true
			}));
			dispatch(setLoader(false));
		});
};