import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    DialogActions, Grid
} from '@mui/material';
import {useNavigate } from "react-router-dom";
import {Alert} from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";

const AlertModal = ({openModal, closeModal, message, pathToNavigate}) => {

    const navigate = useNavigate();

    const handleNextClick = async () => {
        navigate(pathToNavigate)
    };

    return (

        <Dialog open={openModal} className="assessmentModal">
            <DialogTitle>
                <Grid></Grid>
                <Grid>
                    <IconButton
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        style={{float: "right"}}
                        onClick={()=> closeModal()}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>

            </DialogTitle>

            <DialogContent>
                <div style={{marginBottom: "20px"}} />
                <Alert severity={message.type} style={{width: "94%"}} >
                    {message.message}
                            </Alert>
               <div style={{marginBottom: "20px"}} />
            </DialogContent>

            <DialogActions>
                <Button
                            variant="contained"
                            onClick={handleNextClick}>
                            View Requests
                        </Button>
                        <Button variant="contained" color="secondary" onClick={() => {closeModal()} }>
                            Cancel
                        </Button>
            </DialogActions>
        </Dialog>
    );



}

export default AlertModal;