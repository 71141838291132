import { SHARE_DOC } from "../actions/shareActions";

const init = {
	message: ""
};

const shareReducer = (state = init, action) => {
	switch (action.type) {
	case SHARE_DOC:
		return {
			...state, message: action.payload
		};
	default:
		return state;
	}

};

export default shareReducer;