import {
    GET_STATS, GET_DOCTOR_LIST, GET_SEARCH_RESULT, GET_ADMIN_REPORTS
} from "../actions/docStatsActions";
import {BASE_URL, getCurrentUserToken} from "../../utils";
import {setLoader} from "./loaderActionCreator";
import {setMessage} from "./messageActionCreator";

export const setAdminStats = (data) => ({ type: GET_ADMIN_REPORTS, payload: data });
export const setStatsInfo = (data) => ({ type: GET_STATS, payload: data });
export const setDoctorList = (data) => ({ type: GET_DOCTOR_LIST, payload: data });
export const setSearchResult = (data) => ({ type: GET_SEARCH_RESULT, payload: data});

export const getDoctorList = (params) => async (dispatch) => {
    dispatch(setLoader(true));
    params = new URLSearchParams(params).toString();
    const token = await getCurrentUserToken();
    try {
        const apiUrl = `${BASE_URL}/drowl_search/getTeleMedDoctorStats?${params}`
        fetch(apiUrl, {
            "method":"GET",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "token": token
            }
        }).then(res => res.json()).then(res => {
            dispatch(setDoctorList(res.doctors));
            dispatch(setLoader(false));
        });
    } catch (e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
        dispatch(setLoader(false));
    }
}

export const getDoctorStats = (params) => async (dispatch) => {
    dispatch(setLoader(true));
    params = new URLSearchParams(params).toString();
    const token = await getCurrentUserToken();
    try {
        const apiUrl = `${BASE_URL}/drowl_search/getTeleMedDoctorStats?${params}`
       fetch(apiUrl, {
            "method":"GET",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "token": token
            }
        }).then(res => res.json()).then(res =>
           dispatch(setStatsInfo(res))
       );
        dispatch(setLoader(false));
    } catch (e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
        dispatch(setLoader(false));
    }
};


export const searchDoctor = (params) => async (dispatch) => {
    dispatch(setLoader(true));
    params = new URLSearchParams(params).toString();
    const token = await getCurrentUserToken();
    try {
        const apiUrl = `${BASE_URL}/drowl_search/search_doctor?${params}`
        fetch(apiUrl, {
            "method":"GET",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "token": token
            }
        }).then(res => res.json()).then(res =>
            dispatch(setSearchResult(res))
        );
        dispatch(setLoader(false));
    } catch (e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
        dispatch(setLoader(false));
    }
}

export const getAdminStats = (params) => async (dispatch) => {
    params = new URLSearchParams(params).toString();
    const token = await getCurrentUserToken();
    try {
        const apiUrl = `${BASE_URL}/drowl_search/getAdminStats?${params}`
        fetch(apiUrl, {
            "method":"GET",
            "headers": {
                "content-type": "application/json",
                "accept": "application/json",
                "token": token
            }
        }).then(res => res.json()).then(res =>
            dispatch(setStatsInfo(res))
        );
        dispatch(setLoader(false));
    } catch (e) {
        dispatch(setMessage({text: e.message ?? "Something went wrong, Please try again later!",
            type: "error", show: true}));
        dispatch(setLoader(false));
    }
}