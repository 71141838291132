import React, { useEffect, useState } from 'react';
import IdleJs from 'idle-js';
import SessionTimeoutDialog from "./LogoutDialog";
import {signOut} from "../../ducks/actionCreators/loginActionCreator";
import {useDispatch, useSelector} from "react-redux";

const IdleComponent = () => {

    const [sessionTimeoutDialogOpen, setSessionTimeoutDialogOpen] = useState(false);
    const [countdown, setCountdown] = useState(540000); // Initial countdown value in seconds

    const dispatch = useDispatch();

    const data = useSelector((state) => state.login);

    const idle = new IdleJs({
        idle: 540000,
        onIdle: () => {
            setSessionTimeoutDialogOpen(true);
        },
    });

    useEffect(() => {
        idle.start();
        const countdownInterval = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000); // Update countdown every second

        return () => {
            clearInterval(countdownInterval);
            idle.stop();
        };
    }, []);


    return (
        <div>
            {/* Your app content */}
            {data.isUserAuthenticated &&  <SessionTimeoutDialog
                open={sessionTimeoutDialogOpen}
                onLogout={() => {
                    // Perform logout logic here
                    setSessionTimeoutDialogOpen(false);
                    idle.stop(); // Stop the idle timer
                    dispatch(signOut())
                }}
                onContinue={() => {
                    setSessionTimeoutDialogOpen(false);
                    idle.reset(); // Reset the idle timer
                    setCountdown(540000); // Reset the countdown timer
                }}
                countdown={countdown}
            /> }
        </div>
    );
}


export default IdleComponent;
