import React, { useEffect, useState } from "react";
import TableWeb from "./TableWeb";
import TableMobile from "./TableMobile";

const Table = ({columnHeaders, rowHeaders, rows, page, rowsPerPage, requestSort, sortOrder, sortBy, alignRows, rowClick}) => {

    const [showMobileViewTable, setShowMobileViewTable] = useState(window.innerWidth < 600);

    useEffect(() => {
        function handleWindowResize() {
            setShowMobileViewTable(window.innerWidth < 600);
        };
        window.addEventListener("resize", handleWindowResize);

    }, []);

   return (
       <> { !showMobileViewTable ? <TableWeb
            columnHeaders={columnHeaders}
            rowHeaders={rowHeaders}
            alignRows={alignRows}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            requestSort={requestSort}
            sortOrder={sortOrder}
            sortBy={sortBy}
            rowClick={rowClick}
            // columnClicks={columnClicks}
        /> :
        <TableMobile
            rowHeaders={rowHeaders}
            columnHeaders={columnHeaders}
            rows={rows}
            page={page}
            alignRows={alignRows}
            rowsPerPage={rowsPerPage}
            requestSort={requestSort}
            sortOrder={sortOrder}
            sortBy={sortBy}
            rowClick={rowClick}
            // columnClicks={columnClicks}
        />
    } </>
)
}

export default Table;
