export const  LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const  LOGIN_FAILURE = "LOGIN_FAILURE";
export const  LOGOUT = "LOGOUT";

export const  IS_USER_AUTHENTICATED = "IS_USER_AUTHENTICATED";

export const  SET_USER_DATA = "SET_USER_DATA";

export const SET_STEP = "SET_STEP";
export const CONFIRM_SIGN_UP = "CONFIRM_SIGN_UP"
export const SET_USERNAME = "SET_USERNAME"
export const SET_USER_GROUP = "SET_USER_GROUP"
export const SET_SIGNUP_STATUS = "SET_SIGNUP_STATUS"
export const SET_CODE_DELIVERY_DETAILS = "SET_CODE_DELIVERY_DETAILS"
export const NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED"
export const SET_DOCTOR_GRPS = "SET_DOCTOR_GRPS"