import React, { useEffect, useState } from 'react';
import { TextField, Paper, Button, Avatar, CircularProgress, Grid } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import '../Search/Search.css';
import { useDispatch, useSelector } from "react-redux";
import { CaseStatues } from '../PatientOverview/PatientOverview';
import { getDoctorsInGroup, getPatients, getSearchResults, setSearchButtonClicked } from "../../ducks/actionCreators/searchActionCreator";
import zipcodes from 'zipcodes';
import {
    DateRangeDelimiter,
    LocalizationProvider,
    DesktopDateRangePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@material-ui/pickers/adapter/date-fns";
import { formatDateSlashes } from "../../utils/utlis";
import moment from "moment";
import {Loader} from "@drowl/drowl-react-library";

const AdvancedSearch = () => {
    const advancedSearchFields = {
        'first_name': '',
        'last_name': '',
        'dob': '',
        'zipcode': '',
        'assignee_id': '',
        'patient_name': '',
        'case_id': '',
        'keywords': '',
        'status': '',
        'from_date': '',
        'to_date': '',
        'za_nationalid': ''
    };
    const [advancedSearch, setAdvancedSearch] = useState(advancedSearchFields);
    const [isSearchable, setIsSearchable] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const data = useSelector((state) => state.search);
    const {doctorGroup: doctorInGroup }= data;
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    const dispatch = useDispatch();
    const loaderData = useSelector((state) => state.loader);

    useEffect(() => {
        function handleWindowResize() {
            setIsMobile(window.innerWidth < 600);
        };
        window.addEventListener("resize", handleWindowResize);

    }, []);

    useEffect(() => {
       getDoctorsInGroup()
    }, []);

    const searchAdvanced = (params) => {
        dispatch(getSearchResults(params));
    }

    // Handle when a field changes in the advanced search
    const handleAdvancedSearchChange = (fieldName, value) => {
        dispatch(setSearchButtonClicked(false))
        const newSearchParams = {
            ...advancedSearch,
        };



        if (fieldName === 'assignee_id') {
            newSearchParams[fieldName] = value.id;
        }
        else {
            newSearchParams[fieldName] = value;
        }
        isSearchableSearch(newSearchParams);
        setAdvancedSearch({ ...newSearchParams });
    };


    // Handle when a date range changes in the advanced search
    const handleAdvancedSearchDateRangeChange = (value) => {
        dispatch(setSearchButtonClicked(true))
        const newSearchParams = {
            ...advancedSearch,
        };
        newSearchParams.from_date = formatDateSlashes(value[0]);
        newSearchParams.to_date = formatDateSlashes(value[1]);
        isSearchableSearch(newSearchParams);
        setAdvancedSearch({ ...newSearchParams });
        setDateRange(value)
    };

    // Check if there are values in the Search Fields
    const isSearchableSearch = (params) => {
        //check if any field is searchable
        const isAnySearchable = Object.entries(params).filter(([key,value]) => value !== '');
        setIsSearchable(isAnySearchable.length > 0);
    }

    /* 
    * Validate zip code and fetch information on zipcode
    */
    const isNotValidZipcode = () => {
        if (advancedSearch.zipcode.length > 5) {
            return true;
        }
        const response = zipcodes.lookup(advancedSearch.zipcode);

        return !response;

    };

    const search = async (event) => {

        event.preventDefault();
        // try to search on tickets/cases
        try {
            advancedSearch.from_date = moment(advancedSearch.from_date).format("YYYY-MM-DD");
            advancedSearch.to_date  = moment(advancedSearch.to_date).format("YYYY-MM-DD");
            searchAdvanced(advancedSearch);
        } catch (e) {
            console.error(e);
        }
        // try to search on patients if the user has filled in first or last name or dob
        if (advancedSearch.first_name.length > 0  || advancedSearch.last_name.length > 0 || advancedSearch.dob.length > 0) {
            try {
                // todo change
                dispatch(getPatients({
                    first_name: advancedSearch.first_name,
                    last_name: advancedSearch.last_name,
                    dob: advancedSearch.dob,

                }));

            } catch (e) {
                console.error(e);
            }
        }
    };

    return (
        <Paper className='searchFields' elevation={3}>
            <> {loaderData.loading && <Loader /> } </>
            <form onSubmit={search}>
                {/* Date Range Ticket Start  */}

                        <LocalizationProvider dateAdapter={DateFnsUtils}>
                            <DesktopDateRangePicker
                                startText=""
                                endText=""
                                // inputFormat='yyyy-MM-dd'
                                calendars={1}
                                value={dateRange}
                                onChange={(newValue) => handleAdvancedSearchDateRangeChange(newValue)}
                                renderInput={(startProps, endProps) => (

                                        <Grid container className='advancedSearchGroup'>
                                            <Grid item xs={12} md={6}>
                                                <Grid item xs={4}  className="col-sm-3 advancedSearchFields">Ticket Start Date:</Grid>
                                                <TextField variant='outlined'
                                                           className='advancedField'
                                                    {...startProps} />
                                            </Grid>

                                            {/*<DateRangeDelimiter> - </DateRangeDelimiter>*/}
                                            {/*<div className="mobileBottom"></div>*/}


                                            <Grid item xs={12} md={6}>
                                                <Grid item xs={4}  className="col-sm-3 advancedSearchFields">Ticket End Date:</Grid>
                                                <TextField
                                                    variant='outlined'
                                                    className='advancedField'
                                                    {...endProps} />
                                            </Grid>
                                        </Grid>

                                )}
                            />
                        </LocalizationProvider>


                <Grid container className='advancedSearchGroup'>
                    <Grid item xs={12} md={6}>
                        <Grid item xs={4} className="col-sm-3 advancedSearchFields">Keyword:</Grid>
                        <TextField
                            value={advancedSearch.keywords}
                            variant='outlined'
                            className='advancedField'
                            onChange={(e) => handleAdvancedSearchChange('keywords', e.target.value)}
                        />
                    </Grid>

                </Grid>



                {/* First Name & Last Name */}
                <Grid container className='advancedSearchGroup'>
                    <Grid item xs={12} md={6}>
                        <Grid item xs={4} className="col-sm-3 advancedSearchFields">First Name:</Grid>
                            <TextField
                                value={advancedSearch.first_name}
                                // style={{ width: '70%' }}
                                variant='outlined'
                                className='advancedField'
                                onChange={(e) => handleAdvancedSearchChange('first_name', e.target.value)}
                            />
                    </Grid>


                    {isMobile && <Grid xs={12}><div className="mobileBottom" /></Grid>}


                    <Grid item xs={12} md={6}>
                        <Grid item xs={4} className="col-sm-3 advancedSearchFields">Last Name:</Grid>
                        <TextField
                            value={advancedSearch.last_name}
                            // style={{ width: '70%' }}
                            variant='outlined'
                            className='advancedField'
                            onChange={(e) => handleAdvancedSearchChange('last_name', e.target.value)}
                        />
                    </Grid>
                </Grid>

                <Grid container className='advancedSearchGroup'>
                    <Grid item xs={12} md={6}>
                        <Grid item xs={4} className="col-sm-3 advancedSearchFields">Date of Birth:</Grid>
                        <TextField
                            value={advancedSearch.dob}
                            variant='outlined'
                            className='advancedField'
                            onChange={(e) => handleAdvancedSearchChange('dob', e.target.value)}
                            type="date"
                        />
                    </Grid>

                    {isMobile && <Grid xs={12}><div className="mobileBottom" /></Grid>}

                    <Grid item xs={12} md={6}>
                        <Grid item xs={4} className="col-sm-3 advancedSearchFields">Zip Code:</Grid>
                        <TextField
                            value={advancedSearch.zipcode}
                            variant='outlined'
                            className='advancedField'
                            error={advancedSearch.zipcode.length > 0 ? isNotValidZipcode() : false}
                            onChange={(e) => {
                                handleAdvancedSearchChange('zipcode', e.target.value)
                            }}
                        />
                    </Grid>
                </Grid>


                <Grid container className='advancedSearchGroup'>
                    <Grid item xs={12} md={6}>
                        <Grid item xs={4} className="col-sm-3 advancedSearchFields">Provider:</Grid>
                        <Autocomplete
                            className='advancedField'
                            style={{ display: 'inline-block' }}
                            getOptionSelected={(option, value) => option.id === value.id}
                            getOptionLabel={(option) => `${option.name} ${option.family_name}`}
                            options={doctorInGroup || []}
                            loading={!doctorInGroup}
                            onChange={(e, value) => { if (value) handleAdvancedSearchChange('assignee_id', value) }}
                            renderOption={(option) => (
                                <React.Fragment>
                                    <Avatar className='doctorImage' alt={option.family_name} src={option.image} />
                                    {`${option.name} ${option.family_name}`}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {!doctorInGroup && <CircularProgress color="inherit" size={20} />}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>


                    {isMobile && <Grid xs={12}><div className="mobileBottom" /></Grid>}


                    <Grid item xs={12} md={6}>
                        <Grid item xs={4} className="col-sm-3 advancedSearchFields">Case-Id:</Grid>
                        <TextField
                            value={advancedSearch.case_id}
                            className='advancedField'
                            variant='outlined'
                            onChange={(e) => handleAdvancedSearchChange('case_id', e.target.value)}
                        />
                    </Grid>
                </Grid>


                <Grid container className='advancedSearchGroup'>
                    <Grid item xs={12} md={6}>
                        <Grid item xs={4} className="col-sm-3 advancedSearchFields">Status:</Grid>
                        <TextField
                            select
                            variant="outlined"
                            className='advancedField'
                            onChange={(e) => handleAdvancedSearchChange('status', e.target.value)}
                            SelectProps={{
                                native: true,
                            }}>
                            <option key={99} value=''>None</option>
                            <option key={CaseStatues.OPEN} value={CaseStatues.OPEN}>Open</option>
                            <option key={CaseStatues.ASSESSING} value={CaseStatues.ASSESSING}>Assessing</option>
                            <option key={CaseStatues.WAITINGFORGP} value={CaseStatues.WAITINGFORGP}>Waiting for GP</option>
                            <option key={CaseStatues.INPROGRESS} value={CaseStatues.INPROGRESS} >In Progress</option>
                            <option key={CaseStatues.SCHEDULED} value={CaseStatues.SCHEDULED}>Scheduled</option>
                            <option key={CaseStatues.CLOSED} value={CaseStatues.CLOSED}>Closed</option>
                        </TextField>
                    </Grid>
                    {isMobile && <Grid xs={12}><div className="mobileBottom" /></Grid>}
                    <Grid item xs={12} md={6}>
                        <Grid item xs={4} className="col-sm-3 advancedSearchFields">ID Number :</Grid>
                        <TextField
                            value={advancedSearch.idNum}
                            className='advancedField'
                            variant='outlined'
                            onChange={(e) => handleAdvancedSearchChange('za_nationalid', e.target.value)}
                        />
                    </Grid>

                    <Button type="submit" variant="contained" style={{marginTop : "30px"}} onClick={() => dispatch(setSearchButtonClicked(true))}>Search</Button>

                </Grid>

            </form>
        </Paper>
    );
}

export default AdvancedSearch;