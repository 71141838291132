import React, { useEffect, useState } from 'react';
import {
    Grid, CircularProgress,
    Avatar, TextField, Button, Autocomplete
} from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Link } from 'react-router-dom';
import './Case.css';
import '../Search/Search.css';
import ScheduleTelemedicineModal from './ScheduleTelemedicineModal';
import {useSelector, useDispatch} from "react-redux";
import {
    getProviders,
    changeTicketAssignee,
    setChangeAsigneeStatus
} from "../../ducks/actionCreators/caseActionCreator";
import {Loader} from "@drowl/drowl-react-library";
import ProviderList from "./ProvidersList";
import CaseHistory from "./CaseHistory";

/**
 * Holds a single case's infomation such as
 * status, provider, one link, and schedule
 * @param {} props
 * @returns
 */
const Case = () => {

    const [provider, setProvider] = useState('');

    const [providerLoading, setProviderLoading] = useState(false);

    const [showScheduleModal, setShowScheduleModal] = useState(false);

    const patientOverviewData = useSelector(state => state.patientOverview);

    const providerData = useSelector(state => state.case);
    const loader = useSelector((state) => state.loader);

    let { providers, providerChangeStatus } = providerData;
    providers ||= [];
    //
    let {ticket} = patientOverviewData;
    ticket ||= {};

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProviders());
    },[]);


    useEffect(() => {
        if(providerChangeStatus === "success" || providerChangeStatus === "error") {
            setProviderLoading(false);
            dispatch(setChangeAsigneeStatus(""));
        }
    }, [providerChangeStatus])

    //
    //
    // // init provider select
    useEffect(() => {
        if (ticket && Object.keys(ticket).length > 0) {
            if (ticket.assignee.length !== 0 && !provider && provider.length === 0 && providers && providers.length > 0) {
                const curProvider = providers.find(el => `${el.name} ${el.family_name}` === ticket.assignee)
                if (curProvider) setProvider(curProvider);
            }
        }
    }, [providers]);

    // // // User changes status
    // const handleProviderChange =  (value) => {
    //     setProviderLoading(true);
    //     const previousProvider = provider;
    //
    //     try {
    //         dispatch(changeTicketAssignee(value.id, ticket._id));
    //         setProvider(value);
    //         // setProviderLoading(false);
    //     } catch (e) {
    //         setProvider(previousProvider);
    //         setProviderLoading(false);
    //         console.error(e);
    //     }
    // };
    //
    // // show schedule modal
    const handleShowScheduleModal = () => {
        setShowScheduleModal(true);
    };
    // // close schedule modal
    const handleCloseScheduleModal = () => {
        setShowScheduleModal(false);
    };

    return (
        <div className="caseWindow ">
            {/*<> {loader.loading && <Loader /> } </>*/}
            <h1 className="windowTitle">Case Settings</h1>

             <Grid container
                spacing={3}
                className='caseSettings'
                direction="column"
                justifyContent="center"
                alignItems="center"
                wrap='nowrap'
            >
            {/*    /!* Provider *!/*/}
            {/*    <Grid container item*/}
            {/*        direction="row"*/}
            {/*        justifyContent="flex-start"*/}
            {/*        alignItems="center"*/}
            {/*        style={{ display: 'inline' }}*/}
            {/*    >*/}
            {/*        <Grid item style={{ textAlign: 'start' }}>*/}
            {/*            <div className="caseLabel">Provider:</div>*/}
            {/*            <Autocomplete*/}
            {/*                className="providerFieldWidth"*/}
            {/*                disabled={ticket.status === 3}*/}
            {/*                isOptionEqualToValue={(option,value) => {*/}
            {/*                    return option.id === value.id;*/}
            {/*                }}*/}
            {/*                getOptionLabel={option => `${option.name ?? ''} ${option.family_name ?? ''}`}*/}
            {/*                options={providers}*/}
            {/*                loading={providers && providers.length === 0}*/}
            {/*                // loadingText="Loading ..."*/}
            {/*                value={provider}*/}
            {/*                onChange={(_event, newValue) => {*/}
            {/*                    handleProviderChange(newValue)*/}
            {/*                }}*/}
            {/*                renderOption={(props, option, { selected }) => (*/}
            {/*                    <li {...props} >*/}
            {/*                        <>*/}
            {/*                        <Avatar className='doctorImage' alt={option.family_name} src={option.image} />*/}
            {/*                        {`${option.name} ${option.family_name}`}*/}
            {/*                            </>*/}
            {/*                    </li>*/}
            {/*                )}*/}
            {/*                renderInput={(params) => (*/}
            {/*                    <TextField*/}
            {/*                        variant="standard"*/}
            {/*                        {...params}*/}
            {/*                        // label="Provider"*/}
            {/*                        InputProps={{*/}
            {/*                            ...params.InputProps,*/}
            {/*                            endAdornment: (*/}
            {/*                                <>*/}
            {/*                                    { providers && providers.length === 0  && <CircularProgress color="inherit" size={20} />}*/}
            {/*                                </>*/}
            {/*                            ),*/}
            {/*                        }}*/}
            {/*                    />*/}
            {/*                )}*/}
            {/*            />*/}
            {/*            {providerLoading && provider ?*/}
            {/*                <CircularProgress style={{ margin: 'auto' }} size={24} />*/}
            {/*                :*/}
            {/*                <CheckCircleOutlineIcon style={{ color: '#99d590', margin: 'auto' }} />*/}
            {/*            }*/}
            {/*        </Grid>*/}
            {/*    </Grid>*/}

              <ProviderList />

                {/* Schedule time slot */}
                {/*<Grid container item*/}
                {/*    direction="row"*/}
                {/*    justifyContent="flex-start"*/}
                {/*    alignItems="center">*/}
                {/*    <Grid item style={{ textAlign: 'start' }}>*/}
                {/*        <div className="caseLabel">Schedule:</div>*/}
                {/*        <Button*/}
                {/*            onClick={handleShowScheduleModal}*/}
                {/*            variant='contained'*/}
                {/*            disabled={ticket && (ticket.status === 3)}*/}
                {/*            startIcon={<AccessTimeIcon />}>*/}
                {/*            Reserve Telemedicine Slot*/}
                {/*        </Button>*/}
                {/*    </Grid>*/}

                {/*</Grid>*/}

                {/* One Link */}
                <Grid container item
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item style={{ textAlign: 'start', wordBreak: "break-word" }}>
                        <div className="caseLabel">One Link:</div>
                        <Link to={`/patientOverview/${ticket._id}`} className="oneLink" target='_blank'>
                            {window.location.origin}/patientOverview/{ticket._id}
                        </Link>
                    </Grid>
                </Grid>
            </Grid>

            <ScheduleTelemedicineModal
                showScheduleModal={showScheduleModal}
                handleCloseScheduleModal={handleCloseScheduleModal}
            />

            <CaseHistory />

        </div>
    );
}


export default Case;