export const FAQS = [{
    "Before A Telemedicine Call": [
        {
            displayTitle: "Updating My Profile",
            title: "How do I update the information on my profile?",
            text1: "To update the information on your profile, first click on the circle image on the top right corner of the screen and select the “Settings” option. This is information that can be changed as necessary. Select the green “Save” button to finish updating your profile when complete.",
            link: "https://staticfiles.drowl.com/drowl_files/za_faq_videos/Profile_update.mp4"
        },
        {
            displayTitle: "Taking a Case",
            title: "How do I choose a case and serve a patient?",
            text1: "To choose a case that needs to be served, select the “Open Cases” tab from the “Telemedicine Cases” screen. From here, there will be a list of statuses in the second column where there are cases listed saying “Waiting for GP.” This means that the patient is ready to be seen by a doctor.",
            text2: "Click on any part of this status and it will open a case. Once it is open, switch the status to “In Progress” which can be found in the drop-down menu on the top right of the screen labeled “Status.” This status will inform the other doctors that you have taken this patient and will be helping them moving forward.",
            link: "https://staticfiles.drowl.com/drowl_files/za_faq_videos/Selecting_a_Case.mp4"
        },
        {
            displayTitle: "Status Definitions",
            title: "How do I look at the different statuses and know what is going on in a case?",
            text1: "There are five different statuses that a case goes through from start to finish. These statuses are Open, Assessing, Waiting for GP, In Progress, and Closed.",
            text2: "The first status is the green “Open” Status which means that the patient has just entered into the telemedicine queue from their Eagle Intelligent Health app and that they have not yet been serviced.",
            text3: "The next status that a case goes through is the blue “Assessing” status which is where someone in chat triage  has picked up the case and is determining whether or not the patient is eligible and ready to see a telemedicine doctor or if they need to be referred out to see a doctor immediately in person or if this should not be a telemedicine call at all. Once that telemedicine user is ready to be seen by an Eagle Intelligent Health doctor, the person assessing in chat triage will change the status to “Waiting for GP.",
            text4: "The status “Waiting for GP” means that the case is ready to be claimed by a doctor. When logging on to Eagle Intelligent Health and looking for which cases are ready to be serviced, look specifically for those in this status. Once it is selected, the chat triage and the patient’s history can be seen; then the patient can then be serviced.",
            text5: "Once the case is selected, change the status to “In Progress” which can be selected from the drop-down menu on the top right of the screen titled “Status.” Cases that are in this status mean that a doctor is currently servicing that case and is seeing the patient on telemedicine.",
            text6: "The last case status is “Closed” which will be listed under the “Closed Cases” tab, not the “Open” cases tab. After all of the information has been added into the telemedicine portal, close the case out and complete the transaction with the patient by selecting “Closed” from the drop-down menu on the top right of the screen titled “Status.”",
            link: "https://staticfiles.drowl.com/drowl_files/za_faq_videos/Case_Status_Explanation.mp4"
        },
        {
            displayTitle: "Reviewing Patient Case History",
            title: "How do I review a patient’s medical history?",
            text1: "To review your patient’s medical history and see a patient snapshot, first open the case. To open the case, select the green “Open” button found on the second column of the “Telemedicine Cases” screen.",
            text2: "The provider that will be taking the case, will need to select “In Progress” from the “Status” drop-down menu found on the top right of the screen. Then go to the “Patient” tab found on the left side of the screen.",
            text3: "From the “Patient” tab, select “Snapshot” from the drop down. This will open a sheet that provides very easy to understand and easy to read information about their medical history, social history, and any conditions or medications that the patient is currently taking.",
            text4: "To review if the patient has seen other Eagle Intelligent Health providers, select “All Cases” from the drop-down menu of the “Patient” tab.",
            link: "https://staticfiles.drowl.com/drowl_files/za_faq_videos/EIH__Patient_History.mp4"
        },

        {
            displayTitle: "Patient Snapshot",
            title: "What is a “Patient Snapshot” and where is it located?",
            text1: "The “Patient Snapshot” is a concise bit of information which includes anything the patient has reported or information that has been previously recorded on the Eagle Intelligent Health platform. For instance, any medications or allergies the patient has can be seen. The information found in the “Patient Snapshot” will provide helpful information as the telemedicine call is taking place.",
            text2: "After a case has been taken and switched to the case Status, “In Progress,” the Patient’s medical information can be looked through from the Patient Snapshot.",
            text3: "To change the status to “In Progress” and see the patient’s medical information, click on the drop-down menu on the top right of the screen titled “Status” and select “In Progress.” Then, from the top left side of the screen, select the drop-down arrow on the “Patient” tab and choose “Snapshot.”",
            link: "https://staticfiles.drowl.com/drowl_files/za_faq_videos/Patient_Snapshot.mp4"
        },
        ],
    "During A Telemedicine Call": [
        {
            displayTitle: "Review of Systems",
            title: "How do I complete a review of the patient’s systems when assessing the patient? ",
            text1: "As you assess your patient during a telemedicine visit, in the “Progress Notes” tab there is a section titled “Review of Systems.” When completing a review of the systems, not all listed in that section need to be filled out; only fill out what is pertinent and makes sense for your patient.",
            text2: "To complete the Review of Systems, from an “Open” case, click on the “Progress Notes” tab found on the top of the screen. Scroll down to the section titled “Review of Systems” and select the drop-down arrow next to each one. Then select whichever boxes that indicate the different symptoms the patient is reporting or that has been observed. Each one that is clicked on will be automatically saved where it is then connected to that patient for that specific visit.",
            link: "https://staticfiles.drowl.com/drowl_files/za_faq_videos/Review_of_Systems.mp4"
        },
        {
            displayTitle: "Plan of Treatment",
            title: "How do I include a “Plan of Treatment” after concluding a telemedicine visit?",
            text1: "At the end of the call with your patient, a “Plan of Treatment” should be included. This is information provided to your patient about the call and what they are to do to get the treatment they need in order to get better.",
            text2: "In the text box labeled “Plan of Treatment” located in the middle of the screen of an “open” case, write, for example, “Needs to rest for 24 hours and drink plenty of fluids.”",
            text3: "Provide any information that happened during the visit that you feel would be sufficient and needs to be saved to the portal. For example, if a patient refuses treatment, include that in your Plan of Treatment so that it can be noted what you have provided for the patient and what they are providing as well. Anything included in the “Plan of Treatment” section will go into the patient’s Eagle Intelligent Health app where they will have access to it after the visit is concluded.",
            link: "https://staticfiles.drowl.com/drowl_files/za_faq_videos/Plan_of_Treatment.mp4"
        },
        {
            displayTitle: "Initiating Zoom w/Patient",
            title: "How do I initiate a Zoom call with a patient?",
            text1: "When ready to see your patient on the Zoom call, the only button that needs to be clicked is “Start Video Chat.” The “Start Video Chat” button is a green video camera icon located next to the patient’s name and case ID on the top of the screen. Selecting this icon will then send your patient a link to join from their app. It will also launch a new Zoom window for you.",
            text2: "From there, a pop-up screen will open with a message that says “Open Zoom Meetings.” Please note: if this message is not received, it may mean that there is a pop-up blocker and pop-ups need to be allowed on the page in order to allow the Zoom screen to open.",
            text3: "Once “Open Zoom Meetings” is selected, a Zoom screen will open and it will ask “Join with Computer Audio.” Either join with the computer audio or join with a phone call. It may be preferable to join by phone instead of with the computer audio if there are audio issues or slow internet. This can be done by selecting “Phone Call” and the region “South Africa” from the drop-down menu, where the Zoom meeting can be called into as well.",
            text4: "After these steps, you will be able to see your patient via Zoom screen. The Zoom screen does open on another page which will allow notes to be written into the doctor portal as the Zoom call is taking place or after the call has ended.",
            link: "https://staticfiles.drowl.com/drowl_files/za_faq_videos/Initiate_Zoom_Call.mp4"
        },

        {
            displayTitle: "Adding/Removing Conditions",
            title: "How do I list the observed condition of the patient after a telemedicine visit?",
            text1: "As you create your “Assessment and Plan,” assign a condition to the patient based on what you have observed and heard from the patient.",
            text2: "To add their condition to the portal, from an “open” case, click on the green plus sign found in the middle left of the screen titled “Conditions” under the “Assessment and Plan” section. When the form pulls up, start typing the condition that needs to be added in the text box titled “Conditions.” If the condition does not populate exactly as liked, type in whatever wording and it will save it as written.",
            text3: "Notes can also be written for this condition, as well as whatever else in the “Notes” section found below the condition drop down. Click on the green “Add” button on the bottom of the form to ensure the condition is listed and have it show up in the patient portal.",
            link: "https://staticfiles.drowl.com/drowl_files/za_faq_videos/Conditions.mp4"
        },
        // {
        //     title: "What is the first step to complete once logging into the Eagle Intelligent Health doctor portal? ",
        //     text1: "After logging into the Eagle Intelligent Health doctor portal, the first task is to complete your personal profile. In order to do this, click on the small circle icon on the top right corner of the screen which will lead to your profile. Inside the profile, fill out as many of the items as possible that are applicable to you. This information will be used to complete your prescriptions, work sick notes and any other communication with your patients, so please ensure that it is completed to the best of your ability.",
        //     text2: "There is also an “Edit” icon found on the top middle of the screen to add a profile picture so that patients know to whom they are speaking. After the items are complete, choose the green “Submit” button to save all of the information."
        // },


    ],
    "After A Telemedicine Call": [
        {
            displayTitle: "Writing Referrals",
            title: "How do I write a referral for a patient?",
            text1: "To create a referral, go to the “Progress Notes” tab and select the green plus sign next to the section titled “Notes for Patient” found on the bottom of the screen. This is the same location for writing a work sick note but instead the word “Referral” would be written in the field that says “Note Title.” The specialty can be included for instance by typing “Referral to Podiatrist.” Fill out each field that is relevant including “Date of First Consultation” or “Nature of Illness.”",
            text2: "In the “Nature of Illness” field, write the reason you are referring this patient. Please note: anything left blank in the form will be removed, while any fields filled out will be present in your referral. Select the green “Create” button to finish creating your referral and thus allow it to be immediately available to the patient in their Eagle Intelligent Health app.",
            text3: "(Note: In the months to come, the Eagle Intelligent Health platform will have specialists and a patient referral will not be needed but until the platform becomes available and specialists are hired, in order to best serve your patient, there will be the option of writing referrals as needed.)",
            link: "https://staticfiles.drowl.com/drowl_files/za_faq_videos/Temporary_Referral_Instructions.mp4"
        },
        {
            displayTitle: "Creating Sick Notes",
            title: "How do I create a sick note after a telemedicine visit?",
            text1: "One of the options after a completed telemedicine visit is creating a “Note” for your patient. These types of notes include a work sick note, a school note or any other kind of unique note the patient might request of you.",
            text2: "To create a note, go to the “Progress Notes” tab and select the green plus sign next to the section titled “Notes for Patient” found on the bottom of the screen. When the form pulls up, type in the note title next to the field that says “Note Title.” The title can be labeled as whatever the patient is needing (ex. “Work Sick Note”) and the fields below it can be used, as necessary. Each of these fields is optional so if nothing is written in a field, that field will be removed from the finalized sick note. For example, if there was no “Second Consultation,” that field blank can be left blank and it will be removed from their sick note.",
            text3: "Next to the field titled “Date of first consultation,” write the date the patient was seen by you. Next to the phrase that says “According to my knowledge/as I was informed, he/she was unfit for work from:” write the dates which they reported they were unable to attend work or which dates you are requesting they take off. Next to the field titled “Nature of Illness” include what has been observed and determined from their visit with you.",
            text4: "Once the green “Create” button has been selected on the form, the sick note will immediately be available in the patient’s Eagle Intelligent Health app where the patient will be able to see and access it. Please note: the sick note does take information from your profile and uses it, so make sure your profile is always up to date.",
            link: "https://staticfiles.drowl.com/drowl_files/za_faq_videos/Sick_Note.mp4"
        },
        {
            displayTitle: "Adding Medications and Creating an Rx",
            title: "How do I add a medication and create a prescription on Eagle Intelligent Health?",
            text1: "At the bottom of the “Progress Note” tab on an open case, there is a section titled “Medications” with a green plus sign next to it. To add a medication, select the plus sign and once the form pulls up start typing the medication in the text box next to the field titled “Medication.” Please note that if it does not populate exactly how you’d like, type out exactly what is needed.",
            text2: "To add medication instructions for the patient, go to the “Notes” section found at the bottom of the form and type in those instructions. For example, write “Take one a day, as needed.” Select the green “Add” button to add the prescription to the list.",
            text3: "Once the medication is added to the list, finish creating the prescription by selecting the box/boxes of the medication and clicking on the green “Create Prescription” button. The completed prescription will be located in the middle of the screen next to the section titled “Prescriptions.” The prescription will be available to the patient in their Eagle Intelligent Health app right away where they will be able to access it from there.",
            text4: "If it is needed to change the prescription due to a mistake, select the trashcan icon located next to the completed prescription in the “Prescription” section or by selecting the box of the medication and selecting the “Delete Medication” button. Once deleted, the prescription can be redone. Note: up to 10 medications at a time can be added and one prescription with up to 10 medications can be added.",
            link: "https://staticfiles.drowl.com/drowl_files/za_faq_videos/Scripts.mp4"
        }],
}
]
