import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getDocument, getMyRequest, setDocumentLink} from "../../ducks/actionCreators/requestActionCreator";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import NoteIcon from "@mui/icons-material/Note";
import {Loader} from "@drowl/drowl-react-library";


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Requests = () => {
    const requestData = useSelector((state) => state.requestData);

    console.log(requestData, "requestData");

    const pendingRequest = requestData && requestData.requests.find(x => x.status === "pending");
    const approvedRequest = requestData && requestData.requests.find(x => x.status === "approved");

    const loader = useSelector((state) => state.loader);


    useEffect(() => {
        dispatch(getMyRequest());
    }, []);

    useEffect(() => {
        if(requestData.documentLink) {
            window.open(requestData.documentLink, "_target");
            dispatch(setDocumentLink(""));
        }
    }, [requestData && requestData.documentLink]);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const viewDocument =  (url) => {
        dispatch(getDocument(url, ""));
    }

    return (
        <div id="requestsWindow" className="pageWindow">
                <> {loader.loading && <Loader /> } </>
            <h2> Requests for Access</h2>
            {requestData && requestData.requests && requestData.requests.length > 0 && requestData.requests.map((x) => {
                const textColor = x.status ==="denied" ? 'red' :'green';
                return ( <Accordion sx={{border: "1px solid lightgrey", borderBottom: "1px solid lightgray !important", marginBottom: "5px"}}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography sx={{ width: '50%', flexShrink: 0, textAlign: "left", fontWeight: "500", marginRight: "10px" }}>
                             {x.request_id}
                        </Typography>
                        <Typography sx={{ color: textColor, width: '20%',  flexShrink: 0, textAlign: "left", fontWeight: "500", marginRight: "10px"} }>{x.status.toUpperCase()}</Typography>
                        <Typography sx={{ float: "right" }}>{new Date(x.timestamp).toLocaleDateString()}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        {x.status === "denied" && <Typography sx={{ textAlign: "left", fontWeight: "450", marginLeft: "30px" } }>Reason for denial: <span style={{color: "red"}}>{x.reason}</span></Typography>}
                        {x.documents.map(document => {
                          return( <ListItem style={{textAlign: "left"}}>
                            <ListItemButton key={x.id} onClick={() => viewDocument(document.url)}>
                                <ListItemIcon>
                                    <NoteIcon />
                                </ListItemIcon>
                                <ListItemText primary={document.title} />
                            </ListItemButton>
                        </ListItem>)

                        })}
                    </AccordionDetails>
                </Accordion> )
            }
                )}

            <br/>

            {(requestData.requests.length < 5 && !pendingRequest && !approvedRequest) &&
                <Button variant="contained" onClick={() => { navigate("/createNewRequest")}}> Create new request </Button>
            }

        </div>
    );
}

export default Requests;