import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";

const HistoryTabMobile = ({ rows, rowsPerPage, page, columnHeaders }) => {
    let navigate = useNavigate();
    return (
        <Table>
            {/* No Data  */}
            {rows.length === 0 &&
                <TableRow>
                    <TableCell colSpan={6} align='center'>No data to display</TableCell>
                </TableRow>
            }

            {(rowsPerPage > 0
                    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : rows
            ).map((row) => {
                return ( <TableBody className="mobileTableRow" onClick={() => {
                    navigate(`/viewRequestHistoryDetails/${row.request.request_id}`) } }
                                    key={row.request_id}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 },
                                        //     "&:nth-child(even) td" : {
                                        //     backgroundColor: "#3daf2c"
                                        // },
                                        "&:nth-child(odd) td" : {
                                            backgroundColor: "rgba(238,249,243,.8300000000000001)"
                                        }
                                    }}
                >

                    <TableRow>
                        <TableCell variant='head'>{columnHeaders[0]}</TableCell>
                        <TableCell>
                            {row.request.request_id}
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell variant='head'>{columnHeaders[1]}</TableCell>
                        <TableCell>{row.name}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell variant='head'>{columnHeaders[2]}</TableCell>
                        <TableCell>{row.email}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell variant='head'>{columnHeaders[3]}</TableCell>
                        <TableCell>{new Date(row.request.last_updated).toLocaleDateString()}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell variant='head'>{columnHeaders[4]}</TableCell>
                        <TableCell>{row.admin_id}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell variant='head'>{columnHeaders[5]}</TableCell>
                        <TableCell>{row.request.status}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell variant='head'>{columnHeaders[6]}</TableCell>
                        <TableCell>{row.request.status === "denied" ? row.request.reason : ""}</TableCell>
                    </TableRow>

                </TableBody>)

            })}
        </Table>
    );
};

export default HistoryTabMobile;