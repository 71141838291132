import React, {useState, useEffect} from "react";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {getAdminStats, getDoctorStats} from "../../../ducks/actionCreators/docStatsActionCreator";
import DoctorInfo from "../Overview/DoctorInfo";
import {Charts} from "../Overview/Charts";
import Table from "../../Table";
import {Button} from "@mui/material";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DateRangeIcon from "@mui/icons-material/DateRange";


const DEFAULT_DATE_RANGE = [moment().subtract(29, "days").toDate(), moment().toDate()];

const CombinedStats = () => {

    const [dateRange, setDateRange] = useState({
        from_date: moment(DEFAULT_DATE_RANGE[0]).format("YYYY-MM-DD"),
        to_date: moment(DEFAULT_DATE_RANGE[1]).format("YYYY-MM-DD"),
        label: moment(DEFAULT_DATE_RANGE[0]).format("YYYY-MM-DD") + " to " + moment(DEFAULT_DATE_RANGE[1]).format("YYYY-MM-DD")
    });

    const [rows, setRows] = useState([]);

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const urlParams = useParams();

    const statsData = useSelector(state => state.statsReducer);

    const {stats} = statsData;

    const {doctors} = stats;

    const handleDateRangeChange = (start, end) => {
        setDateRange({
            from_date: start.format("YYYY-MM-DD"),
            to_date: end.format("YYYY-MM-DD"),
            label: start.format("YYYY-MM-DD") + " to " + end.format("YYYY-MM-DD")
        });
        const params = {
            from_date: start.format("YYYY-MM-DD"),
            to_date: end.format("YYYY-MM-DD"),
        };
        dispatch(getAdminStats(params));
    };


    useEffect(() => {
        const params = {
            from_date: moment(DEFAULT_DATE_RANGE[0]).format("YYYY-MM-DD"),
            to_date: moment(DEFAULT_DATE_RANGE[1]).format("YYYY-MM-DD"),
            assignee_id: urlParams.doctorId
        };
        dispatch(getAdminStats(params));
    }, []);

    const columnHeaders = ["Doctor", "Number of Visits", "Amount Owed", "Amount Earned"];

    const rowHeaders = ["doctor", "num_visits", "amt_owed", "amt_earned"];

    useEffect( () => {
        // const compareFn = (i, j) => (Number(i.num_visits) - Number(j.num_visits))
        if(doctors && doctors.length>0){
            // doctors.sort(compareFn).reverse();
            setRows(doctors);
        }
    }, [doctors]);


    return (
        <div className="textBox pageWindow" id="statsWindow">
            <br/>
            <DoctorInfo type="ADMIN" title="OVERVIEW"/>
            <br/>
            <div>
                <div className="filterBox">
                    <DateRangePicker
                        initialSettings={{
                            startDate: moment(dateRange.from_date).toDate(),
                            endDate: moment(dateRange.to_date).toDate(),
                            ranges: {
                                "Today": [moment().toDate(), moment().toDate()],
                                "Yesterday": [
                                    moment().subtract(1, "days").toDate(),
                                    moment().subtract(1, "days").toDate()
                                ],
                                "Last 7 Days": [
                                    moment().subtract(6, "days").toDate(),
                                    moment().toDate()
                                ],
                                "Last 30 Days": [
                                    moment().subtract(29, "days").toDate(),
                                    moment().toDate()
                                ],
                                "This Month": [
                                    moment().startOf("month").toDate(),
                                    moment().endOf("month").toDate()
                                ],
                                "Last Month": [
                                    moment().subtract(1, "month").startOf("month").toDate(),
                                    moment().subtract(1, "month").endOf("month").toDate()
                                ]
                            }
                        }}
                        onCallback={ handleDateRangeChange} >
                        <div
                            className="col-xs-9 col-md-5 col-sm-6 form-control dateRangePicker datePickerSearch"
                        >
                            <span><DateRangeIcon /></span>
                            <span>{dateRange.label}</span>
                        </div>
                    </DateRangePicker>
                    {/*<TextField*/}
                    {/*    className="searchBox"*/}
                    {/*    placeholder="Search"*/}
                    {/*    value={searchText}*/}
                    {/*    onChange={(e) => setSearchText(e.target.value)}*/}
                    {/*    InputProps={{*/}
                    {/*        endAdornment: <InputAdornment position="end"> <IconButton> <PersonSearchIcon /> </IconButton> </InputAdornment>,*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <span style={{marginLeft: "10px"}}></span>
                    {/*<SelectionList*/}
                    {/*    classname="dropdown"*/}
                    {/*    options={SORT_BY_FIELDS}*/}
                    {/*    selectedValue={sortValue}*/}
                    {/*    setSelectedValue={(e) => setSortField(e.target.value)}*/}
                    {/*    defaultTitle="SORT BY"*/}
                    {/*    sortField={() => requestSort(sortValue, rows)}*/}
                    {/*    sortOrder={sortOrder}*/}
                    {/*    sortBy={sortBy}*/}
                    {/*/>*/}
                </div>
            </div>
            <Charts />
            <br/><br/>
            <h4>List of doctors with most num of visits </h4>
                <Table columnHeaders={columnHeaders} rowHeaders={rowHeaders} rows={rows} rowsPerPage={5} page={0} alignRows="" rowClick={true}/>
            <Button onClick={() => navigate("/overviewDetail")}>Show More</Button>
        </div>
    )
}

export default CombinedStats;