import React, {useEffect} from 'react';
import Checkbox from '@mui/material/Checkbox';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import HistoryIcon from '@mui/icons-material/History';
import "../ProgressNote/ProgressNote.css";

import './ProgressNote.css';
import { FormControl, FormGroup, FormControlLabel } from '@mui/material';
import {useDispatch, useSelector} from "react-redux";


export default function ReviewOfSystems(props) {
  const patientOverviewData = useSelector(state => state.patientOverview);
  const { progressNote, ticket } = patientOverviewData;

  const dispatch = useDispatch();

  const [generalChecks, setGeneralChecks] = React.useState(props.reviewOfSystemsSection);
  const [showSymptomsHistory, setShowSymptomsHistory] = React.useState();
  const [history, setHistory] = React.useState();

  useEffect(() => {
    setGeneralChecks(props.reviewOfSystemsSection);
  }, [progressNote])


  // Handle when general items are selected.
  const handleItemCheck = (event) => {
    const eventName = event.target.name;
    const currentState = generalChecks[eventName];
    const newGeneralChecks = { ...generalChecks, [eventName]: !currentState };

    setGeneralChecks(newGeneralChecks);
    props.updatedReviewOfSystemsSection(props.title, newGeneralChecks);
  };

  const showSubGroupItems = (index) => {
    const relatedSubEle = document.getElementById("subGroupItems" + index);
    if (relatedSubEle.offsetParent === null) {
      relatedSubEle.style.display = "block";
    }
    else {
      relatedSubEle.style.display = "none";
    }
  };

  const showSymptomHistory = (data) => {
    setShowSymptomsHistory(!showSymptomsHistory);
    setHistory(data);
}

  return (
    <div>
      <div style={{display: "flex",  marginBottom: "10px"}}>
      <div className="section-subgroup" style={{ marginRight: "15px" }}>{props.title + ':'}</div>
      <div style={{color: "grey !important"}}> <ArrowDropDownCircleIcon onClick={() => { showSubGroupItems(props.index) }} />
      </div>
      </div>
      {/*<FontAwesomeIcon id="pn-ros-General-img" icon={faChevronCircleDown} className="pn-expndFa"  />*/}
      <div className="form-group">
        <div id="pn-ros-General-div" className="pn-expandSubSec" />
        <div className="form-group sub-group-items" id={`subGroupItems${props.index}`}>
          <FormControl component="fieldset" style={{ paddingLeft: '25px' }}>
            {props.reviewOfSystemsSection &&
              <FormGroup>
                {Object.entries(props.reviewOfSystemsSection).map(([key, value]) => {
                  return (<>
                  <div style={{display: "flex"}}><FormControlLabel
                    key={key}
                    control={<Checkbox checked={generalChecks[key]}
                      name={key}
                      onChange={handleItemCheck}
                      disabled={ticket.status === 3}
                    />}
                    label={key}
                  />
                    {props.symptomsHis && props.symptomsHis[key]?.length > 0 && <>
                    <HistoryIcon style={{marginTop: "10px"}} onClick={() => { showSymptomHistory(props.symptomsHis[key]) }} />
                    </>}

                  </div>
                        {props.symptomsHis && props.symptomsHis[key]?.length > 0 && showSymptomsHistory && history.map(x => {
                          const date = new Date(Number(x));
                          const options = { year: 'numeric', month: 'short', day: 'numeric' };
                          const dateString = date.toLocaleDateString('en-US', options);
                          const timeString = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
                          return (<ul><li>This symptom was previously reported on {dateString} at {timeString}.</li></ul>)
                        }) }
                  </>
                  )
                })}

              </FormGroup>
            }
          </FormControl>
        </div>
      </div>
    </div>
  );
}