import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { TextField, IconButton, Button } from '@mui/material';
import './Search.css';
import AdvancedSearch from '../AdvancedSearch/AdvancedSearch';
import SearchResults from '../SearchResults/SearchResults';
import {useDispatch, useSelector} from "react-redux";
import { getSearchResults, getPatients, setSearchButtonClicked } from "../../ducks/actionCreators/searchActionCreator"
import {useNavigate} from "react-router-dom";
import {Loader} from "@drowl/drowl-react-library";


const Search = () => {
    const [searchText, setSearchText] = useState('');
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const dispatch = useDispatch();
    const loaderData = useSelector((state) => state.loader);

    const navigate = useNavigate();

    const data = useSelector((state) => state.search);
    const { search : results, patients , searchButtonClick} = data;
    // Top search bar
    const searchForText = (e) => {
        e.preventDefault();
        const trimmedSearchText = searchText.trim();
        const searchParams = { 'keywords': trimmedSearchText };
        dispatch(getSearchResults(searchParams))
        findPatients(searchParams);
    };
    // advanced search

    const findPatients = (param) => {
       dispatch(getPatients(param));
    }

    return (
        <div id="search" className="searchWindow" >
            <> {loaderData.loading && <Loader /> } </>
                <h1 className='page-title'>
                    Search
                </h1>
            {/*    <TextField*/}
            {/*        variant='outlined'*/}
            {/*        value={searchText}*/}
            {/*        className='searchFields'*/}
            {/*        id='primarySearchBox'*/}
            {/*        onChange={(e) => setSearchText(e.target.value)}*/}
            {/*        InputProps={{*/}
            {/*            endAdornment:*/}
            {/*                <IconButton*/}
            {/*                    aria-label="search"*/}
            {/*                    onClick={searchForText}>*/}
            {/*                    <SearchIcon />*/}
            {/*                </IconButton>*/}
            {/*        }}*/}
            {/*    />*/}
            {/*<div>*/}
            {/*    <div*/}
            {/*        className='searchFields advancedSearchAccordion'*/}
            {/*        onClick={() => { setShowAdvancedSearch(!showAdvancedSearch) }}>*/}
            {/*        Advanced Search */}
            {/*        {showAdvancedSearch ? <span className="caret-align"><ExpandLessIcon /></span> : <span className="caret-align"><ExpandMoreIcon /></span>}*/}
            {/*    </div>*/}

                {/*{showAdvancedSearch &&*/}
                    <AdvancedSearch />
                {/*// }*/}
            {/*</div>*/}

            {searchButtonClick && ((results && results.length === 0) || results === null) ?
                <>
                    <p  style={{marginTop: "50px"}} >No Patient was found with your search criteria.</p>
                    <Button variant="contained" style={{marginTop: "50px"}} onClick={() => {navigate("/createPatient")} }>Create New Patient</Button>
                </>
                 :

            <div id='results'>
                <SearchResults />
            </div> }

            {/*<div id='results'>*/}
            {/*    <SearchResults />*/}
            {/*</div>*/}

        </div >
    );
}

export default Search;

