import { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ROLES} from "../Main";
import {getCognitoGroups} from "../utils";
import {setUserGroup} from "../ducks/actionCreators/loginActionCreator";
import {getProfileImg} from "../ducks/actionCreators/settingsActionCreator";


export const useUserRole = () => {
    const {userGroup} = useSelector((state) => state.login);
    const {profileInfo} = useSelector((state) => state.settings);

    const dispatch = useDispatch();

    const [operatorData, setOperatorData] = useState({});

    const {
            name,
            family_name,
            dob,
            gender,
            bio,
            practice_name,
            south_africa_id,
            bussiness_address1,
            bussiness_city,
            bussiness_state,
            bussiness_zipcode,
            languages,
            user_reg_status
        } = profileInfo || {};

    useEffect(() => {
        getCognitoGroups().then(res => {
            dispatch(setUserGroup(res));
        });
        dispatch(getProfileImg());
    }, []);


    useEffect(() => {
        if(profileInfo && Object.keys(profileInfo).length > 0) {
            setOperatorData({name: name || false,
                family_name: family_name || false,
                dob: dob || false,
                gender: gender || false,
                bio: bio || false,
                practice_name: practice_name || false,
                south_africa_id: south_africa_id || false,
                bussiness_address1: bussiness_address1 || false,
                bussiness_city: bussiness_city || false,
                bussiness_state: bussiness_state || false,
                bussiness_zipcode: bussiness_zipcode || false,
                languages: languages || false});
        }
    }, [name, family_name, dob, gender, bio, practice_name,south_africa_id,  bussiness_address1, bussiness_city,bussiness_state, bussiness_zipcode, languages])


    const isNotEmpty = Object.values(operatorData).every(x => x !== false);

    let userRoles = [];
    if(userGroup && userGroup[0] === "view_doctor_requests") {
        userRoles.push(ROLES.ADMIN)
    }
    else if (userGroup && userGroup[0] === "operators" && !isNotEmpty) {
        userRoles.push(ROLES.UNAPPROVED_OPERATOR)
    }
    else if(userGroup && userGroup[0] === "operators") {
        userRoles.push(ROLES.OPERATOR)
    } else if (user_reg_status === "approved") {
        userRoles.push(ROLES.APPROVED_DOCTOR)
    }
    else {
        userRoles.push(ROLES.UNAPPROVED_DOCTOR)
    }
    return userRoles;
}