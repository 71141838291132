import React, {useEffect} from 'react';
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "./components/Header/Header";
import Login from "./components/Login";
import Footer from "./components/Footer/Footer";
import "./Main.css"

import awsConfig from './config/aws.js';
import analyticsConfig from "./config/analyticsConfig";
import { Amplify, Analytics } from "aws-amplify";
import {getProfileImg, setProfileInfo} from "./ducks/actionCreators/settingsActionCreator";
import {PrivateRoute} from "./routesConfig/PrivateRoute"
import {routes} from "./routesConfig/routes"
import {
    subscribeToAllStatus, subscribeToDocNotifications,
    subscribeToTickets
} from "./graphql/graphqlSubscriptions";
import Missing from "./Missing";
import ShareDocument from "./components/ShareDocument/ShareDocument";
import Unauthorized from "./Unauthorized";
import DemoSubmitForm from "./components/Login/DemoSubmitForm";

Amplify.configure(awsConfig);
Analytics.configure(analyticsConfig);

export const ROLES = {
    ADMIN: 'ADMIN',
    APPROVED_DOCTOR: 'APPROVED_DOCTOR',
    OPERATOR: 'OPERATOR',
    UNAPPROVED_DOCTOR: 'UNAPPROVED_DOCTOR',
    UNAPPROVED_OPERATOR: 'UNAPPROVED_OPERATOR'
};

export const Main = () => {
    const data = useSelector((state) => state.login);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     if(data && data.isUserAuthenticated){
    //         dispatch(setProfileInfo({}));
    //         dispatch(getProfileImg());
    //     }
    // }, [data && data.isUserAuthenticated]);

    useEffect( () => {
        const asyncFunc = async() => {
            await dispatch(subscribeToTickets());
            await dispatch(subscribeToAllStatus());
            await dispatch(subscribeToDocNotifications());
        }
        asyncFunc();
    }, []);

    return (
        <div className="">
            <Header />
                <Routes>
                    {routes.map((route) => (
                        <Route
                            key={route.path}
                            path={route.path}
                            element={<PrivateRoute element={route.element} roles={route.roles} />}
                        />
                    ))}
                    <Route exact path="/" element={<Login />} />
                    <Route exact path="/requestDemo" element={<DemoSubmitForm />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/ShareFile" element={<ShareDocument />} />
                    <Route path="/unauthorized" element={<Unauthorized />} />
                    <Route path="/404" element={<Missing />} />
                    <Route path="*" element={<Missing />} />
                </Routes>
            <Footer />
        </div>
    );
};

const MainRouter = Main;

export default MainRouter;
