import React from "react";
import {Button, MenuItem, Select} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";


const SelectionList = ({options, selectedValue, setSelectedValue, defaultTitle, classname, sortField, type, sortOrder, sortBy}) => {
    return (
        <Select
            className={classname}
        displayEmpty
        value={selectedValue}
        onChange={setSelectedValue}
        renderValue={(selected) => {
            if (selectedValue && selectedValue.length === 0) {
                return (<MenuItem  disabled value="">
                    <span style={{ marginRight: "10px"}}>{defaultTitle}</span>
                </MenuItem>);
            }
            return <p style={{textAlign: "left"}}>{selected}</p> ;
        }}
    >
        {options.map((option, index) => <MenuItem
            className="menuItem"
            key={option.value}
            value={option.label}
        > {type !== "filter" ? <Button className="menuOption" endIcon={sortBy === option.label && sortOrder === "asc" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon/> } onClick={() => sortField(selectedValue)}><p> {option.label} </p></Button> : <p> {option.label} </p> }
        </MenuItem> ) }
    </Select>
    )
}

export default SelectionList;