import {BASE_URL, getCurrentUserToken} from "../../utils";
import {CREATE_PATIENT, PATIENT_MESSAGE} from "../actions/createPatientActions";
import {setSearchButtonClicked} from "./searchActionCreator";
import {setIsLoading} from "./settingsActionCreator";
import {setLoader} from "./loaderActionCreator";
import {Analytics} from "aws-amplify";

export const setCreatePatientRes = (data) => ({ type: CREATE_PATIENT, payload: data });
export const setPatientMessage = (data) => ({ type: PATIENT_MESSAGE, payload: data });

export const createNewPatient = (data)  => async(dispatch) => {
    dispatch(setLoader(true));
    const token = await getCurrentUserToken();

    try {
        const apiUrl = BASE_URL+"/TeleMedicineDrOwl/createPatientProfile"
        await fetch(apiUrl, {
            "method": "POST",
            "headers": {
                "token": token,
                "Content-Type": "application/json"
            },
            "body": JSON.stringify({
                "token": token,
                "idNumber" : data.idNumber,
                "firstName" :data.firstName,
                "middleName" : data.middleName,
                "lastName" : data.lastName,
                "email" : data.email,
                "phoneNumber" : data.phoneNumber,
                "postalCode" : data.postalCode,
                "ethnicity" : data.ethnicity,
                "countryCode" : data.countryCode,
                "gender":data.gender})


        }).then(response => response.json()).then(async (resp) => {
            dispatch(setCreatePatientRes(resp));
            if(resp && resp.status){
                dispatch(setPatientMessage({ message: resp.message, type: "success", show: true}))
                // await Analytics.record({
                //     screen: "patientAddedInTM", datetime: new Date().toLocaleString()
                // });
            }
            else {
                dispatch(setPatientMessage({ message: resp.message, type: "error", show: true}))
            }
            dispatch(setSearchButtonClicked(false));
            dispatch(setLoader(false));
            }
           ).catch(err => {
                dispatch(setPatientMessage({ message: err || "Something went wrong. Please try again later!", type: "error", show: true}))
            dispatch(setLoader(false));
           });
    }
    catch (e) {
        dispatch(setPatientMessage({ message: "Something went wrong. Please try again later!", type: "error", show: true}))
        dispatch(setLoader(false));
        console.log(e);
    }

};