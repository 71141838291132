import React, { useState, useEffect }  from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { TextField, Button, IconButton }from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import { SnackbarAlert } from "../common/SnackbarAlert";
import { changePassword, isUserLoggedIn } from "../../ducks/actionCreators/loginActionCreator";
import { setMessage } from "../../ducks/actionCreators/messageActionCreator";
import {Alert} from "@mui/lab";

export default function ChangePassword(props) {
    const [previousPassword, setPreviousPassword] = useState("");
    const [showPrevious, setShowPrevious] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const dispatch = useDispatch();
    const data = useSelector((state) => state.login);
    const messageData = useSelector((state) => state.message);

    const passwordChange = () => {
        dispatch(isUserLoggedIn());
        dispatch(changePassword(data.userData, previousPassword, password, confirmPassword));
    };

    useEffect(() => {
        setPreviousPassword("");
        setPassword("");
        setConfirmPassword("");
    }, [messageData.message.text]);

    return (
        <div id="loginWindow" className="pageWindow" style={{ marginTop: "60px"}}>
            {messageData.message.show && <Alert
                           onClose={() => dispatch(setMessage({text: "", error: "", show: false}))}
                           severity={messageData.message.type}
            >{messageData.message.text}</Alert> }

            <h3>Change Password</h3>
            {/* Old Password */}
            <div>
                <TextField
                    type={showPrevious ? "text" : "password"}
                    className="resetPasswordTextField"
                    placeholder="Old Password"
                    variant="outlined"
                    value={previousPassword}
                    onChange={(e) => {
                        dispatch(setMessage({text: "", error: "", show: false}))
                        setPreviousPassword(e.target.value.trim())}
                    }
                    InputProps={{
                        endAdornment:
                            <IconButton
                                onClick={() => setShowPrevious(!showPrevious)}
                                edge="end"
                            >
                                {showPrevious ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                    }}
                />
            </div>
            {/* New Password */}
            <br/>
            <div>
                <TextField
                    type={showPassword ? "text" : "password"}
                    className="resetPasswordTextField"
                    placeholder="New Password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => {
                        dispatch(setMessage({text: "", error: "", show: false}))
                        setPassword(e.target.value.trim())
                    }}
                    InputProps={{
                        endAdornment:
                            <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                    }}
                />
            </div>

            <br/>
            <div>
                <TextField
                    type={showPassword ? "text" : "password"}
                    className="resetPasswordTextField"
                    placeholder="Confirm Password"
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => {
                        dispatch(setMessage({text: "", error: "", show: false}))
                        setConfirmPassword(e.target.value.trim())
                    }}
                    InputProps={{
                        endAdornment:
                            <IconButton
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                edge="end"
                            >
                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                    }}
                />
            </div>
            {/* Update Buttons  */}
            <br/>
            <div>
                <Button variant="contained"
                        onClick={() => passwordChange()}
                        className='actionBtn'
                        style={{
                            backgroundColor: "rgb(61, 175, 44)",
                            color: "white"
                        }}
                        id="updatePasswordBtn"
                >
                    Update
                </Button>

            </div>
        </div>
    );
}

