import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

function Loading(props) {
    return (
        <Grid
            container
            justifyContent='center'
            alignItems='center'
            style={{ height: '80vh' }}>
            <Grid item >
                <CircularProgress size={props.size?? 40} />
            </Grid>
        </Grid>
    );
}

export default Loading;