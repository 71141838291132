import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import {Checkbox, FormControlLabel} from "@mui/material";

const PlanMedTableMobile = ({columnHeaders, assessmentMedications, selectedMedications, handleCheckboxSelection})=> (
    <Table sx={{border: "1px solid grey", borderBottom: "1px solid grey !important", borderCollapse: "revert"}}>

        {assessmentMedications && assessmentMedications.map((medication, index) => {
            return ( <TableBody key={index} >
                <TableRow>
                    <TableCell variant='head'> {columnHeaders[0]} </TableCell>
                    <TableCell>
                        {/*<FormControlLabel*/}
                        {/*    control={*/}
                                <Checkbox
                                    checked={selectedMedications[index]}
                                    onChange={(e) => handleCheckboxSelection(e,index)}
                                    disabled = {!!medication && medication.prescription_id}
                                />
                        {/*    }*/}
                        {/*/>*/}
                        {/*<Checkbox*/}
                        {/*    checked={selectedMedications[index]}*/}
                        {/*    onChange={(e) => handleCheckboxSelection(e,index)}*/}
                        {/*    disabled = {!!medication.prescription_id}*/}
                        {/*/>*/}
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell variant='head'> {columnHeaders[1]} </TableCell>
                    <TableCell>
                        {medication.medication}
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell variant='head'> {columnHeaders[2]} </TableCell>
                    <TableCell>
                        {medication.dosage}
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell variant='head'> {columnHeaders[3]} </TableCell>
                    <TableCell>
                        {medication.numPills}
                    </TableCell>
                </TableRow>

                <TableRow>
                    <TableCell variant='head'>{columnHeaders[4]}</TableCell>
                    <TableCell>
                        {medication.instructions}
                    </TableCell>
                </TableRow>

            </TableBody>)

        })}
    </Table> )

export default PlanMedTableMobile;

