import {Button, IconButton, Checkbox, FormControlLabel} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateProgressNote} from "../../ducks/actionCreators/patientOverviewActionCreator";
import {setLoader} from "../../ducks/actionCreators/loaderActionCreator";
import {Loader} from "@drowl/drowl-react-library";

{/* Current Allergies */}
const Allergies = () => {
    const patientOverviewData = useSelector(state => state.patientOverview);
    const { progressNote, ticket } = patientOverviewData;
    const loader = useSelector((state) => state.loader);
    // let  { medicine } = progressNote;

    const [isEdittingAllergies, setIsEdittingAllergies] = useState(false);
    const [currentAllergies, setCurrentAllergies] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        setCurrentAllergies(progressNote && progressNote.allergiesExisting);
    },[progressNote && progressNote.allergiesExisting]);

    const cancelEdit = () => {
        let result = [];

        for (let i = 0; i < currentAllergies.length; i++) {
            let curAllergy = { ...currentAllergies[i] };

            if (curAllergy.hasOwnProperty('doctor_deleted')) {
                curAllergy.doctor_deleted = getInitialStateOfAllergyStatus(currentAllergies)[i];
            } else if (curAllergy.hasOwnProperty('deleted')) {
                curAllergy.deleted = getInitialStateOfAllergyStatus(currentAllergies)[i];
            }
            result.push(curAllergy);
        }

        setCurrentAllergies([...result]);
        setIsEdittingAllergies(false);
    };

    const getInitialStateOfAllergyStatus = (data) => {
        // set the inital state on if a med is deleted or not.
        const result = [];
        for (let i = 0; i < data.length; i++) {
            let curAllergy = { ...data[i] };
            if (curAllergy.doctor_deleted) {
                result.push(curAllergy.doctor_deleted);
            } else if (curAllergy.deleted) {
                result.push(curAllergy.deleted);
            } else {
                result.push(false);
            }
        }
        return result;
    };

    const handleCurrentAllergiesChange = (i, isChecked) => {
        const temp = [...currentAllergies];
        temp[i].doctor_deleted = !isChecked; // isChecked true then doctor_delete is false

        setCurrentAllergies(temp);
    };

    const updateCurrentAllergies = () => {
        try {
            dispatch(setLoader(true));
            const updateProgressNoteVals = {
                ...progressNote,
                'allergiesExisting': currentAllergies
            };
            dispatch(updateProgressNote(updateProgressNoteVals, ticket._id));
            setIsEdittingAllergies(false);
            getInitialStateOfAllergyStatus(currentAllergies);
        }
        catch(err) {
            dispatch(setLoader(false));
        }
    };

    return(
        <>
            <> {loader.loading && <Loader /> } </>
            {(currentAllergies !== undefined && Object.keys(currentAllergies).length !== 0) &&
                <div className="form-group">
                    <div className="pn-subtitle" style={{display: "flex"}}>
                        Current Allergies:
                        {/*{ticket.status !== 3 && !isEdittingAllergies && <> (Click to edit) </>}*/}
                        {/*{ ticket.status !== 3 && <> {isEdittingAllergies ?*/}
                        {/*    <>*/}
                        {/*        <Button variant="contained" color='primary' onClick={() => updateCurrentAllergies()}*/}
                        {/*                style={{ marginLeft: '5px' , color: "white", padding: "5px" }}>*/}
                        {/*            Save*/}
                        {/*        </Button>*/}
                        {/*        <Button variant="contained" color='secondary' onClick={() => cancelEdit('allergies')}*/}
                        {/*                style={{ color: "white", padding: "5px" }}*/}
                        {/*        >*/}
                        {/*            Cancel*/}
                        {/*        </Button>*/}
                        {/*    </>*/}
                        {/*    :*/}
                        {/*    <IconButton onClick={() => setIsEdittingAllergies(true)} color="primary">*/}
                        {/*        <EditIcon fontSize="medium" style={{ marginTop: "-9px"}} />*/}
                        {/*    </IconButton>*/}
                        {/*}</> }*/}
                    </div>
                    <ul>
                        {currentAllergies.map((x, index) => {
                            return (
                                <li className={`subject-text dignsdAddtoPln ${x.deleted || x.doctor_deleted ? 'patientDelete' : ''}`} key={index} >
                                    {x.allergy}, {x.severity}

                                    {isEdittingAllergies && !x.deleted &&
                                        <Checkbox
                                            color='primary'
                                            checked={!x.doctor_deleted ?? true}
                                            onChange={() => handleCurrentAllergiesChange(index, ('doctor_deleted' in x ? x.doctor_deleted : false))}
                                        />}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            }
        </>
    )
}

export default Allergies;