import {
    GET_MEDICATIONS,
    GET_CONDITIONS
} from "../actions/medsCondsActions";

const init = {
    medicationsList: [],
    conditionsList: []
};

const medsCondsReducer = (state = init, action) => {
    switch (action.type) {

        case GET_MEDICATIONS: {
            return {...state, medicationsList: action.payload};
        }
        case GET_CONDITIONS: {
            return {...state, conditionsList: action.payload.conditionList};
        }
        default:
            return state;
    }
};
export default medsCondsReducer;