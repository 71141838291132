import React, {useState, useEffect} from 'react';
import './Header.css'
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SideMenu from '../SideMenu/SideMenu';
import {Tooltip, Grid, Divider} from '@mui/material';
import { signOut } from "../../ducks/actionCreators/loginActionCreator";
import { useSelector, useDispatch } from "react-redux";
import UserMenu from "../SideMenu/UserMenus";


const Header = () => {
  const [toggle, setToggle] = useState( false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.login);
  // toggle side menu

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  useEffect(() => {
    function handleWindowResize() {
      setIsMobile(window.innerWidth < 600);
    };
    window.addEventListener("resize", handleWindowResize);

  }, []);

  useEffect(() => {
   if(!isMobile) {
     setToggle(true);
   }

  }, [isMobile]);

  const location = useLocation();

  const toggleSideMenu = () => {
    setToggle(!toggle);
  };

  //sign out
  const handleSignOut = () => {
    dispatch(signOut());
  };

  return (
      <> {data && data.isUserAuthenticated && location.pathname !== "/shareFile" &&
          <span>
      <div className='headerContainer'>
        <Grid container
              direction="row"
              alignItems="center"
              wrap='nowrap'
              id='header-grid-container'
        >
          {/* Toggle menu Button */}
          {location.pathname !== "/shareFile" && <div className='menuToggleBtn'>
            <MenuIcon
                className='menuIcon'
                onClick={() => toggleSideMenu()}/>
          </div>}

          {/* Dr Owl Logo  */}
          <Grid item xs={8} className='headerLogo'>
            <Link to='/home'>
              <img className='headerLogoImg' src={"../../assets/logos/EagleIntelligentHealth_Logo_500x213.png"}/>
            </Link>
          </Grid>



          {/* User Image */}
          {location.pathname !== "/shareFile" && <Grid item xs>
            <div style={{textAlign: 'right', marginRight: "20px"}}>
              <UserMenu/>
            </div>
          </Grid>}
          {location.pathname !== "/shareFile" && <Grid item xs>
            <Tooltip title='Log Out' alt='logout'>
              <ExitToAppIcon
                  className='menuIcon'
                  style={{paddingLeft: "5px"}}
                  onClick={() => handleSignOut()}/>
            </Tooltip>
          </Grid>}

        </Grid>
         <Divider style={{ border: "3px solid rgb(2, 163, 244)", marginTop: "10px"}}/>
      </div>



            {toggle && location.pathname !== "/shareFile" &&
                <SideMenu toggle={toggle}/>
            }
            {/*{toggleMenu &&*/}
            {/*    <UserMenu toggle={toggleMenu} />*/}
            {/*}*/}
    </span>
      }

        {location.pathname === "/shareFile" &&
            <Grid className="shareLogo">
                <img style={{width: "110%"}} src={"../../assets/logos/EagleIntelligentHealth_Logo_500x213.png"}/>
            </Grid>
        }

      </>
  );

}

export default Header;
