import {ROLES} from "../../Main";
import React from "react";
import SideMenuItem from "./SideMenuItem";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import HomeIcon from "@mui/icons-material/Home";
import ListIcon from "@mui/icons-material/List";
import SearchIcon from "@mui/icons-material/Search";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import HelpIcon from "@mui/icons-material/Help";
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import {useUserRole} from "../../hooks/useUserRole";


const ProtectedRoute = ({ handleAdminPopOverClick }) => {

   const userRoles = useUserRole();
    const menuItems = [
        {
            name: 'Home',
            link: '/home',
            icon: HomeIcon,
            onClick: null,
            roles: [ ROLES.ADMIN, ROLES.OPERATOR, ROLES.APPROVED_DOCTOR],
        },
        {
            name: 'Cases',
            link: '/cases',
            icon: ListIcon,
            onClick: null,
            roles: [ ROLES.ADMIN, ROLES.OPERATOR, ROLES.APPROVED_DOCTOR],
        },
        {
            name: 'Search',
            link: '/search',
            icon: SearchIcon,
            onClick: null,
            roles: [ ROLES.ADMIN, ROLES.APPROVED_DOCTOR],
        },
        {
            name: 'Add Patient',
            link: '/addPatient',
            onClick: null,
            icon: PersonAddIcon,
            roles: [ ROLES.ADMIN, ROLES.APPROVED_DOCTOR],
        },
        {
            name: 'My Reports',
            link: '/myReports',
            onClick: null,
            icon: QueryStatsIcon,
            roles: [ ROLES.ADMIN, ROLES.APPROVED_DOCTOR],
        },
        {
            name: 'Requests',
            link: '/request',
            icon: AppRegistrationIcon,
            onClick: null,
            roles: [ ROLES.APPROVED_DOCTOR, ROLES.UNAPPROVED_DOCTOR],
        },
        {
            name: 'Admin',
            icon: QueryStatsIcon,
            onClick: handleAdminPopOverClick,
            roles: [ ROLES.ADMIN],
        },
        {
            name: 'Faqs',
            link: '/faqs',
            icon: LiveHelpIcon,
            onClick: null,
            roles: [ ROLES.ADMIN, ROLES.OPERATOR, ROLES.APPROVED_DOCTOR],
        },
        {
            name: 'Help',
            link: '/help',
            icon: HelpIcon,
            onClick: null,
            additionalClasses: "helpClass",
            roles: [ ROLES.ADMIN, ROLES.OPERATOR, ROLES.APPROVED_DOCTOR, ROLES.UNAPPROVED_DOCTOR],
        },
        // Add more menu items based on your application's requirements
    ];

    const filteredMenuItems = menuItems.filter(
        (item) => { return item.roles.includes(userRoles[0]) }
            // && item.permissions.every((perm) => roles[userRoles].includes(perm))
    );

    return (
        <nav>
            {filteredMenuItems.map((item) => (
                <SideMenuItem label={item.name}
                              linkTo={item.link}
                              IconToDisplay={item.icon}
                              onClickHandler={item.onClick}
                              additionalClasses={item.additionalClasses}
                />
            ))}
        </nav>
    );
}

export default ProtectedRoute;