import React, {useState} from 'react';
import './SideMenu.css';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import {Popover, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import ProtectedRoute from "./ProtectedRoute";

const SideMenu = ()  => {
    const [statsMenu, setStatsMenu] = useState(false);

    const showStatsMenu = () => {
        setStatsMenu(!statsMenu);
    }

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

      return (
        <span>
          <div id="#sideBar-Container">
            <div className="container-fluid">
              <div id="sidebar-wrapper">
                <div className="sidebar-nav">
                    <ProtectedRoute handleAdminPopOverClick={handleClick} />
                    {/*// TODO:  Overall stats, requests, find a doctor in the menu*/}

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        PaperProps={{
                            style: { width: "150px" },
                        }}
                    >
                         <figure className="fgrSidemenu" onClick={showStatsMenu}>
                              <Link to="/overview">
                                  <span style={{display: "flex", textAlign:"left"}}>
                                      <PersonSearchIcon className='sideMenuIcon' style={{marginRight: "5px", marginLeft: "-30px"}} fontSize='small' />
                                      <figcaption>Overview</figcaption> </span>
                              </Link>
                          </figure>
                          <figure className="fgrSidemenu" onClick={showStatsMenu}>
                              <Link to="/stats">
                                  <span style={{display: "flex"}}>
                                      <PersonSearchIcon className='sideMenuIcon' style={{marginRight: "5px"}} fontSize='small' />
                                      <figcaption>Find a Doctor</figcaption> </span>
                              </Link>
                          </figure>
                          <figure className="fgrSidemenu" onClick={showStatsMenu}>
                              <Link to="/viewRequests">
                                  <span style={{display: "flex"}}>
                                      <AppRegistrationIcon className='sideMenuIcon' style={{marginRight: "5px"}} fontSize='small' />
                                      <figcaption>View Requests</figcaption> </span>
                              </Link>
                          </figure>
                    </Popover>
                    {/*<span style={{ bottom: "10px", position: "absolute" , marginLeft: "18px", marginRight: "auto"}}>*/}
                    {/*    <SideMenuItem label="Help" linkTo="/help" IconToDisplay={HelpIcon} />*/}
                    {/*</span>*/}
                </div>
              </div>
            </div>
          </div>
        </span>
      );
}

export default SideMenu;
