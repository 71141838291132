import React from 'react';
import {Button, Dialog, DialogTitle, DialogContent, DialogActions} from '@mui/material';

const CloseCaseConfirmationModal = ({open, close, confirm}) => {
    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <DialogTitle>
                <b>Confirm Close Case</b>
            </DialogTitle>
            <DialogContent>
                <div className='deleteAssessmentTextContaioner' style={{marginTop: "20px"}}>
                    Are you sure you want to close this case?
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant="contained"  onClick={confirm}>Yes</Button>
                <Button variant="contained" color="secondary" onClick={close}>No</Button>
            </DialogActions>
        </Dialog>
    );

}

export default CloseCaseConfirmationModal;