import React, { useEffect, useState } from 'react';
import { Button, Paper } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './SearchResults.css';
import './../Cases/Cases';
import { Tabs, Tab, Box } from "@mui/material";
import TabPanel from "./../Cases/TabPanel";
import {Link, useNavigate} from 'react-router-dom';
import { formatDateInternational, formatPhoneNumber } from '../../utils/utlis';
import {useSelector} from "react-redux";


let currentIndex = 0;
let currentPatientIndex = 0;
export default function SearchResults({dontShowPatients}) {

    const [displaySubsetResults, setDisplaySubsetResults] = useState([]);
    const [displaySubsetPatients, setDisplaySubsetPatients] = useState([]);
    const [tab, setTab] = useState(0);
    const [page, setPage] = useState(0);
    const countPerPageResults = 10;
    const statusClasses = {
        0: 'caseOpen',
        1: 'caseInprogress',
        2: 'caseScheduled',
        3: 'caseClosed',
    };
    const data = useSelector((state) => state.search);
    const { search : results, patients } = data;

    const statusDisplayName = {
        0: 'Open',
        1: 'In Progress',
        2: 'Scheduled',
        3: 'Closed',
    };

    let navigate = useNavigate();

    useEffect(() => {
        if (results) {
            const temp = [...results];
            const subsetResults = temp.length > countPerPageResults ? temp.splice(0, countPerPageResults) : temp;
            setDisplaySubsetResults(subsetResults);
        }
    }, [results]);

    useEffect(() => {
        console.log(patients, "patients");
        if (patients) {
            const temp1 = [...patients];
            const subsetResults = temp1.length > countPerPageResults ? temp1.splice(0, countPerPageResults) : temp1;
            setDisplaySubsetPatients(subsetResults);
        }
    }, [patients]);

    const onTabChange = (e, newTab) => {
        setTab(newTab);
        setPage(0);
    }

    // Show next subset of patients 
    const showNextPagePatients = () => {
        currentPatientIndex = currentPatientIndex + countPerPageResults;  // start index to key off, end index will be plus count per page

        const temp = [...patients];
        const showResults = temp.splice(currentPatientIndex, countPerPageResults);

        setDisplaySubsetPatients(showResults);
        scrollResultsIntoView();

    };
    // Show prev subset of patients 
    const showPrevPagePatients = () => {
        currentPatientIndex = currentPatientIndex - countPerPageResults;

        const temp = [...patients];
        const showResults = temp.splice(currentPatientIndex, countPerPageResults);

        setDisplaySubsetPatients(showResults);
        scrollResultsIntoView();

    };

    // Show next subset of results 
    const showNextPage = () => {
        currentIndex = currentIndex + countPerPageResults;  // start index to key off, end index will be plus count per page

        const temp = [...results];
        const showResults = temp.splice(currentIndex, countPerPageResults);

        setDisplaySubsetResults(showResults);
        scrollResultsIntoView();

    };
    // Show prev subset of results 
    const showPrevPage = () => {
        currentIndex = currentIndex - countPerPageResults;

        const temp = [...results];
        const showResults = temp.splice(currentIndex, countPerPageResults);

        setDisplaySubsetResults(showResults);
        scrollResultsIntoView();

    };

    // After getting results scroll the top result into view
    const scrollResultsIntoView = () => {
        const searchBox = document.getElementById('primarySearchBox');
        if (searchBox) searchBox.scrollIntoView({ behavior: 'smooth' });
    };

    if (!results) {
        return (
            <div className='resultBody resultEmptyBody'>
                {/*Enter a term to search*/}
            </div>
        );
    }

    return (
        <div className="Search-results">
             <Box sx={{ width: '100%' , border: '1px solid green'}}>
            <Box sx={{ width: '100%' , borderBottom: 1, borderColor: 'divider' }} className="tabCss"></Box>
                 {!dontShowPatients && <Tabs value={tab} className='resultTabs' onChange={onTabChange}>
                <Tab className="search" label="Cases"></Tab>
                 <Tab className='resultTabs second-tabs' label="Patients"></Tab> }
            </Tabs> }

                 <h1> Cases</h1>

            <TabPanel value={tab} index={0}>
                {(results && results.length > 0) &&
                    <div>
                        {displaySubsetResults && displaySubsetResults.map((result) => {
                            return (<div key={result.ticket_id} className='resultGroup'>
                                <Paper className='resultElement' elevation={3}>
                                    <Link className='resultLink' to={`/patientOverview/${result.ticket_id}`}>
                                        <div className='resultHeader'>
                                            {result.first_name &&
                                                <div> Patient: {result.first_name} {result.last_name ?? ''}</div>
                                            }

                                            <div className={`${statusClasses[result.status] ?? statusClasses[3]} resultStatusHeader`}>
                                                {statusDisplayName[result.status] ?? statusDisplayName[3]}
                                            </div>
                                        </div>

                                        <div className='resultBody'>
                                            {result.assignee &&
                                                <div> <b style={{ fontWeight: '600' }}>Provider:</b> {result.assignee} </div>
                                            }

                                            <div>   <b style={{ fontWeight: '600' }}>Case Id:</b> {result.case_id} </div>
                                        </div>
                                    </Link>
                                </Paper>
                            </div>);
                        })}

                        < div className='page-button-container'>
                            {currentIndex !== 0 &&
                                <Button onClick={() => showPrevPage()}> <ArrowBackIosIcon /> Prev </Button>
                            }
                            {displaySubsetResults[displaySubsetResults.length - 1] !== results[results.length - 1] &&
                                <Button onClick={() => showNextPage()}> Next <ArrowForwardIosIcon />  </Button>
                            }
                        </div>
                    </div>
                }
                {/* Zero result display */}
                {results && results.length === 0 &&
                    <div className='resultBody resultEmptyBody'>
                        No results to display
                    </div>
                }
            </TabPanel>
                 {!dontShowPatients && <TabPanel value={tab} index={1}>
                {(patients && patients.length > 0) ?
                    <div>
                        {displaySubsetPatients && displaySubsetPatients.map((result) =>
                            <div key={result.id} className='resultGroup'>
                                <Paper className='resultElement' elevation={3}>
                                    <Link className='resultLink' to={`/patientOverview/${result.id}`}>
                                        <div className='resultHeader'>
                                            {`${result.name} ${result.family_name}`}

                                            <div className={`resultStatusHeader`}>
                                                Dob: {formatDateInternational(result.dob)}
                                            </div>
                                        </div>

                                        <div className='resultBody'>
                                            {result.email &&
                                                <div> <b>Email:</b> {result.email} </div>
                                            }

                                            {result.phone_number &&
                                                <div> <b>Phone Number:</b> {formatPhoneNumber(result.phone_number)} </div>
                                            }
                                        </div>
                                    </Link>
                                </Paper>
                            </div>)}

                        <div className='page-button-container'>
                            {currentPatientIndex !== 0 &&
                                <Button onClick={() => showPrevPagePatients()}> <ArrowBackIosIcon /> Prev </Button>
                            }
                            {patients && displaySubsetPatients[displaySubsetPatients.length - 1] !== patients[patients.length - 1] &&
                                <Button onClick={() => showNextPagePatients()}> Next <ArrowForwardIosIcon />  </Button>
                            }
                        </div>

                    </div>
                    :
                    <div className='resultBody resultEmptyBody'>
                        No patients to display

                    </div>
                }

            </TabPanel > }
            </Box>
        </div >
    );
}