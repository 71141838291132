import PatientOverview from "../components/PatientOverview/PatientOverview";
import PatientProfile from "../components/PatientProfile/PatientProfile";
import CreatePatient from "../components/CreatePatient/CreatePatient";
import Requests from "../components/Requests/Requests";
import Cases from "../components/Cases/Cases";
import Search from "../components/Search/Search";
import Settings from "../components/Settings/Settings";
import ViewRequests from "../components/Admin/ViewRequests";
import FileUpload from "../components/Settings/FileUpload";
import RequestDetails from "../components/Admin/RequestDetails";
import SettingsTabView from "../components/Settings/SettingsTabView";
import ChangePassword from "../components/Login/ChangePassword";
import Help from "../components/Help/Help";
import AddNewPatient from "../components/AddPatient/AddNewPatient";
import AdminStats from "../components/Stats/Overview/AdminStats";
import PaymentDetail from "../components/Stats/PaymentDetail/PaymentDetail";
import AmountOwed from "../components/Stats/AmountOwed/AmountOwed";
import AmountPaid from "../components/Stats/AmountPaid/AmountPaid";
import LastFiveTeleMedPatients from "../components/Stats/Overview/LastFiveTeleMedPatients";
import DoctorReports from "../components/Stats/Overview/DoctorReports";
import CombinedStats from "../components/Stats/CombinedStats/CombinedStats";
import CombinedStatsDetail from "../components/Stats/CombinedStats/CombinedStatsDetail";
import SuccessPasswordChange from "../components/Login/SuccessPasswordChange";
import OverviewStats from "../components/Stats/Overview/OverviewStats";
import Home from "../components/Home/Home";
import FaqNew from "../components/Faqs/FaqNew";

export const ROLES = {
    ADMIN: 'ADMIN',
    APPROVED_DOCTOR: 'APPROVED_DOCTOR',
    OPERATOR: 'OPERATOR',
    UNAPPROVED_DOCTOR: 'UNAPPROVED_DOCTOR',
    UNAPPROVED_OPERATOR: 'UNAPPROVED_OPERATOR'
};

export const routes = [
    {
        path: '/home',
        element: <Home />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN, ROLES.UNAPPROVED_DOCTOR, ROLES.OPERATOR, ROLES.UNAPPROVED_OPERATOR ]
    },
    {
        path: '/cases',
        element: <Cases />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN, ROLES.OPERATOR]
    },
    {
        path: '/patientOverview/:ticket',
        element: <PatientOverview />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN, ROLES.OPERATOR]
    },
    {
        path: '/viewChat/:ticket',
        element: <PatientOverview tabToShow="chat"/>,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN]
    },
    {
        path: '/search',
        element: <Search />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN]
    },
    {
        path: '/patientProfile/:userId',
        element: <PatientProfile />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN]
    },
    {
        path: '/createPatient',
        element: <CreatePatient />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN]
    },
    {
        path: '/settings',
        element: <Settings />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN, ROLES.UNAPPROVED_DOCTOR, ROLES.OPERATOR, ROLES.UNAPPROVED_OPERATOR]
    },
    {
        path: '/request',
        element: <Requests />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN, ROLES.UNAPPROVED_DOCTOR]
    },
    {
        path: '/viewRequests',
        element: <ViewRequests />,
        roles: [ROLES.ADMIN]
    },
    {
        path: '/createNewRequest',
        element: <FileUpload />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN, ROLES.UNAPPROVED_DOCTOR]
    },{
        path: '/viewRequestDetails/:reqId',
        element: <RequestDetails />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN]
    },
    {
        path: '/viewRequestHistoryDetails/:reqId',
        element: <RequestDetails page="history" />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN]
    },
    {
        path: '/viewDocDetails/:reqId',
        element: <RequestDetails page="docDetails" />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN]
    },
    {
        path: '/physicianProfile',
        element: <SettingsTabView />,
        roles: [ROLES.UNAPPROVED_DOCTOR]
    },
    {
        path: '/changePassword',
        element: <ChangePassword />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN, ROLES.OPERATOR]
    },
    {
        path: '/successPassword',
        element: <SuccessPasswordChange />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN]
    },
    {
        path: '/help',
        element: <Help />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.UNAPPROVED_DOCTOR, ROLES.ADMIN, ROLES.OPERATOR]
    },{
        path: '/addPatient',
        element: <AddNewPatient />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN]
    }
    ,{
        path: '/stats',
        element: <AdminStats />,
        roles: [ROLES.ADMIN]
    },{
        path: '/doctorStats/:doctorId',
        element: <OverviewStats />,
        roles: [ROLES.ADMIN]
    },{
        path: '/paymentDetail',
        element: <PaymentDetail />,
        roles: [ROLES.ADMIN]
    }
    ,{
        path: '/amountOwed',
        element: <AmountOwed />,
        roles: [ROLES.ADMIN]
    },
    {
        path: '/amountPaid',
        element: <AmountPaid />,
        roles: [ROLES.ADMIN]
    },{
        path: '/lastestTickets/:doctorId',
        element: <LastFiveTeleMedPatients />,
        roles: [ROLES.ADMIN]
    },
    {
        path: '/myReports',
        element: <DoctorReports />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN]
    },
    {
        path: '/overview',
        element: <CombinedStats />,
        roles: [ROLES.ADMIN]
    },
    {
        path: '/doctorReportsDetail',
        element: <LastFiveTeleMedPatients pageType="myReports" />,
        roles: [ROLES.ADMIN, ROLES.APPROVED_DOCTOR]
    },
    {
        path: '/overviewDetail',
        element: <CombinedStatsDetail pageType="overview" />,
        roles: [ROLES.ADMIN]
    },
    {
        path: '/faqs',
        element: <FaqNew />,
        roles: [ROLES.APPROVED_DOCTOR, ROLES.ADMIN, ROLES.UNAPPROVED_DOCTOR, ROLES.OPERATOR, ROLES.UNAPPROVED_OPERATOR ]
    },

];