import awsConfig from '../config/aws';
import { Auth } from 'aws-amplify';

Auth.configure(awsConfig);

//export let BASE_URL = "https://test-backend.drowl.com/drowlAWSAPI"

export let BASE_URL = "https://prd-backend.drowl.com/drowlAWSAPI"

export const getCurrentUserToken = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.accessToken.jwtToken;
    return token;
};

export const createUUID = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == "x" ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
};

export const getCurrentUserId = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const id = user.signInUserSession.idToken.payload.sub;
    return id;
};

export const getCognitoGroups = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.idToken.payload['cognito:groups'];
    return groups;
}

export const getUserSubId = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const id = user.attributes.sub;
    return id;
};

export const getHeader = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.accessToken.jwtToken;
    const headers = new Headers();
    headers.append('token', token);
    headers.append('Content-Type', 'application/json');
    return headers;
}
